import { configureStore } from '@reduxjs/toolkit';
import reducers from './reducers';
import storage from 'redux-persist/lib/storage';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  createTransform,
} from 'redux-persist';

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create a transform to exclude the 'token' property
const saveSubsetFilter = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState: any, key) => {
    // We want to exclude the 'token' property
    if (key === 'view') {
      const { showSubFamily, ...rest } = inboundState;
      return rest;
    }
    return inboundState;
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // Nothing to change when rehydrating
    return outboundState;
  },
  // Configuration
  { whitelist: ['view'] }, // Only apply to the user key
);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['skillLineData'],
  transforms: [saveSubsetFilter],
};

const persistedReducer = persistReducer(persistConfig, reducers);

// Automatically adds the thunk middleware and the Redux DevTools extension
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
