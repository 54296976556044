import {
  Box,
  ListItem,
  Modal,
  ModalContent,
  UnorderedList,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import LoginContext from '../../context/loginContext';
import { useContext, useState } from 'react';
import {
  CustomModalBody,
  CustomModalBottomTextSection,
  CustomModalButtonSection,
  CustomModalError,
  CustomModalHeader,
  CustomModalInput,
  CustomModalStartText,
} from '../../components/ModelTemplate/ModalTemplateComponents';
import { useTranslation } from 'react-i18next';
import { loginAPI } from '../../utils/helpers/axios';

export const Signup = ({ isOpen, setIsOpen, menuOnToggle, menuIsOpen }) => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const emailContext = useContext(LoginContext);
  const [showPasswordInfo, setShowPasswordInfo] = useState(false);
  const { formik, error, step, setError, setStep, secondStepSignupData } =
    emailContext;

  const { t, i18n } = useTranslation();

  const toast = useToast();

  const renderPasswordInfo = () => {
    return (
      <Box
        w={'207.75px'}
        height={'100px'}
        borderRadius={'5px'}
        padding={'15px'}
        backgroundColor={'#0A0A0A80'}
        display={'flex'}
        flexDirection={'column'}
        fontFamily={'PoppinsLight'}
        fontSize={'12px'}
        position={'absolute'}
        left={'120px'}
        top="-15px"
      >
        <Box lineHeight={'14.4px'}> {t('landing.password_contain')}</Box>
        <UnorderedList>
          <ListItem color={'white'} lineHeight={'14.4px'}>
            {t('landing.password_contain_characters')}
          </ListItem>
          <ListItem color={'white'} lineHeight={'14.4px'}>
            {t('landing.password_contain_other_rules')}
          </ListItem>
        </UnorderedList>
      </Box>
    );
  };

  const closeModal = () => {
    setIsOpen(false);
    menuIsOpen && menuOnToggle();
    setStep(1);
    setError(null);
  };

  const passwordInfoHandler = () => {
    setShowPasswordInfo(!showPasswordInfo);
  };

  const renderSetPassword = () => {
    return (
      <>
        <CustomModalHeader
          title={t('landing.set_password')}
          subTitle={t('landing.last_step_before_journey')}
        />
        <CustomModalError error={error} />
        <CustomModalBody error={error}>
          <CustomModalInput
            formik={formik}
            label={t('landing.new_password')}
            name={'password'}
            onMouseEnter={passwordInfoHandler}
            onMouseLeave={passwordInfoHandler}
            withInfoIcon={true}
            iconInfo={showPasswordInfo && renderPasswordInfo()}
            type={'password'}
            noForgotPassword={true}
          />
          <CustomModalButtonSection
            text={t('landing.lets_go')}
            width={isLargerThan1500 ? '157px' : '105px'}
            height={isLargerThan1500 ? '66px' : '50px'}
            onClick={() => {
              formik.handleSubmit();
            }}
          />
        </CustomModalBody>
      </>
    );
  };

  const renderGetCode = () => {
    return (
      <>
        <CustomModalHeader
          title={t('landing.sign_up')}
          subTitle={
            secondStepSignupData?.isCompany ? '' : t('landing.start_for_free')
          }
        />
        <CustomModalError error={error} />
        <CustomModalBody error={error}>
          {!error ? (
            <>
              <CustomModalStartText
                text={t('landing.verification_code_sent')}
              />
            </>
          ) : (
            <CustomModalStartText
              text={
                <>
                  {t('landing.verification_code_resend_part1')}{' '}
                  <Box
                    textDecoration={'underline'}
                    cursor={'pointer'}
                    as="span"
                    onClick={async () => {
                      try {
                        await loginAPI.post('/resendCode', {
                          email: secondStepSignupData?.email,
                          password: secondStepSignupData?.password,
                          language: i18n.language,
                          teamName: secondStepSignupData?.teamName,
                          teamEmail: secondStepSignupData?.teamEmail,
                          isSignup: true,
                        });

                        toast({
                          title: t('notifications.code_sent'),
                          status: 'success',
                          duration: 3000,
                          isClosable: true,
                        });
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    {t('landing.verification_code_resend_part2')}
                  </Box>{' '}
                  {t('landing.verification_code_resend_part3')}
                </>
              }
            />
          )}
          <Box
            display={'flex'}
            flexDirection={'column'}
            gap={isLargerThan1500 ? '10px' : '5px'}
            width={'100%'}
          >
            <CustomModalInput
              formik={formik}
              label={t('landing.verification_code')}
              name={'code'}
            />
            {secondStepSignupData?.isCompany ? null : (
              <CustomModalBottomTextSection
                text={
                  <>
                    {t('landing.by_clicking_agree')}{' '}
                    <Box as="span" textDecoration={'underline'}>
                      {t('footer.terms_of_service')}
                    </Box>{' '}
                    {t('landing.and')}{' '}
                    <Box as="span" textDecoration={'underline'}>
                      {t('footer.privacy_policy')}
                    </Box>
                    .
                  </>
                }
                linkHref={null}
                linkText={null}
              />
            )}
          </Box>

          <CustomModalButtonSection
            text={
              secondStepSignupData?.isCompany
                ? t('landing.start_only')
                : t('landing.agree_and_start')
            }
            width={isLargerThan1500 ? '395px' : '300px'}
            height={isLargerThan1500 ? '66px' : '50px'}
            onClick={() => {
              formik.handleSubmit();
            }}
          />
        </CustomModalBody>
      </>
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const renderDecider = () => {
    switch (step) {
      case 1:
        return renderGetCode();
      case 2:
        return renderSetPassword();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} blockScrollOnMount={false}>
      <ModalContent
        onKeyDown={handleKeyPress}
        width={isLargerThan1500 ? '598px' : '510px'}
        maxWidth={isLargerThan1500 ? '598px' : '510px'}
        background={'#0A0A0A80'}
        backdropFilter={'blur(20px)'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        fontFamily={'PoppinsLight'}
        borderRadius={'5px'}
        gap={isLargerThan1500 ? '30px' : '20px'}
      >
        {renderDecider()}
      </ModalContent>
    </Modal>
  );
};
