import { Box, HStack, Text, useMediaQuery } from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  headerBgCont,
  headerContainer,
  menuContainer,
  openStyle,
  wrapper,
} from './HeaderStyles';
import { logo } from '../../assets/svg/logo';
import { HamburgerIcon } from '../../assets/svg/HamburgerIcon';
import { SearchBar } from '../SearchBar/SearchBar';
import CircleIcon from '../../assets/svg/CircleIcon';
import { alphaIcon } from '../../../public/svg/alphaIcon';
import { Login } from '../../pages/login/Login';
import { useDispatch, useSelector } from 'react-redux';
import { setCardData, setSkillLineData } from '../../store/actions/cardActions';
import ResizeObserver from 'resize-observer-polyfill';
import { FETCH_USER_WITH_ALL_ATTRIBUTES } from '../../store/actions/types';
import LoginContext from '../../context/loginContext';
import { useTranslation } from 'react-i18next';
import { DemoForm } from '../DemoForm/DemoForm';
import { useLocation, useNavigate } from 'react-router-dom';
import { ForgotPasswordPlain } from '../../pages/login/ForgotPasswordPlain';
import { LoginFormPlain } from '../../pages/login/LoginFormPlain';
import { DemoFormPlain } from '../DemoForm/DemoFormPlain';
import { globalAPI, loginAPI } from '../../utils/helpers/axios';
import { UserPreferences } from '../UserPreferences/UserPreferences';
import DemoContext from '../../context/demoContext';
import { betaColor } from '../../assets/svg/betaColor';
import { useMobileDetect } from '../../utils/useMobile';
import { Signup } from '../../pages/signup/Signup';
import { SignupFormPlain } from '../../pages/signup/SignupFormPlain';
import { SkillsNavigator } from '../TimeMachine/SkillsNavigator';
import { MySkillsCard } from '../TimeMachine/MySkillsCard';
import GeneralDataContext from '../../context/generalDataContext';

export const Header = ({
  menuIsOpen,
  menuOnToggle,
  isDemoOpen,
  setIsDemoOpen,
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isUserPreferencesOpen, setIsUserPreferencesOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);
  const [alphaHovered, setAlphaHovered] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [height, setHeight]: any = useState(0);
  const [pauseAnimation, setPauseAnimation] = useState(false);
  const elementRef: any = useRef(null);
  const elementMenuOpenRef: any = useRef(null);
  const [results, setResults]: any = useState({});
  const { currentUser, view } = useSelector((state: any) => {
    return { currentUser: state.currentUser, view: state.view };
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const emailContext = useContext(LoginContext);
  const {
    showForgotPassword,
    setShowForgotPassword,
    setShowPassword,
    showPassword,
    setError,
    setErrors,
    isLoginOpen,
    setIsLoginOpen,
    closeToogle,
    setCloseToogle,
    error,
    passwordFailedAttempts,
    isSignUp,
    setIsSignup,
    setStep,
    step,
  } = emailContext;

  const demoContext = useContext(DemoContext);
  const { isSubmitted, error: demoError } = demoContext;

  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  const mouseLeaveTimer: any = useRef(null);
  const pauseAnimationTimer: any = useRef(null);
  const cancelSubmitedRef: any = useRef(null);
  const isMobile = useMobileDetect();
  const [blockMouseLeaveClose, setBlockMouseLeaveClose] = useState(false);

  const generalContext = useContext(GeneralDataContext);
  const {
    showSkillsNavigator,
    setShowSkillsNavigator,
    setShowMySkills,
    setShowMySkillsSkillsView,
    careerPathSkillsEditMode,
  } = generalContext;

  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();

  const navigate = useNavigate();

  useEffect(() => {
    if (closeToogle && menuIsOpen) {
      menuOnToggle();
      setIsTop(true);
      setCloseToogle(false);
    } else if (closeToogle) {
      setIsTop(true);
      setCloseToogle(false);
    }
  }, [closeToogle, menuIsOpen]);

  useEffect(() => {
    if (isSearchOpen) {
      setIsSearchOpen(false);
    }
  }, [menuIsOpen]);

  useEffect(() => {
    const handleScroll = (e) => {
      if (
        location.pathname !== '/' &&
        location.pathname !== '/privacy-policy'
      ) {
        return;
      }

      if (window.scrollY > 0 && isTop) {
        setIsTop(false);

        if ((isLoginOpen || isSignUp) && !menuIsOpen) {
          menuOnToggle();
        }
      } else if (window.scrollY <= 0 && !isTop) {
        setIsTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isTop, location, isLoginOpen, isSignUp, isDemoOpen]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const baseElement = document.getElementById('header-container-box');

      if (
        !menuIsOpen &&
        !isLoginOpen &&
        !isDemoOpen &&
        !isUserPreferencesOpen &&
        !elementRef.current
      ) {
        setHeight(baseElement?.getBoundingClientRect().height);
        return;
      }

      const heightSearch = (
        menuIsOpen ? elementMenuOpenRef : elementRef
      ).current?.getBoundingClientRect().height;

      const baseHeight = baseElement?.getBoundingClientRect().height;

      if (heightSearch) {
        setHeight(heightSearch + baseHeight);
      } else {
        setHeight(baseHeight);
      }
    });

    let element;

    if (!currentUser || menuIsOpen) {
      element = elementMenuOpenRef.current;
    } else if (elementRef.current) {
      element = elementRef.current;
    } else {
      element = elementMenuOpenRef.current;
    }

    if (element) {
      observer.observe(element);
    }

    // Cleanup observer on component unmount
    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [
    menuIsOpen,
    isTop,
    isLoginOpen,
    results,
    isDemoOpen,
    isUserPreferencesOpen,
  ]);

  useEffect(() => {
    if (isLoginOpen || isDemoOpen || isSignUp) {
      setTimeout(() => {
        setPauseAnimation(true);
      }, 500);
    }
  }, [isLoginOpen, isDemoOpen, isSignUp]);

  useEffect(() => {
    const getIsAdmin = async () => {
      const { data } = await globalAPI.post('/isAdmin', {
        userId: currentUser.attributes.user_id,
      });

      setIsAdmin(data);
    };

    if (currentUser) {
      getIsAdmin();
    }
  }, [currentUser]);

  const changedByResize = useRef(false);

  useEffect(() => {
    if (!isLargerThan756 && isTop) {
      setIsTop(false);
      changedByResize.current = true;
    } else if (isLargerThan756 && !isTop && changedByResize.current) {
      if (window.scrollY > 0) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }

      changedByResize.current = false;
    }
  }, [isLargerThan756, isTop]);

  const alphaText = !alphaHovered ? (
    <Box fontSize={'16px'} as="span">
      {t('landing.were_at_stage')}
    </Box>
  ) : (
    <Box fontSize={'16px'} as="span">
      <Box fontFamily={'PoppinsRegular'} as="span">
        beta
      </Box>{' '}
      {t('landing.bugs')}
    </Box>
  );

  const handleLoginAndOut = async () => {
    try {
      if (currentUser) {
        await loginAPI.post('/logout');
        navigate('/');
        dispatch({ type: FETCH_USER_WITH_ALL_ATTRIBUTES, payload: null });

        setTimeout(() => {
          menuOnToggle();
          setIsAdmin(false);
        }, 200);
      } else {
        if (isMobile) {
          navigate('/comingSoon');
          menuOnToggle();
          return;
        }
        setIsLoginOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const forYouClick = () => {
    if (location.pathname !== '/') {
      navigate('/');

      setTimeout(() => {
        const element = document.getElementById('for_you_section_landing_page');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 300);
    } else {
      const element = document.getElementById('for_you_section_landing_page');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  const forCompaniesClick = () => {
    if (location.pathname !== '/') {
      navigate('/');

      setTimeout(() => {
        const element = document.getElementById(
          'for_companies_section_landing_page',
        );

        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }, 300);
    } else {
      const element = document.getElementById(
        'for_companies_section_landing_page',
      );

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  const renderTopVersion = () => {
    return (
      <>
        <Box
          w={'100vw'}
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          transition={'all 1s ease'}
          position={'fixed'}
          maxWidth={'1450px'}
          top={'0px'}
        >
          <Box
            w={'fit-content'}
            paddingLeft={'40px'}
            cursor={'pointer'}
            onClick={() => {
              if (!currentUser && location.pathname !== '/') {
                navigate('/');
              } else if (currentUser) {
                navigate('/welcome');
              }
            }}
          >
            {logo(120, 40)}
          </Box>
          <Box
            color={'white'}
            display={'flex'}
            justifyContent={'flex-end'}
            paddingTop={'30px'}
            paddingBottom={'30px'}
            paddingRight={'35px'}
            fontSize={'16px'}
            fontFamily={'PoppinsRegular'}
            alignItems={'center'}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              fontFamily={'PoppinsExtraLight'}
              onMouseOver={() => setAlphaHovered(true)}
              onMouseLeave={() => setAlphaHovered(false)}
            >
              <Box
                whiteSpace={'nowrap'}
                overflowX={'scroll'}
                sx={{
                  scrollbarColor: 'transparent transparent',
                }}
                maxWidth={alphaHovered ? window.innerWidth - 660 : '1000px'}
                padding={'0px'}
                as="span"
              >
                {' '}
                {alphaText}
              </Box>
              {betaColor(alphaHovered)}
            </Box>

            {!currentUser && (
              <Box
                fontFamily={'PoppinsRegular'}
                fontSize={'16px'}
                onClick={forYouClick}
                cursor={'pointer'}
                marginLeft={'25px'}
              >
                {t('landing.for_you')}
              </Box>
            )}
            {!currentUser && (
              <Box
                fontFamily={'PoppinsRegular'}
                fontSize={'16px'}
                onClick={forCompaniesClick}
                cursor={'pointer'}
                marginLeft={'25px'}
              >
                {t('landing.for_companies')}
              </Box>
            )}
            {!currentUser && (
              <Box
                fontFamily={'PoppinsRegular'}
                fontSize={'16px'}
                onClick={() => {
                  setIsDemoOpen(true);
                  setIsLoginOpen(false);
                  setIsUserPreferencesOpen(false);
                  !menuIsOpen && menuOnToggle();
                }}
                cursor={'pointer'}
                marginLeft={'25px'}
              >
                {t('landing.get_a_demo')}
              </Box>
            )}
            <Box
              cursor={'pointer'}
              marginLeft={'25px'}
              fontFamily={'PoppinsRegular'}
              fontSize={'16px'}
              onClick={async () => {
                try {
                  if (!currentUser) {
                    setIsLoginOpen(true);
                    setIsDemoOpen(false);
                  } else {
                    await loginAPI.post('/logout');

                    dispatch({
                      type: FETCH_USER_WITH_ALL_ATTRIBUTES,
                      payload: null,
                    });
                  }
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {currentUser ? t('app.logout') : t('app.login')}
            </Box>
          </Box>
        </Box>
        {isLargerThan756 && (
          <Login
            isOpen={isLoginOpen && isTop}
            setIsOpen={setIsLoginOpen}
            menuOnToggle={menuOnToggle}
            menuIsOpen={menuIsOpen}
          />
        )}
        {isLargerThan756 && (
          <DemoForm
            isOpen={isDemoOpen && isTop && !isLoginOpen}
            setIsOpen={setIsDemoOpen}
            menuOnToggle={menuOnToggle}
            menuIsOpen={menuIsOpen}
            setIsDemoOpen={setIsDemoOpen}
          />
        )}
        {isLargerThan756 && (
          <Signup
            isOpen={isSignUp && isTop && !isLoginOpen && !isDemoOpen}
            setIsOpen={setIsSignup}
            menuOnToggle={menuOnToggle}
            menuIsOpen={menuIsOpen}
          />
        )}
      </>
    );
  };

  const getMenuHeight = () => {
    if (
      menuIsOpen &&
      !isDemoOpen &&
      !isLoginOpen &&
      !isUserPreferencesOpen &&
      !showForgotPassword &&
      !isSignUp
    ) {
      if (location?.pathname !== '/comingSoon') {
        if (isAdmin || !currentUser) {
          return '115px';
        }

        return '80px';
      }
      return '40px';
    } else if (menuIsOpen && isUserPreferencesOpen) {
      return '440px';
    } else if (menuIsOpen && isLoginOpen && !showForgotPassword) {
      if (showPassword) {
        return isLargerThan1500
          ? error
            ? passwordFailedAttempts >= 3
              ? '700px'
              : '650px'
            : '580px'
          : error
          ? passwordFailedAttempts >= 3
            ? '535px'
            : '530px'
          : '500px';
      }
      return isLargerThan1500
        ? error
          ? '510px'
          : '449px'
        : error
        ? '385px'
        : '340px';
    } else if (menuIsOpen && isDemoOpen) {
      if (isSubmitted) {
        return '200px';
      }
      return isLargerThan1500
        ? demoError
          ? '850px'
          : '770px'
        : demoError
        ? '650px'
        : '600px';
    } else if (menuIsOpen && showForgotPassword) {
      if (step === 1) {
        if (isLargerThan1500) {
          return error ? '370px' : '300px';
        } else {
          return error ? '310px' : '260px';
        }
      } else if (step === 2) {
        if (isLargerThan1500) {
          return error ? '440px' : '380px';
        } else {
          return error ? '350px' : '300px';
        }
      } else if (step === 3) {
        if (isLargerThan1500) {
          return error ? '390px' : '330px';
        } else {
          return error ? '300px' : '250px';
        }
      }
    } else if (menuIsOpen && isSignUp) {
      if (step === 1) {
        if (isLargerThan1500) {
          return error ? '400px' : '370px';
        } else {
          return error ? '380px' : '350px';
        }
      } else if (step === 2) {
        if (isLargerThan1500) {
          return error ? '370px' : '320px';
        } else {
          return error ? '330px' : '300px';
        }
      }
    } else {
      return '0px';
    }
  };

  return (
    <>
      {location.pathname !== '/main' &&
      location.pathname !== '/welcome' &&
      location.pathname !== '/comingSoon' &&
      isTop ? (
        renderTopVersion()
      ) : (
        <Box
          position={
            location.pathname === '/' || location.pathname === '/privacy-policy'
              ? 'fixed'
              : 'static'
          }
          padding={location.pathname === '/' ? '0px 30px' : 0}
          {...headerBgCont}
          transition={location.pathname === '/' ? 'all 1s ease' : 'none'}
          onClick={() => {
            dispatch(setSkillLineData(null));
            dispatch(setCardData(null));
          }}
          onMouseEnter={() => {
            if (isSubmitted) {
              setTimeout(() => {
                cancelSubmitedRef.current = true;
              }, 200);
            }

            if (blockMouseLeaveClose) {
              setTimeout(() => {
                setBlockMouseLeaveClose(false);
              }, 200);
            }

            if (mouseLeaveTimer.current) {
              clearTimeout(mouseLeaveTimer.current);
            }

            if (pauseAnimationTimer.current) {
              clearTimeout(pauseAnimationTimer.current);
            }
          }}
          onMouseLeave={() => {
            if (
              (isSubmitted && !cancelSubmitedRef.current) ||
              blockMouseLeaveClose
            ) {
              return;
            }
            cancelSubmitedRef.current = false;
            if (mouseLeaveTimer.current) {
              clearTimeout(mouseLeaveTimer.current);
            }
            if (pauseAnimationTimer.current) {
              clearTimeout(pauseAnimationTimer.current);
            }
            if (pauseAnimation) {
              pauseAnimationTimer.current = setTimeout(() => {
                setPauseAnimation(false);
              }, 300);
            }
            mouseLeaveTimer.current = setTimeout(() => {
              if (menuIsOpen) {
                menuOnToggle();
                setIsDemoOpen(false);
                setIsLoginOpen(false);
                setIsUserPreferencesOpen(false);
                setShowForgotPassword(false);
                setStep(1);
              }
            }, 500);
          }}
        >
          <Box {...wrapper}>
            <Box
              height={height + 'px'}
              width={'100%'}
              backdropFilter={'blur(10px)'}
              position={'absolute'}
              top="80px"
              borderRadius={'30px'}
            ></Box>
            <Box
              {...headerContainer}
              {...((menuIsOpen || isSearchOpen) && openStyle)}
              transition={
                !menuIsOpen && !isSearchOpen
                  ? 'border-radius 1s ease'
                  : 'border-radius 0.2s ease'
              }
              id="header-container-box"
            >
              <Box
                w={'fit-content'}
                cursor={'pointer'}
                onClick={() => {
                  if (!currentUser && location.pathname !== '/') {
                    navigate('/');
                  } else if (currentUser) {
                    navigate('/welcome');
                  }
                }}
              >
                {logo(100, 40)}
              </Box>
              <HStack flex="1" justifyContent="flex-end">
                <Box w={'full'} onFocus={() => menuIsOpen && menuOnToggle()}>
                  {/* //TODO change this later */}
                  {location?.pathname !== '/' &&
                  location?.pathname !== '/welcome' &&
                  location?.pathname !== '/comingSoon' &&
                  location?.pathname !== '/privacy-policy' ? (
                    <SearchBar
                      isOpen={isSearchOpen}
                      setter={setIsSearchOpen}
                      elementRef={elementRef}
                      results={results}
                      setResults={setResults}
                    />
                  ) : null}
                </Box>
                {menuIsOpen ? (
                  <Box
                    cursor={'pointer'}
                    onClick={() => {
                      setPauseAnimation(false);
                      setTimeout(() => {
                        menuOnToggle();
                        setIsLoginOpen(false);
                        setShowPassword(false);
                        setIsDemoOpen(false);
                        setIsUserPreferencesOpen(false);
                        setShowForgotPassword(false);
                        setError(null);
                        setErrors({});
                      }, 200);
                    }}
                  >
                    <CircleIcon />
                  </Box>
                ) : (
                  <Box
                    cursor={'pointer'}
                    onClick={() => {
                      menuOnToggle();
                      setIsLoginOpen(false);
                      setIsUserPreferencesOpen(false);
                      setIsDemoOpen(false);
                      setShowForgotPassword(false);
                      setError(null);
                      setShowPassword(false);
                      setErrors({});
                    }}
                  >
                    <HamburgerIcon w={'18px'} h={'18px'} />
                  </Box>
                )}
              </HStack>

              <Box
                height={getMenuHeight()}
                opacity={menuIsOpen ? 1 : 0}
                {...menuContainer}
                py={menuIsOpen && (!isDemoOpen || isLargerThan1500) ? 5 : 0}
                pb={menuIsOpen ? (isUserPreferencesOpen ? 0 : 10) : 0}
                ref={elementMenuOpenRef}
                backdropFilter={isUserPreferencesOpen ? 'blur(10px)' : 'none'}
                transition={pauseAnimation ? 'none' : 'all 0.5s ease'}
              >
                {menuIsOpen &&
                  !isLoginOpen &&
                  !isDemoOpen &&
                  !isUserPreferencesOpen &&
                  !isSignUp && (
                    <Box
                      display={'flex'}
                      w={'full'}
                      px={4}
                      color="white"
                      position={'relative'}
                      flexDir={'column'}
                      alignItems={'flex-end'}
                    >
                      {location?.pathname !== '/comingSoon' &&
                      location?.pathname !== '/' &&
                      location?.pathname !== '/welcome' &&
                      location?.pathname !== '/privacy-policy' &&
                      currentUser ? (
                        <Text
                          onClick={() => {
                            if (careerPathSkillsEditMode) {
                              return;
                            }
                            if (view.showSkills) {
                              setShowMySkillsSkillsView((prev) => !prev);
                              return;
                            }
                            setShowSkillsNavigator(!showSkillsNavigator);
                            setShowMySkills((prev) => !prev);
                          }}
                          cursor={'pointer'}
                        >
                          {t('app.your_skills')}
                        </Text>
                      ) : null}
                      {isAdmin && location?.pathname !== '/comingSoon' ? (
                        <Text
                          cursor={'pointer'}
                          onClick={() => {
                            const env = import.meta.env.VITE_ENVIRONMENT;
                            let link = '';

                            if (env === 'staging') {
                              link = import.meta.env.VITE_MANAGEMENT_API_TEST;
                            } else if (env === 'production') {
                              link = import.meta.env.VITE_MANAGEMENT_API_PROD;
                            } else {
                              link = import.meta.env.VITE_MANAGEMENT_API_LOCAL;
                            }

                            window.location.assign(`${link}`);
                          }}
                        >
                          {t('landing.management')}
                        </Text>
                      ) : null}
                      {location?.pathname !== '/comingSoon' && (
                        <Text
                          onClick={() => {
                            if (currentUser) {
                              setIsUserPreferencesOpen(!isUserPreferencesOpen);
                            } else {
                              forYouClick();
                            }
                          }}
                          cursor={'pointer'}
                          backdropBlur={'10px'}
                        >
                          {location.pathname !== '/' && currentUser
                            ? t('app.user_preferences')
                            : t('app.for_you')}
                        </Text>
                      )}
                      {!currentUser && location.pathname !== '/comingSoon' && (
                        <>
                          <Text
                            cursor={'pointer'}
                            backdropBlur={'10px'}
                            onClick={forCompaniesClick}
                          >
                            {t('landing.for_companies')}
                          </Text>
                          <Text
                            cursor={'pointer'}
                            onClick={() => {
                              setIsDemoOpen(true);
                              setIsLoginOpen(false);
                              setIsUserPreferencesOpen(false);
                            }}
                            backdropBlur={'10px'}
                          >
                            {t('landing.get_a_demo')}
                          </Text>
                        </>
                      )}
                      <Text onClick={handleLoginAndOut} cursor={'pointer'}>
                        {currentUser ? t('app.logout') : t('app.login')}
                      </Text>
                    </Box>
                  )}

                {isDemoOpen && !isLoginOpen && <DemoFormPlain setIsDemoOpen={setIsDemoOpen} />}

                {isLoginOpen ? (
                  !showForgotPassword ? (
                    <LoginFormPlain
                      setBlockMouseLeaveClose={setBlockMouseLeaveClose}
                    />
                  ) : (
                    <ForgotPasswordPlain />
                  )
                ) : null}

                {isUserPreferencesOpen && <UserPreferences />}
                {isSignUp && (
                  <SignupFormPlain
                    setBlockMouseLeaveClose={setBlockMouseLeaveClose}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
