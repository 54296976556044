import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  fonts: {
    PoppinsBold: `'PoppinsBold'`,
    PoppinsRegular: `'PoppinsRegular'`,
    PoppinsMedium: `'PoppinsMedium'`,
    PoppinsSemiBold: `'PoppinsSemiBold'`,
    PoppinsLight: `'PoppinsLight'`,
    PoppinsExtraLight: `'PoppinsExtraLight'`,
    PPNeueMachina: `'PPNeueMachina'`,
    PoppinsExtraBold: `'PoppinsExtraBold'`,
    PPNeuePlainBlack: `'PPNeuePlainBlack'`,
    PPNeuePlainBold: `'PPNeuePlainBold'`,
    PPNeueMachinaPlainLight: `'PPNeueMachinaPlainLight'`,
    PPNeueMachinaPlainMedium: `'PPNeueMachinaPlainMedium'`,
    PPNeueMachinaSemiBold: `'PPNeueMachinaSemiBold'`,
    PPNeueMachinaPlainThin: `'PPNeueMachinaPlainThin'`,
    PPNeueMachinaPlainUltrabold: `'PPNeueMachinaPlainUltrabold'`,
    PPNeueMachinaPlainUltralight: `'PPNeueMachinaPlainUltralight'`,
  },
})

export default theme