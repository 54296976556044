import { tree, synonymsEnglish, synonymsPortuguese } from './welcomeTree';
import i18next from 'i18next';
const distanceThreshold = 20;
const distanceThresholdTag = 2;
const commonWordsThreshold = 1;
const tagMatchThreshold = 1;

const getSynonyms = (word) => {
  let synonyms =
    i18next.language === 'pt' ? synonymsPortuguese : synonymsEnglish;

  for (let key in synonyms) {
    if (synonyms[key].includes(word)) {
      return [key, ...synonyms[key]];
    }
  }
  return [word];
};

const matchTagWithFuzzyLogic = (inputWords, tag) => {
  return inputWords.some(
    (word) =>
      expandWords(tag).includes(word) ||
      expandWords(tag).some((tagWord) => {
        return levenshtein(word, tagWord) <= distanceThresholdTag;
      }),
  );
};

const expandWords = (text) => {
  return text.toLowerCase().split(/\W+/).filter(Boolean).flatMap(getSynonyms);
};

const levenshtein = (a, b) => {
  let matrix: any = [];

  // Ensure lowercase comparison
  const lowerA = a.toLowerCase();
  const lowerB = b.toLowerCase();

  // Increment along the first column of each row
  for (let i = 0; i <= lowerA.length; i++) {
    matrix[i] = [i];
  }

  // Increment along the first row of each column
  for (let j = 0; j <= lowerB.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (let i = 1; i <= lowerA.length; i++) {
    for (let j = 1; j <= lowerB.length; j++) {
      if (lowerA[i - 1] === lowerB[j - 1]) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1, // deletion
        );
      }
    }
  }

  return matrix[lowerA.length][lowerB.length];
};

// Function to detect if an individual result is nonsensical
function isResultNonsensical(
  result,
  distanceThreshold,
  commonWordsThreshold,
  tagMatchThreshold,
) {
  if (result.tagMatchCount < tagMatchThreshold) {
    return false;
  }

  return (
    result.distance > distanceThreshold ||
    result.commonWordsCount < commonWordsThreshold ||
    result.tagMatchCount < tagMatchThreshold
  );
}

export const findTopMatches = (inputText) => {
  const inputWords = expandWords(inputText);

  const results = tree.map((option) => {
    const optionWords = expandWords(option.input);
    const commonWordsCount = inputWords.filter((word) =>
      optionWords.includes(word),
    ).length;

    // Fuzzy matching for tag detection
    const tagMatchCount = inputWords.filter((word) =>
      option.tags.some((tag) => matchTagWithFuzzyLogic(inputWords, tag)),
    ).length;

    const exactWordMatch = inputWords.some((word) => optionWords.includes(word))
      ? 1
      : 0;

    const distance = levenshtein(inputText, option.input);

    return {
      ...option,
      distance,
      commonWordsCount,
      tagMatchCount,
      exactWordMatch,
    };
  });

  let filteredResults = results.filter(
    (result) =>
      !isResultNonsensical(
        result,
        distanceThreshold,
        commonWordsThreshold,
        tagMatchThreshold,
      ),
  );

  filteredResults = filteredResults.sort((a, b) => {
    if (b.exactWordMatch !== a.exactWordMatch) {
      return b.exactWordMatch - a.exactWordMatch;
    }
    if (b.commonWordsCount !== a.commonWordsCount) {
      return b.commonWordsCount - a.commonWordsCount;
    }
    if (b.tagMatchCount !== a.tagMatchCount) {
      return b.tagMatchCount - a.tagMatchCount;
    }
    return a.distance - b.distance;
  });

  return filteredResults.slice(0, 3);
};
