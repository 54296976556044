import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const ComingSoonTooltip = ({
  mousePosition,
  top = '-30px',
  left = '50%',
  transform = `translateX(-50%)`,
  noInfo = false,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      position={'absolute'}
      fontFamily={'PoppinsRegular'}
      width={noInfo ? "130px" : '80px'}
      height={'20px'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      fontSize={'10px'}
      color={'black'}
      background={'#C5C5C5'}
      top={top}
      left={left}
      transform={transform}
      zIndex={20}
      whiteSpace={"nowrap"}
    >
      {!noInfo ? t('coming_soon.coming_soon') : t('coming_soon.no_info')}
    </Box>
  );
};
