export const loginGoogle = () => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '100%', height: '100%', cursor: 'pointer' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.64 9.20419C17.64 8.56601 17.5827 7.95237 17.4764 7.36328H9V10.8446H13.8436C13.635 11.9696 13.0009 12.9228 12.0477 13.561V15.8192H14.9564C16.6582 14.2524 17.64 11.9451 17.64 9.20419Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99976 18.0009C11.4298 18.0009 13.467 17.195 14.9561 15.8205L12.0475 13.5623C11.2416 14.1023 10.2107 14.4214 8.99976 14.4214C6.65567 14.4214 4.67158 12.8382 3.96385 10.7109H0.957031V13.0428C2.43794 15.9841 5.48158 18.0009 8.99976 18.0009Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.96409 10.7088C3.78409 10.1688 3.68182 9.59203 3.68182 8.99885C3.68182 8.40567 3.78409 7.82885 3.96409 7.28885V4.95703H0.957273C0.347727 6.17203 0 7.54658 0 8.99885C0 10.4511 0.347727 11.8257 0.957273 13.0407L3.96409 10.7088Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z"
        fill="#EA4335"
      />
    </svg>
  );
};
