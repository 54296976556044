import './App.css';
import { Box, ChakraProvider, useDisclosure } from '@chakra-ui/react';
import { Router, Route, Routes, BrowserRouter } from 'react-router-dom';
import history from './utils/history';
import { Login } from './pages/login/Login';
// import { TijubuHeader } from './components/Header/TijubuHeader';
import Fonts from './utils/fonts/Global';
import theme from './utils/fonts/Theme';
import { TijubuFooter } from './components/Footer/TijubuFooter';
import { Main } from './pages/main/Main';
import UserContext from './context/context';
import './config/i18n';
import { Header } from './components/Header/Header';
import { LandingPage } from './pages/landingPage/LandingPage';
import TijubuHome from './pages/welcomePage/TijubuHome';
import { LoginContextWrapper } from './context/loginContext';
import { DemoContextWrapper } from './context/demoContext';
import PrivateRoute from './utils/helpers/PrivateRoute';
import { NotFound } from './pages/errorPages/NotFound';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { defineLocation } from './store/actions/locationActions';
import { ComingSoon } from './pages/comingSoon/ComingSoon';
import { UserRefreshContextWrapper } from './context/userRefreshContext';
import { GeneralDataWrapper } from './context/generalDataContext';
import { PrivacyPolicy } from './pages/privacyPolicy/PrivacyPolicy';

function App() {
  const dispatch = useDispatch();
  const { isOpen: menuIsOpen, onToggle: menuOnToggle } = useDisclosure();
  const [isDemoOpen, setIsDemoOpen] = useState(false);

  useEffect(() => {
    if (
      window.location.pathname === '/welcome' ||
      window.location.pathname === '/main'
    ) {
      dispatch(defineLocation(window.location.pathname));
    }
  }, [window.location]);

  return (
    <>
      <UserContext.Provider value={{}}>
        <BrowserRouter>
          <ChakraProvider
            toastOptions={{ defaultOptions: { position: 'bottom-left' } }}
            theme={theme}
          >
            <Fonts />
            <GeneralDataWrapper>
              <DemoContextWrapper isDemoOpen={isDemoOpen}>
                <LoginContextWrapper>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                  >
                    <Header
                      menuIsOpen={menuIsOpen}
                      menuOnToggle={menuOnToggle}
                      isDemoOpen={isDemoOpen}
                      setIsDemoOpen={setIsDemoOpen}
                    />
                  </Box>

                  <UserRefreshContextWrapper>
                    <Box overflowY={'hidden'} p={0} m={0} transition={'none'}>
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <LandingPage
                              menuIsOpen={menuIsOpen}
                              menuOnToggle={menuOnToggle}
                              isDemoOpen={isDemoOpen}
                              setIsDemoOpen={setIsDemoOpen}
                            />
                          }
                        />
                        <Route
                          path="/main"
                          element={
                            <PrivateRoute>
                              <Main />
                            </PrivateRoute>
                          }
                        />
                        <Route
                          path="/welcome"
                          element={
                            <PrivateRoute>
                              <TijubuHome />
                            </PrivateRoute>
                          }
                        />
                        <Route path="/comingSoon" element={<ComingSoon />} />
                        <Route
                          path="/privacy-policy"
                          element={<PrivacyPolicy />}
                        />
                        <Route path="*" element={<NotFound />} />
                      </Routes>
                    </Box>
                  </UserRefreshContextWrapper>
                </LoginContextWrapper>
              </DemoContextWrapper>
              <TijubuFooter />
            </GeneralDataWrapper>
          </ChakraProvider>
        </BrowserRouter>
      </UserContext.Provider>
    </>
  );
}

export default App;
