import React, { useRef, useState, useEffect } from 'react';
import './segmentedCircleCss.css';

const SegmentedCircle = ({
  segmentSizes,
  segmentLabels,
  onClick,
  radius,
  clickedSection,
  setClickedSection,
  labelOffset,
  labelColor,
  subFamilies,
}) => {
  const gapSize =
    segmentLabels.length > 1
      ? Math.round(radius / (segmentSizes.length * 2))
      : 0; // Gap size between segments
  const totalCircumference = 2 * Math.PI * radius;
  const textOffset = -5; // Distance from the line to the text
  const lineStartOffset = 5; // Offset to start the line ahead of the segment's middle
  const middleAngleThreshold = Math.PI / 5; // Angle threshold (10 degrees in radians) around 90 and 270 degrees

  const polarToCartesian = (radius, angleInRadians) => {
    return {
      x: radius * Math.cos(angleInRadians),
      y: radius * Math.sin(angleInRadians),
    };
  };

  const generateSegments = (segmentSizes, segmentLabels, radius, gapSize) => {
    const segments: any = [];
    const totalSize = segmentSizes.reduce((a, b) => a + b, 0);
    const scaleFactor = totalCircumference / totalSize;

    let currentAngle = 0;

    segmentSizes.forEach((size, index) => {
      const isSub = subFamilies && subFamilies[index];
      const adjustedRadius = !isSub ? radius - 30 : radius;
      const segmentAngle = (size * scaleFactor) / radius;
      const startAngle = currentAngle;
      const endAngle = currentAngle + segmentAngle - gapSize / radius;

      const start = polarToCartesian(adjustedRadius, startAngle);
      const end = polarToCartesian(adjustedRadius, endAngle);

      const largeArcFlag = segmentAngle > Math.PI ? 1 : 0;

      const pathData = [
        `M ${start.x} ${start.y}`,
        `A ${adjustedRadius} ${adjustedRadius} 0 ${largeArcFlag} 1 ${end.x} ${end.y}`,
      ].join(' ');

      const labelAngle = (startAngle + endAngle) / 2;
      const labelRadius = adjustedRadius + labelOffset; // Distance from the circle to the label
      const labelPos = polarToCartesian(labelRadius, labelAngle);
      const lineStartPos = polarToCartesian(
        adjustedRadius + lineStartOffset,
        labelAngle,
      ); // Adjusted to include lineStartOffset
      const lineEndPos = polarToCartesian(
        adjustedRadius + labelOffset + textOffset,
        labelAngle,
      );

      // Determine textAnchor based on labelAngle and quadrant
      let textAnchor = 'middle';
      if (
        Math.abs(labelAngle - Math.PI / 2) < middleAngleThreshold ||
        Math.abs(labelAngle - (3 * Math.PI) / 2) < middleAngleThreshold
      ) {
        textAnchor = 'middle';
      } else if (labelAngle > Math.PI / 2 && labelAngle < (3 * Math.PI) / 2) {
        textAnchor = 'end';
      } else {
        textAnchor = 'start';
      }

      segments.push(
        <SegmentWithLabel
          key={index}
          pathData={pathData}
          clickedSection={clickedSection}
          setClickedSection={setClickedSection}
          onClick={onClick}
          index={index}
          segmentLabels={segmentLabels}
          labelPos={labelPos}
          lineStartPos={lineStartPos}
          lineEndPos={lineEndPos}
          labelColor={labelColor}
          textAnchor={textAnchor}
        />,
      );

      currentAngle += segmentAngle;
    });

    return segments;
  };

  return (
    <svg
      width="100vw"
      height="100vh"
      viewBox="0 0 600 600"
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        pointerEvents: 'none',
      }}
    >
      <defs>
        <filter x="-0.05" y="-0.05" width="1.1" height="1.1" id="solid">
          <feFlood flood-color="black" flood-opacity="0.5" result="bg" />
          <feMerge>
            <feMergeNode in="bg" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g transform={`translate(300, 300)`}>
        {generateSegments(segmentSizes, segmentLabels, radius, gapSize)}
      </g>
    </svg>
  );
};

const SegmentWithLabel = ({
  pathData,
  clickedSection,
  setClickedSection,
  onClick,
  index,
  segmentLabels,
  labelPos,
  lineStartPos,
  lineEndPos,
  labelColor,
  textAnchor,
}) => {
  const textRef: any = useRef(null);
  const [bbox, setBBox] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (textRef.current) {
      const textBBox = textRef.current.getBBox();
      setBBox(textBBox);
    }
  }, [segmentLabels, index]);

  // Adjust positioning of the rectangle based on the quadrant (left, right, top, or bottom)
  const adjustPosition = () => {
    let x = labelPos.x;
    let y = labelPos.y;

    // Center the rect horizontally if textAnchor is 'middle' (top or bottom)
    if (textAnchor === 'middle') {
      x = labelPos.x - bbox.width / 2;
    } else if (textAnchor === 'end') {
      // Shift rect to the left for text on the left side
      x = labelPos.x - bbox.width;
    }

    // Center the rect vertically around the text
    y = labelPos.y - bbox.height / 2;

    return { x, y };
  };

  const { x, y } = adjustPosition();

  return (
    <React.Fragment>
      <path
        id={`segment-${index}`}
        d={pathData}
        fill="none"
        stroke={clickedSection === index ? 'white' : '#F2F3F44D'}
        strokeWidth="5"
        strokeLinecap="round"
        onClick={() => {
          onClick(index);
          if (clickedSection === index) {
            setClickedSection(null);
          } else {
            setClickedSection(index);
          }
        }}
        style={{
          cursor: 'pointer',
          pointerEvents: 'auto',
        }}
      />

      {segmentLabels[index] ? (
        <>
          <line
            x1={lineStartPos.x}
            y1={lineStartPos.y}
            x2={lineEndPos.x}
            y2={lineEndPos.y}
            stroke={labelColor}
            strokeWidth="0.5"
            style={{
              transition: 'none !important',
            }}
          />

          {/* Background rectangle for the text */}
          <rect
            x={x - 5} // Add padding to the x-axis
            y={y - 2.5} // Add padding to the y-axis
            width={bbox.width + 10} // Add padding to the width
            height={bbox.height + 4} // Add padding to the height
            fill={'#0A0A0A26'}
            rx={5} // Rounded corners
            ry={5} // Rounded corners
            style={{ zIndex: 10 }}
          />

          {/* The text element */}
          <text
            ref={textRef}
            x={labelPos.x}
            y={labelPos.y}
            fill={labelColor}
            fontSize="10px"
            fontFamily="PoppinsSemiBold"
            textAnchor={textAnchor}
            dominantBaseline="middle"
            style={{
              zIndex: 20,
              position: 'relative',
              transition: 'none !important',
            }}
          >
            {segmentLabels[index]}
          </text>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default SegmentedCircle;
