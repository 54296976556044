import { CARD_DATA, SKILL_LINE_DATA } from './types';

const setCardData = (payload) => {
  return {
    type: CARD_DATA,
    payload,
  };
};

const setSkillLineData = (payload) => {
  return {
    type: SKILL_LINE_DATA,
    payload,
  };
};

export { setCardData, setSkillLineData };
