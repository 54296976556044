import { Box, FormControl, FormLabel, useMediaQuery } from '@chakra-ui/react';
import { TextInput } from '../../components/TextInput/TextInput';
import { alertIcon } from '../../assets/svg/alertIcon';
import { Cta } from '../../components/Button/Cta';
import LoginContext from '../../context/loginContext';
import { useContext } from 'react';
import {
  CustomPlainBody,
  CustomPlainError,
  CustomPlainHeader,
} from '../../components/ModelTemplate/PlainTemplate';
import {
  CustomModalButtonSection,
  CustomModalInput,
  CustomModalStartText,
} from '../../components/ModelTemplate/ModalTemplateComponents';
import { useTranslation } from 'react-i18next';

export const ForgotPasswordPlain = () => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const loginContext = useContext(LoginContext);
  const { formik, step, error } = loginContext;

  const { t } = useTranslation();

  const renderGetEmailStep = () => {
    return (
      <CustomModalInput
        label={t('user_preferences.email')}
        name={'email'}
        formik={formik}
      />
    );
  };

  const renderConfirmCodeStep = () => {
    return (
      <>
        <CustomModalStartText
          text={t('landing.verification_code_sent')}
          styles={{ width: '75%', alignSelf: 'baseline' }}
        />

        <CustomModalInput
          formik={formik}
          label={t('landing.verification_code')}
          name={'code'}
        />
      </>
    );
  };

  const renderSetPasswordStep = () => {
    return (
      <>
        <FormControl>
          <FormLabel
            color={'white'}
            htmlFor="password"
            fontFamily={'PoppinsLight'}
            fontSize={isLargerThan1500 ? '18px' : '14px'}
          >
            {t('landing.new_password')}
          </FormLabel>
          <TextInput
            onChange={formik.handleChange}
            props={{
              name: 'password',
              value: formik.values.password,
              type: 'password',
              noForgot: true,
            }}
            variation="login"
          />
        </FormControl>
      </>
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box
        onKeyDown={handleKeyPress}
        width={isLargerThan1500 ? '610px' : isLargerThan756 ? '462px' : '300px'}
        maxWidth={
          isLargerThan1500 ? '550px' : isLargerThan756 ? '462px' : '300px'
        }
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        fontFamily={'PoppinsLight'}
        gap={isLargerThan1500 ? '30px' : '20px'}
        textAlign={'left'}
      >
        <CustomPlainHeader
          title={
            step < 3
              ? t('landing.forgot_password')
              : t('landing.reset_password')
          }
          subTitle={t('landing.got_your_back')}
        />
        <CustomPlainError error={error} />
        <CustomPlainBody>
          {step === 1 && renderGetEmailStep()}
          {step === 2 && renderConfirmCodeStep()}
          {step === 3 && renderSetPasswordStep()}
          <CustomModalButtonSection
            onClick={() => {
              formik.handleSubmit();
            }}
            text={t('landing.lets_go')}
            width={isLargerThan1500 ? '157px' : '105px'}
            height={isLargerThan1500 ? '66px' : '50px'}
          />
        </CustomPlainBody>
      </Box>
    </Box>
  );
};
