import { skillLevelCounter } from '../../assets/SkillLevelCounter';
import { Box, HStack, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// SkillHexagonRating component
export const SkillHexagonRating = ({
  item,
  onScoreChange,
  initialScore = 0,
  disabled = false,
  levels = [],
  pinkOutline = false,
}) => {
  const [score, setScore] = useState(initialScore ? initialScore : 1);
  const [hoverScore, setHoverScore] = useState(0);

  const { t } = useTranslation();

  const handleScoreChange = (newScore) => {
    setScore(newScore);
    onScoreChange(newScore, item);
  };

  useEffect(() => {
    if (initialScore === 1) {
      setScore(1);
    } else {
      setScore(initialScore);
    }
  }, [initialScore]);

  return (
    <HStack gap={0}>
      {levels.map((level: any, index) => (
        <Tooltip
          backdropFilter={'blur(25px)'}
          background={'#0A0A0A33'}
          maxW={'150px'}
          label={
            <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
              <Box fontFamily={'PoppinsBold'} fontSize={'10px'}>
                {level?.name}
              </Box>
              <Box fontFamily={'PoppinsLight'} fontSize={'10px'}>
                {level?.description}
              </Box>
            </Box>
          }
        >
          <Box
            onMouseOver={() => {
              if (disabled) return;
              setHoverScore(index + 1);
            }}
            onMouseLeave={() => {
              if (disabled) return;
              setHoverScore(0);
            }}
            key={index + 1}
            onClick={(e) => {
              if (disabled) return;
              e.stopPropagation();
              handleScoreChange(index + 1);
            }}
          >
            {skillLevelCounter(
              score >= index + 1,
              true,
              hoverScore >= index + 1,
              hoverScore > index + 1,
              pinkOutline ? hoverScore >= index + 1 : false,
            )}
          </Box>
        </Tooltip>
      ))}
    </HStack>
  );
};
