export const theSelf = (isLargerThan576, isLargerThan756, isLargerThan992) => {
  return (
    <svg
      width={isLargerThan992 ? '30' : isLargerThan756 ? '25' : '20'}
      height={isLargerThan992 ? '30' : isLargerThan756 ? '25' : '20'}
      viewBox="0 0 43 43"
      style={{
        marginLeft: isLargerThan992
          ? '27px'
          : isLargerThan756
          ? '22px'
          : '15px',
          marginTop: isLargerThan992
          ? '11px'
          : isLargerThan756
          ? '6px'
          : '5px',
      }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="21.5"
        cy="21.5"
        r="21.5"
        fill="url(#paint0_linear_1973_2851)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1973_2851"
          x1="-2.69927"
          y1="1.62703"
          x2="54.4356"
          y2="42.3147"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
