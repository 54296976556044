import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { careersAPI, globalAPI } from '../utils/helpers/axios';

const GeneralDataContext = React.createContext<any>(undefined as any);

const GeneralDataWrapper = ({ children }: { children: React.ReactNode }) => {
  const [families, setFamilies] = useState([]);
  const [roles, setRoles] = useState([]);
  const [familyElements, setFamilyElements] = useState([]);
  const [mySkills, setMySkills]: any = useState(null);
  const [updateMySkills, setUpdateMySkills] = useState(false);
  const [skills, setSkills]: any = useState([]);
  const [showSkillsNavigator, setShowSkillsNavigator] = useState(false);
  const [showMySkills, setShowMySkills] = useState(false);
  const [showMySkillsSkillsView, setShowMySkillsSkillsView] = useState(false);
  const [lastAddedSkill, setLastAddedSkill] = useState(null);
  const [companySkillDefaults, setCompanySkillDefaults] = useState([]);
  const [careerPathSkillsEditMode, setCareerPathSkillsEditMode] =
    useState(false);
  const [userLearnings, setUserLearnings] = useState([]);
  const [updateUserLearnings, setUpdateUserLearnings] = useState(false);

  const { currentUser, view } = useSelector((state: any) => {
    return { currentUser: state.currentUser, view: state.view };
  });

  const { showSkills, showFamily, showSemi, showSubFamily, showProgressions } =
    view;

  useEffect(() => {
    setCareerPathSkillsEditMode(false);
    setLastAddedSkill(null);
    setShowMySkills(false);
    setShowMySkillsSkillsView(false);
    setShowSkillsNavigator(false);
  }, [showSkills, showFamily, showSemi, showSubFamily, showProgressions]);

  useEffect(() => {
    const getFamilies = async () => {
      try {
        const { data } = await careersAPI.post('/getFamiliesByCompany', {
          companyId: currentUser.attributes.company_id,
        });

        const families = data.families.filter((family) => {
          return family.parent_family_id === null;
        });

        const subFamilies = data.families.filter((family) => {
          return family.parent_family_id !== null;
        });

        const elements = families.map((family) => {
          const subFamilies = data.families.filter((subFamily) => {
            return subFamily.parent_family_id === family.family_id;
          });

          //IT NEEDS TO HAVE A UNDEFINED WHEN NO SUBFAMILIES OR THE DRAWING WILL BE WRONG FOR SOME REASON
          return {
            id: family.family_id,
            value: family.roles.length,
            color: 'rgba(255, 255, 255, 0.5)',
            title: family.label,
            subFamilies: subFamilies.map((subFamily) => subFamily.family_id)
              .length
              ? subFamilies.map((subFamily) => subFamily.family_id)
              : [undefined],
          };
        });

        setFamilyElements(elements);
        setFamilies(data.families);
      } catch (err) {
        console.log(err, 'FAMILIES');
      }
    };

    if (currentUser) {
      getFamilies();
    }
  }, [currentUser]);

  useEffect(() => {
    const getRoles = async () => {
      try {
        const { data } = await careersAPI.post('/getRoles', {
          companyId: currentUser.attributes.company_id,
          byCompany: true,
          //withFullSkills: true,
          withOrgGroups: true,
        });

        let serializedRoles = data.roles.map((role: any) => {
          const roleFamily: any = families.find((family: any) => {
            return (
              family.roles.includes(role.role_id) && !family.parent_family_id
            );
          });

          const subFamily: any = families.find((family: any) => {
            return (
              family.roles.includes(role.role_id) && family.parent_family_id
            );
          });

          const jobLevelRange = [0, 0];

          for (let org of role.orgGroups) {
            if (org.job_level_range[0] < jobLevelRange[0]) {
              jobLevelRange[0] = org.job_level_range[0];
            }

            if (org.job_level_range[1] > jobLevelRange[1]) {
              jobLevelRange[1] = org.job_level_range[1];
            }
          }

          //calculate the average of all skill levels
          let total = 0;
          for (let skill of role.skill_level) {
            total += skill.level;
          }

          return {
            id: role.label,
            family: roleFamily?.label,
            family_id: roleFamily?.family_id,
            subfamily_id: subFamily?.family_id,
            subFamily: subFamily?.label,
            role_id: role.role_id,
            jobLevelRange,
            skills: role.skill_level.map((skill: any) => {
              const skillFound = skills.find((s: any) => {
                return s.skill_id === skill.skill_id;
              });

              return {
                name: skillFound?.label,
                level: skill.level,
                skill_id: skill.skill_id,
              };
            }),
            roleLevel: total / role.skill_level.length,
            rolePurpose: role.description,
            accountabilities: role.accountabilities,
            company_id: role.company_id,
            open_opportunities: role.open_opportunities,
          };
        });

        setRoles(serializedRoles);
      } catch (err) {
        console.log(err);
      }
    };

    if (
      families &&
      families?.length &&
      skills &&
      skills?.length &&
      currentUser
    ) {
      getRoles();
    }
  }, [families, currentUser, skills]);

  useEffect(() => {
    const getUserSkills = async () => {
      try {
        const { data } = await careersAPI.post('/getUserSkills', {
          userId: currentUser?.attributes?.user_id,
        });

        setMySkills(data);
      } catch (err) {
        console.log(err);
      }
    };

    if (currentUser) {
      getUserSkills();
    }
  }, [updateMySkills, currentUser]);

  useEffect(() => {
    const getSkills = async () => {
      try {
        const { data } = await careersAPI.post('/getCompanySkills', {
          companyId: currentUser?.attributes?.company_id,
        });

        setSkills(data);
      } catch (err) {
        console.log(err);
      }
    };

    if (currentUser) {
      getSkills();
    }
  }, [currentUser]);

  useEffect(() => {
    const getCompanySkillDefaults = async () => {
      try {
        const { data } = await globalAPI.post('/getCompanySkillLevelDefault', {
          companyId: currentUser?.attributes?.company_id,
        });

        setCompanySkillDefaults(data);
      } catch (err) {
        console.log(err);
      }
    };

    if (currentUser) {
      getCompanySkillDefaults();
    }
  }, [currentUser]);

  useEffect(() => {
    const getUserLearnings = async () => {
      try {
        const { data } = await careersAPI.post('/getUserLearnings', {
          userId: currentUser?.attributes?.user_id,
        });

        setUserLearnings(data);
      } catch (err) {
        console.log(err);
      }
    };

    if (currentUser) {
      getUserLearnings();
    }
  }, [updateUserLearnings, currentUser]);

  return (
    <GeneralDataContext.Provider
      value={{
        families,
        setFamilies,
        familyElements,
        setFamilyElements,
        roles,
        mySkills,
        setUpdateMySkills,
        skills,
        showSkillsNavigator,
        setShowSkillsNavigator,
        showMySkills,
        setShowMySkills,
        lastAddedSkill,
        setLastAddedSkill,
        companySkillDefaults,
        showMySkillsSkillsView,
        setShowMySkillsSkillsView,
        careerPathSkillsEditMode,
        setCareerPathSkillsEditMode,
        setUpdateUserLearnings,
        userLearnings,
      }}
    >
      {children}
    </GeneralDataContext.Provider>
  );
};

export { GeneralDataWrapper };

export default GeneralDataContext;
