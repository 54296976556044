import React, { useEffect, useRef, useState } from 'react';
import { loginAPI } from '../utils/helpers/axios';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_USER_WITH_ALL_ATTRIBUTES } from '../store/actions/types';
import { fetchUserWithAllAttributes } from '../store/actions/usersActions';
import { useNavigate } from 'react-router-dom';

const UserRefreshContext = React.createContext<any>(undefined as any);

const UserRefreshContextWrapper = ({ children }) => {
  const { currentUser, location } = useSelector((state: any) => {
    return { currentUser: state.currentUser, location: state.location };
  });

  const [checkingUser, setCheckingUser] = useState(true);
  const [canRenderLandingPage, setCanRenderLandingPage] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const checkUserFromToken: any = useRef(false);

  useEffect(() => {
    const checkUser = async () => {
      try {
        const { data } = await loginAPI.get('/sessionExpired');
        if (data.email) {
          //@ts-ignore
          dispatch(fetchUserWithAllAttributes(data.user_id, null, null));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          setCheckingUser(false);
        }, 1000);

        checkUserFromToken.current = true;
      }

      checkUserFromToken.current = true;
    };

    if (!checkUserFromToken.current) {
      checkUser();
    }
  }, []);

  useEffect(() => {
    if (currentUser && !checkingUser) {
      if (
        window.location.pathname === '/welcome' ||
        window.location.pathname === '/'
      ) {
        navigate('/welcome');
      }
    }

    if (!checkingUser) {
      setCanRenderLandingPage(true);
    }
  }, [currentUser, checkingUser]);

  return (
    <UserRefreshContext.Provider value={{ checkingUser, canRenderLandingPage }}>
      {children}
    </UserRefreshContext.Provider>
  );
};

export { UserRefreshContextWrapper };

export default UserRefreshContext;
