import {
  INTERNAL_SIGN_OUT,
  FETCH_USER_WITH_ALL_ATTRIBUTES,
} from "../actions/types";

/**
 * reducer for user fetching action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const fetchUserReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_USER_WITH_ALL_ATTRIBUTES:
      return action.payload;
    case INTERNAL_SIGN_OUT:
      return null;
    default:
      return state;
  }
};

export { fetchUserReducer };
