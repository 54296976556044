export const Refresh = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9802 7.05944C14.5302 6.04857 13.8093 5.18184 12.8974 4.55509C11.9855 3.92834 10.918 3.56597 9.81295 3.50806C8.70793 3.45015 7.60841 3.69894 6.63597 4.22694C6.13075 4.50125 5.67005 4.84537 5.2664 5.24711"
        stroke="#F4F3F2"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2.92281 4.63681L3.41364 6.76289C3.53787 7.30102 4.07482 7.63655 4.61295 7.51232L6.73903 7.02149"
        stroke="#F4F3F2"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.01865 11.9407C4.46872 12.9515 5.18957 13.8183 6.1015 14.445C7.01343 15.0718 8.08091 15.4341 9.18594 15.492C10.291 15.5499 11.3905 15.3012 12.3629 14.7732C12.8238 14.5229 13.2477 14.2146 13.625 13.8574"
        stroke="#F4F3F2"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.0752 14.3637L15.5844 12.2376C15.4602 11.6995 14.9232 11.3639 14.3851 11.4882L12.259 11.979"
        stroke="#F4F3F2"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
