export const primary = {
  bg: 'black',
  border: '1px solid black',
  borderRadius: '5px',
  color: 'white',
};

export const primaryHover = {
  bg: 'white',
  color: 'black',
  border: '2px solid black',
};

export const secondary = {
  bg: 'blue',
};

export const noHover = {
  border: '1.5px solid white',
};

export const login = {
  bg: '#F4F3F2',
  color: 'white',
};
