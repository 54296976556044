export const laptop = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 20H3C2.44772 20 2 19.5523 2 19V18C2 17.4477 2.44772 17 3 17H21C21.5523 17 22 17.4477 22 18V19C22 19.5523 21.5523 20 21 20H20.5"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M3 13V16C3 16.5523 3.44772 17 4 17H20C20.5523 17 21 16.5523 21 16V6C21 5.44772 20.5523 5 20 5H4C3.44772 5 3 5.44772 3 6V9"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M14 5.5L10 5.5"
        stroke="white"
        stroke-width="2.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
