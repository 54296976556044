export const dropdownStyles = {
  container: (baseStyles) => {
    return {
      ...baseStyles,
      width: '100%',
      border: '0.5px solid #F4F3F2',
      borderRadius: '5px',
      height: '30px',
      maxHeight: '30px',
    };
  },
  input: (baseStyles) => {
    return {
      ...baseStyles,
      color: 'white',
      fontSize: '12px',
    };
  },
  control: (baseStyles) => {
    return {
      ...baseStyles,
      height: '30px',
      maxHeight: '30px',
      minHeight: '30px',
    };
  },
  inputContainer: (baseStyles) => {
    return {
      ...baseStyles,
      color: 'white',
      fontSize: '12px',
    };
  },
  valueContainer: (baseStyles) => {
    return {
      ...baseStyles,
      color: 'white',
      fontSize: '12px',
    };
  },
  option: (baseStyles) => {
    return {
      ...baseStyles,
      width: '100%',
      bg: 'transparent',
      _hover: {
        bg: '#3399FF',
      },
      fontSize: '12px',
    };
  },
  menuList: (baseStyles) => {
    return {
      ...baseStyles,
      border: '0.5px solid #F4F3F2',
      borderRadius: '8px',
      gap: 0,
      bg: 'black',
      color: 'white',
      maxHeight: '100px',
      fontSize: '12px',
      zIndex: 2
    };
  },
  dropdownIndicator: (baseStyles) => {
    return {
      ...baseStyles,
      background: 'transparent',
      paddingLeft: '0px',
      paddingRight: '9px',
      color: 'white',
      height: "30px"
    };
  },
  indicatorSeparator: (baseStyles) => {
    return { ...baseStyles, display: 'none' };
  },
  clearIndicator: (baseStyles) => {
    return { ...baseStyles, color: 'white', height: "30px", marginRight: '0px' };
  },
};
