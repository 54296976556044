import {
  Box,
  Flex,
  Grid,
  GridItem,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  barCont,
  innerScroll,
  label,
  scrollContainer,
} from './TimeMachineStyles';
import GeneralDataContext from '../../context/generalDataContext';
import { skillLevelCounter } from '../../assets/SkillLevelCounter';
import { useTranslation } from 'react-i18next';
import { arrowDirectional } from '../../assets/svg/arrowDirectional';
import { SkillHexagonRating } from '../common/SkillHexagonRating';
import { careersAPI } from '../../utils/helpers/axios';
import { useSelector } from 'react-redux';
import { closeIcon } from '../../assets/svg/closeIcon';
import ScrollableTextBox from '../common/ScrollableTextBox';
import { addIcon } from '../../assets/svg/addIcon';
import { logoUdemy } from '../../assets/svg/logoUdemy';
import { logoIese } from '../../assets/svg/logoIese';
import { Refresh } from '../../assets/svg/Refresh';
import { maskStyle } from './TimeMachine.service';
import GraphContext from '../../context/context';

export const MySkillsCard = () => {
  const [hoveredLabel, setHoveredLabel] = useState('');
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [isLargerThan2500] = useMediaQuery('(min-width: 2500px)');
  const [refreshLearnings, setRefreshLearnings] = useState(false);

  const [currentSkill, setCurrentSkill]: any = useState(null);
  const [skillToEdit, setSkillToEdit]: any = useState(null);
  const [learnings, setLearnings] = useState([]);

  const [hasOverflowSkillSelf, setHasOverflowSkillSelf] = useState(false);
  const [scrollPositionSkillSelf, setScrollPositionSkillSelf] = useState(0);
  const [hasOverflowMacroSkillSelf, setHasOverflowMacroSkillSelf] =
    useState(false);
  const [scrollPositionMacroSkillSelf, setScrollPositionMacroSkillSelf] =
    useState(0);

  const graphContext = useContext(GraphContext);
  const generalData = useContext(GeneralDataContext);

  const hideSubSkillsTimerRef: any = useRef(null);

  const {
    mySkills,
    skills,
    setUpdateMySkills,
    lastAddedSkill,
    companySkillDefaults,
    setUpdateUserLearnings,
    userLearnings,
  } = generalData;

  const { setSelectedSkillForGraphFilter } = graphContext;

  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });

  const macroSkills: any = useMemo(() => {
    if (!mySkills) return [];
    let skillsToRenderAsMacro: any = [];
    let alreadyAddedSkills: any = [];

    for (let skill of mySkills) {
      if (skill.parent_skill_id === 0 || !skill.parent_skill_id) {
        skillsToRenderAsMacro.push(skill);
        alreadyAddedSkills.push(skill.skill_id);
        continue;
      }

      const macro = skills.find((s) => s.skill_id === skill.parent_skill_id);
      if (macro && !alreadyAddedSkills.includes(macro.skill_id)) {
        skillsToRenderAsMacro.push(macro);
        alreadyAddedSkills.push(macro.skill_id);
      }
    }

    return skillsToRenderAsMacro;
  }, [mySkills]);

  const subSkillsForSelectedSkill = useMemo(() => {
    if (!currentSkill) return [];

    return mySkills.filter(
      (skill) => skill.parent_skill_id === currentSkill.skill_id,
    );
  }, [currentSkill, mySkills]);

  const { t } = useTranslation();

  const renderSubSkillLabel = (skill) => {
    return (
      <Tooltip
        label={skill?.description}
        background={'#0A0A0A33'}
        backdropFilter={'blur(25px)'}
        placement="bottom-end"
        fontFamily={'PoppinsRegular'}
        fontSize={'12px'}
      >
        <Box
          fontFamily={
            skillToEdit?.skill_id === skill.skill_id
              ? 'PoppinsBold'
              : 'PoppinsRegular'
          }
          fontSize={'14px'}
          {...label}
          display={'flex'}
          alignItems={'center'}
        >
          <ScrollableTextBox
            text={skill?.label.trim().toLowerCase()}
            maxW={'calc(100vw * 0.1)'}
          />
        </Box>
      </Tooltip>
    );
  };

  const renderSubSkillsPointer = (currentSkill, subSkills) => {
    if (!hoveredLabel) return null;

    return subSkills.length && hoveredLabel === currentSkill.label ? (
      <Box marginLeft={'5px'}>
        {arrowDirectional({
          transform: '',
          color: '#F4D444',
        })}
      </Box>
    ) : null;
  };

  const onSkillLevelSave = async (level) => {
    try {
      await careersAPI.post('/updateUserSkills', {
        userId: currentUser.attributes.user_id,
        skill: { ...skillToEdit, level },
        isUpdate: true,
      });

      setSkillToEdit(null);
      setUpdateMySkills((prev) => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  const onSkillDelete = async (skill) => {
    try {
      await careersAPI.post('/updateUserSkills', {
        userId: currentUser.attributes.user_id,
        skill,
        isDelete: true,
      });

      setSkillToEdit(null);
      setUpdateMySkills((prev) => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (lastAddedSkill) {
      const macro = skills.find(
        (s) => s.skill_id === lastAddedSkill.parent_skill_id,
      );

      if (macro) {
        setCurrentSkill(macro);
      } else {
        const skill = skills.find(
          (s) => s.skill_id === lastAddedSkill.skill_id,
        );

        if (skill.parent_skill_id === 0) {
          setCurrentSkill(skill);
          return;
        }

        const macro = skills.find((s) => s.skill_id === skill.parent_skill_id);
        setCurrentSkill(macro);
      }
    }
  }, [lastAddedSkill]);

  useEffect(() => {
    const getLearnings = async () => {
      if (currentSkill) {
        const { data } = await careersAPI.post(`/getLearning`, {
          companyId: currentUser.attributes.company_id,
          skillId: skillToEdit.skill_id,
          skill_level: skillToEdit.level,
        });

        setLearnings(data);
      }
    };

    if (!skillToEdit) {
      setLearnings([]);
      return;
    }

    getLearnings();
  }, [skillToEdit]);

  const getTwoRandomIndexes = (array) => {
    if (!array.length) return [0, 0];
    if (array.length === 1) return [0, 0];
    if (array.length === 2) return [0, 1];

    const firstIndex = Math.floor(Math.random() * array.length);
    let secondIndex;

    // Keep generating the second index until it's different from the first
    do {
      secondIndex = Math.floor(Math.random() * array.length);
    } while (secondIndex === firstIndex);

    return [firstIndex, secondIndex];
  };

  const learningsToRender = useMemo(() => {
    if (!learnings) return [];
    let filtered = learnings.filter((learning: any) => {
      return !userLearnings.find(
        (userLearning) => userLearning.id === learning.id,
      );
    });

    if (!filtered.length) return [];
    if (filtered.length === 1) return filtered;

    let indexes = [0, 1];

    if (filtered.length > 2) {
      indexes = getTwoRandomIndexes(filtered);
    }

    return [filtered[indexes[0]], filtered[indexes[1]]];
  }, [refreshLearnings, learnings, userLearnings]);

  useEffect(() => {
    const checkOverflow = () => {
      const element: any = document.getElementById('scroll-box-2-self'); // Your Box selector
      const element2: any = document.getElementById('scroll-box-macro-self'); // Your Box selector
      const isOverflowing = element?.scrollHeight > element?.clientHeight;
      const isOverflowing2 = element2?.scrollHeight > element2?.clientHeight;
      setHasOverflowSkillSelf(isOverflowing);
      setHasOverflowMacroSkillSelf(isOverflowing2);
    };

    // Check overflow when the roles change or component mounts
    checkOverflow();

    // Add event listeners for resize and scroll
    const element: any = document.getElementById('scroll-box-2-self');
    const element2: any = document.getElementById('scroll-box-macro-self');
    const handleScroll = () => {
      setScrollPositionSkillSelf(element?.scrollTop);
      setScrollPositionMacroSkillSelf(element2?.scrollTop);
    };
    if (element) {
      element?.addEventListener('scroll', handleScroll);
    }

    if (element2) {
      element2?.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('resize', checkOverflow);

    // Cleanup
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }

      if (element2) {
        element2.removeEventListener('scroll', handleScroll);
      }

      window.removeEventListener('resize', checkOverflow);
    };
  }, [subSkillsForSelectedSkill, learningsToRender]);

  return (
    <Box
      borderRadius={'5px'}
      position={'relative'}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box
        {...scrollContainer(
          isLargerThan1300,
          isLargerThan1500,
          isLargerThan2500,
        )}
        left={0}
        // position={'absolute'}
        bottom={0}
        py="5"
        width={'auto'}
        marginLeft={'30px'}
        marginRight={'0px'}
        backdropFilter={'blur(25px)'}
        background={'#0A0A0A33'}
        height={'350px'}
        minWidth={'210px'}
        marginBottom={'0px'}
      >
        <Box
          fontSize={'16px'}
          w={'fit-content'}
          textAlign={'left'}
          fontFamily={'PoppinsBold'}
        >
          {t('app.your_skills')}
        </Box>
        <Grid
          display="inline-flex"
          gap={'20px'}
          width="auto"
          overflowY={'hidden'}
          flexGrow={1}
          overflowX={'visible'}
        >
          <GridItem overflow={'hidden'} minW={'150px'}>
            <Box
              {...innerScroll}
              borderLeft={'1px solid white'}
              h={'fit-content !important'}
              maxH={'80%'}
              display={'flex'}
              flexDirection={'column'}
              gap={'10px'}
              onWheel={(e) => {
                e.stopPropagation();
              }}
              id="scroll-box-macro-self"
              style={{
                maskImage: maskStyle(
                  'scroll-box-macro-self',
                  hasOverflowMacroSkillSelf,
                  scrollPositionMacroSkillSelf,
                ),
                WebkitMaskImage: maskStyle(
                  'scroll-box-macro-self',
                  hasOverflowMacroSkillSelf,
                  scrollPositionMacroSkillSelf,
                ),
              }}
            >
              {macroSkills?.length > 0 ? (
                macroSkills.map((skill: any, index) => {
                  if (skill.name) skill.label = skill.name;

                  let subSkills = [];
                  if (hoveredLabel) {
                    const hoveredSkill = skills.find(
                      (skill) => skill.label === hoveredLabel,
                    );
                    subSkills = mySkills.filter(
                      (skill) =>
                        skill.parent_skill_id === hoveredSkill.skill_id,
                    );
                  }

                  return (
                    <Flex
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                      w={'100%'}
                      cursor={'pointer'}
                      onMouseEnter={() => {
                        skill?.label && setHoveredLabel(skill?.label);
                        if (hideSubSkillsTimerRef.current) {
                          clearTimeout(hideSubSkillsTimerRef.current);
                        }
                        hideSubSkillsTimerRef.current = setTimeout(() => {
                          setSkillToEdit(null);
                          setCurrentSkill(null);
                        }, 750);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (hideSubSkillsTimerRef.current) {
                          clearTimeout(hideSubSkillsTimerRef.current);
                        }
                        setCurrentSkill(skill);
                        setSelectedSkillForGraphFilter(skill);

                        if (!subSkills.length && hoveredLabel === skill.label) {
                          setSkillToEdit(skill);
                        }
                      }}
                      key={`${skill?.role_id}-${index}`}
                    >
                      <Box
                        {...barCont}
                        id={`${skill.skill_id}`}
                        cursor={'pointer'}
                        _hover={{
                          div: { background: 'rgba(255,255,255,1)' },
                        }}
                      ></Box>

                      <Box
                        fontFamily={'PoppinsRegular'}
                        fontSize={'14px'}
                        {...label}
                        display={'flex'}
                        alignItems={'center'}
                      >
                        {renderSubSkillLabel(skill)}
                      </Box>

                      {renderSubSkillsPointer(skill, subSkills)}
                      {!subSkills.length && hoveredLabel === skill.label ? (
                        <Box as="span" marginLeft={'5px'} display={'flex'}>
                          {skillToEdit?.skill_id === skill.skill_id ? (
                            <Box display={'flex'} alignItems={'center'}>
                              <SkillHexagonRating
                                item={skill}
                                onScoreChange={onSkillLevelSave}
                                initialScore={skill.level}
                                levels={companySkillDefaults}
                                pinkOutline={true}
                              />
                              <Box
                                onClick={async (e) => {
                                  e.stopPropagation();
                                  await onSkillDelete(skill);
                                }}
                                as="span"
                              >
                                {closeIcon(23, 21)}
                              </Box>
                            </Box>
                          ) : (
                            companySkillDefaults.map((level, index) => {
                              return (
                                <Tooltip
                                  backdropFilter={'blur(25px)'}
                                  background={'#0A0A0A33'}
                                  maxW={'150px'}
                                  label={
                                    <Box
                                      display={'flex'}
                                      flexDirection={'column'}
                                      gap={'10px'}
                                    >
                                      <Box
                                        fontFamily={'PoppinsBold'}
                                        fontSize={'10px'}
                                      >
                                        {level?.name}
                                      </Box>
                                      <Box
                                        fontFamily={'PoppinsLight'}
                                        fontSize={'10px'}
                                      >
                                        {level?.description}
                                      </Box>
                                    </Box>
                                  }
                                >
                                  <Box>
                                    {skillLevelCounter(
                                      skill.level >= index + 1,
                                      false,
                                      false,
                                      false,
                                    )}
                                  </Box>
                                </Tooltip>
                              );
                            })
                          )}
                        </Box>
                      ) : null}
                      {skill?.skill_id === lastAddedSkill?.skill_id ? (
                        <Box
                          as="span"
                          fontFamily={'PoppinsLight'}
                          fontSize={'10px'}
                          marginLeft={'5px'}
                        >
                          new
                        </Box>
                      ) : null}
                    </Flex>
                  );
                })
              ) : (
                <></>
              )}
            </Box>
          </GridItem>
          {subSkillsForSelectedSkill?.length ? (
            <GridItem
              overflow={'hidden'}
              width={'fit-content'}
              onMouseEnter={() => {
                if (hideSubSkillsTimerRef.current) {
                  clearTimeout(hideSubSkillsTimerRef.current);
                }
              }}
            >
              <Box display={'flex'} flexDirection={'column'} height={'100%'}>
                <Box
                  {...innerScroll}
                  borderLeft={skills.length > 8 ? '1px solid white' : ''}
                  h={'fit-content !important'}
                  maxH={'80%'}
                  onWheel={(e) => {
                    e.stopPropagation();
                  }}
                  id="scroll-box-2-self"
                  style={{
                    maskImage: maskStyle(
                      'scroll-box-2-self',
                      hasOverflowSkillSelf,
                      scrollPositionSkillSelf,
                    ),
                    WebkitMaskImage: maskStyle(
                      'scroll-box-2-self',
                      hasOverflowSkillSelf,
                      scrollPositionSkillSelf,
                    ),
                  }}
                >
                  {subSkillsForSelectedSkill?.length > 0 ? (
                    subSkillsForSelectedSkill.map((skill: any, index) => {
                      if (skill.name) skill.label = skill.name;

                      return (
                        <Flex
                          justifyContent={'flex-start'}
                          alignItems={'center'}
                          w={'100%'}
                          cursor={'pointer'}
                          key={`${skill?.role_id}-${index}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            if (skillToEdit?.skill_id === skill.skill_id) {
                              setSkillToEdit(null);
                              return;
                            }
                            setSkillToEdit(skill);
                            setSelectedSkillForGraphFilter(skill);
                          }}
                        >
                          <Box
                            {...barCont}
                            id={`${skill.skill_id}`}
                            cursor={'pointer'}
                            _hover={{
                              div: { background: 'rgba(255,255,255,1)' },
                            }}
                          ></Box>
                          {renderSubSkillLabel(skill)}
                          <Box as="span" marginLeft={'5px'} display={'flex'}>
                            {skillToEdit?.skill_id === skill.skill_id ? (
                              <Box display={'flex'} alignItems={'center'}>
                                <SkillHexagonRating
                                  item={skill}
                                  onScoreChange={onSkillLevelSave}
                                  initialScore={skill.level}
                                  levels={companySkillDefaults}
                                  pinkOutline={true}
                                />
                                <Box
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    await onSkillDelete(skill);
                                  }}
                                  as="span"
                                >
                                  {closeIcon(23, 21)}
                                </Box>
                              </Box>
                            ) : (
                              companySkillDefaults.map((level, index) => {
                                return (
                                  <Tooltip
                                    backdropFilter={'blur(25px)'}
                                    background={'#0A0A0A33'}
                                    maxW={'150px'}
                                    label={
                                      <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        gap={'10px'}
                                      >
                                        <Box
                                          fontFamily={'PoppinsBold'}
                                          fontSize={'10px'}
                                        >
                                          {level?.name}
                                        </Box>
                                        <Box
                                          fontFamily={'PoppinsLight'}
                                          fontSize={'10px'}
                                        >
                                          {level?.description}
                                        </Box>
                                      </Box>
                                    }
                                  >
                                    <Box>
                                      {skillLevelCounter(
                                        skill.level >= index + 1,
                                        false,
                                        false,
                                        false,
                                      )}
                                    </Box>
                                  </Tooltip>
                                );
                              })
                            )}
                          </Box>
                          {skill?.skill_id === lastAddedSkill?.skill_id ? (
                            <Box
                              as="span"
                              fontFamily={'PoppinsLight'}
                              fontSize={'10px'}
                              marginLeft={'5px'}
                            >
                              new
                            </Box>
                          ) : null}
                        </Flex>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </Box>
                {learningsToRender.length ? (
                  <Box
                    height={'50% !important'}
                    border={'2px solid #F4F3F21A'}
                    borderRadius={'5px'}
                    h={'fit-content !important'}
                    padding={'15px'}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={'15px'}
                    width={'225px'}
                  >
                    <Box
                      fontFamily={'PoppinsRegular'}
                      fontSize={'14px'}
                      textAlign={'left'}
                    >
                      suggested learnings
                    </Box>
                    <Box
                      maxH={'100px'}
                      overflowY={'auto'}
                      boxSizing={'border-box'}
                    >
                      {learnings
                        .filter((learning: any) => {
                          return !userLearnings.find(
                            (userLearning) => userLearning.id === learning.id,
                          );
                        })
                        .map((learning: any) => (
                          <Box
                            fontFamily={'PoppinsRegular'}
                            fontSize={'8px'}
                            textAlign={'left'}
                            display={'flex'}
                            alignItems={'center'}
                            lineHeight={'18px'}
                            gap={'15px'}
                          >
                            <Box>
                              {learning?.learning_host === 'udemy'
                                ? logoUdemy()
                                : logoIese()}
                            </Box>
                            <Box display={'flex'} flexDirection={'column'}>
                              <Box display={'flex'}>
                                <Box lineHeight={'18px'}>
                                  <ScrollableTextBox
                                    text={learning?.skill?.label}
                                    maxW={'50px'}
                                    styles={{ marginLeft: '0px' }}
                                  />{' '}
                                </Box>
                                |
                                <Box lineHeight={'18px'}>
                                  <ScrollableTextBox
                                    text={`level ${learning?.skill_level?.map(
                                      (level, index) => {
                                        return `${
                                          index > 0 ? ',' : ''
                                        }${level}`;
                                      },
                                    )}`}
                                    maxW={'80px'}
                                    styles={{
                                      marginLeft: '3px',
                                      marginRight: '3px',
                                    }}
                                  />{' '}
                                </Box>
                                |
                                <Box lineHeight={'18px'}>
                                  {' '}
                                  <ScrollableTextBox
                                    text={learning?.type}
                                    maxW={'40px'}
                                    styles={{ marginLeft: '3px' }}
                                  />
                                </Box>
                              </Box>
                              <Box
                                fontSize={'10px'}
                                fontFamily={'PoppinsExtraBold'}
                                lineHeight={'18px'}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(learning.link);
                                }}
                                cursor={'pointer'}
                              >
                                <ScrollableTextBox
                                  text={learning?.title}
                                  maxW={'130px'}
                                  styles={{ marginLeft: '0px' }}
                                />
                              </Box>
                            </Box>
                            <Box
                              onClick={async () => {
                                try {
                                  await careersAPI.post('/addLearningToUser', {
                                    userId: currentUser.attributes.user_id,
                                    learningId: learning?.id,
                                  });

                                  setUpdateUserLearnings((prev) => !prev);
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                              cursor={'pointer'}
                            >
                              {addIcon()}
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                ) : null}
                {learningsToRender.length ? (
                  <Flex
                    cursor={'pointer'}
                    w={'full'}
                    justifyContent={'center'}
                    pt={3}
                    onClick={() => {
                      setRefreshLearnings((prev) => !prev);
                    }}
                  >
                    <Refresh />
                  </Flex>
                ) : null}
              </Box>
            </GridItem>
          ) : null}
        </Grid>
      </Box>
    </Box>
  );
};
