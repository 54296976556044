import { DEFINE_VIEW } from '../actions/viewActions';

const initialState = {
  showSemi: false,
  showSkills: false,
  showFamily: false,
};

const viewReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFINE_VIEW:
      return {
        ...state,
        ...action.payload,
        progressionsData: action.payload.progressionsData
          ? {
              ...action.payload.progressionsData,
            }
          : undefined,
      };
    default:
      return state;
  }
};

export { viewReducer };
