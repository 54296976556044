import { Box, useMediaQuery } from '@chakra-ui/react';
import {
  footerContainerStyles,
  footerInnerContainerStyles,
  iconButtonContainerStyles,
  iconButtonStyles,
  pageButtonStyles,
  privacyAndTermsContainerStyles,
} from './TijubuFooterStyles';
import { tijubuLogo } from '../../../public/svg/tijubuLogo';
import {
  onPolicyClick,
  onTijubuLogoClick,
  onTermsClick,
} from './TijubuFooter.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ComingSoonTooltip } from '../ComingSoon/ComingSoonTooltip';
import { useTranslation } from 'react-i18next';

export const TijubuFooter = () => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [isLargerThan700] = useMediaQuery('(min-width: 700px)');
  const [isLargerThan440] = useMediaQuery('(min-width: 440px)');
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showComingSoonTooltip, setShowComingSoonTooltip]: any = useState(null);

  // const location = window.location.pathname;
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMousePosition = (event: any) => {
    const boxRect = event.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: event.clientX - boxRect.left - 55,
      y: event.clientY - boxRect.top - 35,
    });
  };

  const comingSoonTooltip = () => {
    return <ComingSoonTooltip mousePosition={mousePosition} />;
  };

  return (
    <Box
      position={
        location.pathname === '/' ||
        location.pathname === '/comingSoon' ||
        location.pathname === '/privacy-policy'
          ? 'relative'
          : 'absolute'
      }
      bg={location.pathname === '/' ? 'black' : 'transparent'}
      {...footerContainerStyles}
    >
      <Box>
        <Box {...footerInnerContainerStyles}>
          <Box {...iconButtonContainerStyles(isLargerThan1500)}>
            <Box onClick={onTijubuLogoClick} {...iconButtonStyles}>
              {tijubuLogo(isLargerThan700)}
            </Box>
            © {new Date().getFullYear()}
            {t('footer.rights_reserved')}
          </Box>
          {isLargerThan440 ? (
            <Box {...privacyAndTermsContainerStyles}>
              <Box
                onClick={onTermsClick}
                {...pageButtonStyles(isLargerThan1500)}
                position={'relative'}
                onMouseEnter={(event) => {
                  setShowComingSoonTooltip('terms');
                  handleMousePosition(event);
                }}
                onMouseLeave={() => {
                  setShowComingSoonTooltip(null);
                }}
              >
                {isLargerThan700
                  ? t('footer.terms_of_service')
                  : t('footer.terms')}
                {showComingSoonTooltip === 'terms' && comingSoonTooltip()}
              </Box>
              <Box
                onClick={() => {
                  onPolicyClick(navigate);
                }}
                {...pageButtonStyles(isLargerThan1500)}
                position={'relative'}
              >
                {isLargerThan700
                  ? t('footer.privacy_policy')
                  : t('footer.privacy')}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
