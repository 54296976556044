import { CARD_DATA, SKILL_LINE_DATA } from '../actions/types';

/**
 * reducer for user fetching action
 * @param {Object} state
 * @param {Object} action
 * @returns {Object}
 */
const cardsDataReducer = (state = null, action) => {
  switch (action.type) {
    case CARD_DATA:
      return action.payload;
    default:
      return state;
  }
};

const skillLineDataReducer = (state = null, action) => {
  switch (action.type) {
    case SKILL_LINE_DATA:
      return action.payload;
    default:
      return state;
  }
};

export { cardsDataReducer, skillLineDataReducer };
