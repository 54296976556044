import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { loginAPI } from '../utils/helpers/axios';
import { fetchUserWithAllAttributes } from '../store/actions/usersActions';
import { useToast } from '@chakra-ui/react';

const LoginContext = React.createContext<any>(undefined as any);

const LoginContextWrapper: React.FC<any> = ({ children }) => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const [error, setError]: any = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [isSignUp, setIsSignup] = useState(false);
  const [isItReallyYou, setIsItReallyYou] = useState(false);
  const [passwordFailedAttempts, setPasswordFailedAttempts] = useState(0);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [closeToogle, setCloseToogle] = useState(false);
  const [secondStepSignupData, setSecondStepSignupData] = useState<any>(null);
  const [forgotPasswordToken, setForgotPasswordToken] = useState(null);

  const toast = useToast();

  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });
  const loginRef = useRef(false);

  const validateLogin = yup.object().shape({
    email: yup
      .string()
      .email(t('login.email_format'))
      .required(t('login.required_email')),
    password: yup
      .string()
      .min(4, t('login.shortpw'))
      .max(20, t('login.bigpw'))
      .required(t('login.required_pw')),
  });

  const validateOnlyEmail = yup.object().shape({
    email: yup
      .string()
      .email(t('login.email_format'))
      .required(t('login.required_email')),
  });

  const validateForgotPasswordEmail = yup.object().shape({
    email: yup
      .string()
      .email(t('login.email_format'))
      .required(t('login.required_email')),
  });

  const validateForgotPasswordCode = yup.object().shape({
    code: yup
      .string()
      .length(6, 'Code must be 6 characters')
      .required('Code is required'),
  });

  const validateForgotPasswordPassword = yup.object().shape({
    password: yup
      .string()
      .min(4, t('login.shortpw'))
      .max(20, t('login.bigpw'))
      .required(t('login.required_pw')),
  });

  const chooseValidationSchema = () => {
    if (showForgotPassword) {
      if (step === 1) {
        return validateForgotPasswordEmail;
      } else if (step === 2) {
        return validateForgotPasswordCode;
      } else {
        return validateForgotPasswordPassword;
      }
    } else if (isSignUp) {
      if (step === 1) {
        return validateForgotPasswordCode;
      } else if (step === 2) {
        return validateForgotPasswordPassword;
      }
    } else if (isItReallyYou) {
      return validateForgotPasswordCode;
    } else if (showPassword) {
      return validateLogin;
    } else {
      return validateOnlyEmail;
    }
  };

  const validatePassword = (str) => {
    // Regular expression: At least 8 characters, includes at least one number or symbol
    const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9!@#$%^*_+\-]).{8,}$/;

    return pattern.test(str);
  };

  const checkOldPassword = async (email, oldPassword) => {
    try {
      await loginAPI.post('/login', {
        email: email,
        password: oldPassword,
      });

      return true;
    } catch (error) {
      return false;
    }
  };

  const savePassword = async (email, oldPassword, newPassword) => {
    const isOldPasswordCorrect = await checkOldPassword(
      secondStepSignupData?.email,
      secondStepSignupData?.oldPassword,
    );

    if (isOldPasswordCorrect) {
      if (!validatePassword(newPassword)) {
        setError(t('errors.invalid_password'));
        return;
      }

      if (newPassword) {
        try {
          await loginAPI.put(`/changePassword/${null}`, {
            password: newPassword,
            email: email,
            oldPassword: oldPassword,
          });

          return true;
        } catch (error) {
          return;
        }
      } else {
        return;
      }
    } else {
      setError(t('errors.old_password_mismatch'));
      return;
    }
  };

  const verifyCode = async (email, code, token, noDelete) => {
    try {
      const { data } = await loginAPI.post('/verifySignupCode', {
        email,
        code,
        token,
        noDelete,
      });

      if (data !== 'Invalid code') {
        return true;
      } else {
        setError(t('errors.incorrect_code'));
        return;
      }
    } catch (err) {
      console.log(err);
      setError(t('errors.incorrect_code'));
      return;
    }
  };

  const startPasswordRecovery = async (values) => {
    try {
      const { data } = await loginAPI.post('/startPasswordRecovery', {
        email: values.email,
        language: language,
      });

      if (data === 'method_not_allowed') {
        setError(t('errors.method_not_allowed'));
        return false;
      }

      setForgotPasswordToken(data.token);

      return true;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  const finishPasswordRecovery = async (values) => {
    try {
      await loginAPI.post('/finishPasswordRecovery', {
        email: values.email,
        code: values.code,
        token: forgotPasswordToken,
        newPassword: values.password,
        language: language,
      });

      return true;
    } catch (err) {
      console.log(err);
      return;
    }
  };

  useEffect(() => {
    if (!isLoginOpen) {
      formik.resetForm();
    }
  }, [isLoginOpen]);

  useEffect(() => {
    if (currentUser && loginRef.current) {
      loginRef.current = false;
      setIsLoginOpen(false);
      formik.resetForm();
      setCloseToogle(true);
      navigate('/welcome');
    }
  }, [currentUser]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      code: '',
      rememberMe: false,
    },
    validationSchema: chooseValidationSchema(),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (step === 3 && showForgotPassword) {
          const outcome = await finishPasswordRecovery(values);

          if (outcome) {
            setStep(1);
            setShowForgotPassword(false);
            setError(null);
            setForgotPasswordToken(null);
            setIsLoginOpen(true);
            formik.setFieldValue('password', '');
            formik.setFieldValue('code', '');

            toast({
              title: t('notifications.password_saved'),
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }

          return;
        } else if (showForgotPassword) {
          let outcome;
          if (step === 1) {
            outcome = await startPasswordRecovery(values);
          } else {
            outcome = await verifyCode(
              values.email,
              values.code,
              forgotPasswordToken,
              true,
            );
          }

          if (outcome) {
            setStep(step + 1);
            setError(null);
          }

          return;
        }

        if (step === 1 && isSignUp) {
          const outcome = await verifyCode(
            secondStepSignupData.email,
            values.code,
            secondStepSignupData.token,
            false,
          );

          if (outcome) {
            setStep(2);
            setError(null);
          }

          return;
        }

        if (step === 2 && isSignUp) {
          const outcome = await savePassword(
            secondStepSignupData.email,
            secondStepSignupData.oldPassword,
            values.password,
          );

          if (outcome) {
            toast({
              title: t('notifications.password_saved'),
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
            setIsSignup(false);
            setIsLoginOpen(true);
            formik.setValues({
              ...formik.values,
              email: secondStepSignupData.email,
              password: '',
            });
            setSecondStepSignupData(null);
            setStep(1);
          }

          return;
        }

        if (passwordFailedAttempts >= 3) {
          return;
        }

        const { email, password } = values;

        // await loginAPI.post('/createLoginAttempt', {
        //   email,
        // });

        const { data } = await loginAPI.post('/internalLogin', {
          email,
          password,
        });

        if (data?.user) {
          dispatch(
            // @ts-ignore
            fetchUserWithAllAttributes(
              data?.user?.data?.attributes.user_id,
              null,
              null,
            ),
          );

          // await loginAPI.post('/updateLoginAttempt', {
          //   email,
          // });

          loginRef.current = true;

          setError(null);
        } else {
          if (passwordFailedAttempts + 1 >= 3) {
            setTimeout(() => {
              setPasswordFailedAttempts(0);
            }, 60000);
            setError(t('landing.password_wrong_3_times'));
            setPasswordFailedAttempts(passwordFailedAttempts + 1);
            return;
          }
          setPasswordFailedAttempts(passwordFailedAttempts + 1);
          setError(t('landing.wrong_credentials'));
        }
      } catch (err: any) {
        if (
          err?.response?.data?.message === 'invalid_login' ||
          err?.response?.data?.message === 'not found'
        ) {
          if (passwordFailedAttempts + 1 >= 3) {
            setTimeout(() => {
              setPasswordFailedAttempts(0);
            }, 60000);
            setError(t('landing.password_wrong_3_times'));
            setPasswordFailedAttempts(passwordFailedAttempts + 1);
            return;
          }
          setError(t('landing.wrong_credentials'));
          setPasswordFailedAttempts(passwordFailedAttempts + 1);
        } else {
          setError(t('landing.ambiguous_error'));
        }
      }
    },
  });

  useEffect(() => {
    const handleMicrosoftLogin = async () => {
      try {
        const { data }: any = await loginAPI.post(
          '/authenticateMicrosoftTijubu',
          {
            code,
            place:
              import.meta.env.VITE_ENVIRONMENT === 'production'
                ? 'tijubu'
                : 'yasmin',
          },
        );

        if (data?.id) {
          dispatch(
            // @ts-ignore
            fetchUserWithAllAttributes(data?.id, null, null),
          );

          loginRef.current = true;
        }
      } catch (err: any) {
        if (err?.response?.data === 'invalid_user') {
          setTimeout(() => {
            setIsLoginOpen(true);
            setError(t('errors.credentials_error'));
          }, 750);
        } else {
          setTimeout(() => {
            setIsLoginOpen(true);
            setError(t('errors.something_wrong'));
          }, 750);
        }
      }
    };

    const code = query.get('code');
    if (code) {
      query.delete('code');
      query.delete('state');
      query.delete('session_state');
      navigate(
        {
          pathname: location.pathname,
          search: query.toString(),
        },
        { replace: true },
      );
      handleMicrosoftLogin();
    }
  }, [query]);

  useEffect(() => {
    if (Object.keys(formik.errors).length && formik.errors.email) {
      setError(t('errors.invalid_email'));
    } else if (Object.keys(formik.errors).length && formik.errors.password) {
      setError(t('errors.invalid_password'));
    } else if (Object.keys(formik.errors).length && formik.errors.code) {
      setError(t('errors.incorrect_code'));
    }
  }, [formik.errors, showPassword]);

  return (
    <LoginContext.Provider
      value={{
        formik,
        error,
        showPassword,
        setError,
        setShowPassword,
        setErrors: formik.setErrors,
        setValues: formik.setValues,
        showForgotPassword,
        setShowForgotPassword,
        step,
        isLoginOpen,
        setIsLoginOpen,
        closeToogle,
        setCloseToogle,
        passwordFailedAttempts,
        isSignUp,
        setIsSignup,
        setStep,
        secondStepSignupData,
        setSecondStepSignupData,
        setForgotPasswordToken,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContextWrapper };

export default LoginContext;
