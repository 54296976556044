export const homeFrame = {
  display: 'flex',
  flexDir: 'column' as const,
  gap: 5,
  w: 'full',
  h: 'full',
  justifyContent: 'center',
  alignItems: 'center',
  p: 5,
  fontFamily: 'PoppinsLight',
};

export const cardsCont = () => {
  return {
    color: 'white',
    w: 'full',
    justifyContent: 'space-around',
    mt: 20,
    fontFamily: 'PoppinsLight',
    fontSize: '22px',
  };
};

export const singleCardCont = (isLargerThan992, isLargerThan1500) => {
  return {
    background: 'rgba(0,0,0,0.3)',
    backdropFilter: 'blur(10px)',
    borderRadius: '5px',
    p: 5,
    flexDir: 'column' as const,
    alignItems: 'flex-start',
    gap: 5,
    transition: 'all 0.2s ease',
    _hover: {
      transform: 'scale(1.02)',
      filter: 'brightness(2)',
    },
    cursor: 'pointer',
    w: '300px',
    textAlign: 'left' as const,
    h: '161px',
    maxH: '200px',
  };
};

export const cardTitle = (isLargerThan992, isLargerThan1500) => {
  return {
    fontFamily: 'PoppinsRegular',
    fontSize: isLargerThan1500 ? '16px' : isLargerThan992 ? '13px' : '14px',
    color: '#F4F3F2',
  };
};

export const singleQuestion = (isLargerThan992, isLargerThan1500) => {
  return {
    background: '#0A0A0A33',
    backdropFilter: 'blur(25px)',
    borderRadius: '5px',
    p: 5,
    alignItems: 'flex-start',
    gap: 5,
    transition: 'all 0.2s ease',
    _hover: {
      transform: 'scale(1.02)',
      filter: 'brightness(2)',
    },
    cursor: 'pointer',
    minW: '100px',
    w: 'fit-content',
    textAlign: 'left' as const,
    // h: '161px',
    maxH: '200px',
  };
};

export const cardAction = (isLargerThan992, isLargerThan1500) => {
  return {
    border: '1px solid rgba(255,255,255,0.2)',
    borderRadius: '5px',
    p: 3,
    fontSize: isLargerThan1500 ? '14px' : isLargerThan992 ? '12px' : '10px',

    h: 'full',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    w: 'full',
  };
};
