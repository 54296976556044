import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text, useMediaQuery } from '@chakra-ui/react';
import ForceGraph from 'force-graph';
import { getCurrentRole, onNodeClick } from './SingleCompanyGraph.service';
import { setCardData } from '../../store/actions/cardActions';
import { SemiCircle } from '../../components/SemiCircle/SemiCircle';
import { TimeMachine } from '../../components/TimeMachine/TimeMachine';
import { Family } from '../../components/Family/Family';
import { SubFamily } from '../../components/SubFamily/SubFamily';
import { screenLabel } from '../../components/TimeMachine/TimeMachineStyles';
import { Progressions } from '../../components/Progressions/Progressions';
import { GraphContextWrapper } from '../../context/context';
import {
  openIcon,
  subtitleCont,
  userIcon,
} from '../../components/Progressions/ProgressionsStyle';
import { UserDiamondLegend } from '../../assets/svg/UserDiamond';
import useSize from '../../utils/useSize';
import { useTranslation } from 'react-i18next';
import UserRefreshContext from '../../context/userRefreshContext';
import { arrowDirectional } from '../../assets/svg/arrowDirectional';
import GeneralDataContext from '../../context/generalDataContext';
import { SkillsNavigator } from '../../components/TimeMachine/SkillsNavigator';
import { MySkillsCard } from '../../components/TimeMachine/MySkillsCard';
import { MyLearningJourney } from '../../components/TimeMachine/MyLearningJourney';
import { handleHorizontalScroll } from '../../utils/common/horizontalScroll';

export const SingleCompanyGraph = ({ graphData, setBigCard }) => {
  const dispatch = useDispatch();
  const viewData = useSelector((state: any) => state.view);
  const { showSkills, showFamily, showSemi, showSubFamily, showProgressions } =
    viewData;

  const generalDataContext = useContext(GeneralDataContext);

  const {
    families,
    familyElements,
    showSkillsNavigator,
    showMySkills,
    setShowMySkills,
    setShowSkillsNavigator,
  } = generalDataContext;

  const [selections, setSelections]: any = useState({
    selectedFamily: null,
    selectedRole: null,
    selectedSubFamily: null,
    subFamilyClickedSection: null,
  });

  const [elements, setElements] = useState(familyElements);
  const [subFamilyElements, setSubFamilyElements] = useState([]);
  const references: any = useRef({
    graphRef: null,
    mousePosition: { x: 0, y: 0 },
    lastRoleSelected: { id: '', skills: [] },
    firstGraphRender: false,
  });
  const [pathMode, setPathMode]: any = useState('skill-based');
  const [openPathOptions, setOpenPathOptions] = useState(false);

  const { t, i18n } = useTranslation();

  const view = useSelector((state: any) => state.view);
  const windowSize = useSize();
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  const refreshContext = useContext(UserRefreshContext);

  const { checkingUser } = refreshContext;

  const skillsContainerRef: any = useRef(null);

  // ? get current user's role
  const userRole = useMemo(() => {
    return getCurrentRole(selections, graphData, references);
  }, [selections, graphData]);

  //required, sometimes the state is not set directly for some reason
  useEffect(() => {
    setElements(familyElements);
  }, [familyElements]);

  //? calculate sections for SUB - family component
  useEffect(() => {
    const subFamilies = families.filter((subFamily) => {
      return subFamily.parent_family_id === selections.selectedFamily;
    });

    const subElements = subFamilies.map((family) => {
      return {
        id: family.family_id,
        //always have at least 1 to avoid drawing errors on the circle
        value: family.roles.length > 0 ? family.roles.length : 1,
        color: 'rgba(255, 255, 255, 0.5)',
        title: family.label,
        family_id: family.parent_family_id,
      };
    });

    setSubFamilyElements(subElements);
  }, [graphData, selections, families]);

  const containerRef = useCallback(
    (node) => {
      if (node && !references.current.firstGraphRender) {
        references.current.firstGraphRender = true;
        if (references.current.graphRef) {
          references.current.graphRef.d3Force('link')?.strength(0);
          references.current.graphRef.d3ReheatSimulation();
        }

        const graphContainer = document.getElementById('2d-graph');
        if (!graphContainer) return;

        references.current.graphRef = ForceGraph()(graphContainer)
          .graphData(
            references.current.graphRef
              ? references.current.graphRef?.graphData()
              : graphData,
          )
          .backgroundColor('transparent')
          .linkColor(() => 'white')
          .onNodeClick((node, event) => {
            onNodeClick(node, event, userRole, references);
            setSelections({ ...selections, selectedRole: node });
          })
          .onNodeHover((node: any) => {
            const element = document.getElementById('role-card');

            if (!node) {
              if (element) {
                element.style.visibility = 'hidden';
                dispatch(setCardData(null));
                setBigCard(false);
              }
              return;
            }
            if (element) {
              element.style.visibility = 'visible';
            }

            const roleData = {
              title: node.id,
              description: node.rolePurpose,
              skills: node.skills.map((skill) => {
                const exists = references.current.lastRoleSelected?.skills.find(
                  (insideSkill) => insideSkill.name === skill.name,
                );
                return {
                  ...skill,
                  match: !!exists,
                  numberMatch: exists?.level === skill?.level,
                };
              }),
              family: node.family,
              subFamily: node.subFamily,
              right:
                window.innerWidth / 2 < references.current.mousePosition?.x,
              mousePosition: references.current.mousePosition,
            };

            dispatch(setCardData(roleData));
          })
          .nodeColor((node: any) => {
            if (node.id === userRole?.id) return 'green';
            return 'rgba(244,243,242, 0.4)';
          })
          // .linkVisibility((link: any) => {
          //   if (!userRole) return false;

          //   const { lastRoleSelected } = references.current;

          //   if (
          //     lastRoleSelected &&
          //     (lastRoleSelected.role_id === link.role_id ||
          //       lastRoleSelected.id === link.source?.role_id ||
          //       lastRoleSelected.id === link.target?.role_id)
          //   ) {
          //     return link.isNaturalProgression;
          //   }

          //   return false;
          // })
          .nodeLabel('none')
          .nodeVal((node: any) => node.size ?? 50)
          .linkDirectionalArrowRelPos(1)
          .enableNodeDrag(false)
          .onEngineTick(() => {
            if (!userRole) return;

            if (references.current.graphRef) {
              const userNode = references.current.graphRef
                .graphData()
                .nodes.find((node) => node.id === userRole?.id);

              // if (userNode) {
              //   references.current.graphRef.centerAt(
              //     userNode.x,
              //     userNode.y,
              //     2000,
              //   );
              // }
            }
          })
          .autoPauseRedraw(true);

        if (references.current.graphRef) {
          references.current.graphRef
            .d3Force('link')
            ?.distance((link) => (link.distance > 1100 ? link.distance : 1000))
            .iterations(graphData.nodes.length * 0.05);
        }
      }
    },
    [userRole, graphData],
  );

  useEffect(() => {
    references.current.graphRef &&
      references.current.graphRef
        .width(windowSize.width)
        .height(windowSize.height)
        .linkWidth(windowSize.width > 1920 ? 2 : 1);
  }, [windowSize]);

  useEffect(() => {
    if (showSkills) {
      setShowSkillsNavigator(false);
      setShowMySkills(false);
    }
  }, [showSkills]);

  const handleClickOutside = (event) => {
    if (
      skillsContainerRef.current &&
      !skillsContainerRef.current.contains(event.target)
    ) {
      setShowSkillsNavigator(false);
      setShowMySkills(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (checkingUser) return null;

  return (
    <Box
      width={'100vw'}
      display={'flex'}
      justifyContent={'center'}
      position={'relative'}
      onClick={() => {
        setShowSkillsNavigator(false);
        setShowMySkills(false);
      }}
    >
      <GraphContextWrapper
        references={references}
        nodes={graphData.nodes}
        pathMode={pathMode}
      >
        {/* //TODO refcator this garbage */}
        {showSemi &&
          (i18n.language === 'pt' ? (
            <Box {...screenLabel}>
              {t('app.view')}{' '}
              <Box as={'span'} fontFamily={'PoppinsBold'}>
                {t('app.roles')}
              </Box>
            </Box>
          ) : (
            <Box {...screenLabel}>
              <Box as={'span'} fontFamily={'PoppinsBold'}>
                {t('app.roles')}
              </Box>{' '}
              {t('app.view')}
            </Box>
          ))}
        {showProgressions && (
          <>
            <Box {...screenLabel}>
              <Box as={'span'} fontFamily={'PoppinsBold'}>
                {t('app.my')}
              </Box>{' '}
              {t('app.career_path')}
            </Box>
            <Box
              position={'absolute'}
              cursor={'pointer'}
              top={'132px'}
              right={'60px'}
              fontFamily={'PoppinsLight'}
              textAlign={'left'}
              color={'white'}
              zIndex={1}
              onClick={() => {
                setOpenPathOptions(!openPathOptions);
              }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                gap={'5px'}
                backgroundColor={'#0A0A0A33'}
                padding={'5px'}
                paddingLeft={'6px'}
                borderRadius={'5px'}
                backdropFilter={'blur(25px)'}
                width={'fit-content'}
              >
                {!openPathOptions
                  ? arrowDirectional({ transform: '' })
                  : arrowDirectional({ transform: 'rotate(90deg)' })}{' '}
                {i18n.language === 'pt' ? (
                  <Box as="span" marginLeft={'5px'}>
                    {t('app.path')}
                  </Box>
                ) : null}
                <Text
                  fontFamily={'PoppinsBold'}
                  textDecoration={'underline'}
                  as={'span'}
                  marginLeft={i18n.language === 'pt' ? '' : '5px'}
                >
                  {pathMode === 'custom'
                    ? t('app.custom')
                    : t('app.skill_based')}
                </Text>{' '}
                {i18n.language === 'en' ? t('app.path') : null}
              </Box>
              {openPathOptions ? (
                <Box display={'flex'} alignItems={'center'} gap={'5px'}>
                  {!openPathOptions
                    ? arrowDirectional({ transform: '', hideArrow: true })
                    : arrowDirectional({ transform: '', hideArrow: true })}{' '}
                  <Text
                    fontFamily={'PoppinsBold'}
                    textDecoration={'underline'}
                    as={'span'}
                    onClick={() => {
                      setPathMode(
                        pathMode === 'custom' ? 'skill-based' : 'custom',
                      );
                    }}
                  >
                    {pathMode === 'custom'
                      ? t('app.skill_based')
                      : t('app.custom')}
                  </Text>{' '}
                </Box>
              ) : null}
            </Box>
            <Progressions references={references} />
          </>
        )}
        {showFamily &&
          (i18n.language === 'pt' ? (
            <Box {...screenLabel}>
              {t('app.view')}{' '}
              <Box as={'span'} fontFamily={'PoppinsBold'}>
                {t('app.families')}
              </Box>
            </Box>
          ) : (
            <Box {...screenLabel}>
              <Box as={'span'} fontFamily={'PoppinsBold'}>
                {t('app.families')}
              </Box>{' '}
              {t('app.view')}
            </Box>
          ))}
        {showSkills &&
          (i18n.language === 'pt' ? (
            <Box {...screenLabel}>
              {t('app.view')}{' '}
              <Box as={'span'} fontFamily={'PoppinsBold'}>
                {t('app.skills')}
              </Box>
            </Box>
          ) : (
            <Box {...screenLabel}>
              <Box as={'span'} fontFamily={'PoppinsBold'}>
                {t('app.skills')}
              </Box>{' '}
              {t('app.view')}
            </Box>
          ))}
        <Box width={'100%'} alignSelf={'center'}>
          {showSemi && (
            <SemiCircle references={references} elements={graphData?.nodes} />
          )}
          {showSkills && <TimeMachine />}
          {showFamily && (
            <>
              <Family
                elements={elements}
                references={references}
                setSelections={setSelections}
                selections={selections}
              />

              {showSubFamily && (
                <SubFamily
                  elements={subFamilyElements}
                  references={references}
                  selections={selections}
                  setSelections={setSelections}
                />
              )}
            </>
          )}
          <Box
            id="2d-graph"
            style={{
              marginTop: showFamily ? '50px' : '0px',
              alignSelf: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            onMouseMove={(event) => {
              references.current.mousePosition = {
                x: event.clientX,
                y: event.clientY,
              };
            }}
            ref={containerRef}
          ></Box>
        </Box>

        <Box
          display={showSkillsNavigator || showMySkills ? 'flex' : 'none'}
          onWheel={(e) => {
            handleHorizontalScroll(e, skillsContainerRef);
          }}
          position={'fixed'}
          width={'fit-content'}
          bottom={'150px'}
          left={0}
          color={'white'}
          zIndex={30}
          overflowX={'scroll'}
          maxWidth={'100vw'}
          ref={skillsContainerRef}
        >
          {showSkillsNavigator && (
            <SkillsNavigator
              setShowMySkills={setShowMySkills}
              setRolesScrollPosition={() => {}}
            />
          )}
          {showMySkills && !showSkills && <MySkillsCard />}
          {showMySkills && !showSkills && <MyLearningJourney />}
        </Box>
      </GraphContextWrapper>
      <Box
        {...subtitleCont}
        bottom={'70px'}
        display={view.showSkills ? 'none' : 'flex'}
        gap={'0px'}
        right={isLargerThan1500 ? '45px' : '35px'}
        pt={'12px'}
        transform={'scale(0.9)'} // change if needing it to be bigger / smaller
      >
        <Box
          w={'67px'}
          h={'39px'}
          justifyContent={'space-between'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Box>
            <UserDiamondLegend isLargerThan1500={isLargerThan1500} />
          </Box>
          <Text fontSize={isLargerThan1500 ? '10px' : '9px'}>
            {t('app.your_role')}
          </Text>
        </Box>
        <Box
          w={'67px'}
          h={'39px'}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Box>
            <Box
              {...userIcon}
              w={isLargerThan1500 ? '20px' : '18px'}
              h={isLargerThan1500 ? '20px' : '18px'}
            ></Box>
          </Box>
          <Text fontSize={isLargerThan1500 ? '10px' : '9px'}>
            {t('app.other_roles')}
          </Text>
        </Box>
        <Box
          w={'67px'}
          h={'39px'}
          justifyContent={'space-between'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Box>
            <Box
              {...openIcon}
              w={isLargerThan1500 ? '20px' : '18px'}
              h={isLargerThan1500 ? '20px' : '18px'}
            ></Box>
          </Box>
          <Text
            // whiteSpace={'nowrap'}
            fontSize={isLargerThan1500 ? '10px' : '9px'}
          >
            {t('app.open_spots')}
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
