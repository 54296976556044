import { useDispatch, useSelector } from 'react-redux';
import {
  calculatePercentageBySkills,
  calculateScore,
} from '../SemiCircle/SemiCircle.service';

import {
  Box,
  HStack,
  VStack,
  Text,
  Flex,
  useMediaQuery,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Tooltip,
} from '@chakra-ui/react';
import {
  expandedCont,
  roleFamily,
  roleTitle,
  sectionTitle,
  singleSkillCont,
  smallCont,
} from './SmallCardStyles';
import { HexagonLine } from '../../assets/svg/HexagonLine';
import { CheckedHex } from '../../assets/svg/CheckedHex';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import GraphContext from '../../context/context';
import { defineView } from '../../store/actions/viewActions';
import { skillLevelCounter } from '../../assets/svg/skillLevelCounter';
import { skillHexagon } from '../../assets/svg/skillHexagon';
import { ctaBtn } from '../../utils/globalStyles/GlobalStyles';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { careersAPI } from '../../utils/helpers/axios';
import {
  accordionButtonStyles,
  accordionContainerStyles,
  roleDescriptionStyles,
} from './RoleCardStyles';
import { arrowDirectional } from '../../assets/svg/arrowDirectional';
import GeneralDataContext from '../../context/generalDataContext';
import ScrollableTextBox from '../common/ScrollableTextBox';

export const AccordionRoleCard = ({ elements }) => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const context = useContext(GraphContext);
  const { expanded, setExpanded, openOpportunities } = context;
  const { cardData } = useSelector((state: any) => {
    return { cardData: state.cardData };
  });
  const [showApply, setShowApply]: any = useState({
    show: false,
    roleToApply: null,
  });

  const cardRef: any = useRef(null);
  const height = window.innerHeight;
  const cardHeight = cardRef.current?.getBoundingClientRect().height;
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });

  const generalData = useContext(GeneralDataContext);

  const { mySkills, companySkillDefaults } = generalData;

  const score = cardData?.skills
    ? calculateScore({ skills: mySkills }, cardData)
    : 0;

  const { t, i18n } = useTranslation();

  const toast = useToast();

  useEffect(() => {
    setExpanded(false);
  }, [cardData]);

  const handleCardRepositioning = () => {
    const height = window.innerHeight;

    const cardHeight = cardRef.current.getBoundingClientRect().height;

    if (cardData?.mousePosition?.y + cardHeight > height) {
      const difference = cardData?.mousePosition?.y + cardHeight - height;
      const newHeight = cardData?.mousePosition?.y - difference;

      // dispatch(
      //   setCardData({
      //     ...cardData,
      //     mousePosition: {
      //       x: cardData?.mousePosition?.x,
      //       y: newHeight < 0 ? newHeight + difference : newHeight,
      //     },
      //   }),
      // );
      return newHeight < 0 ? newHeight + difference : newHeight;
    }

    return 0;
  };

  const canShowOpenOpp = (openOpp) => {
    if (!openOpp) return false;

    if (openOpp?.visibility === 'all' || !currentUser.attributes.country) {
      return true;
    }

    if (openOpp?.visibility === 'open_countries') {
      const isUserCountryPresent = openOpp?.open_location.find((open) => {
        return open?.value === currentUser.attributes.country;
      });

      return !!isUserCountryPresent;
    }

    if (openOpp?.visibility === 'international') {
      const isUserCountryPresent = openOpp?.open_location.find((open) => {
        return open?.value === currentUser.attributes.country;
      });

      return !isUserCountryPresent;
    }

    return true;
  };

  const getWorkTypes = (type) => {
    switch (type) {
      case 'on_site':
        return t('app.on_site');
      case 'hybrid':
        return t('app.hybrid');
      case 'remote':
        return t('app.remote');
      default:
        return 'N/A';
    }
  };

  const openOpp = useMemo(() => {
    return openOpportunities?.find((openOpp) => {
      return openOpp?.role_id === cardData?.role_id;
    });
  }, [openOpportunities, cardData]);

  if (!cardData || cardData.title === 'invisible') return <></>;

  return (
    <Flex
      h={'100vh'}
      w={'100vw'}
      justifyContent={'center'}
      alignItems={'center'}
      pointerEvents={'none'}
      onWheel={(e) => e.stopPropagation()}
    >
      <Box
        left={
          expanded ? `calc(50vw)` : `calc(${cardData.mousePosition.x}px + 50px)`
        }
        top={
          expanded
            ? cardData?.mousePosition?.y + cardHeight > height
              ? '-65px'
              : `calc(0vh)`
            : `calc(${cardData.mousePosition.y}px - 50px)`
        }
        id="role-card"
        ref={cardRef}
        {...smallCont}
        position={expanded ? 'relative' : 'absolute'}
        minW={expanded ? '500px' : '300px'}
        color={'white'}
        w={'fit-content'}
        onClick={(e) => {
          e.stopPropagation();
          // setExpanded(!expanded);
        }}
        padding={'15px 10px'}
        width={'600px'}
      >
        <HStack
          w={'full'}
          cursor={'pointer'}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
          justifyContent={'space-between'}
          padding={'0px 15px'}
        >
          <VStack gap={0}>
            <Text fontSize={isLargerThan1500 ? '24px' : '20px'} {...roleTitle}>
              {cardData?.title && cardData?.title?.toLowerCase()}
            </Text>
            <HStack w={'full'}>
              <Text
                _hover={{ transform: 'scale(1.02)' }}
                onClick={() => {
                  dispatch(
                    defineView({
                      showSemi: false,
                      showSkills: false,
                      showProgressions: false,
                      rolesExtra: undefined,
                      skillsExtra: undefined,
                      showFamily: true,
                      familyData: { family_id: cardData.family_id },
                      showSubFamily: false,
                      skillsFilter: undefined,
                      rolesFilter: undefined,
                      progressionsData: undefined,
                    }),
                  );
                }}
                {...roleFamily}
              >
                {cardData?.family && cardData?.family?.toLowerCase()}
              </Text>
              {cardData?.subFamily ? <Text>|</Text> : null}
              <Text
                _hover={{ transform: 'scale(1.02)' }}
                onClick={() => {
                  dispatch(
                    defineView({
                      showSemi: false,
                      showSkills: false,
                      showProgressions: false,
                      rolesExtra: undefined,
                      skillsExtra: undefined,
                      showFamily: true,
                      showSubFamily: true,
                      familyData: { family_id: cardData.sub_family_id },
                      skillsFilter: undefined,
                      rolesFilter: undefined,
                      progressionsData: undefined,
                    }),
                  );
                }}
                {...roleFamily}
              >
                {cardData?.subFamily && cardData?.subFamily?.toLowerCase()}
              </Text>
            </HStack>
          </VStack>
          {!expanded && (
            <Box ml={'30px'}>
              {score !== 100 ? (
                <HexagonLine>
                  <Box fontFamily={'PPNeuePlainBold'} mb={'-5px'} zIndex={20}>
                    {score}
                  </Box>
                </HexagonLine>
              ) : (
                <CheckedHex />
              )}
            </Box>
          )}
        </HStack>
        {expanded && (
          <Box
            {...expandedCont}
            maxH={'400px'}
            minWidth={'500px'}
            overflowY={'scroll'}
            boxSizing={'border-box'}
            css={{
              /* Firefox */
              scrollbarWidth: 'thin',
              scrollbarColor: '#F2F3F41A transparent',

              /* WebKit (Chrome, Safari) */
              '&::-webkit-scrollbar': {
                width: '9px',
              },
              '&::-webkit-scrollbar-track': {
                background: 'transparent', // Transparent track color
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#F2F3F41A', // Thumb color
                borderRadius: '10px',
                minHeight: '20px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#F2F3F480',
                width: '9px',
              },
            }}
            padding={'0px 15px'}
          >
            <Accordion {...accordionContainerStyles} allowMultiple>
              <AccordionItem
                onClick={(e) => e.stopPropagation()}
                borderRadius={'3px'}
              >
                {({ isExpanded }) => {
                  if (isExpanded) handleCardRepositioning();
                  return (
                    <>
                      <AccordionButton
                        {...accordionButtonStyles}
                        _focus={{
                          border: 'none',
                          outline: 'none',
                        }}
                        _hover={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        {arrowDirectional({
                          transform: isExpanded ? 'rotate(-90deg)' : '',
                        })}

                        <Box
                          {...sectionTitle}
                          as="span"
                          flex="1"
                          textAlign="left"
                        >
                          {t('app.role_purpose')}
                        </Box>
                      </AccordionButton>
                      <AccordionPanel
                        onTouchStart={(e) => e.stopPropagation()}
                        onTouchMove={(e) => e.stopPropagation()}
                        onWheel={(e) => e.stopPropagation()}
                        {...roleDescriptionStyles}
                        whiteSpace={'wrap'}
                        padding={'8px 16px'}
                      >
                        {cardData?.description}
                      </AccordionPanel>
                    </>
                  );
                }}
              </AccordionItem>
            </Accordion>
            <Accordion
              {...accordionContainerStyles}
              allowMultiple
              defaultIndex={[0]}
            >
              <AccordionItem
                onClick={(e) => e.stopPropagation()}
                borderRadius={'3px'}
              >
                {({ isExpanded }) => {
                  return (
                    <>
                      <AccordionButton
                        {...accordionButtonStyles}
                        _focus={{
                          border: 'none',
                          outline: 'none',
                        }}
                        _hover={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        {arrowDirectional({
                          transform: isExpanded ? 'rotate(-90deg)' : '',
                        })}
                        <Box
                          {...sectionTitle}
                          as="span"
                          flex="1"
                          textAlign="left"
                        >
                          {t('app.skills')}
                        </Box>
                      </AccordionButton>
                      <AccordionPanel
                        onTouchStart={(e) => e.stopPropagation()}
                        onTouchMove={(e) => e.stopPropagation()}
                        onWheel={(e) => e.stopPropagation()}
                        {...roleDescriptionStyles}
                        padding={'8px 16px'}
                      >
                        <Flex
                          alignItems={'center'}
                          gap={'22px'}
                          justifyContent={'space-between'}
                        >
                          <Box
                            w={'fit-content'}
                            h={'100%'}
                            alignItems={'center'}
                            display={'flex'}
                            position={'relative'}
                            gap={'22px'}
                            className={'foind1'}
                          >
                            {skillHexagon({
                              score,
                            })}
                          </Box>
                          <Flex flexDir={'column'}>
                            {cardData?.skills &&
                              cardData?.skills.map(
                                (skill: any, index: number) => {
                                  const comparisonSkillLevel = skill.level;
                                  const mySkillLevel =
                                    mySkills?.find(
                                      (insideSkill) =>
                                        insideSkill.skill_id === skill.skill_id,
                                    )?.level || 0;

                                  if (!skill?.name) return <></>;

                                  return (
                                    <Box
                                      {...singleSkillCont}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        dispatch(
                                          defineView({
                                            showSemi: false,
                                            showSkills: true,
                                            showFamily: false,
                                            showSubFamily: false,
                                            showProgressions: false,
                                            roleData: null,
                                            familyData: null,
                                            skillsData: skill,
                                            rolesExtra: undefined,
                                            skillsExtra: undefined,
                                            skillsFilter: undefined,
                                            rolesFilter: undefined,
                                            progressionsData: undefined,
                                          }),
                                        );
                                      }}
                                      key={index}
                                      fontSize={'12px'}
                                    >
                                      <Box
                                        w={'full'}
                                        textAlign={'left'}
                                        whiteSpace={'nowrap'}
                                        minW={'200px'}
                                        lineHeight={'24px'}
                                        style={{
                                          WebkitBoxOrient: 'vertical',
                                          WebkitLineClamp: 1,
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        <ScrollableTextBox
                                          text={skill.name}
                                          maxW={'210px'}
                                        />
                                      </Box>
                                      <Box display={'flex'}>
                                        {companySkillDefaults.map(
                                          (level, index) => {
                                            return (
                                              <Tooltip
                                                backdropFilter={'blur(25px)'}
                                                background={'#0A0A0A33'}
                                                maxW={'150px'}
                                                label={
                                                  <Box
                                                    display={'flex'}
                                                    flexDirection={'column'}
                                                    gap={'10px'}
                                                  >
                                                    <Box
                                                      fontFamily={'PoppinsBold'}
                                                      fontSize={'10px'}
                                                    >
                                                      {level?.name}
                                                    </Box>
                                                    <Box
                                                      fontFamily={
                                                        'PoppinsLight'
                                                      }
                                                      fontSize={'10px'}
                                                    >
                                                      {level?.description}
                                                    </Box>
                                                  </Box>
                                                }
                                              >
                                                <Box>
                                                  {skillLevelCounter(
                                                    mySkillLevel >= index + 1,
                                                    false,
                                                    comparisonSkillLevel >=
                                                      index + 1,
                                                  )}
                                                </Box>
                                              </Tooltip>
                                            );
                                          },
                                        )}
                                      </Box>
                                    </Box>
                                  );
                                },
                              )}
                            <Flex
                              fontSize={'12px'}
                              fontFamily={'PoppinsExtraLight'}
                              gap={5}
                              w={'full'}
                              justifyContent={'flex-end'}
                            >
                              <Flex
                                fontSize={'12px'}
                                fontFamily={'PoppinsLight'}
                                alignItems={'center'}
                                gap={1}
                              >
                                {skillLevelCounter(false, false, true)}{' '}
                                {t('app.required')}
                              </Flex>
                              <Flex
                                fontSize={'12px'}
                                fontFamily={'PoppinsLight'}
                                alignItems={'center'}
                                gap={1}
                              >
                                {skillLevelCounter(true, true, false)}{' '}
                                {t('app.your_level')}
                              </Flex>
                            </Flex>
                          </Flex>
                        </Flex>
                      </AccordionPanel>
                    </>
                  );
                }}
              </AccordionItem>
            </Accordion>
            <Accordion {...accordionContainerStyles} allowMultiple>
              <AccordionItem
                onClick={(e) => e.stopPropagation()}
                borderRadius={'3px'}
              >
                {({ isExpanded }) => {
                  if (isExpanded) handleCardRepositioning();
                  return (
                    <>
                      <AccordionButton
                        {...accordionButtonStyles}
                        _focus={{
                          border: 'none',
                          outline: 'none',
                        }}
                        _hover={{
                          border: 'none',
                          outline: 'none',
                        }}
                      >
                        {arrowDirectional({
                          transform: isExpanded ? 'rotate(-90deg)' : '',
                        })}
                        <Box
                          {...sectionTitle}
                          as="span"
                          flex="1"
                          textAlign="left"
                        >
                          {t('app.accountabilities')}
                        </Box>
                      </AccordionButton>
                      <AccordionPanel
                        onTouchStart={(e) => e.stopPropagation()}
                        onTouchMove={(e) => e.stopPropagation()}
                        onWheel={(e) => e.stopPropagation()}
                        {...roleDescriptionStyles}
                        whiteSpace={'pre-wrap'}
                        padding={'8px 16px'}
                      >
                        {cardData?.accountabilities}
                      </AccordionPanel>
                    </>
                  );
                }}
              </AccordionItem>
            </Accordion>
            {canShowOpenOpp(openOpp) ? (
              <Accordion {...accordionContainerStyles} allowMultiple>
                <AccordionItem
                  onClick={(e) => e.stopPropagation()}
                  borderRadius={'3px'}
                >
                  {({ isExpanded }) => {
                    if (isExpanded) handleCardRepositioning();

                    return (
                      <>
                        <AccordionButton
                          {...accordionButtonStyles}
                          _focus={{
                            border: 'none',
                            outline: 'none',
                          }}
                          _hover={{
                            border: 'none',
                            outline: 'none',
                          }}
                        >
                          {arrowDirectional({
                            transform: isExpanded ? 'rotate(-90deg)' : '',
                          })}
                          <Box
                            {...sectionTitle}
                            as="span"
                            flex="1"
                            textAlign="left"
                          >
                            {t('app.requirements')}
                          </Box>
                        </AccordionButton>
                        <AccordionPanel
                          onTouchStart={(e) => e.stopPropagation()}
                          onTouchMove={(e) => e.stopPropagation()}
                          onWheel={(e) => e.stopPropagation()}
                          {...roleDescriptionStyles}
                          whiteSpace={'wrap'}
                          padding={'8px 16px'}
                        >
                          <Box>
                            <Box display={'flex'} gap={'5px'}>
                              <Box fontFamily={'PoppinsMedium'}>
                                {t('app.roles_based')}{' '}
                              </Box>

                              <Box>
                                {openOpp.location.map((location, index) => {
                                  return (
                                    <Box as="span" fontFamily={'PoppinsLight'}>
                                      {location.value === 'all' ? (
                                        t('app.all_countries')
                                      ) : (
                                        <>
                                          {index > 0 ? ' |' : ''}{' '}
                                          {location.city}
                                          {location.city &&
                                          location.city?.length
                                            ? ','
                                            : ''}{' '}
                                          {location.label}
                                        </>
                                      )}
                                    </Box>
                                  );
                                })}{' '}
                              </Box>
                            </Box>

                            <Box fontFamily={'PoppinsMedium'}>
                              {t('app.work_model')}{' '}
                              <Box as="span" fontFamily={'PoppinsLight'}>
                                {getWorkTypes(openOpp?.work_model)}
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Box as="span" fontFamily={'PoppinsMedium'}>
                              {t('app.can_apply_in')}{' '}
                            </Box>

                            {openOpp.open_location.map((location, index) => {
                              return (
                                <Box as="span">
                                  {index > 0 ? ',' : ''}{' '}
                                  {location.value === 'all'
                                    ? t('app.all_countries')
                                    : location.label}
                                </Box>
                              );
                            })}
                          </Box>
                          <Box marginTop={'10px'} fontFamily={'PoppinsRegular'}>
                            {openOpp?.requirements}
                          </Box>
                        </AccordionPanel>
                      </>
                    );
                  }}
                </AccordionItem>
              </Accordion>
            ) : null}
          </Box>
        )}
        {expanded && (
          <HStack gap={'15px'} padding={'0px 15px'}>
            {canShowOpenOpp(openOpp) && (
              <Box
                {...ctaBtn}
                background={'none'}
                fontSize={'10px'}
                fontFamily={'PoppinsSemiBold'}
                width={'77px'}
                onClick={() => {
                  setShowApply({
                    show: true,
                    roleToApply: cardData,
                    applicationEmail: openOpportunities.find((opp: any) => {
                      return opp.role_id === cardData?.role_id;
                    })?.recruitment_email,
                  });
                }}
                // backdropFilter={'none'}
              >
                <Text>{t('app.apply_now')}</Text>
              </Box>
            )}
            <Box
              {...ctaBtn}
              background={'none'}
              onClick={() => {
                dispatch(
                  defineView({
                    showSemi: false,
                    showSkills: false,
                    showFamily: false,
                    showSubFamily: false,
                    showProgressions: true,
                    roleData: undefined,
                    familyData: undefined,
                    skillsData: undefined,
                    rolesExtra: undefined,
                    skillsExtra: undefined,
                    skillsFilter: undefined,
                    rolesFilter: undefined,
                    progressionsData: elements.filter(
                      (el) => el.role_id === cardData.role_id,
                    )[0],
                  }),
                );
              }}
              fontSize={'10px'}
              fontFamily={'PoppinsSemiBold'}
              width={'120px'}
            >
              <Text>{t('app.paths_from_role')}</Text>
            </Box>
          </HStack>
        )}
      </Box>
      {showApply.show ? (
        <ConfirmModal
          text={t('landing.apply_question')}
          accept={async () => {
            try {
              await careersAPI.post('/createApplication', {
                user_id: currentUser.attributes.user_id,
                user_full_name:
                  currentUser.attributes.first_name +
                  ' ' +
                  currentUser.attributes.last_name,
                user_email: currentUser.attributes.email,
                role_id: showApply?.roleToApply?.role_id,
                country: currentUser.attributes.country,
                companyId: currentUser.attributes.company_id,
                applicationEmail: showApply?.applicationEmail,
                skillPercentageComparison: calculatePercentageBySkills(
                  { skills: mySkills },
                  showApply?.roleToApply,
                ),
                language: i18n.language,
              });
              setShowApply({ show: false, roleToApply: null });
              toast({
                title: t('notifications.app_submited').replace(
                  'email_to_replace',
                  showApply?.applicationEmail,
                ),
                status: 'success',
                duration: 3000,
                isClosable: true,
              });
            } catch (err: any) {
              console.log(err?.response?.data);
              setShowApply({ show: false, roleToApply: null });
              if (err?.response?.data === 'Application already exists') {
                toast({
                  title: t('notifications.app_already_exists'),
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
                return;
              }

              toast({
                title: t('user_preferences.password_ambiguous_error'),
                status: 'error',
                duration: 3000,
                isClosable: true,
              });
            }
          }}
          deny={() => {
            setShowApply({ show: false, roleToApply: null });
          }}
        />
      ) : null}
    </Flex>
  );
};
