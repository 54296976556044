import { combineReducers } from 'redux';
import { fetchUserReducer } from './usersReducer';
import { cardsDataReducer, skillLineDataReducer } from './cardsReducer';
import { viewReducer } from './viewReducer';
import { locationReducer } from './locationReducer';

const rootReducer: any = combineReducers({
  currentUser: fetchUserReducer,
  cardData: cardsDataReducer,
  view: viewReducer,
  skillLineData: skillLineDataReducer,
  location: locationReducer,
});

export default rootReducer;
