import { Box, Input, useMediaQuery, useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  CustomPlainBody,
  CustomPlainHeader,
} from '../ModelTemplate/PlainTemplate';
import {
  CustomModalLabel,
  CustomModalLabelValue,
} from '../ModelTemplate/ModalTemplateComponents';
import { useDispatch, useSelector } from 'react-redux';
import { countries } from '../../utils/locations/englishCountries';
import { countries as portugueseCountries } from '../../utils/locations/portugueseCountries';
import { useCallback, useState } from 'react';
import { Select } from 'chakra-react-select';
import { dropdownStyles } from './UserPreferencesStyles';
import { globalAPI, loginAPI } from '../../utils/helpers/axios';
import { fetchUserWithAllAttributes } from '../../store/actions/usersActions';
import { ctaBtn } from '../../utils/globalStyles/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import { FETCH_USER_WITH_ALL_ATTRIBUTES } from '../../store/actions/types';
import { getCookie, setCookie } from '../../config/cookieHelpers';

export const UserPreferences = () => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [newLanguage, setNewLanguage] = useState(null);

  const [openEdits, setOpenEdits] = useState({
    first_name: false,
    last_name: false,
    password: false,
    email: false,
    birthdate: false,
    gender: false,
    country: false,
    language: false,
  });
  const [oldPassword, setOldPassword]: any = useState(null);
  const [newPassword, setNewPassword]: any = useState(null);
  const [newPasswordConfirmation, setNewPasswordConfirmation]: any =
    useState(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });

  const checkIfAnyEditOpen = () => {
    return Object.values(openEdits).some((value) => value === true);
  };

  const validatePassword = (str) => {
    // Regular expression: At least 8 characters, includes at least one number or symbol
    const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9!@#$%^*_+\-]).{8,}$/;

    return pattern.test(str);
  };

  const checkPassword = useCallback(async () => {
    try {
      await loginAPI.post('/login', {
        email: currentUser.attributes.email,
        password: oldPassword,
      });

      return true;
    } catch (error) {
      return false;
    }
  }, [currentUser, oldPassword]);

  const handleUserDate = (date) => {
    if (!date) return null;
    // Split the date to correct the format
    let [year, month, day] = date.split('/');

    if (!day) {
      [year, month, day] = date.split('-');
    }

    const formattedDate = `${day.padStart(2, '0')}/${month.padStart(
      2,
      '0',
    )}/${year}`;

    return formattedDate;
  };

  const genderTexts = (gender) => {
    switch (gender) {
      case 'M':
        return t('genders.male');
      case 'F':
        return t('genders.female');
      case 'O':
        return t('genders.other');
      default:
        return null;
    }
  };

  const getCountry = (country) => {
    if (!country) return null;

    const countryObj: any = (
      i18n.language === 'en' ? countries : portugueseCountries
    ).find((c: any) => c.alpha2Code === country);

    return countryObj?.name ?? 'none';
  };

  const validate = yup.object().shape({
    email: yup
      .string()
      .email(t('login.email_format'))
      .required(t('login.required_email')),
  });

  const formik = useFormik({
    initialValues: {
      email: currentUser.attributes.email,
      first_name: currentUser.attributes.first_name,
      last_name: currentUser.attributes.last_name,
      birthdate: currentUser.attributes.birthdate
        ? currentUser.attributes.birthdate.replaceAll('/', '-')
        : '',
      gender: genderTexts(currentUser.attributes.gender),
      country: getCountry(currentUser.attributes.country),
    },
    validationSchema: validate,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      try {
        await globalAPI.post('/updateUserData', {
          newData: {
            ...formik.values,
            gender: formik.values.gender
              ? getGenders().find((g) => g.label === formik.values.gender)
                  ?.value
              : null,
            country: formik.values.country
              ? (i18n.language === 'en' ? countries : portugueseCountries).find(
                  (country) => country.name === formik.values.country,
                )?.alpha2Code
              : null,
          },
          userId: currentUser.attributes.user_id,
        });

        setOpenEdits({
          first_name: false,
          last_name: false,
          email: false,
          password: false,
          birthdate: false,
          gender: false,
          country: false,
          language: false,
        });
        dispatch(
          //@ts-ignore
          fetchUserWithAllAttributes(currentUser.attributes.user_id),
          null,
          null,
        );
      } catch (err: any) {
        console.log(err);
      }
    },
  });

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const openAndCloseEdits = (field) => {
    setOpenEdits({
      first_name: false,
      last_name: false,
      email: false,
      password: false,
      birthdate: false,
      gender: false,
      country: false,
      language: false,
      [field]: !openEdits[field],
    });
  };

  const getGenders = () => {
    return [
      { label: t('genders.male'), value: 'M' },
      { label: t('genders.female'), value: 'F' },
      { label: t('genders.other'), value: 'O' },
    ];
  };

  const getLanguages = () => {
    return [
      { label: t('user_preferences.english'), value: 'en' },
      { label: t('user_preferences.portuguese'), value: 'pt' },
    ];
  };

  const changeLanguage = (lngs) => {
    i18n.changeLanguage(lngs);
    setCookie('i18nextLng', lngs, 365);
    setNewLanguage(null);
    openAndCloseEdits('language');
  };

  const renderDataBox = ({
    label,
    labelValue,
    name,
    onClick,
    inputType,
    inputValue,
    inputOnChange,
    cursor = 'pointer',
  }) => {
    return (
      <Box width={'100%'} display={'flex'} alignItems={'center'}>
        <CustomModalLabel
          isLargerThan756={isLargerThan756}
          isLargerThan1500={isLargerThan1500}
          label={label}
          name={name}
          labelStyles={{
            cursor,
            whiteSpace: 'nowrap',
            marginBottom: 0,
            height: '30px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        />
        {!openEdits[name] ? (
          <CustomModalLabelValue
            isLargerThan756={isLargerThan756}
            isLargerThan1500={isLargerThan1500}
            label={labelValue ?? t('user_preferences.none')}
            labelStyles={{
              cursor,
              marginBottom: 0,
              color: labelValue ? 'white' : 'gray',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          />
        ) : (
          <Input
            onClick={(e) => {
              e.stopPropagation();
            }}
            value={inputValue}
            color={'white'}
            onChange={inputOnChange}
            height={'30px'}
            fontSize={'12px'}
            type={inputType}
          />
        )}
      </Box>
    );
  };

  const renderDataBoxWithDropdown = ({
    label,
    labelValue,
    name,
    onClick,
    selectValue,
    selectOptions,
    selectOnChange,
    isClearable = true,
  }) => {
    return (
      <Box width={'100%'} display={'flex'} maxH={'30px'} position={'relative'}>
        <CustomModalLabel
          isLargerThan756={isLargerThan756}
          isLargerThan1500={isLargerThan1500}
          label={label}
          name={name}
          labelStyles={{
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            marginBottom: 0,
            height: '30px',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
        />
        {!openEdits[name] ? (
          <CustomModalLabelValue
            isLargerThan756={isLargerThan756}
            isLargerThan1500={isLargerThan1500}
            label={labelValue ?? t('user_preferences.none')}
            labelStyles={{
              cursor: 'pointer',
              marginBottom: 0,
              color: labelValue ? 'white' : 'gray',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          />
        ) : (
          <Box
            width={'100%'}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Select
              value={selectValue}
              options={selectOptions}
              onChange={selectOnChange}
              chakraStyles={dropdownStyles}
              isClearable={isClearable}
            />
          </Box>
        )}
      </Box>
    );
  };

  const renderPasswordSteps = () => {
    if (!openChangePassword) {
      return (
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          flexDirection={openEdits['password'] ? 'column' : 'row'}
        >
          <CustomModalLabel
            isLargerThan756={isLargerThan756}
            isLargerThan1500={isLargerThan1500}
            label={
              openEdits['password']
                ? t('user_preferences.enter_old_password')
                : t('user_preferences.password')
            }
            name={'password'}
            labelStyles={{
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              marginBottom: 0,
              height: '30px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              openAndCloseEdits('password');
            }}
          />
          {!openEdits['password'] ? (
            <CustomModalLabelValue
              isLargerThan756={isLargerThan756}
              isLargerThan1500={isLargerThan1500}
              label={'********'}
              labelStyles={{ cursor: 'pointer', marginBottom: 0 }}
              onClick={(e) => {
                e.stopPropagation();
                openAndCloseEdits('password');
              }}
            />
          ) : (
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              width={'100%'}
            >
              <Input
                onClick={(e) => {
                  e.stopPropagation();
                }}
                value={oldPassword ?? ''}
                color={'white'}
                onChange={(e: any) => {
                  setOldPassword(e?.target?.value);
                }}
                height={'30px'}
                fontSize={'12px'}
                type={'password'}
                placeholder={t('user_preferences.password')}
              />
              <Box
                {...ctaBtn}
                fontSize={'12px'}
                fontFamily={'PoppinsSemiBold'}
                onClick={async (e) => {
                  e.stopPropagation();
                  if (oldPassword && oldPassword.length) {
                    const isPasswordCorrect = await checkPassword();

                    if (isPasswordCorrect) {
                      setOpenChangePassword(true);
                    } else {
                      toast({
                        title: t('user_preferences.wrong_password'),
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                      });
                    }
                  } else {
                    toast({
                      title: t('user_preferences.enter_password'),
                      status: 'error',
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                }}
                marginTop={'5px'}
              >
                {t('user_preferences.submit_button')}
              </Box>
            </Box>
          )}
        </Box>
      );
    } else if (openChangePassword) {
      return (
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <CustomModalLabel
            isLargerThan756={isLargerThan756}
            isLargerThan1500={isLargerThan1500}
            label={t('user_preferences.new_password_and_confirmation')}
            name={'password'}
            labelStyles={{
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              marginBottom: 0,
              height: '30px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              openAndCloseEdits('password');
            }}
          />

          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            width={'100%'}
          >
            <Input
              onClick={(e) => {
                e.stopPropagation();
              }}
              value={newPassword ?? ''}
              color={'white'}
              onChange={(e: any) => {
                setNewPassword(e?.target?.value);
              }}
              height={'30px'}
              fontSize={'12px'}
              type={'password'}
              placeholder={t('user_preferences.password')}
            />

            <Input
              onClick={(e) => {
                e.stopPropagation();
              }}
              value={newPasswordConfirmation ?? ''}
              color={'white'}
              onChange={(e: any) => {
                setNewPasswordConfirmation(e?.target?.value);
              }}
              height={'30px'}
              fontSize={'12px'}
              marginTop={'5px'}
              type={'password'}
              placeholder={t('user_preferences.password_confirmation')}
            />
            <Box
              {...ctaBtn}
              fontSize={'12px'}
              fontFamily={'PoppinsSemiBold'}
              onClick={async (e) => {
                e.stopPropagation();

                if (!validatePassword(newPassword)) {
                  toast({
                    title: t('user_preferences.password_specifications_error'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  });
                  return;
                }
                if (
                  newPassword &&
                  newPassword.length &&
                  newPasswordConfirmation &&
                  newPasswordConfirmation.length &&
                  newPassword === newPasswordConfirmation
                ) {
                  try {
                    await loginAPI.put(
                      `/changePassword/${currentUser.attributes.user_id}`,
                      {
                        email: currentUser.attributes.email,
                        password: newPassword,
                        oldPassword,
                      },
                    );

                    setOpenChangePassword(false);
                    openAndCloseEdits('password');
                    setOldPassword(null);
                    setNewPassword(null);
                    setNewPasswordConfirmation(null);

                    toast({
                      title: t('user_preferences.password_changed'),
                      status: 'success',
                      duration: 3000,
                      isClosable: true,
                    });
                  } catch (err) {
                    console.log(err);
                    toast({
                      title: t('user_preferences.password_ambiguous_error'),
                      status: 'error',
                      duration: 3000,
                      isClosable: true,
                    });
                  }
                } else {
                  toast({
                    title: t('user_preferences.passwords_dont_match'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  });
                }
              }}
              marginTop={'5px'}
            >
              {t('user_preferences.submit_button')}
            </Box>
          </Box>
        </Box>
      );
    }
  };

  const deleteAccount = async () => {
    try {
      const isPasswordValid = await checkPassword();

      if (isPasswordValid) {
        await loginAPI.post(`/deleteUser`, {
          userId: currentUser.attributes.user_id,
        });

        await loginAPI.post('/logout');
        navigate('/');
        dispatch({ type: FETCH_USER_WITH_ALL_ATTRIBUTES, payload: null });
      } else {
        toast({
          title: t('user_preferences.wrong_password'),
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderDeleteAccount = () => {
    if (!openDeleteAccount) {
      return (
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          flexDirection={openEdits['password'] ? 'column' : 'row'}
        >
          <CustomModalLabel
            isLargerThan756={isLargerThan756}
            isLargerThan1500={isLargerThan1500}
            label={t('user_preferences.delete_account')}
            name={'delete'}
            labelStyles={{
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              marginBottom: 0,
              height: '30px',
            }}
            onClick={(e) => {
              setOpenDeleteAccount(true);
            }}
          />
        </Box>
      );
    } else if (openDeleteAccount) {
      return (
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <CustomModalLabel
            isLargerThan756={isLargerThan756}
            isLargerThan1500={isLargerThan1500}
            label={t('user_preferences.delete_account')}
            name={'delete'}
            labelStyles={{
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              marginBottom: 0,
              height: '30px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              deleteAccount();
            }}
          />
          <Input
            onClick={(e) => {
              e.stopPropagation();
            }}
            value={oldPassword ?? ''}
            color={'white'}
            onChange={(e: any) => {
              setOldPassword(e?.target?.value);
            }}
            height={'30px'}
            fontSize={'12px'}
            type={'password'}
            placeholder={t('user_preferences.password')}
          />
          <Box
            {...ctaBtn}
            fontSize={'12px'}
            fontFamily={'PoppinsSemiBold'}
            onClick={(e) => {
              e.stopPropagation();
              setOpenDeleteAccount(false);
            }}
            marginTop={'5px'}
          >
            {t('user_preferences.submit_button')}
          </Box>
        </Box>
      );
    }
  };

  //its needed for the scrollbar when we use it
  const isFirefox = () => {
    return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  };

  return (
    <Box
      width={'100%'}
      display={'flex'}
      justifyContent={'center'}
      marginBottom={'70px'}
      onClick={async () => {
        if (checkIfAnyEditOpen() && !openEdits.language) {
          formik.handleSubmit();
        } else if (openEdits.language) {
          changeLanguage(newLanguage ?? i18n.language);
        }
      }}
      position={'relative'}
      zIndex={10}
      //not beeing used but its already implemented for the future
      maxHeight={'460px'}
      height={'440px'}
      overflowY={'scroll'}
      sx={{
        '&::-webkit-scrollbar': {
          width: '8px', // Set your desired width
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent', // Track color
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'darkgray', // Thumb color
          borderRadius: '10px',
          border: '2px solid transparent', // Add border to match track background
          backgroundClip: 'padding-box', // Ensure the border is visible
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'darkgray', // Keep the same color on hover
          border: '2px solid transparent', // Prevent it from enlarging
        },
        /* For Firefox */
        ...(isFirefox() && {
          scrollbarWidth: 'thin',
          scrollbarColor: 'darkgray transparent',
        }),
      }}
    >
      <Box
        onKeyDown={handleKeyPress}
        width={isLargerThan1500 ? '610px' : isLargerThan756 ? '462px' : '300px'}
        maxWidth={
          isLargerThan1500 ? '610px' : isLargerThan756 ? '462px' : '300px'
        }
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        fontFamily={'PoppinsLight'}
        gap={isLargerThan1500 ? '30px' : '20px'}
        textAlign={'left'}
      >
        <CustomPlainHeader
          title={t('user_preferences.title')}
          subTitle={null}
          styles={{ fontFamily: 'PPNeueMachinaPlainLight' }}
        />
        <CustomPlainBody stackStyles={{ gap: '5px' }}>
          {renderDataBox({
            label: t('user_preferences.first_name'),
            labelValue: currentUser.attributes.first_name,
            name: 'first_name',
            onClick: () => {
              openAndCloseEdits('first_name');
              if (
                openEdits.first_name &&
                formik.values.first_name !== currentUser.attributes.first_name
              ) {
                formik.handleSubmit();
              }
            },
            inputType: null,
            inputValue: formik.values.first_name,
            inputOnChange: (event) =>
              formik.setFieldValue('first_name', event.target.value),
          })}
          {renderDataBox({
            label: t('user_preferences.last_name'),
            labelValue: currentUser.attributes.last_name,
            name: 'last_name',
            onClick: () => {
              openAndCloseEdits('last_name');
              if (
                openEdits.last_name &&
                formik.values.last_name !== currentUser.attributes.last_name
              ) {
                formik.handleSubmit();
              }
            },
            inputType: null,
            inputValue: formik.values.last_name,
            inputOnChange: (event) =>
              formik.setFieldValue('last_name', event.target.value),
          })}
          {renderDataBox({
            label: t('user_preferences.email'),
            labelValue: currentUser.attributes.email,
            name: 'email',
            onClick: () => {
              if (currentUser.attributes.company_id) {
                return;
              }
              openAndCloseEdits('email');
              if (
                openEdits.email &&
                formik.values.email !== currentUser.attributes.email
              ) {
                formik.handleSubmit();
              }
            },
            inputType: null,
            inputValue: formik.values.email,
            inputOnChange: (event) =>
              formik.setFieldValue('email', event.target.value),
            cursor: currentUser.attributes.company_id ? 'default' : 'pointer',
          })}
          {!currentUser.attributes.company_id && renderPasswordSteps()}

          {renderDataBoxWithDropdown({
            label: t('user_preferences.gender'),
            name: 'gender',
            labelValue: genderTexts(currentUser.attributes.gender),
            onClick: () => {
              openAndCloseEdits('gender');
              if (
                openEdits.gender &&
                formik.values.gender !== currentUser.attributes.gender
              ) {
                formik.handleSubmit();
              }
            },
            selectValue: formik.values.gender
              ? {
                  label: formik.values.gender,
                  value: formik.values.gender.slice(0, 1),
                }
              : null,
            selectOptions: getGenders().filter(
              (g) => g.label !== formik.values.gender,
            ),
            selectOnChange: (value) => {
              formik.setFieldValue('gender', genderTexts(value?.value));
            },
          })}
          {renderDataBoxWithDropdown({
            label: t('user_preferences.country'),
            name: 'country',
            labelValue: getCountry(currentUser.attributes.country),
            onClick: () => {
              openAndCloseEdits('country');
              if (
                openEdits.country &&
                formik.values.country !== currentUser.attributes.country
              ) {
                formik.handleSubmit();
              }
            },
            selectValue: formik.values.country
              ? {
                  label: formik.values.country,
                  value:
                    (i18n.language === 'en'
                      ? countries
                      : portugueseCountries
                    ).find((c) => c.name === formik.values.country)
                      ?.alpha2Code ?? 'none',
                }
              : null,
            selectOptions: (i18n.language === 'en'
              ? countries
              : portugueseCountries
            )
              .map((c) => {
                return { label: c.name, value: c.alpha2Code };
              })
              .filter((c) => c.label !== formik.values.country),
            selectOnChange: (value) => {
              formik.setFieldValue('country', getCountry(value?.value));
            },
          })}
          {renderDataBox({
            label: t('user_preferences.birthdate'),
            labelValue: handleUserDate(currentUser.attributes.birthdate),
            name: 'birthdate',
            onClick: () => {
              openAndCloseEdits('birthdate');
              if (
                openEdits.birthdate &&
                formik.values.birthdate !== currentUser.attributes.birthdate
              ) {
                formik.handleSubmit();
              }
            },
            inputType: 'date',
            inputValue: formik.values.birthdate,
            inputOnChange: (event) =>
              formik.setFieldValue('birthdate', event.target.value),
          })}
          {renderDataBox({
            label: t('user_preferences.role'),
            labelValue: currentUser.role?.label,
            name: 'role',
            onClick: () => {},
            inputType: null,
            inputValue: null,
            inputOnChange: () => {},
            cursor: 'default',
          })}
          {renderDataBoxWithDropdown({
            label: t('user_preferences.language'),
            name: 'language',
            labelValue: getLanguages().find((lang) => {
              return lang.value === (getCookie('i18nextLng') || 'en');
            })?.label,
            onClick: () => {
              openAndCloseEdits('language');
              if (openEdits.language && newLanguage !== i18n.language) {
                changeLanguage(newLanguage ?? i18n.language);
              }
            },
            selectValue: getLanguages().find((lang) => {
              if (newLanguage) {
                return lang.value === newLanguage;
              }
              return lang.value === i18n.language;
            }),
            selectOptions: getLanguages(),
            selectOnChange: (value) => {
              setNewLanguage(value?.value ?? i18n.language);
            },
            isClearable: false,
          })}
          {!currentUser.attributes.company_id && renderDeleteAccount()}
        </CustomPlainBody>
      </Box>
    </Box>
  );
};
