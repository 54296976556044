import { Box } from '@chakra-ui/react';
import { useContext } from 'react';
import GeneralDataContext from '../../context/generalDataContext';
import ScrollableTextBox from '../common/ScrollableTextBox';
import { logoUdemy } from '../../assets/svg/logoUdemy';
import { logoIese } from '../../assets/svg/logoIese';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const MyLearningJourney = () => {
  const generalData = useContext(GeneralDataContext);

  const { userLearnings } = generalData;

  const { view } = useSelector((state: any) => {
    return { view: state.view };
  });

  const { t } = useTranslation();

  return (
    <Box
      width={'479px'}
      height={'350px'}
      background={'#0A0A0A33'}
      backdropFilter={'blur(25px)'}
      display={'flex'}
      color={'#F4F3F2'}
      position={'relative'}
      bottom={0}
      padding={'20px 15px'}
      m={20}
      marginTop={'0px'}
      left={0}
      flexDirection={'column'}
      gap={'15px'}
      marginLeft={'30px'}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onScroll={(e) => {
        e.stopPropagation();
      }}
      marginRight={view.showSkills ? '0px' : '35px'}
      pointerEvents={'auto'}
      borderRadius={'5px'}
      marginBottom={"0px"}
    >
      <Box
        fontSize={'16px'}
        w={'fit-content'}
        textAlign={'left'}
        fontFamily={'PoppinsBold'}
      >
        {t('learning.my_learning_journey')}
      </Box>
      <Box display={'flex'} gap={'15px'} height={'fit-content'}>
        <Box
          borderRadius={'5px'}
          border={'2px solid #F4F3F21A'}
          width={'217px'}
          height={'fit-content'}
          padding={'10px 15px'}
          display={'flex'}
          flexDirection={'column'}
          gap={'15px'}
          minHeight={'100px'}
        >
          <Box
            fontFamily={'PoppinsRegular'}
            fontSize={'14px'}
            lineHeight={'18px'}
            width={'100%'}
            textAlign={'left'}
          >
            {t('learning.my_wish_list')}
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={'10px'}>
            {userLearnings.map((learning: any) => (
              <Box
                fontFamily={'PoppinsRegular'}
                fontSize={'8px'}
                textAlign={'left'}
                display={'flex'}
                alignItems={'center'}
                lineHeight={'18px'}
                gap={'15px'}
              >
                <Box>
                  {learning?.learning_host === 'udemy'
                    ? logoUdemy()
                    : logoIese()}
                </Box>
                <Box display={'flex'} flexDirection={'column'}>
                  <Box display={'flex'}>
                    <Box lineHeight={'18px'}>
                      <ScrollableTextBox
                        text={learning?.skill?.label}
                        maxW={'50px'}
                        styles={{ marginLeft: '0px' }}
                      />{' '}
                    </Box>
                    |{' '}
                    <Box lineHeight={'18px'}>
                      <ScrollableTextBox
                        text={`level ${learning?.skill_level?.map(
                          (level, index) => {
                            return `${index > 0 ? ',' : ''}${level}`;
                          },
                        )}`}
                        maxW={'80px'}
                        styles={{
                          marginLeft: '3px',
                          marginRight: '3px',
                        }}
                      />{' '}
                    </Box>{' '}
                    |{' '}
                    <Box lineHeight={'18px'}>
                      {' '}
                      <ScrollableTextBox
                        text={learning?.type}
                        maxW={'40px'}
                        styles={{ marginLeft: '3px' }}
                      />
                    </Box>
                  </Box>
                  <Box
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(learning.link);
                    }}
                    cursor={'pointer'}
                    fontSize={'10px'}
                    fontFamily={'PoppinsExtraBold'}
                    lineHeight={'18px'}
                    whiteSpace={'nowrap'}
                  >
                    <ScrollableTextBox
                      text={learning?.title}
                      maxW={'130px'}
                      styles={{ marginLeft: '0px' }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          borderRadius={'5px'}
          border={'2px solid #F4F3F21A'}
          width={'217px'}
          height={'100%'}
          minHeight={'100px'}
          padding={'10px 15px'}
          display={'flex'}
          flexDirection={'column'}
          gap={'15px'}
        >
          <Box
            fontFamily={'PoppinsRegular'}
            fontSize={'14px'}
            lineHeight={'18px'}
            width={'100%'}
            textAlign={'left'}
          >
            {t('learning.learning_history')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
