export const arrowDirectional = ({
  transform = 'rotate(-90deg)',
  hideArrow = false,
  color = '#F4F3F2',
  width = 7,
  height = 12,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform, visibility: hideArrow ? 'hidden' : 'visible' }}
    >
      <path
        d="M1 11L6 6L1 1"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
