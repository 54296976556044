export const skillLevelCounter = (
  isWhite,
  isCareer?: boolean,
  whiteOutline?: boolean,
) => {
  const filledColor = '#EE4B60';
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.974242 6.08592C0.616744 5.46696 0.616744 4.70427 0.974242 4.08531L2.38619 1.64073C2.7435 1.0221 3.40366 0.641035 4.11807 0.641035L6.94336 0.641035C7.65777 0.641035 8.31792 1.0221 8.67523 1.64073L10.0872 4.08531C10.4447 4.70427 10.4447 5.46696 10.0872 6.08592L8.67523 8.5305C8.31792 9.14913 7.65776 9.53019 6.94336 9.53019L4.11807 9.53019C3.40366 9.53019 2.7435 9.14913 2.38619 8.5305L0.974242 6.08592Z"
        fill={isWhite ? filledColor : '#F4F3F240'}
        stroke={whiteOutline ? '#F4F3F2' : 'rgba(0, 0, 0, 0)'}
      />
    </svg>
  );
};
