import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import {
  barCont,
  containerTimeMachine,
  innerScroll,
  labelRole,
  scrollContainerRoles,
} from './TimeMachineStyles';
import { useEffect, useContext, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SkillCard } from '../SkillCard/SkillCard';
import { defineView } from '../../store/actions/viewActions';
import { getSubSkills, maskStyle } from './TimeMachine.service';
import GraphContext from '../../context/context';
import { useTranslation } from 'react-i18next';
import { MySkillsCard } from './MySkillsCard';
import { SkillsNavigator } from './SkillsNavigator';
import GeneralDataContext from '../../context/generalDataContext';
import ScrollableTextBox from '../common/ScrollableTextBox';
import { handleHorizontalScroll } from '../../utils/common/horizontalScroll';
import { MyLearningJourney } from './MyLearningJourney';
import { arrowDirectional } from '../../assets/svg/arrowDirectional';

export const TimeMachine = () => {
  const dispatch: any = useDispatch();
  const context = useContext(GraphContext);
  const generalDataContext = useContext(GeneralDataContext);
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [isLargerThan2500] = useMediaQuery('(min-width: 2500px)');
  const [showRoleArrow, setShowRoleArrow]: any = useState(null);

  const containerRef = useRef(null);

  const [hasOverflow, setHasOverflow] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const { view } = useSelector((state: any) => {
    return { currentUser: state.currentUser, view: state.view };
  });

  const {
    setHoveredLabel,
    currentSkill,
    setCurrentSkill,
    roles,
    setRoles,
    setHoveredSubLabel,
    handleClickSkills: handleClick,
    setSelectedSkillForGraphFilter,
  } = context;

  const {
    skills,
    showMySkillsSkillsView: showMySkills,
    setShowMySkillsSkillsView: setShowMySkills,
  } = generalDataContext;

  const { t } = useTranslation();

  useEffect(() => {
    const handleDataFromView = async () => {
      if (view?.skillsData?.skill_id) {
        const skillToScroll = skills.filter((item) => {
          return item.skill_id === view.skillsData.skill_id;
        });

        //scroll to skill by ID property
        if (skillToScroll.length > 0) {
          const element = document.getElementById(
            `${skillToScroll[0].skill_id}`,
          );

          const subSkills = skills.filter((item) => {
            return item.parent_skill_id === skillToScroll[0].skill_id;
          });

          if (!subSkills.length && skillToScroll[0].parent_skill_id !== 0) {
            const data = await getSubSkills(skillToScroll[0].parent_skill_id);
            setCurrentSkill(data);
            setSelectedSkillForGraphFilter(data?.skills[0]);
            setHoveredSubLabel(skillToScroll[0].label);
          }

          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });

            //setCurrentSkill([view.skillsData]);
          }

          setHoveredLabel(skillToScroll[0].label);
          handleClick(
            skillToScroll[0],
            !subSkills.length && skillToScroll[0].parent_skill_id !== 0,
          );
        }
      }
    };

    handleDataFromView();
  }, [view?.skillsData, skills]);

  useEffect(() => {
    if (view.skillsFilter) {
      setCurrentSkill(undefined);
      setSelectedSkillForGraphFilter(null);
      setRoles([]);
    }
  }, [view.skillsFilter]);

  useEffect(() => {
    if (!view.skillsFilter && !view.skillsData && !view.skillsExtra) {
      setCurrentSkill(null);
      setSelectedSkillForGraphFilter(null);
      setRoles([]);
    }
  }, [view.skillsFilter, view.skillsData, view.skillsExtra]);

  useEffect(() => {
    if (view.mySkillsOpen) {
      setShowMySkills(true);
      dispatch(defineView({ ...view, mySkillsOpen: false }));
    }
  }, [view.mySkillsOpen]);

  useEffect(() => {
    const checkOverflow = () => {
      const element: any = document.getElementById('scroll-box'); // Your Box selector
      const isOverflowing = element?.scrollHeight > element?.clientHeight;
      setHasOverflow(isOverflowing);
    };

    // Check overflow when the roles change or component mounts
    checkOverflow();

    // Add event listeners for resize and scroll
    const element: any = document.getElementById('scroll-box');
    const handleScroll = () => {
      setScrollPosition(element?.scrollTop);
    };
    if (element) {
      element?.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('resize', checkOverflow);

    // Cleanup
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }

      window.removeEventListener('resize', checkOverflow);
    };
  }, [roles]);

  


  return (
    <Box
      {...containerTimeMachine}
      pointerEvents={'auto'}
      justifyContent={'space-between'}
      ref={containerRef}
      onWheel={(e) => {
        handleHorizontalScroll(e, containerRef);
      }}
      css={{
        /* Firefox */
        scrollbarWidth: 'thin',
        scrollbarColor: '#F2F3F41A transparent',

        /* WebKit (Chrome, Safari) */
        '&::-webkit-scrollbar': {
          width: '9px',
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent', // Transparent track color
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#F2F3F41A', // Thumb color
          borderRadius: '10px',
          minHeight: '20px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#F2F3F480',
          width: '9px',
        },
      }}
    >
      {currentSkill?.skills && false && <SkillCard skill={currentSkill} />}

      <SkillsNavigator
        setShowMySkills={setShowMySkills}
        setRolesScrollPosition={setScrollPosition}
      />
      {showMySkills && <MySkillsCard />}
      {showMySkills && <MyLearningJourney />}
      {roles.length > 0 && (
        <Box
          {...scrollContainerRoles(isLargerThan1300, isLargerThan2500)}
          marginLeft={'30px'}
          backdropFilter={'blur(25px)'}
          background={'#0A0A0A33'}
          marginBottom={'0px'}
          alignItems={'flex-end'}
        >
          <Flex fontSize={'16px'} gap={0} flexDir={'row'} w={'full'}>
            <Text
              as={'span'}
              w={'full'}
              textAlign={'right'}
              fontFamily={'PoppinsExtraBold'}
            >
              {t('app.roles')}
            </Text>
            <Text
              w={'fit-content'}
              textAlign={'left'}
              ml={2}
              fontFamily={'PoppinsRegular'}
              whiteSpace={'nowrap'}
            >
              {t('app.for_selected_skill')}
            </Text>
          </Flex>
          <Box
            {...innerScroll}
            my={0}
            py={'30px'}
            mb={'-3'}
            h={'calc(100% - 80px) !important '}
            borderRight={hasOverflow ? '1px solid white' : ''}
            gap={'9px'}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            width={'fit-content'}
            id="scroll-box"
            style={{
              maskImage: maskStyle('scroll-box', hasOverflow, scrollPosition),
              WebkitMaskImage: maskStyle(
                'scroll-box',
                hasOverflow,
                scrollPosition,
              ),
            }}
            onWheel={(e) => {
              if (hasOverflow) {
                e.stopPropagation();
              }
            }}
          >
            {roles.map((role: any, index) => (
              <Box
                {...barCont}
                justifyContent={'flex-end'}
                key={role?.role_id}
                onMouseEnter={() => {
                  setShowRoleArrow(`role-list-${index}`);
                }}
                onMouseLeave={() => {
                  setShowRoleArrow(null);
                }}
                _hover={{
                  div: {
                    background: 'rgba(255,255,255,0.1)',
                    borderRadius: '5px',
                  },
                  transition: 'all 0.3s ease',
                }}
                id={`role-list-${index}`}
              >
                <Box
                  {...labelRole}
                  fontFamily={'PoppinsRegular'}
                  fontSize={'14px'}
                  onClick={() => {
                    dispatch(
                      defineView({
                        showSemi: true,
                        showSkills: false,
                        showFamily: false,
                        showSubFamily: false,
                        showProgressions: false,
                        roleData: {
                          ...role,
                          x: null,
                          y: null,
                          vx: null,
                          vy: null,
                        },
                        familyData: undefined,
                        skillsData: undefined,
                        rolesExtra: { skill: currentSkill.skills[0].skill_id },
                        skillsExtra: undefined,
                        skillsFilter: undefined,
                        rolesFilter: undefined,
                        progressionsData: undefined,
                      }),
                    );
                  }}
                  //transition={'all 0.3s ease'}
                  whiteSpace={'nowrap'}
                  display={'flex'}
                  width={'100%'}
                >
                  {arrowDirectional({
                    transform: 'rotate(180deg)',
                    hideArrow: showRoleArrow !== `role-list-${index}`,
                  })}
                  <ScrollableTextBox
                    text={role.id}
                    maxW={'calc(100vw * 0.1)'}
                  />
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
