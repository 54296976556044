import { border } from '@chakra-ui/react';

export const primary = {
  w: '100%',
  py: 4,
  px: 2,
  bg: 'white',
  border: '1px solid black',
};

export const primaryHover = {
  border: '1px solid black',
};

export const secondary = {
  w: '100%',
  py: 4,
  px: 2,
  bg: 'gray.200',
};

export const inputGroup = {
  w: '100%',
  bg: 'white',
  border: '1px solid black',
  borderRadius: '5px',
};

export const login = (isLargerThan1500) => {
  return {
    w: '100%',
    bg: 'transparent',
    border: '0.5px solid #F4F3F2',
    borderRadius: '5px',
    padding: '15px',
    height: isLargerThan1500 ? '50px' : '45px',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'PoppinsLight',
  };
};

export const loginHover = {
  border: '1px solid white',
};

export const loginFocus = {
  border: '1px solid white',
};
