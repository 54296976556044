export const calculateNodePositions = (nodes, nodesPerScreen, height) => {
  let circle = height / 3.5;
  if (circle > 499) circle = 500;
  const radius = circle; // Radius of choice, // TODO implement dynamic
  const centerX = circle; // TODO implement dynamic regarding screen size
  const centerY = circle; // TODO implement dynamic regarding screen size
  const angleIncrement = Math.PI / (nodesPerScreen + 3);
  const final = nodes.map((node, i) => {
    const angle = Math.PI - angleIncrement * i - 20.865; // This number can be tweaked so the positions can be sorted in a better way
    const x = centerX + radius * Math.cos(angle);
    const y = centerY - radius * Math.sin(angle); // Negating y to calculate inversely
    return { ...node, x, y };
  });
  return final;

  //THIS RENDERS A MORE PERFECT SEMI CIRCLE NEED TO POSITION AND TEST IT BETTER
  // // Center horizontally with a 70px offset from the left
  // const centerX = 20 + radius / 2;
  // const centerY = height / 2; // Centered vertically on the screen

  // // Calculate the angle increment to space nodes evenly along the semi-circle
  // const angleIncrement = Math.PI / (nodesPerScreen - 1); // Covers the range from 0 to π

  // // Map each node to a position on the vertical semi-circle
  // const final = nodes.map((node, i) => {
  //   const angle = angleIncrement * i; // Angle along the semi-circle
  //   const x = centerX + radius * Math.sin(angle) - 20; // sin for x to make it vertical
  //   const y = centerY - radius * Math.cos(angle); // cos for y for vertical positioning
  //   return { ...node, x, y };
  // });

  // return final;
};

export const calculateScore = (baseRole, role) => {
  const score = calculatePercentageBySkills(baseRole, role);

  return score;
};

export const calculatePercentageBySkills = (baseRole, evaluatedRole) => {
  if (!evaluatedRole?.skills || !baseRole?.skills) return 0;

  const skillBaseHas = baseRole.skills.map((skill) => ({
    name: skill?.name ? skill.name?.trim() : skill?.label?.trim(),
    level: skill.level,
  }));

  const totalSkills = evaluatedRole.skills.length;
  let percentageSum = 0;

  evaluatedRole.skills.forEach((evaluatedSkill) => {
    const baseSkill = skillBaseHas.find(
      (baseSkill) => baseSkill.name === evaluatedSkill.name?.trim(),
    );

    if (baseSkill) {
      if (baseSkill.level >= evaluatedSkill.level) {
        // If base skill level is equal or higher, count it as 100%
        percentageSum += 100;
      } else {
        // If base skill level is lower, calculate how close it is to the evaluated skill level
        const levelDifferencePercentage =
          (baseSkill.level / evaluatedSkill.level) * 100;
        percentageSum += levelDifferencePercentage;
      }
    }
  });

  // Calculate the final percentage score
  return Math.round(percentageSum / totalSkills);
};
