export const search = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3574 13.5489C20.0041 11.7017 19.9263 9.69151 19.1386 7.89553C18.3509 6.09954 16.9075 4.64132 15.0792 3.79461C13.251 2.94789 11.1637 2.7709 9.20911 3.29686C7.25456 3.82283 5.56719 5.01558 4.46376 6.65121C3.36033 8.28684 2.91671 10.2529 3.21619 12.1802C3.51567 14.1076 4.53764 15.8637 6.09027 17.119C7.6429 18.3743 9.6194 19.0424 11.6488 18.9979C13.6781 18.9533 15.6208 18.1993 17.112 16.8772"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1875 16.9579L22.3958 21.958"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
