import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineView } from '../store/actions/viewActions';
import useSize from '../utils/useSize';
import { careersAPI } from '../utils/helpers/axios';
import { calculatePercentageBySkills } from '../components/SemiCircle/SemiCircle.service';
import { getSubSkills } from '../components/TimeMachine/TimeMachine.service';
import GeneralDataContext from './generalDataContext';
const GraphContext = React.createContext<any>(undefined as any);

interface GraphContextWrapperProps {
  children: any;
  references: any;
  nodes: any;
  pathMode: any;
}

const GraphContextWrapper: React.FC<GraphContextWrapperProps> = ({
  children,
  references,
  nodes,
  pathMode,
}) => {
  // redux destructuring
  const dispatch = useDispatch();

  const state = useSelector((state: any) => state);

  const { view, currentUser } = state;

  const {
    showFamily,
    showSubFamily,
    showProgressions,
    showSemi,
    showSkills,
    roleData,
    skillsData,
  } = view;

  // state management (internal of context)
  //semi circle
  const [selectedNode, setSelectedNode]: any = useState(null);
  const [expanded, setExpanded] = useState(false);

  //skills
  const [hoveredLabel, setHoveredLabel]: any = useState('');
  const [hoveredSubLabel, setHoveredSubLabel]: any = useState('');
  const [clickedLabel, setClickedLabel]: any = useState('');
  const [clickedSubLabel, setClickedSubLabel]: any = useState('');
  const [currentSkill, setCurrentSkill]: any = useState(null);
  const [selectedSkillForGraphFilter, setSelectedSkillForGraphFilter]: any =
    useState(null);
  const [roles, setRoles]: any = useState([]);

  //family
  const [selectedSection, setSelectedSection]: any = useState(null);
  const [selectedSubSection, setSelectedSubSection]: any = useState(null);

  //progressions
  const [cards, setCards]: any = useState([]);
  const [expandedCardIndex, setExpandedCardIndex] = useState(null);
  const [viewCardIndex, setViewCardIndex]: any = useState(0);
  const [firstEntry, setFirstEntry] = useState(true);
  const [customProgressions, setCustomProgressions] = useState(null);
  const [careerConfigs, setCareerConfigs]: any = useState(null);
  const [openOpportunities, setOpenOpportunities] = useState([]);
  const windowSize = useSize();
  const previousProgressions: any = useRef([]);

  const generalDataContext = useContext(GeneralDataContext);
  const { skills, roles: rolesData } = generalDataContext;

  const drawRoundedRectangle = (ctx, radius, number) => {
    const squareWidth = 45;
    const squareHeight = 43;
    const borderRadius = 10;
    const squareFillColor = '#0A0A0A33';
    const fontSize = 33;
    const fontColor = '#FFFFFF'; // White color

    // Calculate the position of the rectangle to be above the circle
    const rectX = -squareWidth / 2;
    const rectY = -(radius + squareHeight + 10); // 5px padding above the circle

    // Draw rounded rectangle
    ctx.beginPath();
    const r = borderRadius;

    // Drawing rounded rectangle path
    ctx.moveTo(rectX + r, rectY);
    ctx.arcTo(
      rectX + squareWidth,
      rectY,
      rectX + squareWidth,
      rectY + squareHeight,
      r,
    );
    ctx.arcTo(
      rectX + squareWidth,
      rectY + squareHeight,
      rectX,
      rectY + squareHeight,
      r,
    );
    ctx.arcTo(rectX, rectY + squareHeight, rectX, rectY, r);
    ctx.arcTo(rectX, rectY, rectX + squareWidth, rectY, r);
    ctx.closePath();

    ctx.fillStyle = squareFillColor;
    ctx.fill();

    // Set font properties for the number
    ctx.font = `${fontSize}px PoppinsExtraBold`;
    ctx.fillStyle = fontColor;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    // Calculate the position to center the number inside the rectangle
    const textX = rectX + squareWidth / 2;
    const textY = rectY + squareHeight / 2 + 5;

    ctx.fillText(number, textX, textY);
  };

  const drawSquare = (
    ctx,
    x,
    y,
    innerOutline,
    fill,
    outerOutline = null,
    drawNumber,
    number,
  ) => {
    const size = 70; // Size of the square
    const cornerRadius = 5; // Radius for rounded corners
    const outerSize = size + 20; // Larger size for the outer outline

    ctx.save();
    ctx.translate(x, y);

    // Draw outer outline
    if (outerOutline) {
      ctx.beginPath();
      ctx.moveTo(-outerSize / 2 + cornerRadius, -outerSize / 2);
      ctx.arcTo(
        outerSize / 2,
        -outerSize / 2,
        outerSize / 2,
        outerSize / 2,
        cornerRadius,
      );
      ctx.arcTo(
        outerSize / 2,
        outerSize / 2,
        -outerSize / 2,
        outerSize / 2,
        cornerRadius,
      );
      ctx.arcTo(
        -outerSize / 2,
        outerSize / 2,
        -outerSize / 2,
        -outerSize / 2,
        cornerRadius,
      );
      ctx.arcTo(
        -outerSize / 2,
        -outerSize / 2,
        outerSize / 2,
        -outerSize / 2,
        cornerRadius,
      );
      ctx.closePath();
      ctx.strokeStyle = outerOutline;
      ctx.lineWidth = 10; // Thicker outer outline
      ctx.stroke();
    }

    // Draw square with rounded corners
    ctx.beginPath();
    ctx.moveTo(-size / 2 + cornerRadius, -size / 2);
    ctx.arcTo(size / 2, -size / 2, size / 2, size / 2, cornerRadius);
    ctx.arcTo(size / 2, size / 2, -size / 2, size / 2, cornerRadius);
    ctx.arcTo(-size / 2, size / 2, -size / 2, -size / 2, cornerRadius);
    ctx.arcTo(-size / 2, -size / 2, size / 2, -size / 2, cornerRadius);
    ctx.fillStyle = fill;
    ctx.fill();
    ctx.strokeStyle = innerOutline;
    ctx.lineWidth = 3; // Thinner inner outline (adjust as needed)
    ctx.stroke();

    if (drawNumber) {
      drawRoundedRectangle(ctx, innerOutline ? 65 : 45, number);
    }

    ctx.restore();
  };

  const drawCircle = (
    ctx,
    x,
    y,
    innerOutline,
    fill,
    outerOutline = null,
    drawNumber,
    number,
  ) => {
    const radius = 25; // Radius of the circle
    const outerRadius = 28; // Larger radius for the outer outline

    ctx.save();
    ctx.translate(x, y);

    // Draw outer outline
    if (outerOutline) {
      ctx.beginPath();
      ctx.arc(0, 0, outerRadius, 0, 2 * Math.PI);
      ctx.strokeStyle = outerOutline;
      ctx.lineWidth = 10; // Thicker outer outline
      ctx.stroke();
    }

    // Draw circle
    ctx.beginPath();
    ctx.arc(0, 0, radius, 0, 2 * Math.PI);
    ctx.fillStyle = fill;
    ctx.fill();
    ctx.strokeStyle = innerOutline;
    ctx.lineWidth = 3; // Thinner inner outline (adjust as needed)
    ctx.stroke();

    if (drawNumber) {
      drawRoundedRectangle(ctx, innerOutline ? outerRadius : radius, number);
    }

    ctx.restore();
  };

  const drawRotatedSquare = (
    ctx,
    x,
    y,
    innerOutline,
    fill,
    outerOutline = null,
    drawNumber,
    number,
  ) => {
    ctx.save();
    ctx.translate(x, y);
    ctx.rotate(Math.PI / 4);
    drawSquare(ctx, 0, 0, innerOutline, fill, outerOutline, false, number);

    if (drawNumber) {
      ctx.rotate(-Math.PI / 4);
      drawRoundedRectangle(ctx, outerOutline ? 65 : 45, number);
    }
    ctx.restore();
  };

  const calculateDistances = (currentRole) => {
    const distancesObject = {};
    for (let i = 0; i < nodes.length; i++) {
      const currentRoleSkills: any = currentRole.skills;
      const comparedRole = nodes[i];
      const comparedRoleSkills: any = comparedRole.skills;

      const uniqueIds = [
        ...new Set([
          ...currentRoleSkills.map((skill: any) => skill.skill_id),
          ...comparedRoleSkills.map((skill: any) => skill.skill_id),
        ]),
      ];

      let insideRoot = 0;
      let countEqualSkills = 0;

      for (const id of uniqueIds) {
        let weight = 1;

        const skillInCurrentRole = currentRoleSkills.find(
          (skill: any) => skill.skill_id === id,
        );

        const skillInCompareRole = comparedRoleSkills.find(
          (skill: any) => skill.skill_id === id,
        );

        if (skillInCompareRole && skillInCurrentRole) {
          weight = 1;
          countEqualSkills++;
        } else {
          weight = 1;
        }

        const firstOperand =
          currentRoleSkills.find((skill: any) => skill.skill_id === id)
            ?.level ?? 0;

        const secondOperand =
          comparedRoleSkills.find((skill: any) => skill.skill_id === id)
            ?.level ?? 0;

        insideRoot += Math.pow(firstOperand - secondOperand, 2) * weight;
      }

      const distance = Math.sqrt(insideRoot);

      distancesObject[comparedRole.role_id] = {
        distance: distance * 100,
        role_id: comparedRole.role_id,
        label: comparedRole.id,
        jobLevelRange: comparedRole.jobLevelRange,
        percentageComparison: calculatePercentageBySkills(
          currentRole,
          comparedRole,
        ),
      };
    }

    return distancesObject;
  };

  function isRangeAtLeastEqualOrHigher(range1 = [0, 0], range2 = [0, 0]) {
    const [start1, end1] = range1;
    const [start2, end2] = range2;

    // Check if range1 is equal to or entirely higher than range2
    return start1 >= start2 && end1 >= end2;
  }

  const getNextThreeProgreessions = () => {
    const { lastRoleSelected } = references.current;

    if (pathMode === 'custom' && customProgressions) {
      const nextProgressions: any =
        customProgressions[`${lastRoleSelected.role_id}`];

      if (!nextProgressions) return [];

      return nextProgressions.map((role) => {
        return { role_id: role };
      });
    }

    if (
      !cards[cards.length - 1] ||
      !cards[cards.length - 1].skills ||
      !careerConfigs
    ) {
      return [];
    }

    const reducedSkills = cards.reduce((acc, role) => {
      role.skills.forEach((skill) => {
        const existingSkill = acc.find((s) => s.skill_id === skill.skill_id);
        if (existingSkill) {
          // If the skill exists, update the level if it's higher
          existingSkill.level = Math.max(existingSkill.level, skill.level);
        } else {
          // If it doesn't exist, add it to the accumulator
          acc.push({
            skill_id: skill.skill_id,
            name: skill.name.trim(),
            level: skill.level,
          });
        }
      });
      return acc;
    }, []);

    const distances = calculateDistances({ skills: reducedSkills });

    const values = Object.values(distances).filter((value: any) => {
      let rangeComparison;

      if (careerConfigs.compareOrgGroups) {
        rangeComparison = isRangeAtLeastEqualOrHigher(
          value.jobLevelRange,
          lastRoleSelected.jobLevelRange,
        );

        return (
          //value.distance !== 0 &&
          value.role_id !== lastRoleSelected.role_id &&
          rangeComparison &&
          value.percentageComparison >= careerConfigs.pathVisibilityPercentage
        );
      } else {
        return (
          value.role_id !== lastRoleSelected.role_id &&
          value.percentageComparison >= careerConfigs.pathVisibilityPercentage
        );
      }
    });
    const sortedValues = values.sort(
      (a: any, b: any) => a.distance - b.distance,
    );

    return sortedValues;
  };

  useEffect(() => {
    if (!showFamily) {
      setSelectedSubSection(null);
      setSelectedSection(null);
    }

    if (!showProgressions && cards.length) {
      setCards([]);
    }

    if (
      showProgressions &&
      cards.length === 0 &&
      currentUser?.role.skills &&
      !view.progressionsData
    ) {
      setCards([currentUser?.role]);
      references.current.lastRoleSelected = currentUser?.role;
    } else if (
      view.progressionsData &&
      cards.length === 0 &&
      showProgressions
    ) {
      setCards([view.progressionsData]);
      references.current.lastRoleSelected = view.progressionsData;
    }
  }, [showFamily, showProgressions, currentUser, cards, view.progressionsData]);

  useEffect(() => {
    const getCustomProgressions = async () => {
      try {
        const { data } = await careersAPI.post('/getCustomPaths', {
          companyId: currentUser.attributes.company_id,
        });

        setCustomProgressions(data?.pathObject);
      } catch (err) {
        console.log(err);
      }
    };

    if (pathMode === 'custom' && !customProgressions && currentUser) {
      getCustomProgressions();
    }
  }, [pathMode, customProgressions, currentUser]);

  useEffect(() => {
    const getCareerConfigs = async () => {
      try {
        const { data } = await careersAPI.post('/getCareerConfigs', {
          companyId: currentUser.attributes.company_id,
        });

        setCareerConfigs(data);
      } catch (err) {
        console.log(err);
      }
    };

    getCareerConfigs();
  }, []);

  const previousPathMode = useRef(pathMode);

  useEffect(() => {
    if (previousPathMode.current !== pathMode) {
      setCards(cards.slice(cards.length - 1));
      previousPathMode.current = pathMode;
    }

    previousProgressions.current = [];
  }, [pathMode]);

  useEffect(() => {
    const getOpenOpportunities = async () => {
      try {
        const { data } = await careersAPI.post(
          '/getOpenOpportunitiesByCompany',
          {
            companyId: currentUser.attributes.company_id,
          },
        );

        setOpenOpportunities(data?.open_opportunities);
      } catch (err) {
        console.log(err);
      }
    };

    getOpenOpportunities();
  }, []);

  const orange = '#F6851E';
  const white = 'white';
  const greyed = 'rgba(255, 255, 255, 0.2)';
  const pink = '#EE4B60';

  const orangeOutlineCircle = (
    ctx,
    x,
    y,
    outline?: any,
    drawNumber?: any,
    number?: any,
  ) => {
    drawCircle(ctx, x, y, orange, greyed, outline, drawNumber, number);
  };
  const orangeFilledCircle = (
    ctx,
    x,
    y,
    outline?: any,
    drawNumber?: any,
    number?: any,
  ) => {
    drawCircle(ctx, x, y, orange, orange, outline, drawNumber, number);
  };
  const whiteFilledCircle = (
    ctx,
    x,
    y,
    outline?: any,
    drawNumber?: any,
    number?: any,
  ) => {
    drawCircle(ctx, x, y, white, white, outline, drawNumber, number);
  };
  const greyedCircle = (
    ctx,
    x,
    y,
    outline?: any,
    drawNumber?: any,
    number?: any,
  ) => {
    drawCircle(ctx, x, y, greyed, greyed, outline, drawNumber, number);
  };
  const userSquare = (
    ctx,
    x,
    y,
    outline?: any,
    drawNumber?: any,
    number?: any,
  ) => {
    drawRotatedSquare(ctx, x, y, pink, greyed, outline, drawNumber, number);
  };
  const filledUserSquare = (
    ctx,
    x,
    y,
    outline?: any,
    drawNumber?: any,
    number?: any,
  ) => {
    drawRotatedSquare(ctx, x, y, pink, pink, outline, drawNumber, number);
  };

  const centerValues = (): any => {
    if (showSemi) {
      //return [window.innerWidth * -1, (window.innerHeight / 2) * -1, 1000];
      return [
        windowSize.width < 1920 ? windowSize.width * -1 : -1720,
        windowSize.height < 1660
          ? (windowSize.height / 2.5) * -1
          : (windowSize.height / 7) * -1,
        1000,
      ];
    } else if (showProgressions) {
      return [0, -250, 1000];
    } else if (showSkills) {
      return [0, 0, 1000];
    } else if (showFamily) {
      return [0, 0, 1000];
    } else {
      return [0, 0, 1000];
    }
  };

  const zoomValues = (): any => {
    if (showSubFamily) {
      return windowSize.width < 1200
        ? [1000, windowSize.height / 3.1]
        : windowSize.width < 1920
        ? [1000, windowSize.height / 3.1]
        : [1000, windowSize.height / 3.1];
    } else if (showFamily) {
      return windowSize.width < 1200
        ? [1000, windowSize.height / 3.4]
        : windowSize.width < 1920
        ? [1000, windowSize.height / 3.4]
        : [1000, windowSize.height / 3.4];
    } else if (showSkills) {
      return windowSize.width < 1920
        ? [1000, windowSize.width / 6]
        : [1000, windowSize.width / 12];
    } else if (showProgressions) {
      if (windowSize.width < 1200) {
        return [1000, windowSize.width / 7];
      }
      return [1000, windowSize.width / 10];
    } else if (showSemi) {
      if (windowSize.width < 1200) {
        return [1000, windowSize.width / 5.75];
      }
      return [1000, windowSize.width / 10];
    } else {
      return [1000, windowSize.width / 20];
    }
  };

  const panValues = (): any => {
    if (showSkills || showProgressions) {
      return true;
    } else if (showFamily || showSubFamily || showSemi) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    setFirstEntry(true);
  }, [showProgressions]);

  useEffect(() => {
    setTimeout(() => {
      references.current.graphRef
        .enablePanInteraction(panValues())
        .enableZoomInteraction(panValues())
        .zoomToFit(...zoomValues())
        .centerAt(...centerValues());
    }, 100);
  }, [
    references,
    view,
    showSemi,
    showProgressions,
    showFamily,
    showSubFamily,
    showSkills,
    roleData,
    skillsData,
    // cards,
    dispatch,
    selectedNode,
    currentSkill,
    selectedSection,
    selectedSubSection,
    references,
    windowSize,
  ]);

  const canShowOpenOpp = (openOpp) => {
    if (
      openOpp?.visibility === 'all' ||
      !currentUser.attributes.country ||
      !openOpp
    ) {
      return true;
    }

    if (openOpp?.visibility === 'open_countries') {
      const isUserCountryPresent = openOpp?.open_location.find((open) => {
        return open?.value === currentUser.attributes.country;
      });

      return !!isUserCountryPresent;
    }

    if (openOpp?.visibility === 'international') {
      const isUserCountryPresent = openOpp?.open_location.find((open) => {
        return open?.value === currentUser.attributes.country;
      });

      return !isUserCountryPresent;
    }

    return true;
  };

  // master function for graph manipulation
  useEffect(() => {
    const clickedIds = cards.map((card) => card.role_id);
    const { lastRoleSelected } = references.current;
    const nextProgressions: any = getNextThreeProgreessions();

    setTimeout(() => {
      references.current.graphRef
        .enablePanInteraction(panValues())
        .enableZoomInteraction(panValues())
        .onNodeClick((insideNode) => {
          references.current.lastRoleSelected = insideNode;
          if (showSkills) {
            dispatch(
              defineView({
                showSemi: true,
                showSkills: false,
                showFamily: false,
                showSubFamily: false,
                showProgressions: false,
                roleData: { ...insideNode, x: undefined, y: undefined },
                familyData: undefined,
                skillsData: undefined,
                rolesExtra: undefined,
                skillsExtra: undefined,
                progressionsData: null,
                skillsFilter: undefined,
                rolesFilter: undefined,
              }),
            );
          } else if (showProgressions) {
            setExpandedCardIndex(null);
            references.current.lastRoleSelected = insideNode;

            const find = nextProgressions.find(
              (role) => role.role_id === insideNode.role_id,
            );

            if (clickedIds.includes(insideNode.role_id)) {
              const index = cards.indexOf(insideNode) + 1;

              setCards(cards.slice(0, index));
              previousProgressions.current = previousProgressions.current.slice(
                0,
                index,
              );
              setViewCardIndex(cards.length);
            } else if (find) {
              setCards([...cards, insideNode]);
              previousProgressions.current = [
                ...previousProgressions.current,
                nextProgressions,
              ];
              setViewCardIndex(cards.length);
            } else {
              setViewCardIndex(0);
              setCards([insideNode]);
              previousProgressions.current = [];
            }
          } else if (showFamily) {
            dispatch(
              defineView({
                showSemi: true,
                showSkills: false,
                showFamily: false,
                showSubFamily: false,
                showProgressions: false,
                roleData: { ...insideNode, x: undefined, y: undefined },
                familyData: undefined,
                skillsData: undefined,
                skillsExtra: undefined,
                rolesExtra: { family: insideNode.family_id },
                progressionsData: null,
                skillsFilter: undefined,
                rolesFilter: undefined,
              }),
            );
          } else if (showSemi) {
            dispatch(
              defineView({
                showSemi: true,
                showSkills: false,
                showFamily: false,
                showSubFamily: false,
                showProgressions: false,
                roleData: { ...insideNode, x: undefined, y: undefined },
                familyData: undefined,
                skillsData: undefined,
                rolesExtra: undefined,
                skillsExtra: undefined,
                progressionsData: null,
                skillsFilter: undefined,
                rolesFilter: undefined,
              }),
            );
          }
        })
        .linkVisibility((link) => {
          if (showProgressions) {
            if (!currentUser?.role) return false;

            for (let i = cards.length - 1; i >= 1; i--) {
              if (
                (cards[i].role_id === link.source?.role_id ||
                  cards[i].role_id === link.target?.role_id) &&
                (cards[i - 1].role_id === link.source?.role_id ||
                  cards[i - 1].role_id === link.target?.role_id)
              ) {
                if (
                  previousProgressions.current[i - 1] &&
                  previousProgressions.current[i - 1].find(
                    (role) =>
                      role.role_id === link.source.role_id ||
                      role.role_id === link.target.role_id,
                  )
                ) {
                  return true;
                }

                return false;
              }
            }

            if (
              lastRoleSelected &&
              (lastRoleSelected.role_id === link.role_id ||
                lastRoleSelected.role_id === link.source?.role_id ||
                lastRoleSelected.role_id === link.target?.role_id)
            ) {
              const sliced = clickedIds.slice(0, clickedIds.length - 2);

              if (
                sliced.includes(link.source.role_id) ||
                sliced.includes(link.target.role_id)
              ) {
                return false;
              }

              const onNext = nextProgressions.find(
                (role) =>
                  role.role_id === link.source.role_id ||
                  role.role_id === link.target.role_id,
              );

              if (onNext) return true;
              return false;
            }
            return false;
            // do something
          } else {
            return false;
          }
        })
        .onNodeHover((insideNode) => {
          //show label only
        })
        .nodeLabel(
          (insideNode) => `
        <p style="font-family: 'PoppinsLight';">
          ${insideNode.id}
        </p>
      `,
        )
        .nodeCanvasObjectMode(() => 'replace')
        .nodeCanvasObject((insideNode, ctx) => {
          const isUserRole = insideNode?.role_id === currentUser?.role?.role_id;
          const x = insideNode?.x;
          const y = insideNode?.y;
          const isOpenOpp = openOpportunities.find((opp: any) => {
            return opp.role_id === insideNode.role_id;
          });
          let showOpenOpp = true;
          if (isOpenOpp) {
            showOpenOpp = canShowOpenOpp(isOpenOpp);
          }
          const cardIds = cards ? cards.map((card) => card.id) : [];

          if (showSkills) {
            if (selectedSkillForGraphFilter) {
              const currSkill = selectedSkillForGraphFilter?.skill_id;

              const hasSkill = insideNode.skills
                .map((insideSkill) => insideSkill.skill_id)
                .includes(currSkill);

              if (hasSkill) {
                if (isUserRole) {
                  filledUserSquare(ctx, x, y, greyed);
                  return;
                }

                if (isOpenOpp && showOpenOpp) {
                  orangeFilledCircle(ctx, x, y, greyed);
                  return;
                }

                whiteFilledCircle(ctx, x, y, greyed);
                return;
              } else {
                if (isUserRole) {
                  userSquare(ctx, x, y);
                  return;
                }
                if (isOpenOpp && showOpenOpp) {
                  orangeOutlineCircle(ctx, x, y);
                  return;
                }
                greyedCircle(ctx, x, y);
                return;
              }
            } else {
              if (isUserRole) {
                userSquare(ctx, x, y, greyed);
                return;
              }

              if (isOpenOpp && showOpenOpp) {
                orangeOutlineCircle(ctx, x, y);
                return;
              }

              greyedCircle(ctx, x, y);
              return;
            }
          } else if (showProgressions) {
            const isOpenOpp = openOpportunities.find((opp: any) => {
              return opp.role_id === insideNode.role_id;
            });

            let showOpenOpp = true;

            if (isOpenOpp) {
              showOpenOpp = canShowOpenOpp(isOpenOpp);
            }

            // const cardsZero = cards.length === 0 && isUserRole;
            const condition =
              insideNode.role_id === lastRoleSelected?.role_id ||
              clickedIds.includes(insideNode.id);
            const isFirstEntry =
              insideNode?.role_id === view?.progressionsData?.role_id &&
              firstEntry;

            const drawNumber =
              cardIds.includes(insideNode.id) &&
              cardIds.indexOf(insideNode.id) > 0;
            const number = drawNumber ? cardIds.indexOf(insideNode.id) : null;

            if (condition) {
              if (isUserRole) {
                filledUserSquare(ctx, x, y, greyed, drawNumber, number);
                return;
              }
              if (isOpenOpp && showOpenOpp) {
                orangeFilledCircle(ctx, x, y, greyed, drawNumber, number);
                return;
              }
              whiteFilledCircle(ctx, x, y, greyed, drawNumber, number);
              return;
            }
            if (isUserRole) {
              userSquare(ctx, x, y, null, drawNumber, number);
              return;
            }
            // if (isFirstEntry) {
            //   console.log("Here4", insideNode)
            //   setCards([insideNode]);
            //   references.current.lastRoleSelected = insideNode;
            //   setFirstEntry(false);
            // }

            if (isOpenOpp && showOpenOpp) {
              orangeOutlineCircle(ctx, x, y, null, drawNumber, number);
              return;
            } //! all nodes

            //continue colors logic
            greyedCircle(ctx, x, y, null, drawNumber, number);
            return;
          } else if (showFamily) {
            if (
              showSubFamily &&
              selectedSubSection &&
              selectedSection?.subFamilies.includes(selectedSubSection?.id)
            ) {
              if (insideNode.subfamily_id === selectedSubSection?.id) {
                if (isOpenOpp && showOpenOpp) {
                  orangeFilledCircle(ctx, x, y);
                  return 'white';
                }

                if (isUserRole) {
                  filledUserSquare(ctx, x, y, greyed);
                  return;
                }
                whiteFilledCircle(ctx, x, y);
                return 'white';
              } else {
                if (isOpenOpp && showOpenOpp) {
                  orangeOutlineCircle(ctx, x, y);
                  return 'rgba(255, 255, 255, 0.2)';
                }
                if (isUserRole) {
                  filledUserSquare(ctx, x, y, greyed);
                  return;
                }

                greyedCircle(ctx, x, y);
                return 'rgba(255, 255, 255, 0.2)';
              }
            } else {
              if (insideNode.family_id === selectedSection?.id) {
                if (isOpenOpp && showOpenOpp) {
                  orangeFilledCircle(ctx, x, y);
                  return 'white';
                }
                if (isUserRole) {
                  filledUserSquare(ctx, x, y, greyed);
                  return;
                }
                whiteFilledCircle(ctx, x, y);
                return 'white';
              } else {
                if (isOpenOpp && showOpenOpp) {
                  orangeOutlineCircle(ctx, x, y);
                  return 'rgba(255, 255, 255, 0.2)';
                }
                if (isUserRole) {
                  filledUserSquare(ctx, x, y, greyed);
                  return;
                }
                greyedCircle(ctx, x, y);
                return 'rgba(255, 255, 255, 0.2)';
              }
            }
          } else if (showSemi) {
            // do something

            if (isUserRole) {
              if (insideNode.role_id === selectedNode?.role_id) {
                filledUserSquare(ctx, x, y);
                return 'white';
              }

              userSquare(ctx, x, y);
              return;
            }

            if (insideNode.role_id === selectedNode?.role_id) {
              if (isOpenOpp && showOpenOpp) {
                orangeFilledCircle(ctx, x, y);
                return 'white';
              }

              whiteFilledCircle(ctx, x, y);
              return 'white';
            }

            if (
              insideNode.role_id === view?.roleData?.role_id &&
              selectedNode === null
            ) {
              if (isOpenOpp && showOpenOpp) {
                orangeFilledCircle(ctx, x, y);
                return 'white';
              }
              whiteFilledCircle(ctx, x, y);
              return 'white';
            }

            if (isOpenOpp && showOpenOpp) {
              orangeOutlineCircle(ctx, x, y);
              return 'rgba(244,243,242, 0.4)';
            }

            greyedCircle(ctx, x, y);
            return 'rgba(244,243,242, 0.4)';
          }
        });
    }, 10);
  }, [
    references,
    view,
    showSemi,
    showProgressions,
    showFamily,
    showSubFamily,
    showSkills,
    roleData,
    skillsData,
    cards,
    dispatch,
    selectedNode,
    currentSkill,
    selectedSection,
    selectedSubSection,
    windowSize,
    pathMode,
    customProgressions,
    careerConfigs,
    currentUser,
    selectedSkillForGraphFilter,
  ]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === 'visible' &&
        references.current.graphRef
      ) {
        // Reinitialize forces
        const graphRef = references.current.graphRef;
        graphRef.d3ReheatSimulation().zoomToFit(...zoomValues()); // Reheat the simulation
      }
    };

    // Add event listener for tab visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup listener
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [references]);

  const handleClickSkills = async (skill, isSub = false) => {
    if (isSub) {
      const parent = skills.find(
        (item) => item.skill_id === skill.parent_skill_id,
      );

      setClickedLabel(parent);
      setClickedSubLabel(skill);
    } else {
      setClickedLabel(skill);
      setClickedSubLabel(null);
    }

    if (!isSub) {
      const data = await getSubSkills(skill?.skill_id);
      setCurrentSkill(data);
    }

    const rolesToShow: any = [];

    for (const node of rolesData) {
      if (
        node?.skills?.filter((inSkill) => inSkill.skill_id === skill.skill_id)
          .length > 0
      ) {
        rolesToShow.push(node);
      }
    }

    setRoles(rolesToShow);
    setSelectedSkillForGraphFilter(skill);
  };

  //JSX

  return (
    <GraphContext.Provider
      value={{
        selectedNode,
        setSelectedNode,
        expandedCardIndex,
        setExpandedCardIndex,
        viewCardIndex,
        setViewCardIndex,
        cards,
        setCards,
        hoveredLabel,
        setHoveredLabel,
        hoveredSubLabel,
        setHoveredSubLabel,
        clickedLabel,
        setClickedLabel,
        clickedSubLabel,
        setClickedSubLabel,
        currentSkill,
        setCurrentSkill,
        roles,
        setRoles,
        selectedSection,
        setSelectedSection,
        setSelectedSubSection,
        expanded,
        setExpanded,
        openOpportunities,
        handleClickSkills,
        setSelectedSkillForGraphFilter,
      }}
    >
      {children}
    </GraphContext.Provider>
  );
};

export { GraphContextWrapper };

export default GraphContext;
