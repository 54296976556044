import { Box, useMediaQuery } from '@chakra-ui/react';
import { useContext } from 'react';
import DemoContext from '../../context/demoContext';

import {
  CustomPlainBody,
  CustomPlainError,
  CustomPlainHeader,
} from '../ModelTemplate/PlainTemplate';
import {
  CustomModalBottomTextSection,
  CustomModalButtonSection,
  CustomModalInput,
  CustomModalInputSubtitle,
  CustomModalMiddleText,
} from '../ModelTemplate/ModalTemplateComponents';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const DemoFormPlain = ({ setIsDemoOpen }) => {
  const demoContext = useContext(DemoContext);
  const { formikDemo, isSubmitted, error: demoError, errors } = demoContext;
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');

  const { t } = useTranslation();

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      formikDemo.handleSubmit();
    }
  };

  const navigate = useNavigate();

  return (
    <Box
      onKeyDown={handleKeyPress}
      width={'100%'}
      justifyContent={'center'}
      display={'flex'}
    >
      <Box
        width={isLargerThan1500 ? '610px' : isLargerThan756 ? '462px' : '300px'}
        maxWidth={
          isLargerThan1500 ? '550px' : isLargerThan756 ? '462px' : '300px'
        }
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        fontFamily={'PoppinsLight'}
        gap={isLargerThan1500 ? '30px' : '20px'}
        textAlign={'start'}
      >
        {!isSubmitted && (
          <>
            <CustomPlainHeader
              title={t('landing.start_our_journey')}
              subTitle={t('landing.request_your_demo')}
            />
            <CustomPlainError error={demoError} />
            <CustomPlainBody error={demoError}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={isLargerThan1500 ? '15px' : '10px'}
                width={'100%'}
              >
                <CustomModalInput
                  formik={formikDemo}
                  label={t('landing.name') + '*'}
                  name={'name'}
                  labelStyles={{
                    color: errors['name'] ? '#D52127' : 'white',
                    fontFamily: errors['name']
                      ? 'PoppinsMedium'
                      : 'PoppinsLight',
                  }}
                />
                <CustomModalInput
                  formik={formikDemo}
                  label={t('landing.organization') + '*'}
                  name={'organization'}
                  type={'organization'}
                  labelStyles={{
                    color: errors['organization'] ? '#D52127' : 'white',
                    fontFamily: errors['organization']
                      ? 'PoppinsMedium'
                      : 'PoppinsLight',
                  }}
                />
                <CustomModalInput
                  formik={formikDemo}
                  label={t('landing.job_title') + '*'}
                  name={'jobTitle'}
                  labelStyles={{
                    color: errors['jobTitle'] ? '#D52127' : 'white',
                    fontFamily: errors['jobTitle']
                      ? 'PoppinsMedium'
                      : 'PoppinsLight',
                  }}
                />
                <CustomModalInput
                  formik={formikDemo}
                  label={t('landing.email_address') + '*'}
                  name={'emailDemo'}
                  type={'emailDemo'}
                  labelStyles={{
                    color: errors['emailDemo'] ? '#D52127' : 'white',
                    fontFamily: errors['emailDemo']
                      ? 'PoppinsMedium'
                      : 'PoppinsLight',
                  }}
                />

                <CustomModalInputSubtitle text={t('landing.required')} />
              </Box>

              <CustomModalBottomTextSection
                text={
                  <Box textAlign={"left"}>
                    <Box as="span">{t('landing.unsubscribe_1')}</Box>
                    <Box
                      as="span"
                      textDecoration={'underline'}
                      cursor={'pointer'}
                      onClick={() => {
                        setIsDemoOpen(false);
                        navigate('/terms-of-service');
                      }}
                    >
                      {t('landing.unsubscribe_terms_link')}
                    </Box>
                    <Box as="span"> {t('landing.unsubscribe_2')}</Box>
                    <Box
                      as="span"
                      textDecoration={'underline'}
                      cursor={'pointer'}
                      onClick={() => {
                        setIsDemoOpen(false);
                        navigate('/privacy-policy');
                      }}
                    >
                      {t('landing.unsubscribe_privacy_link')}.
                    </Box>
                    <Box as="span"> {t('landing.unsubscribe_3')}</Box>
                  </Box>
                }
                linkHref={null}
                linkText={null}
              />
              <CustomModalButtonSection
                text={t('landing.get_a_demo')}
                width={isLargerThan1500 ? '200px' : '145px'}
                height={isLargerThan1500 ? '66px' : '45px'}
                onClick={async () => {
                  try {
                    formikDemo.handleSubmit();
                  } catch (err) {
                    console.log(err);
                  }
                }}
              />
            </CustomPlainBody>
          </>
        )}

        {isSubmitted && (
          <CustomPlainBody>
            <CustomPlainHeader
              title={t('landing.start_our_journey')}
              subTitle={t('landing.demo_requested')}
            />
            <CustomPlainError error={demoError} />
            <Box
              width={'100%'}
              marginBottom={isLargerThan1500 ? '30px' : '20px'}
            >
              <CustomModalMiddleText
                text={t('landing.thank_you')}
                styles={{ padding: '0px' }}
              />
              <CustomModalMiddleText
                text={t('landing.3_days')}
                styles={{ padding: '0px' }}
              />
            </Box>
          </CustomPlainBody>
        )}
      </Box>
    </Box>
  );
};
