export const mainContainerStyles = {
  display: 'flex',
  flexDirection: 'column' as const,
  alignItems: 'start',
  backgroundColor: 'transparent',
  visibility: 'hidden' as const,
  position: 'absolute' as const,
  width: '550px',
  padding: '25px',
  backdropFilter: 'blur(20px)',
  borderRadius: '5px',
  zIndex: 40,
};

export const smallContainerStyles = {
  backdropFilter: 'blur(20px)',
  borderRadius: '5px',
  fontSize: '14px',
  padding: '5px 10px',
  whitespace: 'nowrap',
  visibility: 'hidden' as const,
  position: 'absolute' as const,
  background: '#0A0A0A26',
  color: 'white',
};

export const titleStyles = {
  fontSize: '20px',
  color: 'white',
  textAlign: 'left' as const,
  mb: 4,
  fontFamily: 'PoppinsExtraBold',
  lineHeight: '20px',
  marginBottom: '7px',
};

export const familyStyles = {
  display: 'flex',
  fontSize: '16px',
  color: 'white',
  textAlign: 'center' as const,
  mb: 4,
  fontFamily: 'PoppinsLight',
};

export const firstAccordionTitleStyles = {
  fontSize: '18px',
  color: 'white',
  fontFamily: 'PoppinsMedium',
};

export const accordionWidth = {
  w: '100%',
};

export const accordionContainerStyles = {
  ...accordionWidth,
  borderTop: '1.5px solid #F4F3F21A',
  borderBottom: '1.5px solid #F4F3F21A',
  borderLeft: '2.5px solid #F4F3F21A',
  borderRight: '2.5px solid #F4F3F21A',
  borderRadius: '5px',
  outline: 'none',
};

export const accordionButtonStyles = {
  ...accordionWidth,
  borderRadius: '5px',
  padding: '8px 16px',
  border: 'none',
  outline: 'none',
  display: 'flex',
  gap: '10px',
};

export const roleDescriptionStyles = {
  fontSize: '12px',
  fontFamily: 'PoppinsRegular',
  color: 'white',
  //10px from button padding + 24px from the accordion button size
  paddingLeft: '34px',
  paddingRight: '5px',
  paddingTop: '0px',
  paddingBottom: '10px',
  textAlign: 'left' as const,
  outline: 'none',
  border: 'none',
};

export const roleDescriptionStylesSemi = {
  fontSize: '12px',
  fontFamily: 'PoppinsRegular',
  color: 'white',
  paddingTop: '0px',
  paddingBottom: '10px',
  textAlign: 'left' as const,
  outline: 'none',
  border: 'none',
};

export const skillsContainerStyles = {
  fontSize: '12px',
  fontFamily: 'PoppinsLight',
  color: 'white',
  textAlign: 'left' as const,
  outline: 'none',
};

export const closeIconStyles = {
  color: 'white',
  w: '5px',
  position: 'absolute' as const,
  right: 15,
  top: 15,
  cursor: 'pointer',
};

export const skillBoxStyles = {
  display: 'flex',
  alignItems: 'center',
  w: '100%',
  justifyContent: 'space-between',
  gap: 10,
};
