import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Link,
  ModalBody,
  ModalHeader,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import { alertIcon } from '../../assets/svg/alertIcon';
import { loginMicrosoft } from '../../../public/svg/loginMicrosoft';
import { loginGoogle } from '../../../public/svg/loginGoogle';
import { TextInput } from '../TextInput/TextInput';
import { Cta } from '../Button/Cta';
import { infoIcon } from '../../assets/svg/infoIcon';
import { loginAPI } from '../../utils/helpers/axios';

export const CustomModalHeader = ({ title, subTitle }) => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <ModalHeader
      fontFamily={'PPNeueMachinaPlainUltrabold'}
      fontSize={isLargerThan1500 ? '30px' : '20px'}
      color={'white'}
      zIndex={25}
      padding={'0px 25px'}
      alignSelf={'baseline'}
      marginTop={isLargerThan1500 ? '30px' : '20px'}
    >
      {title && <Box lineHeight={'25.5px'}> {title}</Box>}
      {subTitle && (
        <Box
          fontSize={isLargerThan1500 ? '16px' : '12px'}
          fontFamily={'PoppinsLight'}
          lineHeight={'13.6px'}
          marginTop={isLargerThan1500 ? '10px' : '0px'}
        >
          {subTitle}
        </Box>
      )}
    </ModalHeader>
  );
};

export const CustomModalError = ({ error }) => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    error && (
      <Box
        fontSize={isLargerThan1500 ? '19px' : '14px'}
        lineHeight={isLargerThan1500 ? '30px' : '20px'}
        fontFamily={'PoppinsLight'}
        display={'flex'}
        color={'white'}
        alignSelf={'baseline'}
        alignItems={'center'}
        padding={'0px 25px'}
      >
        <Box as="span">{alertIcon()}</Box>
        <Box marginLeft={'5px'}>{error}</Box>
      </Box>
    )
  );
};

export const CustomModalBody = ({ children }: any) => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <ModalBody
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      width={'100%'}
      paddingTop={'0px'}
    >
      <VStack
        w={'full'}
        maxW={'550px'}
        gap={isLargerThan1500 ? '30px' : '20px'}
      >
        {children}
      </VStack>
    </ModalBody>
  );
};

export const CustomModalSSO = ({ setError, email }) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} width={'20%'}>
      <Box
        onClick={() => {
          setError('Coming soon.');
        }}
        w={'38px'}
        h={'38px'}
      >
        {loginGoogle()}
      </Box>
      {/*<Box w={'38px'} h={'38px'}>
        {loginFacebook()}
      </Box>
      <Box w={'38px'} h={'38px'}>
        {loginLinkedin()}
      </Box>*/}
      <Box
        onClick={async () => {
          document.cookie = 'DA=microsoft';

          // const id = 'c17d355a-7a9d-4fb1-967c-261cf9c80c14';
          const id = 'aa8273a1-ea94-4831-922d-1223d7c0a41f';

          const environments = {
            development: import.meta.env.VITE_CAREERS_OWN_LINK_LOCAL,
            staging: import.meta.env.VITE_CAREERS_OWN_LINK_TEST,
            production: import.meta.env.VITE_CAREERS_OWN_LINK_PROD,
          };

          // await loginAPI.post('/createLoginAttempt', {
          //   email,
          // });

          // window.location.assign(
          //   `https://login.microsoftonline.com/b127a1fd-4a91-4888-aad1-b2319ec38988/oauth2/v2.0/authorize?client_id=${id}&state=DA&redirect_uri=${
          //     environments[import.meta.env.VITE_ENVIRONMENT]
          //   }&scope=https://graph.microsoft.com/User.Read&response_type=code`,
          // );

          //THIS ONE IS FOR THE NEW MICROSOFT MULTI TENANT
          window.location.assign(
            `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${id}&state=DA&redirect_uri=${
              environments[import.meta.env.VITE_ENVIRONMENT]
            }&scope=https://graph.microsoft.com/User.Read&response_type=code&prompt=select_account`,
          );
        }}
        w={'38px'}
        h={'38px'}
      >
        {loginMicrosoft()}
      </Box>
    </Box>
  );
};

export const CustomModalDivider = () => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <Box
      position="relative"
      width="100%"
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      maxWidth={'550px'}
    >
      <Box width={'45%'}>
        <Divider borderTop={'1px solid white'} />
      </Box>

      <Box
        fontSize={isLargerThan1500 ? '16px' : isLargerThan756 ? '14px' : '12px'}
        color={'white'}
      >
        or
      </Box>

      <Box width={'45%'}>
        <Divider borderTop={'1px solid white'} />
      </Box>
    </Box>
  );
};

export const CustomModalInputSubtitle = ({ text }) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  return (
    <Text
      w={'full'}
      textAlign={'left'}
      color={'white'}
      fontSize={isLargerThan756 ? '12px' : '10px'}
      mt={'0px'}
      fontFamily={'PoppinsExtraLight'}
      lineHeight={'16.8px'}
    >
      {text}
    </Text>
  );
};

export const CustomModalInput = ({
  formik,
  label,
  name,
  type,
  onForgotPassword,
  onMouseEnter,
  onMouseLeave,
  withInfoIcon,
  iconInfo,
  noForgotPassword,
  labelStyles = {},
  onLabelClick,
  noLabel = false,
  id = '',
}: any) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <FormControl>
      {!noLabel ? (
        <FormLabel
          color={'white'}
          htmlFor={name}
          fontFamily={'PoppinsLight'}
          fontSize={
            isLargerThan1500 ? '16px' : isLargerThan756 ? '14px' : '12px'
          }
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          display={'flex'}
          alignItems={'center'}
          onClick={onLabelClick}
          {...labelStyles}
        >
          {label}
          {withInfoIcon && (
            <Box
              marginLeft={'3px'}
              as="span"
              cursor={'pointer'}
              width={'fit-content'}
            >
              {infoIcon()}
            </Box>
          )}
          {withInfoIcon && iconInfo}
        </FormLabel>
      ) : null}
      <TextInput
        onChange={formik.handleChange}
        props={{
          name,
          value: formik.values[name],
          type,
          id,
          noForgot: noForgotPassword,
        }}
        variation="login"
        onForgotPassword={type === 'password' && onForgotPassword}
      />
    </FormControl>
  );
};

export const CustomModalBottomTextSection = ({ text, linkText, linkHref }) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <Text
      w={'full'}
      textAlign={'center'}
      color={'white'}
      fontSize={isLargerThan1500 ? '12px' : isLargerThan756 ? '11px' : '10px'}
      fontFamily={'PoppinsExtraLight'}
    >
      {text}
      {linkText && linkHref && (
        <Link color={'white'} textDecoration={'underline'} href={linkHref}>
          {linkText}
        </Link>
      )}
    </Text>
  );
};

export const CustomModalButtonSection = ({ text, width, height, onClick }) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <Box
      display={'flex'}
      justifyContent={'center'}
      w={'full'}
      onClick={onClick}
      marginBottom={isLargerThan1500 ? '30px' : '20px'}
    >
      <Cta
        text={text}
        props={{
          type: 'submit',
          width,
          height,
          mt: 1,
          borderRadius: '60px',
          bg: '#F4F3F226',
          border: '1.5px solid #F4F3F2',
          fontSize: isLargerThan1500
            ? '24px'
            : isLargerThan756
            ? '18px'
            : '11px',
          fontFamily: 'PoppinsBold',
          disply: 'flex',
          justifyContent: 'center',
          minWidth: 'fit-content',
        }}
        variation="login"
      />
    </Box>
  );
};

export const CustomModalMiddleText = ({ text, styles = {} }: any) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <Text
      fontSize={isLargerThan1500 ? '20px' : isLargerThan756 ? '16px' : '14px'}
      w={'full'}
      textAlign={'left'}
      color={'white'}
      fontFamily={'PoppinsLight'}
      padding={'0px 25px'}
      lineHeight={isLargerThan1500 ? '30px' : '20px'}
      {...styles}
    >
      {text}
    </Text>
  );
};

export const CustomModalStartText = ({ text, styles = {} }: any) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <Box
      fontSize={isLargerThan1500 ? '18px' : isLargerThan756 ? '14px' : '12px'}
      fontFamily={'PoppinsLight'}
      lineHeight={isLargerThan1500 ? '21.6px' : '15px'}
      color={'white'}
      textAlign={'left'}
      width={'100%'}
      {...styles}
    >
      {text}
    </Box>
  );
};

export const CustomModalLabel = ({
  isLargerThan756,
  isLargerThan1500,
  labelStyles = {},
  label,
  withInfoIcon = false,
  name,
  iconInfo = '',
  onClick,
}) => {
  return (
    <FormLabel
      color={'white'}
      htmlFor={name}
      fontFamily={'PoppinsLight'}
      fontSize={isLargerThan1500 ? '16px' : isLargerThan756 ? '14px' : '12px'}
      display={'flex'}
      alignItems={'center'}
      onClick={onClick}
      {...labelStyles}
    >
      {label}
      {withInfoIcon && (
        <Box marginLeft={'3px'} as="span" cursor={'pointer'}>
          {infoIcon()}
        </Box>
      )}
      {withInfoIcon && iconInfo}
    </FormLabel>
  );
};

export const CustomModalLabelValue = ({
  isLargerThan756,
  isLargerThan1500,
  labelStyles = {},
  label,
  onClick,
}: any) => {
  return (
    <FormLabel
      color={'white'}
      fontFamily={'PoppinsLight'}
      fontSize={isLargerThan1500 ? '14px' : isLargerThan756 ? '12px' : '11px'}
      marginLeft={'5px'}
      display={'flex'}
      alignItems={'center'}
      onClick={onClick}
      {...labelStyles}
    >
      {label}
    </FormLabel>
  );
};
