import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

//used to get query params from the url
const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export { useQuery };
