import {
  Box,
  ChakraProps,
  Input,
  InputGroup,
  InputRightAddon,
  useMediaQuery,
} from '@chakra-ui/react';
// import {Text, Input, Label} from 'chakra-ui-react'
import {
  inputGroup,
  primary,
  primaryHover,
  secondary,
  login,
  loginHover,
  loginFocus,
} from './TextInputStyle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TextInputProps extends ChakraProps {
  variation?: 'primary' | 'secondary' | 'login';
  setter?: (e) => void;
  props?: any;
  onChange?: (e) => void;
  placeholder?: string;
  onForgotPassword?: (e) => void;
}

export const TextInput = ({
  variation = 'primary',
  setter,
  props,
  onChange,
  placeholder,
  onForgotPassword,
}: TextInputProps) => {
  let styles = {};
  let passwordStyles: any = { ...inputGroup };
  let hoverStyles = {};
  let focusStyles = {};

  const [view, setView] = useState(false);
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');

  const { t } = useTranslation();

  const isPw = props.type === 'password';

  if (variation === 'primary') {
    styles = { ...primary, styles };
    hoverStyles = { ...primaryHover };
  }
  if (variation === 'secondary') {
    styles = { ...secondary, styles };
  }
  if (variation === 'login') {
    styles = { ...login(isLargerThan1500), styles };
    passwordStyles = {};
    hoverStyles = { ...loginHover };
    focusStyles = { ...loginFocus };
  }

  if (isPw) {
    return (
      <Box {...passwordStyles}>
        <InputGroup>
          <Input
            placeholder={placeholder}
            onChange={
              onChange
                ? (e: any) => onChange(e)
                : (e: any) => setter && setter(e.target.value)
            }
            {...props}
            {...styles}
            _hover={{ ...hoverStyles }}
            _focus={{ ...focusStyles }}
            type={view ? 'text' : 'password'}
          />
          {/*<InputRightAddon
            _hover={{ filter: 'brightness(90%)' }}
            onClick={() => setView(!view)}
          >
            {!view ? <ViewOffIcon /> : <ViewIcon />}
          </InputRightAddon>*/}
        </InputGroup>
        {!props.noForgot ? (
          <Box
            marginTop={'10px'}
            fontSize={isLargerThan756 ? '14px' : '12px'}
            color={'white'}
            textDecoration={'underline'}
            textAlign={'left'}
            onClick={onForgotPassword}
            cursor={'pointer'}
            width={'fit-content'}
          >
            {t('landing.forgot_password')}
          </Box>
        ) : null}
      </Box>
    );
  }

  return (
    <Input
      placholder={'imagina'}
      onChange={
        onChange
          ? (e: any) => onChange(e)
          : (e: any) => setter && setter(e.target.value)
      }
      {...props}
      {...styles}
      _hover={{ ...hoverStyles }}
      _focus={{ ...focusStyles }}
      autoComplete="off"
    />
  );
};
