import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { search } from '../../../public/svg/search';
import { useTranslation } from 'react-i18next';

const AutoComplete = ({
  dataset,
  value,
  setValue,
  setShowCards,
  cardClickFlag = Date.now(),
}) => {
  const innerWidth = window.innerWidth;
  const [words] = useState(dataset);
  const [currentSuggestion, setCurrentSuggestion]: any = useState(null);
  const [blinking, setBlinking] = useState(true);
  const [lineVisible, setLineVisible] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setBlinking(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (cardClickFlag !== null) {
      //@ts-ignore
      inputRef.current.innerText = value;
      //? this will ensure the text is placed correctly, a fake event of sorts to trigger the onInputChange
      onInputChange({
        target: {
          value,
        },
        keyCode: 8,
      });
    }
  }, [cardClickFlag]);

  const { t } = useTranslation();
  const wasSpaceRef = useRef(false);

  const renderMicrophone = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20 11.5C20 15.9183 16.4183 19.5 12 19.5C7.58172 19.5 4 15.9183 4 11.5M12 22V20M13.5 2.29076C13.0368 2.10325 12.5305 2 12 2C9.79086 2 8 3.79086 8 6V11C8 13.2091 9.79086 15 12 15C14.2091 15 16 13.2091 16 11V6C16 5.4102 15.8723 4.85022 15.6432 4.34617"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <line
          x1="19"
          y1="5"
          x2="5"
          y2="19"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          style={{
            opacity: lineVisible ? 1 : 0,
            transition: 'opacity 0.5s ease',
          }}
        />
      </svg>
    );
  };

  const getSpanBack = () => {
    const element = document.getElementById('write-div');
    if (element) {
      const span = document.createElement('span');
      span.style.color = 'gray';
      span.id = 'suggestion-span';
      element.appendChild(span);
    }
  };

  const onInputChange = useCallback(
    (e) => {
      const element = document.getElementById('write-div');
      if (wasSpaceRef.current) {
        if (element) {
          element.style.marginTop = '0rem';
        }

        wasSpaceRef.current = false;
        return;
      }

      if (!element) return;
      element.style.marginTop = '0rem';
      let suggestionSpan: any = document.getElementById('suggestion-span');

      if (!suggestionSpan) {
        getSpanBack();
      }

      suggestionSpan = document.getElementById('suggestion-span');

      if (element && element?.innerText?.trim() === suggestionSpan?.innerText) {
        element.innerText = '';
        element.style.marginTop = '18px';
        getSpanBack();
        setCurrentSuggestion(null);
        setValue(null);
        return;
      }

      if (element.innerText && element.innerText.length >= 2) {
        let textContent = '';
        for (let i = 0; i < element.childNodes.length; i++) {
          const node = element.childNodes[i];
          if (node.nodeType === Node.TEXT_NODE) {
            textContent += node.nodeValue;
          }
        }

        if (
          textContent.length - 1 === textContent.lastIndexOf('\u00A0') &&
          suggestionSpan
        ) {
          suggestionSpan.innerText = '';
          setCurrentSuggestion(null);
          return;
        }

        let word = element.innerText.toLowerCase().trim();
        let spaceIndex = word.lastIndexOf(' ');

        if (spaceIndex >= 0) {
          word = word.slice(spaceIndex).trim();
        } else {
          spaceIndex = 0;
        }

        if (currentSuggestion?.trim().length) {
          const index = word.lastIndexOf(currentSuggestion);
          word = word.slice(0, index).trim();
        }

        const filtered = words.filter((possibleWord, index) => {
          return (
            possibleWord.startsWith(word) && possibleWord.length > word.length
          );
        });

        if (filtered.length) {
          const firstSuggestion = filtered[0];
          const rest = firstSuggestion.slice(word.length);

          if (suggestionSpan) {
            suggestionSpan.innerText = rest;
          }

          setCurrentSuggestion(rest);
        } else {
          if (suggestionSpan) {
            suggestionSpan.innerText = null;
          }

          setCurrentSuggestion(null);
        }
      } else {
        if (suggestionSpan) {
          suggestionSpan.innerText = null;
        }

        setCurrentSuggestion(null);
      }

      element.scrollLeft = element.scrollWidth;
      setValue(element.innerText);
    },
    [words, currentSuggestion, innerWidth, setValue],
  );

  const onWritableInputKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 9) {
        e.nativeEvent.preventDefault();

        const element = document.getElementById('write-div');
        if (!element) return;

        element.innerText = element.innerText;
        element.innerHTML = element.innerHTML.replace(/<br>/g, '');

        const range = document.createRange();
        const selection: any = window.getSelection();

        // Select the entire contents of the editable div
        range.selectNodeContents(element);

        // Collapse the range to the end
        range.collapse(false);

        // Clear existing selections
        selection.removeAllRanges();

        // Add the new range to the selection
        selection.addRange(range);

        const span = document.createElement('span');
        span.style.color = 'gray';
        span.id = 'suggestion-span';
        element.appendChild(span);

        setCurrentSuggestion(' ');
      }
      //right key
      if (e.keyCode === 39) {
        const selection: any = window.getSelection();

        let newValue = value;

        if (currentSuggestion?.length) {
          const index = newValue.lastIndexOf(currentSuggestion);
          newValue = newValue.slice(0, index).trim();
        }

        if (
          currentSuggestion?.length &&
          selection.getRangeAt(0).startOffset === newValue?.length
        ) {
          e.preventDefault();
        }
      }
      //space key
      if (e.keyCode === 32) {
        const suggestionSpan = document.getElementById('suggestion-span');
        if (suggestionSpan) {
          suggestionSpan.innerText = '';
        }

        wasSpaceRef.current = true;
      }
      //delete key
      if (e.keyCode === 8) {
        const element = document.getElementById('write-div');

        if (element && !element.innerText.length) {
          e.preventDefault();
        }
      }
      //enter key
      if (e.keyCode === 13) {
        e.nativeEvent.preventDefault();
      }
    },
    [value, currentSuggestion],
  );

  const onWritableInputClick = useCallback(
    (e) => {
      const div: any = document.getElementById('write-div');
      const span = document.getElementById('suggestion-span');
      const placeholder = document.getElementById('below-input-text');

      if (placeholder) {
        placeholder.remove();
        return;
      }

      if (e.target.id === 'suggestion-span') {
        e.nativeEvent.preventDefault();
        return;
      }

      if (currentSuggestion?.trim().length && span) {
        const range = document.createRange();
        range.setStartBefore(div);
        range.setEndBefore(span);

        const rect = range.getBoundingClientRect();

        if (e.clientX > rect.right) {
          e.preventDefault();
        }
      }
    },
    [currentSuggestion],
  );

  const searchRender = useMemo(() => {
    return <div id="magnifier-container">{search()}</div>;
  }, []);

  return (
    <div id="auto-complete-container">
      <div
        id="editable-div-container"
        className={blinking ? 'blink' : ''}
        style={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          ref={inputRef}
          contentEditable="true"
          onInput={onInputChange}
          id="write-div"
          onKeyDown={onWritableInputKeyDown}
          onMouseDown={onWritableInputClick}
          style={{
            //caretColor: !currentSuggestion ? 'transparent' : 'none',
            position: 'relative',
            zIndex: 1,
          }}
        >
          <span id="suggestion-span" style={{ color: 'gray' }}></span>
        </div>
      </div>

      {searchRender}

      <div
        style={{
          position: 'absolute',
          top: '40%',
          transform: 'translateY(-50%)',
          right: '24px',
          cursor: 'pointer',
        }}
        onClick={() => {
          setLineVisible(true);
        }}
      >
        {renderMicrophone()}
      </div>

      <div
        style={{
          fontSize: '12px',
          width: '100%',
          color: 'white',
          fontFamily: 'PoppinsLight',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div style={{ marginRight: '0.5rem' }}>{t('welcome.microphone')}</div>
      </div>
    </div>
  );
};

export default AutoComplete;
