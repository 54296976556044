import { useEffect, useRef, useState } from 'react';
const useSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const timeout: any = useRef(null);

  useEffect(() => {
    const windowSizeHandler = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      if (windowSize.width > 992 && window.innerWidth <= 992) {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      } else if (windowSize.width <= 992 && window.innerWidth > 992) {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      } else {
        timeout.current = setTimeout(() => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }, 200);
      }
    };
    window.addEventListener('resize', windowSizeHandler);

    return () => {
      window.removeEventListener('resize', windowSizeHandler);
    };
  }, [windowSize]);

  return windowSize;
};

export default useSize;
