import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Spinner,
  useBoolean,
} from '@chakra-ui/react';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  categoryCont,
  closeIcon as closeIconStyles,
  closeModal,
  cssProp,
  searchBar,
  searchCont,
  searchDropdownContainer,
  searchIcon,
  searchLabel,
  searchResultsCont,
} from './SearchBarStyles';
import { SearchIcon } from '../../assets/svg/SearchIcon';
import { careersAPI } from '../../utils/helpers/axios';
import { useDispatch, useSelector } from 'react-redux';
import { defineView } from '../../store/actions/viewActions';
import { ctaBtn } from '../../utils/globalStyles/GlobalStyles';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../../config/i18n';
import { closeIcon } from '../../assets/svg/closeIcon';

export const SearchBar = ({
  setter,
  isOpen,
  elementRef,
  results,
  setResults,
}): any => {
  const dispatch = useDispatch();
  const [val, setVal] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const inputRef: any = useRef(null);
  // const [isFocused, setIsFocused] = useState(false);
  const [isFocused, setIsFocused] = useBoolean();
  const location = useLocation();
  const [contentHeight, setContentHeight] = useState(0);

  const previousResults: any = useRef({});
  const previousHeight = useRef(0);
  const totalLengthRef = useRef(0);
  const totalPreviousLengthRef = useRef(0);

  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });

  useEffect(() => {
    if (elementRef.current) {
      // Calculate the total length of all arrays
      let totalLength: any = Object.values(results).reduce(
        (acc: any, value: any) =>
          Array.isArray(value) ? acc + value.length : acc,
        0,
      );

      // Calculate the previous total length of all arrays
      let totalPreviousLength: any = Object.values(
        previousResults.current,
      ).reduce(
        (acc: any, value: any) =>
          Array.isArray(value) ? acc + value.length : acc,
        0,
      );

      // Add 1 to totalLength if skillsCount or rolesCount is greater than 3
      if (results.skillsCount > 3) {
        totalLength += 1;
      }

      if (results.rolesCount > 3) {
        totalLength += 1;
      }

      totalLengthRef.current = totalLength;

      // Add 1 to totalPreviousLength if previous skillsCount or rolesCount is greater than 3
      if (previousResults.current.skillsCount > 3) {
        totalPreviousLength += 1;
      }

      if (previousResults.current.rolesCount > 3) {
        totalPreviousLength += 1;
      }

      totalPreviousLengthRef.current = totalPreviousLength;

      // Calculate the height adjustment based on the differences in array lengths
      let calculatedHeight = 0;

      // Initial condition when results is empty
      if (isOpen && !Object.keys(results).length) {
        previousResults.current = results;
        previousHeight.current = 75;
        setContentHeight(75);
        return;
      }

      // Adjust height based on the difference in total lengths
      if (totalLength > totalPreviousLength) {
        calculatedHeight = 75 + 24 * totalLength;
      } else if (totalLength < totalPreviousLength) {
        calculatedHeight = 75 + 24 * totalLength;
      } else if (totalLength === totalPreviousLength) {
        previousResults.current = results;
        return;
      }

      let hasCurrentItemsCounter = 0;

      ['roles', 'skills', 'families'].forEach((key) => {
        const hasCurrentItems = results[key]?.length > 0;

        if (hasCurrentItems) {
          hasCurrentItemsCounter += 1;
        }
      });

      ['roles', 'skills', 'families'].forEach((key) => {
        const hasCurrentItems = results[key]?.length > 0;

        if (hasCurrentItems) {
          if (hasCurrentItemsCounter === 0) {
            calculatedHeight += 26;
          } else if (hasCurrentItemsCounter === 1) {
            calculatedHeight += 25;
          } else if (hasCurrentItemsCounter === 2) {
            calculatedHeight += 23;
          } else if (hasCurrentItemsCounter === 3) {
            calculatedHeight += 22;
          }
        }
      });

      previousResults.current = results;
      previousHeight.current = calculatedHeight;

      setContentHeight(calculatedHeight);
    }
  }, [isOpen, results, contentHeight]);

  const { t } = useTranslation();

  const fetchResults = async () => {
    setIsLoading(true);
    const {
      data: { skills, roles, families, skillsCount, rolesCount },
    } = await careersAPI.post('/searchBarSpotlight', {
      search: val.trim(),
      take: 3,
      companyId: currentUser?.attributes?.company_id,
    });

    setResults({ skills, roles, families, skillsCount, rolesCount });
    setIsLoading(false);
  };

  const fetchData = async (type) => {
    if (type === 'skill') {
      const { data } = await careersAPI.post('/searchSkills', {
        search: val.trim(),
        companyId: currentUser?.attributes?.company_id,
      });
      return data;
    } else if (type === 'role') {
      const filterValue = val.toLowerCase();
      return filterValue;
    }
    return [];
  };

  const handleClickDropdown = async (element, type) => {
    const data = await fetchData(type);
    setVal('');
    inputRef.current.blur();
    setIsFocused.off();
    setter && setter(false);
    if (type === 'role') {
      // TODO
      dispatch(
        defineView({
          showSemi: true,
          showSkills: false,
          showFamily: false,
          showSubFamily: false,
          showProgressions: false,
          roleData: { ...element, x: undefined, y: undefined },
          familyData: undefined,
          skillsData: undefined,
          rolesExtra: val?.trim().replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ''),
          skillsExtra: undefined,
          skillsFilter: undefined,
          rolesFilter: undefined,
          progressionsData: undefined,
        }),
      );
    }
    if (type === 'skill') {
      // Done
      dispatch(
        defineView({
          showSemi: false,
          showSkills: false,
          showFamily: false,
          showSubFamily: false,
          showProgressions: false,
          skillsData: element,
          roleData: undefined,
          familyData: undefined,
          rolesExtra: undefined,
          skillsExtra: data,
          skillsFilter: undefined,
          rolesFilter: undefined,
          progressionsData: undefined,
        }),
      );
      dispatch(
        defineView({
          showSemi: false,
          showSkills: true,
          showFamily: false,
          showSubFamily: false,
          showProgressions: false,
          skillsData: element,
          roleData: undefined,
          familyData: undefined,
          skillsExtra: data,
          rolesExtra: undefined,
          skillsFilter: undefined,
          rolesFilter: undefined,
          progressionsData: undefined,
        }),
      );
    }
    if (type === 'family') {
      dispatch(
        defineView({
          showSemi: false,
          showSkills: false,
          showFamily: true,
          showSubFamily: false,
          showProgressions: false,
          familyData: element,
          skillsData: undefined,
          roleData: undefined,
          rolesExtra: undefined,
          skillsExtra: undefined,
          skillsFilter: undefined,
          rolesFilter: undefined,
          progressionsData: undefined,
        }),
      );
    }

    return;
  };

  const handleFocus = () => {
    setter && val.length > 3 && setTimeout(() => setter(true), 50);
    setIsFocused.on();
  };

  const timer: any = useRef(null);

  useEffect(() => {
    setIsLoading(true);
    if (val.length > 3) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      setter && setter(true);

      timer.current = setTimeout(() => {
        fetchResults();
      }, 500);
    } else {
      setResults({});
      setter && setter(false);
    }
  }, [val]);

  return (
    <Flex {...searchCont} transition={'all 0.3s ease'}>
      {(isFocused || isOpen || val.length > 3) && (
        <Box
          {...closeModal}
          onClick={() => {
            setter && setter(false);
            setIsFocused.off();
            inputRef.current.blur();
          }}
          style={{ pointerEvents: 'none' }}
        ></Box>
      )}
      <InputGroup mx={0} w={'calc(100% - 24px)'} transition={'all 0.3s ease'}>
        <Flex {...searchCont}>
          <Box
            w={isFocused ? '1px' : 'full'}
            transition={'all 0.3s ease'}
            h={'full'}
            minW={isFocused ? '1px' : 'auto'}
            onClick={() => {
              inputRef.current.focus();
              setIsFocused.on();
            }}
          ></Box>
          {location.pathname !== '/welcome' && (
            <InputLeftAddon
              {...searchIcon}
              onClick={() => setIsFocused.on()}
              children={
                <Box>
                  <SearchIcon w={20} h={20} />
                </Box>
              }
            />
          )}
          <Input
            {...searchBar}
            ml={isFocused ? 0 : 0}
            pl={isFocused ? 0 : 0}
            p={isFocused ? 3 : 0}
            w={isFocused ? 'full' : '0px !important'}
            value={val}
            onChange={(e) => setVal(e.target.value)}
            _focusVisible={{
              outline: 'none',
            }}
            onFocus={() => handleFocus()}
            ref={inputRef}
            onBlur={() => val.length < 3 && setIsFocused.off()}
            borderBottom={isFocused ? '1px solid white' : 'none'}
            //backdropBlur={'10px'}
          />
          <Box
            __css={{ ...cssProp }}
            height={isOpen ? contentHeight + 'px' : '0px !important'}
            opacity={isOpen ? 1 : 0}
            {...searchDropdownContainer}
            pt={isFocused && val.length > 3 && isOpen ? 5 : 0}
            ref={elementRef}
          >
            <Box {...searchResultsCont}>
              {results?.skills?.length > 0 && (
                <>
                  <Box {...categoryCont}>
                    <Box {...searchLabel}>{t('app.skills')}</Box>
                    <Box {...searchResultsCont}>
                      {results.skills.map((skill, index) => {
                        return (
                          <Box
                            _hover={{
                              transform: 'scale(1.05)',
                              fontFamily: 'PoppinsBold',
                            }}
                            cursor={'pointer'}
                            key={`${skill.skill_id}-${index}`}
                            onClick={() => handleClickDropdown(skill, 'skill')}
                            fontSize={'16px'}
                          >
                            {skill.label.toLowerCase()}
                          </Box>
                        );
                      })}
                      {results.skillsCount > 3 && (
                        <Box
                          _hover={{
                            transform: 'scale(1.05)',
                            fontFamily: 'PoppinsBold',
                          }}
                          fontSize={'16px'}
                          cursor={'pointer'}
                          key={`see-all-${4}`}
                          onClick={() => {
                            setVal('');
                            setIsFocused.off();
                            dispatch(
                              defineView({
                                showSemi: false,
                                showSkills: true,
                                showFamily: false,
                                showSubFamily: false,
                                showProgressions: false,
                                roleData: undefined,
                                familyData: undefined,
                                skillsData: undefined,
                                rolesExtra: undefined,
                                skillsExtra: undefined,
                                skillsFilter: val
                                  .trim()
                                  .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ''),
                                rolesFilter: undefined,
                                progressionsData: undefined,
                              }),
                            );
                          }}
                        >
                          {t('app.see_all')}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              )}
              {results?.roles?.length > 0 && (
                <>
                  <Box {...categoryCont}>
                    <Box {...searchLabel}>{t('app.roles')}</Box>
                    <Box {...searchResultsCont}>
                      {results.roles.map((role, index) => {
                        return (
                          <Box
                            _hover={{
                              transform: 'scale(1.05)',
                              fontFamily: 'PoppinsBold',
                            }}
                            fontSize={'16px'}
                            cursor={'pointer'}
                            key={`${role.role_id}-${index}`}
                            onClick={() => handleClickDropdown(role, 'role')}
                          >
                            {role.label.toLowerCase()}
                          </Box>
                        );
                      })}
                      {results.rolesCount > 3 && (
                        <Box
                          _hover={{
                            transform: 'scale(1.05)',
                            fontFamily: 'PoppinsBold',
                          }}
                          fontSize={'16px'}
                          cursor={'pointer'}
                          key={`see-all-${4}`}
                          onClick={() => {
                            setVal('');
                            setIsFocused.off();
                            dispatch(
                              defineView({
                                showSemi: true,
                                showSkills: false,
                                showFamily: false,
                                showSubFamily: false,
                                showProgressions: false,
                                roleData: undefined,
                                familyData: undefined,
                                skillsData: undefined,
                                rolesExtra: undefined,
                                skillsExtra: undefined,
                                skillsFilter: undefined,
                                rolesFilter: val
                                  .trim()
                                  .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, ''),
                                progressionsData: undefined,
                              }),
                            );
                          }}
                        >
                          {t('app.see_all')}
                        </Box>
                      )}
                    </Box>
                  </Box>
                </>
              )}
              {results?.families?.length > 0 && (
                <>
                  <Box {...categoryCont}>
                    <Box {...searchLabel}>{t('app.families')}</Box>
                    <Box {...searchResultsCont}>
                      {results.families.map((family, index) => {
                        return (
                          <Box
                            _hover={{
                              transform: 'scale(1.05)',
                              fontFamily: 'PoppinsBold',
                            }}
                            fontSize={'16px'}
                            cursor={'pointer'}
                            key={`${family.family_id}-${index}`}
                            onClick={() =>
                              handleClickDropdown(family, 'family')
                            }
                          >
                            {family.label.toLowerCase()}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </>
              )}

              {!isLoading && (
                <Flex
                  borderTop={'1px solid rgba(255,255,255,0.1)'}
                  pt={3}
                  cursor={'pointer'}
                  w={'full'}
                  justifyContent={'center'}
                  gap={'10'}
                  alignItems={'center'}
                >
                  <Box
                    onClick={() => {
                      setVal('');
                      setIsFocused.off();
                      dispatch(
                        defineView({
                          showSemi: false,
                          showSkills: true,
                          showFamily: false,
                          showSubFamily: false,
                          showProgressions: false,
                          roleData: undefined,
                          familyData: undefined,
                          skillsData: undefined,
                          rolesExtra: undefined,
                          skillsExtra: undefined,
                          skillsFilter: undefined,
                          rolesFilter: undefined,
                          progressionsData: undefined,
                        }),
                      );
                    }}
                    {...ctaBtn}
                    fontSize={'10px'}
                    fontFamily={'PoppinsSemiBold'}
                  >
                    {i18n.language === 'pt'
                      ? t('app.see_all').replace('as', 'os').replace('as', 'os')
                      : t('app.see_all')}{' '}
                    {t('app.skills')}
                  </Box>
                  <Box
                    onClick={() => {
                      setVal('');
                      setIsFocused.off();
                      dispatch(
                        defineView({
                          showSemi: false,
                          showSkills: false,
                          showFamily: true,
                          showSubFamily: false,
                          showProgressions: false,
                          roleData: undefined,
                          familyData: undefined,
                          skillsData: undefined,
                          rolesExtra: undefined,
                          skillsExtra: undefined,
                          skillsFilter: undefined,
                          rolesFilter: undefined,
                          familySeeAll: true,
                          progressionsData: undefined,
                        }),
                      );
                    }}
                    {...ctaBtn}
                    fontSize={'10px'}
                    fontFamily={'PoppinsSemiBold'}
                  >
                    {t('app.see_all')} {t('app.families')}
                  </Box>

                  <Box
                    onClick={() => {
                      setVal('');
                      setIsFocused.off();
                      dispatch(
                        defineView({
                          showSemi: true,
                          showSkills: false,
                          showFamily: false,
                          showSubFamily: false,
                          showProgressions: false,
                          rolesExtra: { openOpps: true },
                          familyData: undefined,
                          skillsData: undefined,
                          roleData: undefined,
                          skillsExtra: undefined,
                          skillsFilter: undefined,
                          rolesFilter: undefined,
                          progressionsData: undefined,
                        }),
                      );
                    }}
                    {...ctaBtn}
                    fontSize={'10px'}
                    fontFamily={'PoppinsSemiBold'}
                  >
                    {t('app.see_all')} {t('app.open_opportunities')}
                  </Box>
                  <Box
                    {...ctaBtn}
                    fontSize={'10px'}
                    fontFamily={'PoppinsSemiBold'}
                    onClick={() => {
                      setVal('');
                      setIsFocused.off();
                      dispatch(
                        defineView({
                          showSemi: false,
                          showSkills: false,
                          showFamily: false,
                          showSubFamily: false,
                          showProgressions: true,
                          roleData: undefined,
                          familyData: undefined,
                          skillsData: undefined,
                          rolesExtra: undefined,
                          skillsExtra: undefined,
                          skillsFilter: undefined,
                          rolesFilter: undefined,
                          progressionsData: undefined,
                        }),
                      );
                    }}
                  >
                    {t('app.map_my_career')}
                  </Box>
                </Flex>
              )}

              {isLoading && (
                <Flex
                  w={'full'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Spinner mt={3} />
                </Flex>
              )}
            </Box>
          </Box>
          <InputRightAddon
            {...searchIcon}
            p={isFocused ? "5.5px" : 0}
            onClick={() => {
              setVal('');
              inputRef.current.blur();
              setIsFocused.off();
              setter && setter(false);
            }}
            borderBottom={isFocused ? '1px solid white' : 'none'}
            borderRadius={'0px'}
            children={
              !isFocused ? (
                <></>
              ) : (
                <Box>
                  {closeIcon(28, 28)}
                </Box>
              )
            }
          />
        </Flex>
      </InputGroup>
    </Flex>
  );
};
