export const totalCont = {
  display: 'flex' as const,
  flexDirection: 'row' as const,
  justifyContent: 'center' as const,
  alignItems: 'center' as const,
  //transition: 'all 0.2s ease',
  h: '102px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  gap: "15px"
};

export const mainContainer = {
  position: 'absolute' as const,
  width: 'fit-content',
  height: 'fit-content',
  bottom: '60px',
  left: '20px',
  color: 'white',
  overflow: 'hidden' as const,
  zIndex: 20,
  //transition: 'all 0.2s ease',
  maxW: '98vw',
  overflowX: 'scroll' as const,
};

export const hideScrollBar = {
  /* Hide both vertical and horizontal scrollbars for Webkit-based browsers (Chrome, Safari, Edge) */
  '::-webkit-scrollbar': {
    display: 'none',
  },
  /* Hide both scrollbars for Firefox */
  scrollbarWidth: 'none' /* Firefox */,
  /* Hide both scrollbars for Internet Explorer and Edge */
  '-ms-overflow-style': 'none' /* IE and Edge */,
  /* Ensure scrolling works without showing scrollbars */
  overflowY: 'scroll',
  overflowX: 'scroll',
};

export const cardContainer = {
  position: 'relative' as const,
  display: 'flex' as const,
  gap: "15px",
  flexDirection: 'row' as const,
  justifyContent: 'center',
  alignItems: 'flex-end',
  w: 'fit-content',
  height: 'fit-content',
  ////transition: 'all 0.2s ease',
  color: 'white',
};

export const singleCardCont = {
  py: 3,
  pb: 4,
  px: 5,
  background: 'rgba(0,0,0,0.3)',
  fontFamily: 'PoppinsLight',
  borderRadius: '5px',
  display: 'flex' as const,
  flexDirection: 'row' as const,
  alignItems: 'center' as const,
  justifyContent: 'flex-start' as const,
  gap: 5,
  //transition: 'all 0.2s ease',
};

export const cardNum = {
  fontSize: '64px',
  fontWeight: 'bold' as const,
  fontFamily: 'PoppinsExtraBold',
  color: 'white',
  my: '-20px',
  //transition: 'all 0.2s ease',
  cursor: 'pointer',
};

export const numCont = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'center',
  gap: 0,
  fontSize: '12px',
  //transition: 'all 0.2s ease',
};

export const midExpandedCard = {
  h: '100%',
  display: 'flex' as const,
  flexDirection: 'column' as const,
  justifyContent: 'flex-start' as const,
  alignItems: 'flex-start' as const,
  p: 2,
  pl: 5,
  //transition: 'all 0.2s ease',
};

export const midExpandedCardCont = {
  display: 'flex' as const,
  flexDirection: 'row' as const,
  alignItems: 'center' as const,
  justifyContent: 'space-between' as const,
  gap: 20,
  w: '100%',
  borderLeft: '1px solid white',
  //transition: 'all 0.2s ease',
  cursor: 'pointer',
};

export const cardTitle = {
  fontFamily: 'PoppinsExtraBold',
  //transition: 'all 0.2s ease',
  cursor: 'pointer',
};

export const score = {
  fontFamily: 'PPNeuePlainBold',
  fontWeight: 'bold',
  fontSize: '24px',
  //transition: 'all 0.2s ease',
};

export const expandedContainer = {
  background: 'rgba(0,0,0,0.3)',
  // mt: '-1px',
  borderRadius: '0 0 5px 5px',
  display: 'flex' as const,
  flexDirection: 'row' as const,
  alignItems: 'flex-start' as const,
  justifyContent: 'space-between' as const,
  p: 5,
  pt: 0,
  //transition: 'all 0.2s ease',
  h: '100%',
  gap: 5,
};

export const expandedSkillsCont = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  justifyContent: 'flex-start' as const,
  alignItems: 'flex-start' as const,
  gap: 1,
  //transition: 'all 0.2s ease',
  h: 'full',
  pt: 0,
};

export const skills = {
  display: 'flex' as const,
  flexDirection: 'row' as const,
  gap: 20,
  justifyContent: 'space-between' as const,
  alignItems: 'flex-start' as const,
  w: 'full',
  //transition: 'all 0.2s ease',
  h: '100%',
};

export const learningMainTitle = {
  fontFamily: 'PoppinsBold',
  fontSize: '14px',
  color: 'white',
  mb: 5,
  //transition: 'all 0.2s ease',
};

export const expandedLearningCont = {
  p: 5,
  border: '1px solid rgba(255, 255, 255, 0.1)',
  borderRadius: 'lg',
  m: 5,
  mt: 0,
  whiteSpace: 'nowrap',
  display: 'flex' as const,
  flexDirection: 'column' as const,
  justifyContent: 'center' as const,
  alignItems: 'flex-start' as const,
  //transition: 'all 0.2s ease',
};

export const learningList = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  gap: 2,
  justifyContent: 'center' as const,
  alignItems: 'flex-start' as const,
  maxH: '300px',
  overflowY: 'auto' as const,
  w: 'fit-content',
  //transition: 'all 0.2s ease',
};

export const learningLabel = {
  fontSize: '12px',
  color: 'white',
  w: 'fit-content',
  //transition: 'all 0.2s ease',
};

export const learningTitle = {
  fontFamily: 'PoppinsBold',
  fontSize: '12px',
  color: 'white',
  whiteSpace: 'nowrap',
  w: 'fit-content',
  //transition: 'all 0.2s ease',
};

export const learningSingleCont = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  justifyContent: 'center' as const,
  alignItems: 'flex-start' as const,
  //transition: 'all 0.2s ease',
  fontSize: '10px',
};

export const hoverCont = {
  _hover: {
    transform: 'scale(1.02)',
  },
  //transition: 'all 0.2s ease',
  overflow: 'hidden',
};

export const applyNow = {
  fontFamily: 'PoppinsBold',
  fontSize: '16px',
  color: 'white',
  //transition: 'all 0.2s ease',
  px: 3,
  py: 1,
  // mt: 5,
  w: 'fit-content',
  display: 'flex' as const,
  flexDirection: 'row' as const,
  justifyContent: 'center' as const,
  alignItems: 'center' as const,
  gap: 3,
  cursor: 'pointer',
  borderRadius: '100px',
  border: '1px solid white',
  backdropFilter: 'blur(10px)',
  _hover: {
    background: 'rgba(255, 255, 255, 0.2)',
    transform: 'scale(1.02)',
  },
  className: 'applyNow',
};

export const actionBtn = {
  // fontFamily: 'PoppinsBold',
  fontSize: '16px',
  borderRadius: '5px',
  background: 'rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  px: 3,
  py: 1,
  transition: 'background 0.3 ease',
  _hover: {
    transition: 'background 0.3 ease',
    background: 'rgba(255, 255, 255, 0.2)',
  },
};

export const dropdown = {
  position: 'fixed' as const,
  cursor: 'pointer',
  top: '20vh',
  right: '50px',
  fontFamily: 'PoppinsLight',
};

export const subtitleCont = {
  position: 'fixed' as const,
  // bottom: '50px',
  color: 'white',
  background: 'rgba(0,0,0,0.3)',
  backdropFilter: 'blur(10px)',
  display: 'flex' as const,
  flexDirection: 'row' as const,
  fontFamily: 'PoppinsLight',
  borderRadius: '5px',
  pointerEvents: 'auto' as const,
};

export const userIcon = {
  h: '50px',
  w: '50px',
  borderRadius: '100%',
  bg: 'rgba(255, 255, 255, 0.2)',
};

export const openIcon = {
  h: '50px',
  w: '50px',
  borderRadius: '100%',
  border: '2px solid #F6851E',
  bg: 'rgba(255, 255, 255, 0.2)',
};
