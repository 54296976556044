import { Button } from '@chakra-ui/react';
import { primary, primaryHover, secondary, noHover, login } from './CtaStyle';

interface ButtonProps {
  variation?: 'primary' | 'secondary' | 'no_hover' | 'login';
  props?: any;
  text?: any;
  onClick?: () => void;
  disabled?: boolean;
  onSubmit?: () => void;
}

export const Cta = ({
  variation = 'primary',
  props,
  text,
  onClick,
  disabled = false,
  onSubmit,
}: ButtonProps) => {
  let styles = {};
  let hoverStyles = {};

  if (variation === 'primary') {
    styles = { ...primary, styles };
    hoverStyles = { ...primaryHover };
  }
  if (variation === 'secondary') {
    styles = { ...secondary, styles };
  }

  if (variation === 'no_hover') {
    hoverStyles = { ...noHover };
  }

  if (variation === 'login') {
    styles = { ...login, styles };
    hoverStyles = { ...noHover };
  }

  return (
    <Button
      isDisabled={disabled}
      onClick={onClick ? onClick : () => {}}
      {...styles}
      {...props}
      {...onSubmit}
      _hover={{ ...hoverStyles }}
    >
      {text}
    </Button>
  );
};
