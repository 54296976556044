import React, { useState, useEffect, useRef, useContext } from 'react';
import { Flex, Box, VStack } from '@chakra-ui/react';
import {
  boxStyles,
  flexStyles,
  nodeBoxStyles,
  scoreBoxStyles,
} from './SemiCircleStyles';
import { useDispatch, useSelector } from 'react-redux';
import { setCardData, setSkillLineData } from '../../store/actions/cardActions';
import { calculateNodePositions, calculateScore } from './SemiCircle.service';
import GraphContext from '../../context/context'; // Correct import
import { UserDiamond } from '../../assets/svg/UserDiamond';
import { CheckMark } from '../../assets/svg/CheckMark';
import useSize from '../../utils/useSize';
import GeneralDataContext from '../../context/generalDataContext';
import { AccordionRoleCard } from '../RoleCard/AccordionRoleCard';
import { arrowDirectional } from '../../assets/svg/arrowDirectional';

export const SemiCircle = ({ references, elements }) => {
  const [visibleNodes, setVisibleNodes]: any = useState([]);
  const [nodeXY, setNodeXY]: any = useState([]);
  const [scrollLock, setScrollLock] = useState(false);
  const [totalNodes, setTotalNodes]: any = useState([]); // Generate nodes with 53 items
  const scrollSpeed = 1;
  const nodesPerScreen = 11;
  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });

  const generalDataContext = useContext(GeneralDataContext);

  const { roles, mySkills } = generalDataContext;

  const dispatch = useDispatch();

  const windowSize = useSize();
  const circleRadius = 26;

  const context = useContext(GraphContext);

  const {
    selectedNode,
    setSelectedNode,
    expanded,
    setExpanded,
    openOpportunities,
  } = context;

  const view = useSelector((state: any) => state.view);
  // Generate nodes with random scores, for test purposes only
  // to be replaced with an actual API call

  useEffect(() => {
    let nodes: any = [];
    // setup extra nodes that aren't highlighted
    if (view?.rolesExtra && !view?.rolesExtra?.openOpps) {
      //setup nodes from family view
      if (view?.rolesExtra?.family) {
        //do something
        nodes = roles
          .filter((role) => {
            return role?.family_id === view.rolesExtra.family;
          })
          .map((node, i) => ({
            ...node,
            score: calculateScore({ skills: mySkills }, node),
            index: i,
          }));
      } else if (view?.rolesExtra?.skill) {
        nodes = roles
          .filter((role) => {
            const skillIds = role.skills.map((skill) => skill.skill_id);
            return skillIds.includes(view.rolesExtra.skill);
          })
          .map((node, i) => ({
            ...node,
            score: calculateScore({ skills: mySkills }, node),
            index: i,
          }));
      } else {
        nodes = roles
          .filter((role) => {
            if (
              role?.id.toLowerCase().includes(view.rolesExtra.toLowerCase())
            ) {
              return role;
            }
          })
          .map((node, i) => ({
            ...node,
            score: calculateScore({ skills: mySkills }, node),
            index: i,
          }));
      }
    } else {
      if (view?.rolesExtra?.openOpps) {
        nodes = roles
          .filter((role) => {
            return openOpportunities.find((opp: any) => {
              return opp.role_id === role?.role_id;
            });
          })
          .map((node, i) => ({
            ...node,
            score: calculateScore({ skills: mySkills }, node),
            index: i,
          }));
      } else {
        nodes = roles
          .filter((node) => {
            if (view.rolesFilter) {
              return node.id
                .toLowerCase()
                .includes(view.rolesFilter.toLowerCase());
            }
            return true;
          })
          .map((node, i) => ({
            ...node,
            score: calculateScore({ skills: mySkills }, node),
            index: i,
          }));
      }
    }

    let roleToShowIndex = nodes
      .map((role) => role?.role_id)
      .indexOf(view.roleData?.role_id);

    setSelectedNode(nodes[roleToShowIndex]);

    if (nodes[roleToShowIndex]) {
      setTimeout(() => {
        handleNodeHover(
          nodes[roleToShowIndex],
          nodes[roleToShowIndex].x,
          nodes[roleToShowIndex].y,
        );
      }, 50);
    }

    const limit = nodes.length - 1;

    let halfDisplay = Math.floor(nodesPerScreen / 2);

    let rolesToShow: any = [];

    if (nodes.length > nodesPerScreen) {
      if (
        roleToShowIndex + halfDisplay > limit ||
        roleToShowIndex - halfDisplay < 0
      ) {
        const overflowAfter = roleToShowIndex + halfDisplay - limit;
        const overflowBefore = halfDisplay - roleToShowIndex;

        if (roleToShowIndex + halfDisplay > limit) {
          rolesToShow = [
            ...nodes.slice(roleToShowIndex - halfDisplay),
            ...nodes.slice(0, overflowAfter),
          ];
        } else if (roleToShowIndex - halfDisplay < 0) {
          rolesToShow = [
            ...nodes.slice(nodes.length - overflowBefore),
            ...nodes.slice(0, roleToShowIndex + halfDisplay + 1),
          ];
        }
      } else {
        rolesToShow = nodes.slice(
          roleToShowIndex - halfDisplay,
          roleToShowIndex + halfDisplay + 1,
        );
      }
    } else {
      rolesToShow = nodes;
    }

    roleToShowIndex = nodes
      .map((role) => role.role_id)
      .indexOf(view.roleData?.role_id);

    if (rolesToShow.length < nodesPerScreen) {
      const diff = nodesPerScreen - rolesToShow.length;
      const invisible = { id: 'invisible' };

      // Calculate the number of invisible elements to add before and after
      const beforeCount = Math.floor(diff / 2);
      const afterCount = diff - beforeCount;

      // Insert roleToShow in the center of the array
      const roleToShow = rolesToShow[roleToShowIndex]; // Replace this with the actual roleToShow object
      const centerIndex = Math.floor(nodes.length / 2);
      rolesToShow.splice(centerIndex, 0, roleToShow);

      for (let i = 0; i < beforeCount; i++) {
        nodes.unshift({ ...invisible, invisible: true });
      }

      // Insert invisible elements at the end
      for (let i = 0; i < afterCount - 1; i++) {
        rolesToShow.push({ ...invisible, invisible: true });
      }
    }

    rolesToShow = [...new Set(rolesToShow)].filter((role) => role);

    const initialVisibleNodes = calculateNodePositions(
      rolesToShow,
      nodesPerScreen,
      windowSize.height,
    );

    const rolesWithScores = initialVisibleNodes.map((role, i) => ({
      ...role,
      score: role.invisible ? 0 : calculateScore({ skills: mySkills }, role),
    }));

    setNodeXY(
      rolesWithScores.map((node) => ({
        x: node.x,
        y: node.y,
      })),
    );

    setTotalNodes(nodes);
    setVisibleNodes(rolesWithScores);

    if (rolesWithScores.length === nodesPerScreen) {
      halfDisplay = 5;
    }

    if (view.roleData) {
      setTimeout(() => {
        const roleHover = rolesWithScores.filter(
          (role) => role.role_id === view.roleData.role_id,
        )[0];
        handleNodeHover(
          roleHover,
          roleHover.x,
          roleHover.y, // Center node
        );
      }, 50);
    }
  }, [
    roles,
    view.roleData,
    view.rolesExtra,
    windowSize,
    view.rolesFilter,
    openOpportunities,
  ]);

  useEffect(() => {
    if (totalNodes.length > nodesPerScreen) {
      setScrollLock(false);
    } else {
      setScrollLock(true);
    }
  }, [totalNodes, visibleNodes]);

  const scrollCounter = useRef(0); // Initialize the scroll counter using useRef
  const threshold = 6; // Replace X with the desired number of scroll events
  const thresholdMobile = 12; // Replace X with the desired number of scroll events

  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].pageY);
    setScrollTop(e.currentTarget.scrollTop);
  };

  const handleScroll = (e) => {
    if (scrollLock) return;
    scrollCounter.current++; // Increment the counter each time the event fires

    if (e.touches && e.touches[0] && scrollCounter.current < thresholdMobile) {
      return; // If the counter has not reached the threshold, exit the function
    }
    if (scrollCounter.current < threshold) {
      return; // If the counter has not reached the threshold, exit the function
    }

    scrollCounter.current = 0; // Reset the counter after the threshold is reached

    const delta = e.deltaY;
    let direction = delta > 0 ? 1 : -1;

    if (e.touches && e.touches[0]) {
      const touchY = e.touches[0].pageY;
      const scrollDiff = startY - touchY;

      // Determine if scrolling up or down
      if (scrollDiff > 0 && e.touches[0]) {
        direction = 1;
      } else if (scrollDiff < 0 && e.touches[0]) {
        direction = -1;
      }
    }

    let start, end;

    if (direction === 1) {
      // Scrolling down
      start = visibleNodes[0].index + scrollSpeed;
      end = start + nodesPerScreen;

      if (start >= totalNodes.length) {
        start = start % totalNodes.length;
      }

      if (end > totalNodes.length) {
        end = end % totalNodes.length;
      }

      const slicedNodes =
        end > start
          ? totalNodes.slice(start, end)
          : totalNodes.slice(start).concat(totalNodes.slice(0, end));

      const newNodes: any = [];

      for (let i = 0; i < nodesPerScreen; i++) {
        newNodes.push({
          x: nodeXY[i].x,
          y: nodeXY[i].y,
          ...slicedNodes[i],
        });
      }

      setVisibleNodes(newNodes);
      //
    } else if (direction === -1) {
      //
      start = visibleNodes[0].index - scrollSpeed;
      if (start < 0) {
        start = totalNodes.length + start;
      }

      end = start + nodesPerScreen;
      if (end > totalNodes.length) {
        end = end % totalNodes.length;
      }

      const slicedNodes =
        end > start
          ? totalNodes.slice(start, end)
          : totalNodes.slice(start).concat(totalNodes.slice(0, end));

      const newNodes: any = [];

      for (let i = 0; i < nodesPerScreen; i++) {
        newNodes.push({
          x: nodeXY[i].x,
          y: nodeXY[i].y,
          ...slicedNodes[i],
        });
      }

      setVisibleNodes(newNodes);
    }
  };

  const handleNodeHover = (node, x, y) => {
    // return;
    const element = document.getElementById('role-card');

    if (
      element?.style.visibility === 'hidden' ||
      (!element?.style.visibility && element)
    ) {
      element.style.visibility = 'visible';
    }

    //assign the proper nodeXY coordinates to the node being hovered

    const hoveredRoleIndex = visibleNodes.findIndex(
      (role) => role?.role_id === node?.role_id,
    );

    const roleData = {
      title: node?.id,
      description: node?.rolePurpose,
      role_id: node?.role_id,
      family_id: node?.family_id,
      sub_family_id: node?.subfamily_id,
      skills: node?.skills?.map((skill) => {
        const exists: any = references.current.lastRoleSelected?.skills.find(
          (insideSkill: any) => {
            return insideSkill.name === skill.name;
          },
        );

        return {
          ...skill,
          match: !!exists,
          numberMatch: exists?.level === skill?.level,
        };
      }),
      family: node?.family,
      subFamily: node?.subFamily,
      accountabilities: node?.accountabilities,
      open_opportunities: node?.open_opportunities,
      mousePosition: {
        x: x + 30,
        y: y + 30,
        // x:
        //   nodeXY?.length > 0 && hoveredRoleIndex >= 0
        //     ? visibleNodes[hoveredRoleIndex]?.x + 30
        //     : node?.x + 30, // 30 is the offset of the node
        // y:
        //   nodeXY?.length > 0 && hoveredRoleIndex >= 0
        //     ? visibleNodes[hoveredRoleIndex]?.y + 30
        //     : node?.y + 30,
      },
    };

    const skillLineData = {
      x:
        nodeXY?.length > 0 && hoveredRoleIndex >= 0
          ? nodeXY[hoveredRoleIndex]?.x
          : node?.x,
      y:
        nodeXY?.length > 0 && hoveredRoleIndex >= 0
          ? nodeXY[hoveredRoleIndex]?.y
          : node?.y,
      id: node?.id,
    };

    dispatch(setCardData(roleData));
    dispatch(setSkillLineData(skillLineData));
  };

  const canShowOpenOpp = (openOpp) => {
    if (openOpp?.visibility === 'all' || !currentUser.attributes.country) {
      return true;
    }

    if (openOpp?.visibility === 'open_countries') {
      const isUserCountryPresent = openOpp?.open_location.find((open) => {
        return open?.value === currentUser.attributes.country;
      });

      return !!isUserCountryPresent;
    }

    if (openOpp?.visibility === 'international') {
      const isUserCountryPresent = openOpp?.open_location.find((open) => {
        return open?.value === currentUser.attributes.country;
      });

      return !isUserCountryPresent;
    }

    return true;
  };

  return (
    <Flex
      onTouchStart={(e) => handleTouchStart(e)}
      onTouchMove={(e) => handleScroll(e)}
      onWheel={handleScroll}
      {...flexStyles(windowSize)}
      pointerEvents={'none'}
      transition={'all 0.1s ease'}
    >
      {!scrollLock && (
        <VStack
          position={'absolute'}
          top={windowSize.height < 1000 ? '17%' : '23%'}
          left={'70px'}
          border={'1px solid white'}
          borderRadius={'100px'}
          h={'50px'}
          w={'50px'}
          cursor={'pointer'}
          justifyContent={'center'}
          zIndex={1000}
          alignItems={'center'}
          onClick={() => {
            handleScroll({ deltaY: 1 });
          }}
        >
          {arrowDirectional({
            transform: 'rotate(180deg)',
            color: 'white',
            width: 5,
            height: 10,
          })}
        </VStack>
      )}
      {!scrollLock && (
        <VStack
          transition={'all 0.2s ease'}
          cursor={'pointer'}
          position={'absolute'}
          top={
            windowSize.height < 1000
              ? '71%'
              : windowSize.height > 1800
              ? 'calc(23% + 1000px)'
              : '79%'
          }
          left={'70px'}
          zIndex={1000}
          border={'1px solid white'}
          borderRadius={'100px'}
          h={'50px'}
          w={'50px'}
          onClick={() => {
            handleScroll({ deltaY: -1 });
          }}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {arrowDirectional({
            transform: 'rotate(180deg)',
            color: 'white',
            width: 5,
            height: 10,
          })}
        </VStack>
      )}
      <Box {...boxStyles(windowSize)} pointerEvents={'auto'}>
        {visibleNodes.map((node, i) => {
          const isBaseRole =
            node.role_id === currentUser.role?.role_id &&
            currentUser.role?.role_id;
          const isOpenOpp = openOpportunities.find(
            (opp: any) => opp.role_id === node.role_id,
          );

          let showOpenOpp = true;

          if (isOpenOpp) {
            showOpenOpp = canShowOpenOpp(isOpenOpp);
          }

          if (isBaseRole) {
            return (
              <Box
                key={node.id + i}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedNode(node);
                  setExpanded(true);
                }}
                transition={'all 0.1s ease'}
                onMouseEnter={() =>
                  !node.invisible &&
                  handleNodeHover(node, nodeXY[i].x, nodeXY[i].y - 20)
                }
              >
                <UserDiamond
                  position={{ x: nodeXY[i].x, y: nodeXY[i].y - 20 }}
                  isSelected={node?.role_id !== selectedNode?.role_id}
                  hideCheck={true}
                  score={node.score}
                />
              </Box>
            );
          }

          return (
            <Box
              key={node.id + i}
              {...nodeBoxStyles(nodeXY[i].x, nodeXY[i].y - 20, circleRadius)}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedNode(node);
                setExpanded(true);
              }}
              transition={'all 0.1s ease !important'}
              opacity={node.invisible ? 0 : 1}
              onMouseEnter={() =>
                !node.invisible &&
                handleNodeHover(node, nodeXY[i].x, nodeXY[i].y - 20)
              }
              cursor={node.invisible ? 'default' : 'pointer'}
              backdropFilter={'blur(10px)'}
            >
              <Box
                {...scoreBoxStyles(circleRadius)}
                border={isOpenOpp && showOpenOpp ? '2px solid #F6851E' : ''}
                background={
                  node?.role_id !== selectedNode?.role_id
                    ? 'rgba(255,255,255,0.3)'
                    : isOpenOpp && showOpenOpp
                    ? '#F6851E'
                    : 'white'
                }
                color={
                  node?.role_id !== selectedNode?.role_id
                    ? 'white'
                    : isOpenOpp && showOpenOpp
                    ? 'white'
                    : 'black'
                }
              >
                {node.score !== 100 ? (
                  node.score
                ) : (
                  <CheckMark
                    color={
                      node?.role_id !== selectedNode?.role_id
                        ? 'white'
                        : isOpenOpp && showOpenOpp
                        ? 'white'
                        : 'black'
                    }
                  />
                )}
              </Box>
            </Box>
          );
        })}
        {/* <RoleCard setBigCard={() => {}} smallCard={!expandCard} /> */}

        <AccordionRoleCard elements={elements} />
      </Box>
    </Flex>
  );
};
