import React from 'react';

interface CircleIconProps {
  size?: number;
  strokeWidth?: number;
}

const CircleIcon: React.FC<CircleIconProps> = ({
  size = 18,
  strokeWidth = 1.5,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <circle
        cx={size / 2}
        cy={size / 2}
        r={(size - strokeWidth) / 2}
        fill="transparent"
        stroke="white"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default CircleIcon;
