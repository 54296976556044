import { Box, Image, useMediaQuery } from '@chakra-ui/react';
import { Cta } from '../../components/Button/Cta';
import landingPageLight from '/images/landingPageLight-min.png';
import AnimatedBoxes from './AnimatedBoxes';
import { glassMagnifier } from '../../assets/svg/glassMagnifier';
import { compass } from '../../assets/svg/compass';
import { lightningBolt } from '../../assets/svg/lightningBolt';
import { heart } from '../../assets/svg/heart';
import { welcomeGradientSVG } from '../../assets/svg/welcomeGradientSVG';
import { facebook } from '../../assets/svg/facebook';
import { linkedin } from '../../assets/svg/linkedin';
import { youtube } from '../../assets/svg/youtube';
import { discord } from '../../assets/svg/discord';
import { instagram } from '../../assets/svg/instagram';
import { theSelf } from '../../assets/svg/theSelf';
import {
  animatedBoxesContainer,
  companiesSectionContainer,
  companyCardContainer,
  companyCardDescriptionContainer,
  companyCardIconContainer,
  companyCardTitleContainer,
  firstDescriptionContainer,
  firstDescriptionLastSentenceContainer,
  firstLampContainer,
  firstTitleInsideSectionContainer,
  firstTitleSectionContainer,
  firstTitleStyles,
  getADemoButton,
  landingPageContainer,
  lastSentenceFirstTitleStyles,
  secondLampContainer,
  socialMediaIconsContainer,
  socialMediaIconsInnerContainer,
  startYoursButton,
} from './LandingPageStyles';
import CookieBanner from '../../components/CookieBanner/CookieBanner';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ComingSoonTooltip } from '../../components/ComingSoon/ComingSoonTooltip';
import { useTranslation } from 'react-i18next';
import UserRefreshContext from '../../context/userRefreshContext';
import LoginContext from '../../context/loginContext';
import { useQuery } from '../../utils/helpers/useQuery';
import CryptoJS from 'crypto-js';

export const LandingPage = ({
  menuIsOpen,
  menuOnToggle,
  isDemoOpen,
  setIsDemoOpen,
}) => {
  const [isLargerThan576] = useMediaQuery('(min-width: 576px)');
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [showComingSoonTooltip, setShowComingSoonTooltip]: any = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const { t } = useTranslation();

  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });

  const query = useQuery();

  const refreshContext = useContext(UserRefreshContext);

  const { canRenderLandingPage } = refreshContext;

  const emailContext = useContext(LoginContext);
  const {
    setIsLoginOpen,
    setStep,
    setIsSignup,
    setSecondStepSignupData,
    isLoginOpen,
  } = emailContext;

  document.body.classList.remove('hiddenBody');

  useEffect(() => {
    if (!isDemoOpen && !isLoginOpen) {
      document.body.classList.remove('hiddenBody');
    }
  }, [isDemoOpen, isLoginOpen]);

  useEffect(() => {
    const token = query.get('t');
    const encryptedEmail = query.get('e');
    const encryptedPassword = query.get('p');
    const step = query.get('step');
    const isCompany = query.get('c');
    const teamName = query.get('tN');
    const teamEmail = query.get('tE');

    if (
      step === 'setPassword' &&
      token &&
      encryptedEmail &&
      encryptedPassword &&
      canRenderLandingPage
    ) {
      setIsSignup(true);

      setSecondStepSignupData({
        token,
        oldPassword: CryptoJS.enc.Base64.parse(query.get('p')).toString(
          CryptoJS.enc.Utf8,
        ),
        email: CryptoJS.enc.Base64.parse(query.get('e')).toString(
          CryptoJS.enc.Utf8,
        ),
        isCompany: isCompany === 'true',
        teamName,
        teamEmail,
      });

      query.delete('p');
      query.delete('t');
      query.delete('e');
      query.delete('step');
      query.delete('c');
      query.delete('tN');
      query.delete('tE');
    }
  }, [query, canRenderLandingPage]);

  const handleMousePosition = (event: any) => {
    const boxRect = event.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: event.clientX - boxRect.left,
      y: event.clientY - boxRect.top + 25,
    });
  };

  const comingSoonTooltip = () => {
    return <ComingSoonTooltip mousePosition={mousePosition} />;
  };

  if (!canRenderLandingPage) {
    return (
      <Box
        position={'relative'}
        zIndex={50}
        backgroundColor={'white'}
        width={'100vw'}
        height={'1000vh'}
      ></Box>
    );
  }

  return (
    <Box {...landingPageContainer()}>
      {welcomeGradientSVG()}
      <Box
        {...firstTitleSectionContainer(
          isLargerThan576,
          isLargerThan756,
          isLargerThan992,
        )}
      >
        <Box
          {...firstTitleInsideSectionContainer(
            isLargerThan576,
            isLargerThan756,
            isLargerThan992,
          )}
        >
          <Box
            {...firstTitleStyles(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            <Box>{t('landing.journey')}</Box>
            <Box>{t('landing.of_you')}</Box>
            <Box display={'flex'} gap={'17.5px'}>
              {t('landing.becoming')}
              <Box
                {...lastSentenceFirstTitleStyles(
                  isLargerThan576,
                  isLargerThan992,
                )}
                as="span"
              >
                {t('landing.you')}{' '}
                {theSelf(isLargerThan576, isLargerThan756, isLargerThan992)}
              </Box>
            </Box>

            <Box
              {...firstDescriptionContainer(
                isLargerThan576,
                isLargerThan756,
                isLargerThan992,
              )}
            >
              {t('landing.journey_text')}
            </Box>

            <Box
              {...firstDescriptionLastSentenceContainer(
                isLargerThan576,
                isLargerThan756,
                isLargerThan992,
              )}
            >
              {t('landing.journey_bold')}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        {...firstLampContainer(
          isLargerThan576,
          isLargerThan756,
          isLargerThan992,
        )}
      >
        <Image
          position={'absolute'}
          src={landingPageLight}
          height={
            isLargerThan992
              ? '400px'
              : isLargerThan756
              ? '350px'
              : isLargerThan576
              ? '275px'
              : '200px'
          }
          width={'100%'}
        />
        <Cta
          variation="no_hover"
          text={t('landing.start_yours_now')}
          onClick={() => {
            setIsDemoOpen(true);
            setIsLoginOpen(false);
            if (!menuIsOpen) {
              menuOnToggle();
            }
            setTimeout(() => {
              document.body.classList.add('hiddenBody');
            }, 200);
          }}
          props={{
            ...startYoursButton(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
              isLargerThan1500,
            ),
          }}
        />
      </Box>
      <Box
        {...animatedBoxesContainer(
          isLargerThan576,
          isLargerThan756,
          isLargerThan992,
        )}
        id="for_you_section_landing_page"
      >
        <AnimatedBoxes />
      </Box>

      <Box
        {...companiesSectionContainer(
          isLargerThan576,
          isLargerThan756,
          isLargerThan992,
        )}
        id="for_companies_section_landing_page"
      >
        <Box
          {...companyCardContainer(
            isLargerThan576,
            isLargerThan756,
            isLargerThan992,
          )}
        >
          <Box
            {...companyCardIconContainer(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            {glassMagnifier()}
          </Box>
          <Box {...companyCardTitleContainer()}>
            {t('landing.role_clarity')}
          </Box>
          <Box
            {...companyCardDescriptionContainer(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            {t('landing.desc1')}
          </Box>
        </Box>
        <Box
          {...companyCardContainer(
            isLargerThan576,
            isLargerThan756,
            isLargerThan992,
          )}
        >
          <Box
            {...companyCardIconContainer(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            {compass()}
          </Box>
          <Box {...companyCardTitleContainer()}>
            {' '}
            {t('landing.career_path_mapping')}
          </Box>
          <Box
            {...companyCardDescriptionContainer(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            {t('landing.desc2')}
          </Box>
        </Box>
        <Box
          {...companyCardContainer(
            isLargerThan576,
            isLargerThan756,
            isLargerThan992,
          )}
        >
          <Box
            {...companyCardIconContainer(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            {lightningBolt()}
          </Box>
          <Box {...companyCardTitleContainer()}>
            {t('landing.reskill_upskill')}
          </Box>
          <Box
            {...companyCardDescriptionContainer(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            {t('landing.desc3')}
          </Box>
        </Box>
        <Box
          {...companyCardContainer(
            isLargerThan576,
            isLargerThan756,
            isLargerThan992,
          )}
        >
          <Box
            {...companyCardIconContainer(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            {heart()}
          </Box>
          <Box {...companyCardTitleContainer()}>
            {t('landing.internal_sourcing')}
          </Box>
          <Box
            {...companyCardDescriptionContainer(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
            )}
          >
            {t('landing.desc4')}
          </Box>
        </Box>
      </Box>

      {/* <Box {...animatedLogosContainer()}>
        <Box {...animatedLogosTitleContainer()}>
          Trusted by leading brands worldwide
        </Box>

        <AnimatedLogos />
      </Box> */}

      <Box
        {...secondLampContainer(
          isLargerThan576,
          isLargerThan756,
          isLargerThan992,
        )}
      >
        <Image
          position={'absolute'}
          src={landingPageLight}
          transform={'rotate(180deg)'}
          height={
            isLargerThan992
              ? '400px'
              : isLargerThan756
              ? '350px'
              : isLargerThan576
              ? '275px'
              : '200px'
          }
          width={'100%'}
        />
        <Cta
          variation="no_hover"
          text={t('landing.get_a_demo')}
          onClick={() => {
            setIsDemoOpen(true);
            setIsLoginOpen(false);
            if (!menuIsOpen) {
              menuOnToggle();
            }
            setTimeout(() => {
              document.body.classList.add('hiddenBody');
            }, 200);
          }}
          props={{
            ...getADemoButton(
              isLargerThan576,
              isLargerThan756,
              isLargerThan992,
              isLargerThan1500,
            ),
          }}
        />
      </Box>

      <Box
        {...socialMediaIconsContainer(
          isLargerThan576,
          isLargerThan756,
          isLargerThan992,
        )}
      >
        <Box
          {...socialMediaIconsInnerContainer(
            isLargerThan576,
            isLargerThan756,
            isLargerThan992,
          )}
        >
          <Box
            onMouseEnter={(event) => {
              handleMousePosition(event);
              setShowComingSoonTooltip('facebook');
            }}
            onMouseLeave={() => {
              setShowComingSoonTooltip(null);
            }}
            position={'relative'}
          >
            {facebook()}{' '}
            {showComingSoonTooltip === 'facebook' && comingSoonTooltip()}
          </Box>
          <Box
            onMouseEnter={(event) => {
              setShowComingSoonTooltip('discord');
              handleMousePosition(event);
            }}
            onMouseLeave={() => {
              setShowComingSoonTooltip(null);
            }}
            position={'relative'}
          >
            {discord()}{' '}
            {showComingSoonTooltip === 'discord' && comingSoonTooltip()}
          </Box>
          <Box
            onMouseEnter={(event) => {
              setShowComingSoonTooltip('instagram');
              handleMousePosition(event);
            }}
            onMouseLeave={() => {
              setShowComingSoonTooltip(null);
            }}
            position={'relative'}
          >
            {instagram()}{' '}
            {showComingSoonTooltip === 'instagram' && comingSoonTooltip()}
          </Box>
          <Box
            onMouseEnter={(event) => {
              setShowComingSoonTooltip('linkedin');
              handleMousePosition(event);
            }}
            onMouseLeave={() => {
              setShowComingSoonTooltip(null);
            }}
            position={'relative'}
          >
            {linkedin()}{' '}
            {showComingSoonTooltip === 'linkedin' && comingSoonTooltip()}
          </Box>
          <Box
            onMouseEnter={(event) => {
              setShowComingSoonTooltip('youtube');
              handleMousePosition(event);
            }}
            onMouseLeave={() => {
              setShowComingSoonTooltip(null);
            }}
            position={'relative'}
          >
            {youtube()}{' '}
            {showComingSoonTooltip === 'youtube' && comingSoonTooltip()}
          </Box>
        </Box>
      </Box>
      <CookieBanner />
    </Box>
  );
};
