import { Box } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

// Animation constants
const scrollAmount = 1.5; // Pixels to increment
const resetDelay = 1000; // Delay before reset in ms

// Your Box component
const ScrollableTextBox = ({ text = '', maxW = '120px', styles = {} }) => {
  const textAnimationRef: any = useRef(null);
  const blockAnimationRef: any = useRef(false);
  const textTimeoutRef: any = useRef(null);
  const insideTimeoutRef: any = useRef(null);

  // Function to start the scrolling animation
  const startScrolling = (target) => {
    if (blockAnimationRef.current) return; // Prevent multiple animations

    const animate = () => {
      if (blockAnimationRef.current) return; // Stop if blocked or no longer animating

      if (target.scrollLeft < target.scrollWidth - target.clientWidth - 1) {
        target.scrollLeft += scrollAmount;
        textAnimationRef.current = requestAnimationFrame(animate); // Continue animation
      } else {
        blockAnimationRef.current = true; // Set block to true
        textTimeoutRef.current = setTimeout(() => {
          target.scrollLeft = 0; // Reset scroll position

          insideTimeoutRef.current = setTimeout(() => {
            blockAnimationRef.current = false; // Allow new animation
            textAnimationRef.current = requestAnimationFrame(animate);
          }, 500);
        }, resetDelay);
      }
    };

    textAnimationRef.current = requestAnimationFrame(animate); // Start the animation
  };

  // Event handler for mouse enter
  const handleMouseEnter = (e) => {
    e.target.style.textOverflow = 'unset'; // Remove ellipsis
    startScrolling(e.target); // Start the scroll
  };

  // Event handler for mouse leave
  const handleMouseLeave = (e) => {
    e.target.style.textOverflow = 'ellipsis'; // Restore ellipsis
    e.target.scrollLeft = 0; // Reset scroll position

    // Clear animation on leave
    if (textAnimationRef.current) {
      cancelAnimationFrame(textAnimationRef.current); // Stop the animation
    }

    if (textTimeoutRef.current) {
      clearTimeout(textTimeoutRef.current); // Clear timeout
    }

    if (insideTimeoutRef.current) {
      clearTimeout(insideTimeoutRef.current);
    }
    blockAnimationRef.current = false; // Allow new animation on next enter
  };

  // Ensure cleanup when the component unmounts
  useEffect(() => {
    return () => {
      if (textAnimationRef.current) {
        cancelAnimationFrame(textAnimationRef.current); // Clean up animation on unmount
      }
      if (textTimeoutRef.current) {
        clearTimeout(textTimeoutRef.current); // Clear timeout on unmount
      }

      if (insideTimeoutRef.current) {
        clearTimeout(insideTimeoutRef.current);
      }
    };
  }, []);

  // Box element with event handlers
  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        overflow: 'hidden', // Keeps scroll hidden
        textOverflow: 'ellipsis',
        maxWidth: maxW,
        marginLeft: '5px',
        whiteSpace: 'nowrap', // Ensure text is a single line
        color: 'white',
        ...styles,
      }}
    >
      {text.trim().toLowerCase()}
    </Box>
  );
};

export default ScrollableTextBox;
