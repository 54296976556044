export const betaColor = (invert) => {
  return (
    <svg
      width="30"
      height="25"
      viewBox="0 0 17 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        backgroundColor: invert ? 'white' : undefined,
        borderRadius: invert ? '5px' : undefined,
        marginLeft: invert ? '15px' : undefined,
        transition: 'margin-left 0.5s ease',
        padding: invert ? '2px 0px' : undefined,
      }}
    >
      <path
        d="M0.724568 26.6515H0.34275C0.225578 26.5343 0.166992 26.4576 0.166992 26.4212C0.166992 26.3606 0.938709 23.2576 2.48214 17.1121C4.02558 10.9626 4.83568 7.75253 4.91245 7.48182C5.43366 5.85354 6.16093 4.45758 7.09426 3.29394C7.59932 2.63535 8.06396 2.16061 8.4882 1.8697C9.36093 1.1303 10.3407 0.683838 11.4276 0.530303C11.468 0.530303 11.5609 0.524243 11.7064 0.512121C11.8518 0.504041 11.963 0.5 12.0397 0.5C13.2599 0.5 14.2539 0.863636 15.0215 1.59091C15.7852 2.31818 16.167 3.26364 16.167 4.42727C16.167 5.37677 15.8963 6.30202 15.3549 7.20303C14.8135 8.10808 14.0761 8.8697 13.1427 9.48788L13.5246 9.8697C14.5104 10.8556 15.0034 12.1242 15.0034 13.6758C15.0034 15.6758 14.3165 17.3646 12.9427 18.7424C11.565 20.1162 9.93467 20.9485 8.05184 21.2394C7.8579 21.2798 7.50033 21.3 6.97911 21.3C5.24982 21.3 4.0175 20.6313 3.28214 19.2939L3.16699 19.1182L1.30639 26.4758C1.26598 26.5929 1.07204 26.6515 0.724568 26.6515ZM9.76699 8.4697C10.5064 8.4697 11.2256 8.58687 11.9246 8.82121L12.0943 8.67576C13.1246 7.9202 13.7852 6.60101 14.0761 4.71818C14.1124 4.54445 14.1306 4.22525 14.1306 3.76061C14.1306 3.40909 14.1225 3.18485 14.1064 3.08788C13.8316 2.09798 13.0943 1.60303 11.8943 1.60303C11.4862 1.60303 11.1367 1.64343 10.8458 1.72424C9.83568 1.99495 8.90437 2.66768 8.05184 3.74242C7.19932 4.82121 6.52053 6.16465 6.01548 7.77273C5.91851 8.08384 5.55083 9.5101 4.91245 12.0515C4.27002 14.5889 3.94073 15.9465 3.92457 16.1242C3.90437 16.2212 3.89427 16.4232 3.89427 16.7303C3.89427 17.8172 4.17911 18.6758 4.74881 19.3061C5.32255 19.9364 6.09426 20.2515 7.06396 20.2515C8.80942 20.2515 10.1771 19.5586 11.167 18.1727C12.1569 16.7869 12.7003 15.0859 12.7973 13.0697V12.8939C12.7973 11.7667 12.4579 10.8636 11.7791 10.1848C10.963 10.4596 10.1387 10.597 9.30639 10.597C7.77103 10.597 7.00336 10.296 7.00336 9.69394C7.00336 9.55657 7.03366 9.42121 7.09426 9.28788C7.36497 8.74242 8.25588 8.4697 9.76699 8.4697Z"
        fill={invert ? 'black' : 'url(#paint0_linear_2089_3650)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2089_3650"
          x1="3.16699"
          y1="3.5758"
          x2="19.1467"
          y2="16.6655"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0333845" stop-color="#784299" />
          <stop offset="0.249994" stop-color="#784299" />
          <stop offset="0.505207" stop-color="#D52127" />
          <stop offset="1" stop-color="#F6851E" />
        </linearGradient>
      </defs>
    </svg>
  );
};
