import { careersAPI } from '../../utils/helpers/axios';

export const calculateWidth = (skill, nodes, real?: boolean) => {
  const width = nodes.filter((node) =>
    node.skills?.some((inSkill) => inSkill.skill_id === skill.skill_id),
  ).length;
  const percentage = (width * 200) / nodes.length;
  // if (real && percentage < 5) percentage = 5; ///? this was done in order to always show a bar even if the skill presence percentage was less than 5%
  return `${percentage.toFixed(0)}%`;
};

export const getSubSkills = async (skillId) => {
  const { data } = await careersAPI.post('/getSkills', {
    withSubSkills: true,
    skillIds: [skillId],
  });
  return data;
};


export const maskStyle = (id, hasOverflow, scrollPosition) => {
  const element: any = document.getElementById(id);
  if (!hasOverflow) {
    return 'none'; // No mask if no overflow
  }

  if (scrollPosition === 0) {
    // Mask only at the bottom when at the top
    return 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0))';
  }

  if (
    scrollPosition > 0 &&
    scrollPosition < element.scrollHeight - element.clientHeight
  ) {
    // Apply mask at both top and bottom when scrolling
    return undefined;
  }

  // Mask only at the top when scrolled to the bottom
  return 'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 15%, rgb(0, 0, 0) 100%)';
};