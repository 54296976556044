import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';
import LoginContext from '../../context/loginContext';
import { useContext, useEffect, useRef } from 'react';
import { alertIcon } from '../../assets/svg/alertIcon';
import { loginGoogle } from '../../../public/svg/loginGoogle';
import { loginMicrosoft } from '../../../public/svg/loginMicrosoft';
import { TextInput } from '../../components/TextInput/TextInput';
import { Cta } from '../../components/Button/Cta';
import {
  CustomPlainBody,
  CustomPlainError,
  CustomPlainHeader,
} from '../../components/ModelTemplate/PlainTemplate';
import {
  CustomModalButtonSection,
  CustomModalDivider,
  CustomModalInput,
  CustomModalSSO,
} from '../../components/ModelTemplate/ModalTemplateComponents';
import { useTranslation } from 'react-i18next';
import { loginAPI } from '../../utils/helpers/axios';

export const LoginFormPlain = ({ setBlockMouseLeaveClose }) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const emailContext = useContext(LoginContext);
  const {
    formik,
    error,
    setShowForgotPassword,
    showForgotPassword,
    setError,
    setValues,
    showPassword,
    setShowPassword,
    setErrors,
  } = emailContext;

  const { t } = useTranslation();

  const handleEmailDebounce: any = useRef(null);

  useEffect(() => {
    const handleEmail = async () => {
      try {
        const { data } = await loginAPI.post('/checkUser', {
          email: formik.values.email,
        });

        if (data === 'show_password') {
          setShowPassword(true);
          setError(null);
          setErrors({});
          return;
        }

        if (data === 'redirect_microsoft') {
          setShowPassword(false);
          const id = 'aa8273a1-ea94-4831-922d-1223d7c0a41f';

          const environments = {
            development: import.meta.env.VITE_CAREERS_OWN_LINK_LOCAL,
            staging: import.meta.env.VITE_CAREERS_OWN_LINK_TEST,
            production: import.meta.env.VITE_CAREERS_OWN_LINK_PROD,
          };

          formik.setFieldValue('email', '');

          window.location.assign(
            `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${id}&state=DA&redirect_uri=${
              environments[import.meta.env.VITE_ENVIRONMENT]
            }&scope=https://graph.microsoft.com/User.Read&response_type=code&prompt=select_account`,
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(formik.values.email) && !showForgotPassword) {
      if (handleEmailDebounce.current) {
        clearTimeout(handleEmailDebounce.current);
      }
      handleEmailDebounce.current = setTimeout(() => {
        handleEmail();
      }, 200);
    }
  }, [formik.values.email, showPassword, showForgotPassword]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const onForgotPassword = () => {
    setShowForgotPassword(true);
    setError(null);
    setValues({ ...formik.values, password: '' });
    setBlockMouseLeaveClose(true);
  };

  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box
        onKeyDown={handleKeyPress}
        width={isLargerThan1500 ? '610px' : isLargerThan756 ? '462px' : '300px'}
        maxWidth={
          isLargerThan1500 ? '550px' : isLargerThan756 ? '462px' : '300px'
        }
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        fontFamily={'PoppinsLight'}
        gap={isLargerThan1500 ? '30px' : '20px'}
        textAlign={'left'}
      >
        <CustomPlainHeader
          title={t('landing.resume_journey_ready')}
          subTitle={null}
          styles={{
            fontFamily: 'PPNeueMachinaPlainLight',
            fontSize: isLargerThan1500
              ? '25px'
              : isLargerThan756
              ? '20px'
              : '15px',
          }}
        />
        <CustomPlainError error={error} />
        <CustomPlainBody error={error}>
          <CustomModalSSO setError={setError} email={formik.values.email} />
          <CustomModalDivider />
          <CustomModalInput
            formik={formik}
            label={'Email'}
            name={'email'}
            type={null}
            onForgotPassword={null}
          />
          {showPassword && (
            <CustomModalInput
              formik={formik}
              label={t('user_preferences.password')}
              name={'password'}
              type={'password'}
              onForgotPassword={onForgotPassword}
            />
          )}
          <CustomModalButtonSection
            text={
              showPassword ? t('landing.resume_journey') : t('landing.lets_go')
            }
            onClick={() => {
              formik.handleSubmit();
            }}
            width={
              showPassword
                ? isLargerThan1500
                  ? '299px'
                  : '220px'
                : isLargerThan1500
                ? '157px'
                : '105px'
            }
            height={isLargerThan1500 ? '66px' : '45px'}
          />
        </CustomPlainBody>
      </Box>
    </Box>
  );

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      width={isLargerThan1500 ? '550px' : isLargerThan756 ? '462px' : '300px'}
      maxWidth={
        isLargerThan1500 ? '550px' : isLargerThan756 ? '462px' : '300px'
      }
      fontFamily={'PoppinsLight'}
    >
      <Box
        color={'white'}
        fontSize={isLargerThan756 ? '20px' : '15px'}
        lineHeight={isLargerThan1500 ? '25.5px' : '20px'}
        fontFamily={'PPNeueMachinaPlainLight'}
        alignSelf={'baseline'}
      >
        Are you ready to resume your journey?
      </Box>

      {error ? (
        <Box
          fontSize={isLargerThan1500 ? '20px' : '14px'}
          lineHeight={isLargerThan1500 ? '30px' : '20px'}
          fontFamily={'PoppinsLight'}
          display={'flex'}
          color={'white'}
          alignSelf={'baseline'}
          alignItems={'center'}
          marginTop={isLargerThan1500 ? '15px' : '10px'}
          textAlign={'left'}
        >
          <Box as="span">{alertIcon()}</Box>
          <Box marginLeft={'5px'}>{error}</Box>
        </Box>
      ) : null}

      <Box
        marginTop={'25px'}
        display={'flex'}
        justifyContent={'space-between'}
        width={'20%'}
      >
        <Box w={'38px'} h={'38px'}>
          {loginGoogle()}
        </Box>
        {/*<Box w={'38px'} h={'38px'}>
        {loginFacebook()}
       </Box>
      <Box w={'38px'} h={'38px'}>
        {loginLinkedin()}
      </Box>*/}
        <Box
          onClick={() => {
            document.cookie = 'DA=microsoft';

            // const id = 'c17d355a-7a9d-4fb1-967c-261cf9c80c14';
            const id = import.meta.env.VITE_MICROSOFT_ID;

            const environments = {
              development: import.meta.env.VITE_CAREERS_OWN_LINK_LOCAL,
              staging: import.meta.env.VITE_CAREERS_OWN_LINK_TEST,
              production: import.meta.env.VITE_CAREERS_OWN_LINK_PROD,
            };

            window.location.assign(
              `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${id}&state=DA&redirect_uri=${
                environments[import.meta.env.VITE_ENVIRONMENT]
              }&scope=https://graph.microsoft.com/User.Read&response_type=code`,
            );
          }}
          w={'38px'}
          h={'38px'}
        >
          {loginMicrosoft()}
        </Box>
      </Box>

      <Box
        position="relative"
        width="100%"
        marginTop={'30px'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        maxWidth={'550px'}
      >
        <Box width={'45%'}>
          <Divider borderTop={'1px solid white'} />
        </Box>

        <Box
          fontSize={
            isLargerThan1500 ? '16px' : isLargerThan756 ? '14px' : '12px'
          }
          color={'white'}
        >
          or
        </Box>

        <Box width={'45%'}>
          <Divider borderTop={'1px solid white'} />
        </Box>
      </Box>

      <VStack w={'full'}>
        <FormControl>
          <FormLabel
            color={'white'}
            htmlFor="email"
            fontFamily={'PoppinsLight'}
            fontSize={
              isLargerThan1500 ? '16px' : isLargerThan756 ? '14px' : '12px'
            }
          >
            Email
          </FormLabel>
          <TextInput
            onChange={formik.handleChange}
            props={{
              name: 'email',
              value: formik.values.email,
            }}
            variation="login"
          />
        </FormControl>

        {showPassword ? (
          <FormControl>
            <FormLabel
              color={'white'}
              htmlFor="password"
              fontFamily={'PoppinsLight'}
              fontSize={
                isLargerThan1500 ? '16px' : isLargerThan756 ? '14px' : '12px'
              }
            >
              Password
            </FormLabel>
            <TextInput
              onChange={formik.handleChange}
              props={{
                name: 'password',
                type: 'password',
                value: formik.values.password,
              }}
              variation="login"
              onForgotPassword={() => {
                setShowForgotPassword(true);
                setError(null);
                setValues({ ...formik.values, password: '' });
              }}
            />
          </FormControl>
        ) : null}
        <Box
          display={'flex'}
          justifyContent={'center'}
          w={'full'}
          mt={3}
          marginBottom={'10px'}
          onClick={formik.handleSubmit}
        >
          <Cta
            text={'resume our journey'}
            props={{
              type: 'submit',
              w: isLargerThan1500 ? '299px' : '170px',
              height: isLargerThan1500 ? '66px' : '45px',
              mt: 1,
              borderRadius: '60px',
              bg: '#F4F3F226',
              border: '1.5px solid #F4F3F2',
              fontSize: isLargerThan1500 ? '24px' : '13px',
              fontFamily: 'PoppinsBold',
            }}
            variation="login"
          />
        </Box>
      </VStack>
    </Box>
  );
};
