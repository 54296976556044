import { Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={`

    @font-face {
      font-family: "PoppinsExtraBold";
      src: url("/fonts/Poppins-ExtraBold.woff2");
    }

    @font-face {
      font-family: "PoppinsBold";
      src: url("/fonts/Poppins-Bold.woff2");
    }
    
    @font-face {
      font-family: "PoppinsRegular";
      src: url("/fonts/Poppins-Regular.woff2");
    }
    
    @font-face {
      font-family: "PoppinsMedium";
      src: url("/fonts/Poppins-Medium.woff2");
    }
    
    @font-face {
      font-family: "PoppinsSemiBold";
      src: url("/fonts/Poppins-SemiBold.woff2");
    }

    @font-face {
      font-family: "PoppinsLight";
      src: url("/fonts/Poppins-Light.woff2");
    }
    
    @font-face {
      font-family: "PoppinsExtraLight";
      src: url("/fonts/Poppins-ExtraLight.woff2");
    }
    
    @font-face {
      font-family: "PPNeueMachina";
      src: url("/fonts/PPNeueMachina-PlainRegular.woff2");
    }

    @font-face {
      font-family: "PPNeuePlainBlack";
      src: url("/fonts/PPNeueMachina-PlainBlack.woff2");
    }

    @font-face {
      font-family: "PPNeuePlainBold";
      src: url("/fonts/PPNeueMachina-PlainBold.woff2");
    }

    @font-face {
      font-family: "PPNeueMachinaPlainLight";
      src: url("/fonts/PPNeueMachina-PlainLight.woff2");
    }

    @font-face {
      font-family: "PPNeueMachinaPlainMedium";
      src: url("/fonts/PPNeueMachina-PlainMedium.woff2");
    }

    @font-face {
      font-family: "PPNeueMachinaSemiBold";
      src: url("/fonts/PPNeueMachina-Semibold.woff2");
    }

    @font-face {
      font-family: "PPNeueMachinaPlainThin";
      src: url("/fonts/PPNeueMachina-PlainThin.woff2");
    }

    @font-face {
      font-family: "PPNeueMachinaPlainUltrabold";
      src: url("/fonts/PPNeueMachina-PlainUltrabold.woff2");
    }

    @font-face {
      font-family: "PPNeueMachinaPlainUltralight";
      src: url("/fonts/PPNeueMachina-PlainUltralight.woff2");
    }
    
    `}
  />
);

export default Fonts;
