import { Box, useMediaQuery } from '@chakra-ui/react';
import { useMobileDetect } from '../../utils/useMobile';
import { useTranslation } from 'react-i18next';
import {
  firstDescriptionContainer,
  firstTitleInsideSectionContainer,
  firstTitleSectionContainer,
  firstTitleStyles,
  lastSentenceFirstTitleStyles,
} from '../landingPage/LandingPageStyles';
import { theSelf } from '../../assets/svg/theSelf';
import { laptop } from '../../assets/svg/laptop';
import landingPageLight from '/images/landingPageLight-min.png';
import { useEffect } from 'react';
import useSize from '../../utils/useSize';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const ComingSoon = () => {
  const isMobile = useMobileDetect();
  const { t } = useTranslation();
  const [isLargerThan576] = useMediaQuery('(min-width: 576px)');
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const [isLongerThan650] = useMediaQuery('(min-height: 650px)');

  const { currentUser, location } = useSelector((state: any) => {
    return { currentUser: state.currentUser, location: state.location };
  });

  const navigate = useNavigate();

  const windowSize = useSize();

  useEffect(() => {
    if (currentUser && windowSize.width > 992) {
      navigate(location);
    }
  }, [currentUser, windowSize]);

  return (
    <>
      <Box
        position={'absolute'}
        height={'110px'}
        backgroundColor={'black'}
        zIndex={5}
        top={'0px'}
        width={'100%'}
      ></Box>
      <Box
        width={'100vw'}
        height={!isLongerThan650 ? '650px' : 'calc(100dvh - 60px)'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={isMobile ? 'flex-end' : 'center'}
        justifyContent={'center'}
        color={'white'}
        position={'relative'}
        minWidth={'350px'}
        overflow={'hidden'}
      >
        {isMobile ? (
          <Box
            position={'absolute'}
            backgroundSize={'contain'}
            backgroundImage={landingPageLight}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            transform={'rotate(90deg)'}
            width={!isLongerThan650 ? '720px' : 'calc(100dvh)'}
            left={
              !isLongerThan650
                ? 'calc(720px * 0.48 * -1)'
                : 'calc((100dvh) * 0.48 * -1)'
            }
            height={'100%'}
            zIndex={0}
            top={'0px'}
          ></Box>
        ) : null}
        {isMobile ? (
          <>
            <Box
              {...firstTitleSectionContainer(
                isLargerThan576,
                isLargerThan756,
                isLargerThan992,
              )}
              padding={'0px'}
              zIndex={2}
              marginTop={!isLongerThan650 ? '130px' : 'calc(100dvh * 0.3)'}
              position={'relative'}
              left={'30px'}
              height={'45%'}
            >
              <Box
                {...firstTitleInsideSectionContainer(
                  isLargerThan576,
                  isLargerThan756,
                  isLargerThan992,
                )}
              >
                <Box
                  {...firstTitleStyles(
                    isLargerThan576,
                    isLargerThan756,
                    isLargerThan992,
                  )}
                  display={'flex'}
                  flexDir={'column'}
                  fontSize={'35px'}
                  lineHeight={'44.76px'}
                  width={undefined}
                  minWidth={'350px'}
                  paddingLeft={'10px'}
                >
                  <Box>{t('coming_soon.mobile_experience_1')}</Box>
                  <Box>{t('coming_soon.mobile_experience_2')}</Box>
                  <Box>{t('coming_soon.mobile_experience_3')}</Box>
                  <Box>{t('coming_soon.mobile_experience_4')}</Box>

                  <Box display={'flex'} gap={'17.5px'}>
                    {t('coming_soon.mobile_experience_5')}
                    <Box
                      {...lastSentenceFirstTitleStyles(
                        isLargerThan576,
                        isLargerThan992,
                      )}
                      as="span"
                    >
                      {t('coming_soon.soon')}{' '}
                      {theSelf(
                        isLargerThan576,
                        isLargerThan756,
                        isLargerThan992,
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              {...firstDescriptionContainer(
                isLargerThan576,
                isLargerThan756,
                isLargerThan992,
              )}
              padding={'0px 30px'}
              zIndex={2}
            >
              {t('coming_soon.mobile_experience_description')}
            </Box>
            <Box
              display={'flex'}
              width={'100%'}
              justifyContent={'center'}
              position={'relative'}
              zIndex={2}
              padding={'0px 30px'}
            >
              {laptop()}
            </Box>
          </>
        ) : (
          <>
            <Box fontFamily={'PPNeuePlainBold'} fontSize={'25px'}>
              {t('coming_soon.coming_soon')}
            </Box>
            <Box fontFamily={'PoppinsLight'} fontSize={'15px'}>
              {t('coming_soon.desktop_experience')}
            </Box>
            <Box fontFamily={'PoppinsLight'} fontSize={'15px'}>
              {t('coming_soon.resize')}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
