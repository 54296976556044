import axios from 'axios';
import store from '../../store/storeConfig.js';
import { FETCH_USER_WITH_ALL_ATTRIBUTES } from '../../store/actions/types';

const environments = {
  development: {
    loginAPI: import.meta.env.VITE_LOGIN_API_LOCAL,
    globalAPI: import.meta.env.VITE_GLOBAL_API_LOCAL,
    careersAPI: import.meta.env.VITE_CAREERS_API_LOCAL,
    emailAPI: import.meta.env.VITE_EMAIL_API_LOCAL,
  },
  staging: {
    loginAPI: import.meta.env.VITE_LOGIN_API_TEST,
    globalAPI: import.meta.env.VITE_GLOBAL_API_TEST,
    careersAPI: import.meta.env.VITE_CAREERS_API_TEST,
    emailAPI: import.meta.env.VITE_EMAIL_API_TEST,
  },
  production: {
    loginAPI: import.meta.env.VITE_LOGIN_API_PROD,
    globalAPI: import.meta.env.VITE_GLOBAL_API_PROD,
    careersAPI: import.meta.env.VITE_CAREERS_API_PROD,
    emailAPI: import.meta.env.VITE_EMAIL_API_PROD,
  },
};

export const loginAPI = axios.create({
  baseURL: environments[import.meta.env.VITE_ENVIRONMENT]?.loginAPI,
  withCredentials: true,
});

loginAPI.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    /*if (isTesting) {
      return;
    }*/

    //if the error status code is 403 then we try to refresh the access token
    if (error.response.status === 403 && !originalRequest._retry) {
      if (originalRequest.url === '/sessionExpired') {
        return Promise.reject(error);
      }
      originalRequest._retry = true;

      await loginAPI.post('/internalRefresh');

      return loginAPI(originalRequest);
    } else if (error.response.status === 403 && originalRequest._retry) {
      store.dispatch({
        type: FETCH_USER_WITH_ALL_ATTRIBUTES,
        payload: null,
      });
      window.location.assign('/');
    }

    return Promise.reject(error);
  },
);

export const globalAPI = axios.create({
  baseURL: environments[import.meta.env.VITE_ENVIRONMENT]?.globalAPI,
  withCredentials: true,
});

globalAPI.interceptors.response.use(
  (response) => response,
  async function (error) {
    /*if (isTesting) {
      return;
    }*/

    console.log(error);

    const originalRequest = error.config;
    //if no error response, backend went down or something similar so we show the error page
    if (!error.response) {
      console.error(error);
      //redirectToErrorPage();
      return Promise.reject(error);
    }

    //if the error status code is 403 then we try to refresh the access token
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      await loginAPI.post('/internalRefresh');

      return globalAPI(originalRequest);
    } else if (error.response.status === 403 && originalRequest._retry) {
      store.dispatch({
        type: FETCH_USER_WITH_ALL_ATTRIBUTES,
        payload: null,
      });
      window.location.assign('/');
    }

    return Promise.reject(error);
  },
);

export const careersAPI = axios.create({
  baseURL: environments[import.meta.env.VITE_ENVIRONMENT]?.careersAPI,
  withCredentials: true,
});

careersAPI.interceptors.response.use(
  (response) => response,
  async function (error) {
    /*if (isTesting) {
      return;
    }*/

    console.log(error);

    const originalRequest = error.config;
    //if no error response, backend went down or something similar so we show the error page
    if (!error.response) {
      console.error(error);
      //redirectToErrorPage();
      return Promise.reject(error);
    }

    //if the error status code is 403 then we try to refresh the access token
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      await loginAPI.post('/internalRefresh');

      return careersAPI(originalRequest);
    } else if (error.response.status === 403 && originalRequest._retry) {
      store.dispatch({
        type: FETCH_USER_WITH_ALL_ATTRIBUTES,
        payload: null,
      });
      window.location.assign('/');
    }

    return Promise.reject(error);
  },
);

export const emailAPI = axios.create({
  baseURL: environments[import.meta.env.VITE_ENVIRONMENT]?.emailAPI,
});
