import { position } from '@chakra-ui/react';

export const containerTimeMachine = {
  position: 'absolute' as const,
  display: 'flex',
  h: 'fit-content',
  w: '100%',
  minW: "100vw",
  // background: 'white',
  pointerEvents: 'none' as const,
  left: 0,
  bottom: "60px",
  zIndex: 20,
  //justifyContent: 'space-between',
  alignItems: 'flex-end',
  fontFamily: 'PoppinsLight',
  color: 'white',
  overflow: "scroll",
  boxSizing: 'border-box' as const,
};

export const scrollContainer = (isLargerThan1300, isLargerThan1500, isLargerThan2500) => {
  return {
    pointerEvents: 'auto' as const,
    position: 'relative' as const,
    m: 20,
    marginTop: "0px",
    mx: isLargerThan1300 ? 10 : 2,
    //background: 'rgba(0,0,0,0.5)',
    padding: 5,
    borderRadius: '5px',
    h: 'calc(36vh)',
    display: 'flex',
    flexDir: 'column' as const,
    justifyContent: 'flex-start',

    // h: 'fit-content',
    //w: isLargerThan2500 ? '600px' : isLargerThan1500 ? '440px' : isLargerThan1300 ? '400px' : "330px",
    w: isLargerThan2500 ? '600px' : isLargerThan1500 ? '600px' : isLargerThan1300 ? '600px' : "600px",
    overflowY: 'hidden' as const,
    minW: '250px',
  };
};

export const barCont = {
  pointerEvents: 'auto' as const,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  h: '20px',
  paddingLeft: "5px",
  cursor: 'pointer',
  transition: 'all 0.3s ease',
};

export const singleBar = {
  pointerEvents: 'auto' as const,

  background: 'rgba(255,255,255,0.5)',
  height: '10px',
  borderRadius: '5px',
  //minW: '20px',
  transition: 'all 0.3s ease',
};

export const label = {
  pointerEvents: 'auto' as const,

  whiteSpace: 'nowrap' as const,
  // ml: 5,
  w: 'fit-content',
  textAlign: 'left' as const,
};
export const labelRole = {
  pointerEvents: 'auto' as const,

  // ml: 5,
  px: 2,
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDir: 'row' as const,
  textAlign: 'right' as const,
  gap: 2,
  w: 'fit-content',
  css: {
    span: {
      display: 'none',
    },
    p: {
      display: 'none',
    },
  },
  _hover: {
    span: {
      display: 'flex',
    },
    svg: { display: 'block' },
    transition: 'all 0.3s ease',
  },
  className: 'role-card',
};

export const innerScroll = {
  pointerEvents: 'auto' as const,

  overflowY: 'auto' as const, // Ensures vertical scrolling is enabled if content overflows
  css: `
    overflow-y: auto;
    height: 100%;
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    &::-webkit-scrollbar {
      display: none; /* Chrome and Safari */
    }
  `,
  h: 'full',
  my: 5,
  py: 5,
  w: 'auto',
};

export const scrollContainerRoles = (isLargerThan1300, isLargerThan2500) => {
  return {
    pointerEvents: 'auto' as const,

    position: 'relative' as const,
    m: 20,
    marginTop: "0px",
    mx: isLargerThan1300 ? 10 : 2,
    display: 'flex',
    flexDir: 'column' as const,
    justifyContent: 'space-around',
    padding: 5,
    borderRadius: '5px',
    h: '350px',
    w: isLargerThan2500 ? '600px' : isLargerThan1300 ? 'fit-content' : "350px",
    maxH: isLargerThan2500 ? '600px' : '350px',
    overflowY: 'hidden' as const,
    minW: isLargerThan1300 ? '270px' : "350px",
    right: 0,
    bottom: 0,
    color: 'white',
  };
};

export const percentage = {
  pointerEvents: 'auto' as const,

  zIndex: 25,
  color: 'white',
  position: 'absolute' as const,
  bottom: '10px',
};

export const screenLabel = {
  pointerEvents: 'auto' as const,

  position: 'absolute' as const,
  left: '60px',
  top: '132px',
  fontSize: 'xl',
  color: 'white',
  fontFamily: 'PoppinsLight',
};
