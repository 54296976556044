export const addIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.39942 11.8C4.40231 12.5535 5.64901 13 7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 0.999999 7 0.999999C3.68629 0.999999 1 3.68629 1 7C1 7.85333 1.17814 8.66505 1.49925 9.4"
        stroke="white"
        stroke-linecap="round"
      />
      <path
        d="M5.20078 7L8.80078 7"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 5.1998L7 8.7998"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
