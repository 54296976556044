export const CheckedHex = () => {
  return (
    <svg
      width="52"
      height="50"
      viewBox="0 0 52 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.44338 27.5C1.55021 25.953 1.55021 24.047 2.44338 22.5L12.0566 5.84937C12.9498 4.30236 14.6004 3.34937 16.3868 3.34937L35.6132 3.34937C37.3996 3.34937 39.0502 4.30236 39.9434 5.84937L49.5566 22.5C50.4498 24.047 50.4498 25.953 49.5566 27.5L39.9434 44.1506C39.0502 45.6976 37.3996 46.6506 35.6133 46.6506L16.3868 46.6506C14.6004 46.6506 12.9498 45.6976 12.0566 44.1506L2.44338 27.5Z"
        fill="#F4F3F2"
        stroke="#F4F3F2"
        strokeWidth="3"
      />
      <path
        d="M35.5479 18L21.867 32.5157L16.2761 26.6989"
        stroke="#0A0A0A"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
