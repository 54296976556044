export const glassMagnifier = () => {
  return (
    <svg
      width="281"
      height="160"
      viewBox="0 0 281 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{ mixBlendMode: 'hard-light' }}
        filter="url(#filter0_f_2525_5934)"
      >
        <path
          d="M173.906 85.6436C177.181 75.9004 176.787 65.2967 172.798 55.8233C168.81 46.3498 161.5 38.658 152.242 34.1917C142.984 29.7254 132.414 28.7918 122.517 31.5662C112.619 34.3405 104.075 40.6321 98.4874 49.2597C92.8998 57.8873 90.6534 68.2578 92.1699 78.4243C93.6864 88.5908 98.8615 97.8542 106.724 104.476C114.586 111.097 124.595 114.621 134.871 114.386C145.147 114.151 154.984 110.174 162.536 103.2M162.918 103.626L189.292 130"
          stroke="url(#paint0_linear_2525_5934)"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M173.906 85.6436C177.181 75.9004 176.787 65.2967 172.798 55.8233C168.81 46.3498 161.5 38.658 152.242 34.1917C142.984 29.7254 132.414 28.7918 122.517 31.5662C112.619 34.3405 104.075 40.6321 98.4874 49.2597C92.8998 57.8873 90.6534 68.2578 92.1699 78.4243C93.6864 88.5908 98.8615 97.8542 106.724 104.476C114.586 111.097 124.595 114.621 134.871 114.386C145.147 114.151 154.984 110.174 162.536 103.2M162.918 103.626L189.292 130"
        stroke="#F4F3F2"
        strokeOpacity="0.75"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g style={{ mixBlendMode: 'hard-light' }}>
        <path
          d="M173.906 85.6436C177.181 75.9004 176.787 65.2967 172.798 55.8233C168.81 46.3498 161.5 38.658 152.242 34.1917C142.984 29.7254 132.414 28.7918 122.517 31.5662C112.619 34.3405 104.075 40.6321 98.4874 49.2597C92.8998 57.8873 90.6534 68.2578 92.1699 78.4243C93.6864 88.5908 98.8615 97.8542 106.724 104.476C114.586 111.097 124.595 114.621 134.871 114.386C145.147 114.151 154.984 110.174 162.536 103.2M162.918 103.626L189.292 130"
          stroke="url(#paint1_linear_2525_5934)"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2525_5934"
          x="61.708"
          y="0"
          width="57.584"
          height="160"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="12.5"
            result="effect1_foregroundBlur_2525_5934"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2525_5934"
          x1="85.5823"
          y1="33.7838"
          x2="217.361"
          y2="125.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2525_5934"
          x1="85.5823"
          y1="33.7838"
          x2="217.361"
          y2="125.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
