export const bodyStyles = {
  margin: '0px',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'calc(100vh - 150px)',
  width: '100vw',
  backgroundColor: 'white',
  zIndex: 20,
};

export const semiCircleContainerStyles = {
  position: 'relative' as const,
  width: '300px', // width is height in the vertical rotation
  height: '150px', // height is width in the vertical rotation
  // overflow: 'hidden',
  borderBottomLeftRadius: '150px',
  borderBottomRightRadius: '150px',
  backgroundColor: '#fff',
  border: '2px solid #ccc',
  transform: 'rotate(-90deg)', // Rotate to make it a vertical semi-circle
};

export const nodeStyles = {
  width: '40px',
  height: '40px',
  backgroundColor: '#007bff',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  fontSize: '14px',
  fontWeight: 'bold',
  position: 'absolute' as const,
};

export const flexStyles = (windowSize: { width: any; height: any } | null) => {
  return {
    alignItems: 'center',
    justify: 'left',
    w: '100%',
    overflowY: 'clip' as const,
    sx: { '&::-webkit-scrollbar': { display: 'none' } },
    position: 'absolute' as const,
    top: `${windowSize?.height < 1000 ? '10vh' : '0px'}`,
    zIndex: 20,
    h: '100vh',
    className: 'scrollSection',
    transition: 'all 0.1s ease',
    pointerEvents: 'auto',
  };
};

export const boxStyles = (windowSize: { width: any; height: any } | null) => {
  return {
    position: 'relative' as const,
    w: '600px',
    h:
      windowSize?.height >= 1000
        ? `${windowSize?.height / 1.75}px`
        : `${windowSize?.height / 1.4}px`,
    left:
      50 - windowSize?.height / 3 < -540
        ? '-540px'
        : `calc(50px - ${windowSize?.height / 3}px)`,
    top: `calc(10px + ${windowSize?.height / 50}px)`,
    pt: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
};

export const nodeBoxStyles = (x, y, radius) => {
  const baseStyles = {
    position: 'absolute' as const,
    left: `${Math.round(x)}px`,
    top: `${Math.round(y)}px`,
    // transform: 'translate(-50%, -50%)',
    width: `${radius * 2 + 10}px`,
    height: `${radius * 2 + 10}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.1s ease',
    color: 'white',
    // background: 'rgba(0,0,0,0.3)',
  };

  return {
    ...baseStyles,
    // border: '1px solid white', // Orange border
    // borderRadius: '50%', // Full circle
    borderRadius: '50%',
  };
};

export const scoreBoxStyles = (radius) => ({
  width: `${radius * 2 + 2}px`,
  height: `${radius * 2 + 2}px`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: 'all 0.1s ease',
  color: 'white',
  borderRadius: '50%',
  // background: 'rgba(0,0,0,0.3)',
});
