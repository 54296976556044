import {
  Box,
  FormControl,
  FormLabel,
  VStack,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Divider,
  useMediaQuery,
} from '@chakra-ui/react';
import { TextInput } from '../../components/TextInput/TextInput';
import { Cta } from '../../components/Button/Cta';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useRef, useState } from 'react';
import { loginGoogle } from '../../../public/svg/loginGoogle';
import { loginMicrosoft } from '../../../public/svg/loginMicrosoft';
import LoginContext from '../../context/loginContext';
import { ForgotPassword } from './ForgotPassword';
import { alertIcon } from '../../assets/svg/alertIcon';
import {
  CustomModalBody,
  CustomModalButtonSection,
  CustomModalDivider,
  CustomModalError,
  CustomModalHeader,
  CustomModalInput,
  CustomModalSSO,
} from '../../components/ModelTemplate/ModalTemplateComponents';
import { loginAPI } from '../../utils/helpers/axios';

export const Login = ({ isOpen, setIsOpen, menuOnToggle, menuIsOpen }) => {
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const emailContext = useContext(LoginContext);
  const {
    formik,
    error,
    showPassword,
    setError,
    setShowPassword,
    showForgotPassword,
    setShowForgotPassword,
    setErrors,
    setValues,
  } = emailContext;

  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  const closeModal = () => {
    formik.setFieldValue('email', '');
    setIsOpen(false);
    menuIsOpen && menuOnToggle();
    setShowForgotPassword(false);
    setError(null);
    setShowPassword(false);
    setErrors({});
  };

  const handleEmailDebounce: any = useRef(null);

  useEffect(() => {
    const handleEmail = async () => {
      try {
        const { data } = await loginAPI.post('/checkUser', {
          email: formik.values.email,
        });

        if (data === 'show_password') {
          setShowPassword(true);
          setError(null);
          setErrors({});
          return;
        }

        if (data === 'redirect_microsoft') {
          setShowPassword(false);
          const id = 'aa8273a1-ea94-4831-922d-1223d7c0a41f';

          const environments = {
            development: import.meta.env.VITE_CAREERS_OWN_LINK_LOCAL,
            staging: import.meta.env.VITE_CAREERS_OWN_LINK_TEST,
            production: import.meta.env.VITE_CAREERS_OWN_LINK_PROD,
          };

          formik.setFieldValue('email', '');

          window.location.assign(
            `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${id}&state=DA&redirect_uri=${
              environments[import.meta.env.VITE_ENVIRONMENT]
            }&scope=https://graph.microsoft.com/User.Read&response_type=code&prompt=select_account`,
          );
        }
      } catch (err) {
        console.log(err);
      }
    };

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (regex.test(formik.values.email) && !showForgotPassword) {
      if (handleEmailDebounce.current) {
        clearTimeout(handleEmailDebounce.current);
      }
      handleEmailDebounce.current = setTimeout(() => {
        handleEmail();
      }, 200);
    }
  }, [formik.values.email, showPassword, showForgotPassword]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      formik.handleSubmit();
    }
  };

  const onForgotPassword = () => {
    setShowForgotPassword(true);
    setError(null);
    setValues({ ...formik.values, password: '' });
  };

  const renderLoginModalContent = () => {
    return (
      <>
        <CustomModalHeader
          title={t('landing.login')}
          subTitle={t('landing.resume_journey')}
        />
        <CustomModalError error={error} />
        <CustomModalBody error={error}>
          <CustomModalSSO setError={setError} email={formik.values.email} />
          <CustomModalDivider />
          <CustomModalInput
            formik={formik}
            label={'Email'}
            name={'email'}
            type={null}
            onForgotPassword={null}
          />
          {showPassword && (
            <CustomModalInput
              formik={formik}
              label={t('user_preferences.password')}
              name={'password'}
              type={'password'}
              onForgotPassword={onForgotPassword}
            />
          )}
          <CustomModalButtonSection
            text={
              showPassword ? t('landing.resume_journey') : t('landing.lets_go')
            }
            onClick={() => {
              formik.handleSubmit();
            }}
            width={
              showPassword
                ? isLargerThan1500
                  ? '299px'
                  : '220px'
                : isLargerThan1500
                ? '157px'
                : '105px'
            }
            height={isLargerThan1500 ? '66px' : '45px'}
          />
        </CustomModalBody>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} blockScrollOnMount={false}>
      <ModalContent
        onKeyDown={handleKeyPress}
        width={isLargerThan1500 ? '610px' : '510px'}
        maxWidth={isLargerThan1500 ? '610px' : '510px'}
        background={'#0A0A0A80'}
        backdropFilter={'blur(20px)'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
        fontFamily={'PoppinsLight'}
        borderRadius={'5px'}
        gap={isLargerThan1500 ? '30px' : '20px'}
      >
        {!showForgotPassword ? renderLoginModalContent() : <ForgotPassword />}
      </ModalContent>
    </Modal>
  );
};
