import { careersAPI, globalAPI } from '../../utils/helpers/axios';
import { FETCH_USER_WITH_ALL_ATTRIBUTES } from './types';

const fetchUserWithAllAttributes = (user_id, loggedIn, dispatch) => {
  return async (dispatch) => {
    let user: any = {};
    try {
      const { data: loginUser } = await globalAPI.post(`/findById`, {
        userId: user_id,
      });

      const { data: role } = await careersAPI.post('/getUserRole', {
        userId: user_id,
      });

      user = loginUser;
      dispatch({
        type: FETCH_USER_WITH_ALL_ATTRIBUTES,
        payload: { ...loginUser?.data, role },
      });
    } catch (err) {
      console.log(err);
    }

    return user?.data;
  };
};

export { fetchUserWithAllAttributes };
