export const logoUdemy = () => {
  return (
    <svg
      width="18"
      height="36"
      viewBox="0 0 18 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.999 13.9951L7.99851 9.99512L1 13.9951V9.99512L7.9995 5.99512L15 9.99512L14.999 13.9951Z"
        fill="#A435F0"
      />
      <path
        d="M1 16.9951H4.66664V26.0936C4.66664 28.4443 6.38538 29.5902 7.9995 29.5902C9.62757 29.5902 11.3334 28.4138 11.3334 26.0621V16.9951H15V26.3104C15 28.4759 14.3334 30.1468 13.0003 31.2926C11.6661 32.4375 10.0002 32.9951 7.96861 32.9951C5.93901 32.9951 4.27208 32.4385 2.96982 31.2926C1.66657 30.1478 1 28.539 1 26.403V16.9951Z"
        fill="#F4F3F2"
      />
    </svg>
  );
};
