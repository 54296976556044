import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { mainContainerFamily } from './FamilyStyles';
import { useDispatch, useSelector } from 'react-redux';
import { defineView } from '../../store/actions/viewActions';
import SegmentedCircle from './SegmentedCircle';
import GraphContext from '../../context/context';

//TODO setshow to redux dispatch;

const Family = ({ elements, references, setSelections, selections }) => {
  const context = useContext(GraphContext);
  const [sections, setSections] = useState(elements);
  const { selectedSection, setSelectedSection, setSelectedSubSection } =
    context;
  const [familyClickedSection, setFamilyClickedSection] = useState(null);
  const dispatch = useDispatch();
  const view = useSelector((state: any) => state.view);

  useEffect(() => {
    if (
      selectedSection &&
      view.familyData &&
      selectedSection.id === view.familyData.family_id
    ) {
      return;
    }
    if (view.familySeeAll) {
      setFamilyClickedSection(null);
      setSelections((prev: any) => {
        return {
          ...prev,
          selectedFamily: null,
          selectedSubFamily: null,
          subFamilyClickedSection: null,
        };
      });
      setSelectedSection(null);
      dispatch(
        defineView({
          familySeeAll: false,
        }),
      );
      return;
    }

    if (view.familyData && sections.length > 0) {
      const searchedFamily = sections.filter((section) => {
        if (section && section?.id) {
          return (
            section?.id === view?.familyData?.family_id ||
            section.subFamilies.indexOf(view?.familyData?.family_id) > -1
          );
        }
      });

      if (searchedFamily.length > 0) {
        const fam = searchedFamily[0];
        const index = sections.indexOf(fam);

        if (
          //selectedSection?.id !== fam.id &&
          !selectedSection?.subFamilies?.includes(fam.id)
        ) {
          handleClick(
            index,
            fam.subFamilies.includes(view.familyData.family_id)
              ? view.familyData.family_id
              : null,
          );

          setFamilyClickedSection(index);

          dispatch(
            defineView({
              showSubFamily: true,
            }),
          );
        }
      }

      dispatch(defineView({ familyData: null }));
    }
  }, [sections, view.familyData, view.familySeeAll]);

  useEffect(() => {
    setSections(elements);
    setSelections((prev: any) => {
      return {
        ...prev,
        subFamilyClickedSection: null,
      };
    });
  }, [elements]);

  const handleClick = (index, subFamilyId) => {
    const clickedSection = sections[index];

    if (selectedSection?.id === clickedSection.id && !subFamilyId) {
      setSelections((prev: any) => {
        return {
          ...prev,
          selectedFamily: null,
          selectedSubFamily: null,
          subFamilyClickedSection: null,
        };
      });
      setSelectedSection(null);
      setSelectedSubSection(null);
      dispatch(defineView({ showSubFamily: false }));

      return;
    }

    setSelections((prev: any) => {
      return {
        ...prev,
        selectedFamily: clickedSection.id,
        selectedSubFamily: subFamilyId,
        subFamilyClickedSection: null,
      };
    });
    setSelectedSection(clickedSection);
    dispatch(defineView({ showSubFamily: true }));
  };

  const totalValue = useMemo(() => {
    return sections.reduce((acc, item) => acc + item.value, 0);
  }, [sections]);

  const getSegmentSizes = () => {
    const sizes: any = [];

    sections.forEach((section) => {
      const percentage = (section.value / totalValue) * 100;
      sizes.push(percentage);
    });

    return sizes;
  };

  const segmentLabels = useMemo(() => {
    if (selectedSection) {
      return sections.map((section) => {
        if (section.id === selectedSection.id) {
          return section.title;
        }

        return null;
      });
    }

    return sections.map((section) => section.title);
  }, [selectedSection, sections]);

  const segmentIsSub = useMemo(() => {
    return sections.map((section) => {
      return section.subFamilies.length > 0;
    });
  }, [selectedSection, sections]);

  return (
    <Box {...mainContainerFamily} pointerEvents={'none'}>
      <SegmentedCircle
        segmentSizes={getSegmentSizes()}
        segmentLabels={segmentLabels}
        subFamilies={segmentIsSub}
        onClick={(index) => handleClick(index, null)}
        radius={selectedSection ? 190 : 180}
        clickedSection={familyClickedSection}
        setClickedSection={setFamilyClickedSection}
        labelOffset={30}
        labelColor={'white'}
      />
    </Box>
  );
};

export { Family };
