import { Box } from '@chakra-ui/react';

export const HexagonLine = ({ children }) => {
  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      position={'relative'}
      display={'flex'}
      pr={5}
      className={'findme'}
      h={'60px'}
      w={'60px'}
    >
      <Box mb={'3px'} alignSelf={'center'} justifySelf={'center'}>
        {children}
      </Box>
      <Box
        position={'absolute'}
        // top={'calc(50% - 30px)'} right={'calc(50%)'}
      >
        <svg
          width="60"
          height="60"
          viewBox="0 0 52 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.44338 26.5C1.55021 24.953 1.55021 23.047 2.44338 21.5L12.0566 4.84937C12.9498 3.30236 14.6004 2.34937 16.3868 2.34937L35.6132 2.34937C37.3996 2.34937 39.0502 3.30236 39.9434 4.84936L49.5566 21.5C50.4498 23.047 50.4498 24.953 49.5566 26.5L39.9434 43.1506C39.0502 44.6976 37.3996 45.6506 35.6132 45.6506L16.3868 45.6506C14.6004 45.6506 12.9498 44.6976 12.0566 43.1506L2.44338 26.5Z"
            stroke="#F4F3F2"
            strokeWidth="3"
          />
        </svg>
      </Box>
    </Box>
  );
};
