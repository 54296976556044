export const compass = () => {
  return (
    <svg
      width="281"
      height="160"
      viewBox="0 0 281 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{ mixBlendMode: 'hard-light' }}
        filter="url(#filter0_f_2525_5935)"
      >
        <path
          d="M94.5147 110.559C96.0507 112.854 99.1562 113.469 101.451 111.933C103.746 110.397 104.361 107.291 102.825 104.996L94.5147 110.559ZM115.896 117.847C113.575 116.351 110.481 117.019 108.984 119.339C107.487 121.66 108.155 124.754 110.476 126.251L115.896 117.847ZM155.74 70.6733C158.399 69.9288 159.952 67.1695 159.207 64.5104C158.462 61.8512 155.703 60.2991 153.044 61.0437L155.74 70.6733ZM132.295 72.0457L130.947 67.2309C129.265 67.7018 127.951 69.0159 127.48 70.6976L132.295 72.0457ZM148.205 87.9556L149.553 92.7704C151.235 92.2996 152.549 90.9854 153.02 89.3038L148.205 87.9556ZM126.108 94.1428L121.293 92.7946C120.806 94.5341 121.295 96.401 122.572 97.6783C123.85 98.9556 125.717 99.4447 127.456 98.9576L126.108 94.1428ZM154.567 83.7795C155.311 81.1203 153.759 78.3611 151.1 77.6165C148.441 76.872 145.681 78.424 144.937 81.0832L154.567 83.7795ZM185.25 80C185.25 104.853 165.103 125 140.25 125V135C170.626 135 195.25 110.376 195.25 80H185.25ZM95.25 80C95.25 55.1472 115.397 35 140.25 35V25C109.874 25 85.25 49.6243 85.25 80H95.25ZM140.25 35C165.103 35 185.25 55.1472 185.25 80H195.25C195.25 49.6243 170.626 25 140.25 25V35ZM102.825 104.996C98.0402 97.8488 95.25 89.2578 95.25 80H85.25C85.25 91.2994 88.6628 101.817 94.5147 110.559L102.825 104.996ZM140.25 125C131.267 125 122.913 122.373 115.896 117.847L110.476 126.251C119.06 131.788 129.288 135 140.25 135V125ZM153.044 61.0437L130.947 67.2309L133.643 76.8605L155.74 70.6733L153.044 61.0437ZM146.857 83.1408L124.76 89.328L127.456 98.9576L149.553 92.7704L146.857 83.1408ZM130.923 95.4909L137.11 73.3939L127.48 70.6976L121.293 92.7946L130.923 95.4909ZM144.937 81.0832L143.39 86.6075L153.02 89.3038L154.567 83.7795L144.937 81.0832Z"
          fill="url(#paint0_linear_2525_5935)"
        />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }}>
        <path
          d="M97.0922 109.168C97.8602 110.316 99.413 110.623 100.56 109.855C101.708 109.087 102.015 107.535 101.247 106.387L97.0922 109.168ZM115.041 119.948C113.881 119.2 112.333 119.534 111.585 120.694C110.837 121.855 111.171 123.402 112.331 124.15L115.041 119.948ZM155.566 68.2659C156.896 67.8937 157.672 66.514 157.3 65.1845C156.927 63.8549 155.548 63.0788 154.218 63.4511L155.566 68.2659ZM132.795 72.0457L132.121 69.6383C131.28 69.8737 130.623 70.5308 130.388 71.3716L132.795 72.0457ZM148.705 87.9556L149.379 90.363C150.22 90.1276 150.877 89.4705 151.112 88.6297L148.705 87.9556ZM126.608 94.1428L124.2 93.4687C123.957 94.3385 124.201 95.2719 124.84 95.9106C125.479 96.5492 126.412 96.7937 127.282 96.5502L126.608 94.1428ZM152.659 83.1054C153.031 81.7758 152.255 80.3962 150.926 80.0239C149.596 79.6516 148.217 80.4277 147.844 81.7573L152.659 83.1054ZM188.25 80C188.25 106.234 166.984 127.5 140.75 127.5V132.5C169.745 132.5 193.25 108.995 193.25 80H188.25ZM93.25 80C93.25 53.7665 114.516 32.5 140.75 32.5V27.5C111.755 27.5 88.25 51.0051 88.25 80H93.25ZM140.75 32.5C166.984 32.5 188.25 53.7665 188.25 80H193.25C193.25 51.0051 169.745 27.5 140.75 27.5V32.5ZM101.247 106.387C96.1959 98.8408 93.25 89.7682 93.25 80H88.25C88.25 90.789 91.5071 100.825 97.0922 109.168L101.247 106.387ZM140.75 127.5C131.272 127.5 122.45 124.727 115.041 119.948L112.331 124.15C120.523 129.434 130.282 132.5 140.75 132.5V127.5ZM154.218 63.4511L132.121 69.6383L133.469 74.4531L155.566 68.2659L154.218 63.4511ZM148.031 85.5482L125.934 91.7354L127.282 96.5502L149.379 90.363L148.031 85.5482ZM129.015 94.8169L135.202 72.7198L130.388 71.3716L124.2 93.4687L129.015 94.8169ZM147.844 81.7573L146.298 87.2815L151.112 88.6297L152.659 83.1054L147.844 81.7573Z"
          fill="#F4F3F2"
          fillOpacity="0.75"
        />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }}>
        <path
          d="M97.0922 109.168C97.8602 110.316 99.413 110.623 100.56 109.855C101.708 109.087 102.015 107.535 101.247 106.387L97.0922 109.168ZM115.041 119.948C113.881 119.2 112.333 119.534 111.585 120.694C110.837 121.855 111.171 123.402 112.331 124.15L115.041 119.948ZM155.566 68.2659C156.896 67.8937 157.672 66.514 157.3 65.1845C156.927 63.8549 155.548 63.0788 154.218 63.4511L155.566 68.2659ZM132.795 72.0457L132.121 69.6383C131.28 69.8737 130.623 70.5308 130.388 71.3716L132.795 72.0457ZM148.705 87.9556L149.379 90.363C150.22 90.1276 150.877 89.4705 151.112 88.6297L148.705 87.9556ZM126.608 94.1428L124.2 93.4687C123.957 94.3385 124.201 95.2719 124.84 95.9106C125.479 96.5492 126.412 96.7937 127.282 96.5502L126.608 94.1428ZM152.659 83.1054C153.031 81.7758 152.255 80.3962 150.926 80.0239C149.596 79.6516 148.217 80.4277 147.844 81.7573L152.659 83.1054ZM188.25 80C188.25 106.234 166.984 127.5 140.75 127.5V132.5C169.745 132.5 193.25 108.995 193.25 80H188.25ZM93.25 80C93.25 53.7665 114.516 32.5 140.75 32.5V27.5C111.755 27.5 88.25 51.0051 88.25 80H93.25ZM140.75 32.5C166.984 32.5 188.25 53.7665 188.25 80H193.25C193.25 51.0051 169.745 27.5 140.75 27.5V32.5ZM101.247 106.387C96.1959 98.8408 93.25 89.7682 93.25 80H88.25C88.25 90.789 91.5071 100.825 97.0922 109.168L101.247 106.387ZM140.75 127.5C131.272 127.5 122.45 124.727 115.041 119.948L112.331 124.15C120.523 129.434 130.282 132.5 140.75 132.5V127.5ZM154.218 63.4511L132.121 69.6383L133.469 74.4531L155.566 68.2659L154.218 63.4511ZM148.031 85.5482L125.934 91.7354L127.282 96.5502L149.379 90.363L148.031 85.5482ZM129.015 94.8169L135.202 72.7198L130.388 71.3716L124.2 93.4687L129.015 94.8169ZM147.844 81.7573L146.298 87.2815L151.112 88.6297L152.659 83.1054L147.844 81.7573Z"
          fill="url(#paint1_linear_2525_5935)"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2525_5935"
          x="60.25"
          y="0"
          width="160"
          height="160"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="12.5"
            result="effect1_foregroundBlur_2525_5935"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2525_5935"
          x1="83.9726"
          y1="33.7838"
          x2="216.845"
          y2="128.406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2525_5935"
          x1="84.4726"
          y1="33.7838"
          x2="217.345"
          y2="128.406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
