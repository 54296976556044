export const wrapper = {
  position: 'relative' as const,
  zIndex: 100,
  fontFamily: 'PoppinsLight',
};

export const headerContainer = {
  width: '90vw',
  maxW: '920px',
  background: 'rgb(0, 0, 0, 0.5)',
  borderRadius: '30px',
  position: 'relative' as const,
  top: "80px",
  left: '0',
  zIndex: 20,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  p: 2,
  px: 5,
  transition: "none"
};

export const headerBgCont = {
  width: '90vw',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  h: '0px',
  zIndex: 35,
};

export const logoContainer = {
  h: '10px',
  w: '10px',
};

export const openStyle = {
  borderRadius: '30px 30px 0px 0px',
  // background: 'rgb(0, 0, 0, 0.8)',
};

export const menuContainer = {
  position: 'absolute' as const,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  top: '56px',
  left: '0px',
  flexDirection: 'column' as const,
  background: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '0px 0px 29px 29px',
  transition: 'height 0.3s ease, opacity 0.3s ease',
  overflow: 'hidden',
};

export const searchBar = {
  transition: 'width 0.3s ease, opacity 0.3s ease',
  border: 'none',
  background: 'blue',
  all: 'unset',
};
