export const footerContainerStyles = {
  bottom: 0,
  width: '100%',
  paddingLeft: '10.5px',
  paddingRight: '10.5px',
  color: 'white',
};

export const footerInnerContainerStyles = {
  margin: '0 auto',
  h: '60px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const iconButtonContainerStyles = (isLargerThan1500) => {
  return {
    whiteSpace: 'nowrap',
    fontSize: isLargerThan1500 ? '16px' : '14px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'PoppinsLight',
  };
};

export const iconButtonStyles = {
  cursor: 'pointer',
  marginRight: '11px',
};

export const privacyAndTermsContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
};

export const pageButtonStyles = (isLargerThan1500) => {
  return {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    fontFamily: 'PoppinsLight',
    fontSize: isLargerThan1500 ? '16px' : '14px',
  };
};
