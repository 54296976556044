export const skillHexagon = ({ score }) => {
  const percentage = score / 100;
  const strokeDasharray = 460; // This should be the full path length, update accordingly
  const pathLength = strokeDasharray * percentage;
  const strokeDashoffset = strokeDasharray - pathLength;

  const checkIconPath =
    'M9.9647 14.9617L17.4693 7.44735L18.5307 8.50732L9.96538 17.0837L5.46967 12.588L6.53033 11.5273L9.9647 14.9617Z'; // Correct SVG path data for a checkmark

  return (
    <svg
      width="173"
      height="160"
      viewBox="0 0 173 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {score !== 100 && (
        <>
          <path
            d="M12.7776 90.0029C9.20259 83.8134 9.20259 76.1865 12.7776 69.9969L40.7595 21.5504C44.3326 15.364 50.9342 11.5534 58.0782 11.5534L114.056 11.5534C121.2 11.5534 127.802 15.364 131.375 21.5504L159.357 69.9969C162.932 76.1865 162.932 83.8134 159.357 90.0029L131.375 138.449C127.802 144.636 121.2 148.446 114.056 148.446L58.0782 148.446C50.9342 148.446 44.3326 144.636 40.7595 138.449L12.7776 90.0029Z"
            fill="#F4F3F2"
            fillOpacity="0.25"
          />
          <path
            d="M36.4298 19.0496L8.44789 67.4961C3.97916 75.2331 3.97916 84.7667 8.44789 92.5037L36.4298 140.95C40.8962 148.683 49.1481 153.446 58.0782 153.446L114.056 153.446C122.986 153.446 131.238 148.683 135.704 140.95L163.686 92.5037C168.155 84.7667 168.155 75.2331 163.686 67.4961L135.704 19.0496C131.238 11.3167 122.986 6.55339 114.056 6.55339L58.0782 6.55339C49.1482 6.55339 40.8962 11.3167 36.4298 19.0496Z"
            stroke="#F4F3F2"
            strokeOpacity="0.25"
            strokeWidth="10"
          />
        </>
      )}
      {score === 100 && (
        <path
          d="M12.7776 90.0029C9.20259 83.8134 9.20259 76.1865 12.7776 69.9969L40.7595 21.5504C44.3326 15.364 50.9342 11.5534 58.0782 11.5534L114.056 11.5534C121.2 11.5534 127.802 15.364 131.375 21.5504L159.357 69.9969C162.932 76.1865 162.932 83.8134 159.357 90.0029L131.375 138.449C127.802 144.636 121.2 148.446 114.056 148.446L58.0782 148.446C50.9342 148.446 44.3326 144.636 40.7595 138.449L12.7776 90.0029Z"
          fill="url(#paint0_linear_1983_2844)"
        />
      )}
      <path
        d="M83.5 11.5L58.0577 11.5373C50.924 11.5478 44.336 15.3571 40.7681 21.5345L12.6423 70.2316C9.14015 76.2953 9.06429 83.749 12.4423 89.8827L26 114.5L40.6962 138.798C44.3189 144.788 50.8094 148.447 57.8094 148.447L114.055 148.447C121.199 148.447 127.8 144.637 131.374 138.45L159.356 90.0038C162.93 83.8142 162.93 76.1873 159.356 69.9977L131.374 21.5512C127.8 15.3649 121.199 11.5542 114.055 11.5542H86.066"
        stroke="url(#paint0_linear_1983_2844)"
        strokeWidth="10"
        strokeLinecap="round"
        strokeDasharray={
          score === 100 ? '0, 0' : `${pathLength}, ${strokeDasharray}`
        }
        strokeDashoffset={score === 100 ? '0' : -strokeDashoffset}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1983_2844"
          x1="32.0012"
          y1="157.04"
          x2="161.073"
          y2="-26.1591"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
      </defs>

      {score === 100 ? (
        <>
          <g transform="translate(40, 30) scale(4)">
            <path
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.9647 14.9617L17.4693 7.44735L18.5307 8.50732L9.96538 17.0837L5.46967 12.588L6.53033 11.5273L9.9647 14.9617Z"
              fill="#ffffff"
              strokeLinecap="round"
              strokeLinejoin={'round'}
            />
          </g>
          <text
            x="50%"
            y="80%"
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
            fontSize="8"
            fontFamily="PoppinsLight"
          >
            {score}/100
          </text>
        </>
      ) : (
        <>
          <text
            x="50%"
            y="52.5%"
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
            fontSize="64"
            fontFamily="PPNeuePlainBold"
          >
            {score}
          </text>

          <text
            x="50%"
            y="80%"
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
            fontSize="8"
            fontFamily="PoppinsLight"
          >
            {score}/100
          </text>
        </>
      )}
    </svg>
  );
};
