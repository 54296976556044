export const heart = () => {
  return (
    <svg
      width="281"
      height="159"
      viewBox="0 0 281 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{ mixBlendMode: 'hard-light' }}
        filter="url(#filter0_f_2525_5937)"
      >
        <path
          d="M184.446 39.2229C196.114 51.5201 196.114 71.4579 184.446 83.7551L142.742 127.706C141.559 128.953 139.572 128.953 138.389 127.706L96.6854 83.7551C85.017 71.4579 85.017 51.5201 96.6854 39.2229C108.354 26.9257 127.272 26.9257 138.94 39.2229L139.84 40.1712C140.235 40.5868 140.897 40.5868 141.291 40.1712L142.191 39.2229C144.485 36.8047 147.06 34.862 149.805 33.3949C153.072 31.6488 156.58 30.5762 160.143 30.1773C162.253 29.9409 164.383 29.9409 166.494 30.1773"
          stroke="url(#paint0_linear_2525_5937)"
          strokeWidth="10"
          strokeLinecap="round"
        />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }}>
        <path
          d="M184.315 39.2229C195.983 51.5201 195.983 71.4579 184.315 83.7551L142.611 127.706C141.428 128.953 139.441 128.953 138.258 127.706L96.5541 83.7551C84.8856 71.4579 84.8856 51.5201 96.554 39.2229C108.222 26.9257 127.141 26.9257 138.809 39.2229L139.709 40.1712C140.103 40.5868 140.765 40.5868 141.16 40.1712L142.06 39.2229C144.354 36.8047 146.929 34.862 149.674 33.3949C152.941 31.6488 156.449 30.5762 160.012 30.1773C162.122 29.9409 164.252 29.9409 166.362 30.1773"
          stroke="#F4F3F2"
          strokeOpacity="0.75"
          strokeWidth="5"
          strokeLinecap="round"
        />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }}>
        <path
          d="M184.446 39.2229C196.114 51.5201 196.114 71.4579 184.446 83.7551L142.742 127.706C141.559 128.953 139.572 128.953 138.389 127.706L96.6854 83.7551C85.017 71.4579 85.017 51.5201 96.6854 39.2229C108.354 26.9257 127.272 26.9257 138.94 39.2229L139.84 40.1712C140.235 40.5868 140.897 40.5868 141.291 40.1712L142.191 39.2229C144.485 36.8047 147.06 34.862 149.805 33.3949C153.072 31.6488 156.58 30.5762 160.143 30.1773C162.253 29.9409 164.383 29.9409 166.494 30.1773"
          stroke="url(#paint1_linear_2525_5937)"
          strokeWidth="5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2525_5937"
          x="57.9341"
          y="0"
          width="165.263"
          height="158.642"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="12.5"
            result="effect1_foregroundBlur_2525_5937"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2525_5937"
          x1="81.3263"
          y1="33.7838"
          x2="216.285"
          y2="134.951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2525_5937"
          x1="81.3263"
          y1="33.7838"
          x2="216.285"
          y2="134.951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
