import { useEffect, useRef, useState } from 'react';
import { RoleSelection } from './RoleSelection';
import { SortedFamily } from './SortedFamily';
import { SortedRoles } from './SortedRoles';
import { SortedSkills } from './SortedSkills';
import { Box } from '@chakra-ui/react';
import { mainFrameStyles } from './MainStyle';
import { SingleCompanyGraph } from '../graphs/SingleCompanyGraph';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { careersAPI } from '../../utils/helpers/axios';
import { setCardData, setSkillLineData } from '../../store/actions/cardActions';
import CookieBanner from '../../components/CookieBanner/CookieBanner';
import { Navigate, useNavigate } from 'react-router-dom';

/*
views:
role selection;
sorted_family;
sorted_roles;
sorted_skills;

*/

export const Main = () => {
  const [view, setView] = useState('role_selection');
  const [graphData, setGraphData]: any = useState(null);
  const [completionRate, setCompletionRate] = useState(0);
  const [bigCard, setBigCard] = useState(false);
  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  }, isEqual);
  const navigate = useNavigate();

  const gettingDataRef = useRef(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCompanyData = async () => {
      let start = 0;
      let rolesNumber;
      document.body.classList.add('hiddenBody');

      const { data } = await careersAPI.post(
        '/getRolesDistancesForCompanyInChunks',
        {
          companyId: currentUser.attributes.company_id,
          // companyId: 'XPTO_corporation',
          //companyId: 'acme_corporation',
          // companyId: 'ee88e6a3-4830-4a5a-8a98-997839d63678',
          start,
          rolesNumber,
        },
      );

      console.log(data)

      setGraphData({ nodes: data.nodes, links: data.links });
    };

    if (!gettingDataRef.current) {
      gettingDataRef.current = true;
      getCompanyData();
    }

    //do not add graphData to the dependency array
    //eslint-disable-next-line
  }, [currentUser]);

  const renderView = () => {
    switch (view) {
      case 'role_selection':
        return <RoleSelection />;
      case 'sorted_family':
        return <SortedFamily />;
      case 'sorted_roles':
        return <SortedRoles />;
      case 'sorted_skills':
        return <SortedSkills />;
      default:
        return <RoleSelection />;
    }
  };

  return (
    <Box
      onClick={() => {
        dispatch(setSkillLineData(null));
        dispatch(setCardData(null));
      }}
      {...mainFrameStyles}
    >
      {graphData ? (
        <SingleCompanyGraph graphData={graphData} setBigCard={setBigCard} />
      ) : null}
      {/* <RoleCard bigCard={bigCard} setBigCard={setBigCard} /> */}
      <CookieBanner />
    </Box>
  );
};
