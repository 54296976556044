export const landingPageContainer = () => {
  return {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    background: 'black',
    paddingTop: '120px',
    maxWidth: '1920px',
    overflowY: 'hidden' as const,
  };
};

export const firstTitleStyles = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    fontFamily: 'PPNeueMachinaPlainMedium',
    fontWeight: 400,
    fontSize: isLargerThan992
      ? '65px'
      : isLargerThan756
        ? '40px'
        : isLargerThan576
          ? '35px'
          : '30px',
    lineHeight: isLargerThan992
      ? '70.15px'
      : isLargerThan756
        ? '60px'
        : isLargerThan576
          ? '45px'
          : '40px',
    textAlign: 'left' as const,
    color: 'white',
    paddingBottom: isLargerThan992
      ? '45px'
      : isLargerThan756
        ? '40px'
        : isLargerThan576
          ? '35px'
          : '25px',
    width: '75%',
  };
};

export const lastSentenceFirstTitleStyles = (
  isLargerThan576,
  isLargerThan992,
) => {
  return {
    display: 'flex',
    alignItems: 'center',
  };
};

export const firstTitleInsideSectionContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    w: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column' as const,
    marginBottom: '45px',
  };
};

export const firstTitleSectionContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    marginTop: '100px',
    padding: isLargerThan992
      ? '0 200px'
      : isLargerThan756
        ? '0 100px'
        : '0 50px',
    w: '100vw',
    maxWidth: '1450px',
  };
};

export const firstDescriptionContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    fontFamily: 'PoppinsExtraLight',
    fontSize: isLargerThan992
      ? '18px'
      : isLargerThan756
        ? '15px'
        : isLargerThan576
          ? '15px'
          : '14px',
    lineHeight: isLargerThan992
      ? '36px'
      : isLargerThan756
        ? '36px'
        : isLargerThan576
          ? '32px'
          : '25px',
    color: 'white',
    textAlign: 'left' as const,
    paddingTop: isLargerThan992
      ? '45px'
      : isLargerThan756
        ? '35px'
        : isLargerThan576
          ? '30px'
          : '30px',
  };
};

export const firstDescriptionLastSentenceContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    fontFamily: 'PoppinsBold',
    color: 'white',
    textAlign: 'left' as const,
    fontSize: isLargerThan992
      ? '18px'
      : isLargerThan756
        ? '15px'
        : isLargerThan576
          ? '15px'
          : '14px',
    lineHeight: isLargerThan992
      ? '36px'
      : isLargerThan756
        ? '36px'
        : isLargerThan576
          ? '32px'
          : '25px',
  };
};

export const firstLampContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    width: '100%',
    height: isLargerThan992
      ? '400px'
      : isLargerThan756
        ? '350px'
        : isLargerThan576
          ? '275px'
          : '175px',
    position: 'relative' as const,
    display: 'flex',
    justifyContent: 'center',
  };
};

export const startYoursButton = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
  isLargerThan1500,
) => {
  return {
    background: 'transparent',
    width: isLargerThan992
      ? '250px'
      : isLargerThan756
        ? '200px'
        : isLargerThan576
          ? '175px'
          : '110px',
    height: isLargerThan992
      ? '65px'
      : isLargerThan756
        ? '50px'
        : isLargerThan576
          ? '45px'
          : '30px',
    borderRadius: '60px',
    border: '1.5px solid white',
    fontFamily: 'PoppinsRegular',
    fontSize: isLargerThan992
      ? '18px'
      : isLargerThan756
        ? '16px'
        : isLargerThan576
          ? '15px'
          : '10px',
    lineHeight: '36px',
    textAlign: 'center',
    color: 'white',
    marginTop: isLargerThan1500
      ? '90px'
      : isLargerThan992
        ? '7.5%'
        : isLargerThan756
          ? '65px'
          : isLargerThan576
            ? '50px'
            : '37.5px',
  };
};

export const animatedBoxesContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    marginTop: isLargerThan992
      ? '4rem'
      : isLargerThan756
        ? '3rem'
        : isLargerThan576
          ? '2.5rem'
          : '4rem',
    height: 'auto',
    width: '100%',
    padding: isLargerThan992
      ? '0px 60px'
      : isLargerThan756
        ? '0px 60px'
        : isLargerThan576
          ? '0px 30px'
          : '0px 30px',
  };
};

export const companiesSectionContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    height: 'auto',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap' as const,
    padding: '0px 60px',
    textAlign: 'center' as const,
    color: 'white',
    justifyContent: isLargerThan992
      ? 'center'
      : isLargerThan756
        ? 'center'
        : isLargerThan576
          ? 'center'
          : 'center',
    marginTop: isLargerThan992
      ? '5rem'
      : isLargerThan756
        ? '2rem'
        : isLargerThan576
          ? '2rem'
          : '2rem',
    marginBottom: isLargerThan992
      ? '5rem'
      : isLargerThan756
        ? '4rem'
        : isLargerThan576
          ? '2rem'
          : '2rem',
    position: 'relative' as const,
    zIndex: 2,
    gap: isLargerThan992
      ? '80px'
      : isLargerThan756
        ? '40px'
        : isLargerThan576
          ? '30px'
          : '30px',
  };
};

export const companyCardContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    display: 'flex',
    flexDirection: 'column' as const,
    fontSize: isLargerThan992
      ? '18px'
      : isLargerThan756
        ? '18px'
        : isLargerThan576
          ? '18px'
          : '14px',
    alignItems: 'center',
    border: '2.5px solid #F4F3F21A',
    paddingBottom: '15px',
    width: '264px',
    borderRadius: '5px',
  };
};

export const companyCardIconContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    display: 'flex',
    width: isLargerThan992
      ? '80%'
      : isLargerThan756
        ? '80%'
        : isLargerThan576
          ? '80%'
          : '60%',
    justifyContent: 'center',
  };
};

export const companyCardTitleContainer = () => {
  return {
    width: '80%',
    fontFamily: 'PoppinsBold',
  };
};

export const companyCardDescriptionContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    marginTop: '1rem',
    fontFamily: 'PoppinsExtraLight',
    fontSize: isLargerThan992
      ? '18px'
      : isLargerThan756
        ? '18px'
        : isLargerThan576
          ? '18px'
          : '14px',
    width: '80%',
  };
};

export const animatedLogosContainer = () => {
  return {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column' as const,
    width: '100%',
    position: 'relative' as const,
    zIndex: 2,
    marginTop: '60px',
  };
};

export const animatedLogosTitleContainer = () => {
  return {
    fontFamily: 'PoppinsBold',
    color: 'white',
    fontSize: '18px',
    marginBottom: '2rem',
  };
};

export const secondLampContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    width: '100%',
    height: isLargerThan992
      ? '400px'
      : isLargerThan756
        ? '350px'
        : isLargerThan576
          ? '275px'
          : '175px',
    position: 'relative' as const,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
  };
};

export const getADemoButton = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
  isLargerThan1500,
) => {
  return {
    background: 'transparent',
    width: isLargerThan992
      ? '250px'
      : isLargerThan756
        ? '200px'
        : isLargerThan576
          ? '175px'
          : '110px',
    height: isLargerThan992
      ? '65px'
      : isLargerThan756
        ? '50px'
        : isLargerThan576
          ? '45px'
          : '30px',
    borderRadius: '60px',
    border: '1.5px solid white',
    fontFamily: 'PoppinsRegular',
    fontSize: isLargerThan992
      ? '18px'
      : isLargerThan756
        ? '16px'
        : isLargerThan576
          ? '15px'
          : '10px',
    lineHeight: '36px',
    textAlign: 'center',
    color: 'white',
    marginTop: isLargerThan1500
      ? '90px'
      : isLargerThan992
        ? '7.5%'
        : isLargerThan756
          ? '65px'
          : isLargerThan576
            ? '50px'
            : '37.5px',
  };
};

export const socialMediaIconsContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: isLargerThan992
      ? '140px'
      : isLargerThan756
        ? '110px'
        : isLargerThan576
          ? '100px'
          : '90px',
    marginBottom: window.innerWidth > 1500 ? '10rem' : '75px',
    position: 'relative' as const,
    zIndex: 2,
  };
};

export const socialMediaIconsInnerContainer = (
  isLargerThan576,
  isLargerThan756,
  isLargerThan992,
) => {
  return {
    width: isLargerThan992
      ? '30%'
      : isLargerThan756
        ? '35%'
        : isLargerThan576
          ? '40%'
          : '50%',
    display: 'flex',
    justifyContent: 'space-between',
  };
};
