export const closeIcon = (width = 37, height = 35) => {
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="11.5" r="7" stroke="#F4F3F2" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5303 9.53032C15.8232 9.23743 15.8232 8.76256 15.5303 8.46966C15.2374 8.17677 14.7626 8.17677 14.4697 8.46966L12.5 10.4394L10.5303 8.4697C10.2374 8.17681 9.76256 8.17681 9.46967 8.4697C9.17678 8.7626 9.17678 9.23747 9.46967 9.53036L11.4393 11.5L9.46967 13.4697C9.17678 13.7626 9.17678 14.2374 9.46967 14.5303C9.76256 14.8232 10.2374 14.8232 10.5303 14.5303L12.5 12.5607L14.4697 14.5304C14.7626 14.8233 15.2374 14.8233 15.5303 14.5304C15.8232 14.2375 15.8232 13.7626 15.5303 13.4697L13.5606 11.5L15.5303 9.53032Z"
        fill="#F4F3F2"
      />
    </svg>
  );
};
