export const tijubuLogo = (isLargerThan700) => {
  return (
    <svg
      width={isLargerThan700 ? '24px' : '15.6px'}
      height={isLargerThan700 ? '24px' : '15.6px'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM18.3333 9.6C17.6 9.6 16.8667 9.53333 16.1333 9.33333C15.3333 9.13333 14.5333 9 13.6667 9C13.5333 9 13.3333 9 13.2 9V18.8H10.8V9C10.6667 9 10.4667 9 10.3333 9C9.46667 9 8.66667 9.13333 7.86667 9.33333C7.2 9.53333 6.4 9.6 5.66667 9.6V7.2H18.2667V9.6C18.3333 9.6 18.3333 9.6 18.3333 9.6Z"
        fill="white"
      />
    </svg>
  );
};
