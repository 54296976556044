export const mainFrameStyles = {
  w: '100vw',
  minH: 'calc(100vh)',
  backgroundImage: 'url("/images/background.png")',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundAttachment: 'fixed',
  justifyContent: 'center',
  display: 'flex',
};
