import { Box, useMediaQuery } from '@chakra-ui/react';
import { notFound } from '../../assets/svg/404';

export const NotFound = () => {
  const [isLargerThan576] = useMediaQuery('(min-width: 576px)');
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1200] = useMediaQuery('(min-width: 1200px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <Box
      width={'100vw'}
      height={'100vh'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      overflowY={'scroll'}
    >
      <Box mb="30px">
        {notFound(isLargerThan1500, isLargerThan1200, isLargerThan756, isLargerThan576)}
      </Box>
      <Box
        width={isLargerThan1500 ? '1350px' : '76.5vw'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'start'}
      >
        <Box
          color={'white'}
          textAlign={'left'}
          fontSize={
            isLargerThan1500
              ? '86px'
              : isLargerThan1200
              ? '60px'
              : isLargerThan756
              ? '50px'
              : isLargerThan576
              ? '40px'
              : '30px'
          }
          fontFamily={'PPNeueMachina'}
          width={isLargerThan1500 ? '100%' : isLargerThan1200 ? '90%' : '100%'}
          lineHeight={
            isLargerThan1500
              ? '86px'
              : isLargerThan1200
              ? '60px'
              : isLargerThan756
              ? '50px'
              : isLargerThan576
              ? '40px'
              : '30px'
          }
          mb={'15px'}
        >
          Our journey wasn’t as glorious as we expected...
        </Box>

        <Box
          color={'#F4F3F2'}
          width={isLargerThan1500 ? '80%' : isLargerThan1200 ? '65%' : '100%'}
          textAlign={'left'}
          marginLeft={'5px'}
          fontSize={
            isLargerThan1500
              ? '24px'
              : isLargerThan1200
              ? '18px'
              : isLargerThan756
              ? '16px'
              : isLargerThan576
              ? '14px'
              : '12px'
          }
          fontFamily={'PoppinsExtraLight'}
          lineHeight={
            isLargerThan1500
              ? '36px'
              : isLargerThan1200
              ? '30px'
              : isLargerThan756
              ? '26px'
              : isLargerThan576 ? '22px' : "20px"
          }
        >
          <Box>
            It seems like we've encountered an unexpected twist in our journey
            together.
          </Box>
          <Box>But don’t worry, every adventure has its surprises.</Box>
          <Box fontFamily={'PoppinsSemiBold'}>
            Let’s find our{' '}
            <Box
              as="span"
              textDecoration={'underline'}
              cursor={'pointer'}
              onClick={() => {
                window.location.assign('/');
              }}
            >
              way back
            </Box>
            !
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
