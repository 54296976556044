export const ctaBtn = {
  fontFamily: 'PoppinsBold',
  fontSize: '14px',
  color: 'white',
  transition: 'all 0.2s ease',
  px: 3,
  py: 1,
  // mt: 5,
  w: 'fit-content',
  display: 'flex' as const,
  flexDirection: 'row' as const,
  justifyContent: 'center' as const,
  alignItems: 'center' as const,
  gap: 3,
  cursor: 'pointer',
  borderRadius: '100px',
  border: '1px solid white',
  _hover: {
    background: 'rgba(255, 255, 255, 0.2)',
    transform: 'scale(1.02)',
    border: '1px solid white',
  },
  minWidth: 'fit-content',
  whiteSpace: 'nowrap',
};
