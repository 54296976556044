import {
  Button,
  Modal,
  ModalContent,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import { ctaBtn } from '../../utils/globalStyles/GlobalStyles';

export const ConfirmModal = ({
  text = 'Are you sure?',
  accept = () => {},
  deny = () => {},
}) => {
  const { onClose, isOpen } = useDisclosure({ defaultIsOpen: true });
  const onConfirm = () => {
    accept();
    onClose();
  };

  const onDeny = () => {
    deny();
    onClose();
  };

  return (
    <>
      <Modal
        motionPreset={'slideInBottom'}
        isCentered
        onClose={() => {
          onClose();
          deny();
        }}
        isOpen={isOpen}
      >
        <ModalContent
          fontFamily={'PoppinsRegular'}
          w={'fit-content'}
          height={'120px'}
          justifyContent={'center'}
          background={'gray.800'}
          backdropFilter={'blur(10px)'}
        >
          <ModalBody>
            <Text color={'white'} mt="15px">
              {text}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent={'center'}>
            <Button
              bg={'rgba(255, 255, 255, 0.2)'}
              {...ctaBtn}
              onClick={onConfirm}
              colorScheme="blue"
              mr={3}
            >
              Yes
            </Button>
            <Button
              {...ctaBtn}
              bg={'rgba(255, 255, 255, 0.2)'}
              onClick={onDeny}
            >
              No
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
