import { useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { mainContainerFamily } from './SubFamilyStyles';
import SegmentedCircle from '../Family/SegmentedCircle';
import GraphContext from '../../context/context';

const SubFamily = ({ elements, references, selections, setSelections }) => {
  const context = useContext(GraphContext);
  const [sections, setSections]: any = useState([]);
  const [sectionsTotal, setSectionsTotal] = useState(0);
  const [selectedSection, setSelectedSection]: any = useState(null);
  const { setSelectedSubSection } = context;

  useEffect(() => {
    setSections(elements);
    setSectionsTotal(elements.reduce((acc, item) => acc + item.value, 0));
  }, [elements]);

  useEffect(() => {
    if (selections.selectedSubFamily && sections.length > 0) {
      let index;
      const searchedFamily = sections.filter((section) => {
        if (section && section?.id) {
          return section?.id === selections.selectedSubFamily;
        }
      });

      if (searchedFamily.length > 0) {
        const fam = searchedFamily[0];
        index = sections.indexOf(fam);

        if (selectedSection?.id !== fam.id) {
          handleClick(index);
          setSelectedSection(sections[index]);
          //não limpar a selected sub family faz este use effect correr infinitamente
          setSelections((prev) => {
            return {
              ...prev,
              selectedSubFamily: null,
              subFamilyClickedSection: index,
            };
          });
        } else {
          setSelections((prev) => {
            return {
              ...prev,
              selectedSubFamily: null,
              subFamilyClickedSection: index,
            };
          });
        }
      }
    }
  }, [sections, selections.selectedSubFamily]);

  useEffect(() => {
    if (selectedSection) {
      setSelectedSection(null);
    }
  }, [selections.selectedFamily]);

  const handleMouseOver = (index) => {
    const newSections: any = sections.map((section: any, i) => {
      if (i === index) {
        return { ...section, value: section.value + sectionsTotal / 60 };
      }
      return section;
    });
    setSections(newSections);
  };

  const handleMouseOut = (index) => {
    const newSections: any = sections.map((section: any, i) => {
      if (i === index) {
        return { ...section, value: section.value - sectionsTotal / 60 };
      }
      return section;
    });
    setSections(newSections);
  };

  const handleClick = (index) => {
    const clickedSection: any = sections[index];

    if (selectedSection?.id === clickedSection.id) {
      setSelectedSection(null);
      setSelectedSubSection(null);
      return;
    }

    setSelectedSection(clickedSection);
    setSelectedSubSection(clickedSection);
  };

  useEffect(() => {
    if (selectedSection) {
      references.current.graphRef.nodeColor((insideNode) => {
        if (insideNode.subfamily_id === selectedSection.id) {
          return 'white';
        } else {
          return 'rgba(255, 255, 255, 0.2)';
        }
      });
    } else {
      references.current.graphRef.nodeColor((insideNode) => {
        if (insideNode.family_id === selections.selectedFamily) {
          return 'white';
        } else {
          return 'rgba(255, 255, 255, 0.2)';
        }
      });
    }
  }, [selectedSection]);

  const totalValue = useMemo(() => {
    return sections.reduce((acc, item: any) => acc + item.value, 0);
  }, [sections]);

  const getSegmentSizes = () => {
    const sizes: any = [];

    sections.forEach((section: any) => {
      const percentage = (section.value / totalValue) * 100;
      sizes.push(percentage);
    });

    return sizes;
  };

  const segmentLabels = useMemo(() => {
    if (selectedSection) {
      return sections.map((section: any) => {
        if (section.id === selectedSection.id) {
          return section.title;
        }

        return null;
      });
    }

    return sections.map((section: any) => section.title);
  }, [selectedSection, sections]);

  return (
    <Box {...mainContainerFamily}>
      <SegmentedCircle
        segmentSizes={getSegmentSizes()}
        segmentLabels={segmentLabels}
        onClick={(index) => handleClick(index)}
        radius={190}
        clickedSection={selections.subFamilyClickedSection}
        setClickedSection={(index) => {
          setSelections((prev) => {
            return {
              ...prev,
              subFamilyClickedSection:
                selections.subFamilyClickedSection === index ? null : index,
              selectedSubFamily: null,
            };
          });
        }}
        labelOffset={50}
        labelColor={'white'}
        subFamilies={[]}
      />
    </Box>
  );
};

export { SubFamily };
