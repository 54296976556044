import { text } from 'stream/consumers';

export const roleTitle = {
  fontFamily: 'PPNeuePlainBold',
  color: 'white',
  cursor: 'pointer',
  w: 'full',
  textAlign: 'left' as const,
  transition: 'all 0.1s ease',
  mb: '-3px',
  pt: 1,
  pointerEvents: 'auto' as const,
};

export const roleFamily = {
  fontSize: '16px',
  color: 'white',
  cursor: 'pointer',
  transition: 'all 0.1s ease',
  pointerEvents: 'auto' as const,
};

export const smallCont = {
  py: 3,
  pb: 4,
  px: 5,
  // pr: 10,
  background: 'rgba(0,0,0,0.3)',
  fontFamily: 'PoppinsLight',
  borderRadius: '5px',
  backdropFilter: 'blur(25px)',
  display: 'flex' as const,
  flexDirection: 'column' as const,
  alignItems: 'flex-start' as const,
  justifyContent: 'flex-start' as const,
  gap: "15px",
  transition: 'all 0.1s ease',
  whiteSpace: 'nowrap' as const,
  pointerEvents: 'auto' as const,
};

export const expandedCont = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  w: 'full',
  gap: "15px",
  pointerEvents: 'auto' as const,
};

export const cardSection = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  gap: 4,
  w: 'full',
  border: '2px solid rgba(255,255,255,0.2)',
  borderRadius: '5px',
  p: 4,
  whiteSpace: 'normal',
  transition: 'all 0.1s ease',
  pointerEvents: 'auto' as const,
};

export const sectionTitle = {
  w: 'full',
  textAlign: 'left' as const,
  fontFamily: 'PoppinsSemiBold',
  pointerEvents: 'auto' as const,
  fontSize: '18px',
};

export const singleSkillCont = {
  display: 'flex' as const,
  flexDirection: 'row' as const,
  gap: 2,
  justifyContent: 'space-between' as const,
  alignItems: 'center' as const,
  pointerEvents: 'auto' as const,
};
