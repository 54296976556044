import React, { useContext, useEffect, useRef, useState } from 'react';
import './TijubuHome.css';
import AutoComplete from '../../components/AutoComplete/AutoComplete';
import wordList from 'word-list-json';
import {
  Box,
  Flex,
  HStack,
  Text,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { defineView } from '../../store/actions/viewActions';
import background from '/images/background.png';
import {
  cardAction,
  cardTitle,
  cardsCont,
  singleCardCont,
  singleQuestion,
} from './TijubuHomeStyles';
import { useTranslation } from 'react-i18next';
import { findTopMatches } from '../../utils/welcomeTreeHelpers';
import { ComingSoonTooltip } from '../../components/ComingSoon/ComingSoonTooltip';
import GeneralDataContext from '../../context/generalDataContext';
import { tree } from '../../utils/welcomeTree';

//rendes the first tijubu page after login
const TijubuHome = () => {
  const [value, setValue]: any = useState(null);
  const [showCards, setShowCards]: any = useState(false);
  const [cardsToShow, setCardsToShow]: any = useState([]);
  const [showTooltip, setShowTooltip]: any = useState('none');
  const [showComingSoonAfterCalculation, setShowComingSoonAfterCalculation] =
    useState(null);
  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });
  const dispatch = useDispatch();
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  const generalDataContext = useContext(GeneralDataContext);

  const { families, roles, skills } = generalDataContext;

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsRendered(true);
    }, 1000); // 1 second delay

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = getRandomInt(i + 1);
      [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

  const questions = tree.map((question) => {
    return {
      label: question?.input,
      value: question?.input,
    };
  });

  // Shuffle the questions array and take the first 3 unique elements
  const shuffledQuestions = shuffleArray([...questions]);

  const [initQuestions, setInitQuestions] = useState([
    shuffledQuestions[0],
    shuffledQuestions[1],
    shuffledQuestions[2],
  ]);
  const [questionClickFlag, setQuestionClickFlag]: any = useState(null);

  const { t } = useTranslation();

  const delayTimeout: any = useRef(null);

  useEffect(() => {
    if (delayTimeout.current) clearTimeout(delayTimeout.current);
    delayTimeout.current = setTimeout(() => {
      // if (value && value.toLowerCase().indexOf('skills') >= 0) {
      //   setShowCards('skills');
      // } else if (value && value.toLowerCase().indexOf('options') >= 0) {
      //   setShowCards('options');
      // } else {
      //   setShowCards(false);
      // }

      if (!value) {
        setCardsToShow([]);
        return;
      }

      const results = findTopMatches(value);

      setCardsToShow(results);
    }, 750);
  }, [value]);

  const getDataForClick = (card, type, index) => {
    let roleData = card.action.roleData;
    let rolesExtra = card.action.rolesExtra;
    let familyData: any;
    let skillsExtra = card.action.skillsExtra;
    let skillsFilter = card.action.filter;
    let blockRedirect = false;

    if (type === 'roles') {
      if (card?.action?.type === 'my_role_family') {
        roleData = {
          label: currentUser.role.family,
          family_id: currentUser.role.family_id,
        };

        rolesExtra = { family: currentUser.role.family_id };
      } else if (card?.action?.type === 'my_role') {
        roleData = {
          role_id: currentUser.role.role_id,
        };
      } else if (card?.action?.type === 'open_opportunities') {
        rolesExtra = { openOpps: true };
      } else if (card.action.filter && card.action.filter.length) {
        rolesExtra = card.action.filter;
      }
    }

    if (type === 'family') {
      if (card?.action?.filter === 'my_role') {
        if (!currentUser.role.family_id) {
          setShowTooltip(index);
          setShowComingSoonAfterCalculation(index);
          blockRedirect = true;
        }

        familyData = {
          family_id: currentUser.role.family_id,
        };
      } else if (card?.action.filter && card.action.filter.length) {
        let parentFamily: any;

        if (card.action.noLowerFilter) {
          parentFamily = families.find(
            (family: any) =>
              family.label.includes(card.action.filter) &&
              !family.parent_family_id,
          );
        } else {
          parentFamily = families.find(
            (family: any) =>
              family.label
                .toLowerCase()
                .includes(card.action.filter.toLowerCase()) &&
              !family.parent_family_id,
          );
        }

        let subFamily: any;

        if (!parentFamily) {
          if (card.action.noLowerFilter) {
            subFamily = families.find(
              (family: any) =>
                family.label.includes(card.action.filter) &&
                family.parent_family_id,
            );
          } else {
            subFamily = families.find(
              (family: any) =>
                family.label
                  .toLowerCase()
                  .includes(card.action.filter.toLowerCase()) &&
                family.parent_family_id,
            );
          }

          familyData = {
            family_id: subFamily?.family_id,
          };
        } else {
          familyData = {
            family_id: parentFamily?.family_id,
          };
        }

        if (!familyData.family_id) {
          setShowTooltip(index);
          setShowComingSoonAfterCalculation(index);
          blockRedirect = true;
        }
      }
    }

    if (type === 'skills') {
      if (card?.action?.type === 'my_role') {
        skillsExtra = currentUser.role.skills;
      } else if (card?.action?.type === 'skills_in_family') {
        const familyLabels = card?.action.family;

        const family = families.find((family: any) =>
          familyLabels.includes(family.label.toLowerCase()),
        );

        if (!family) {
          setShowTooltip(index);
          setShowComingSoonAfterCalculation(index);
          blockRedirect = true;
        } else {
          let familyRoles = family.roles;

          let rolesInfo = roles.filter((role: any) =>
            familyRoles.includes(role.role_id),
          );

          let skills: any = [];

          for (let role of rolesInfo) {
            for (let skill of role.skills) {
              if (!skills.find((s: any) => s.skill_id === skill.skill_id)) {
                skills.push(skill);
              }
            }
          }

          skillsExtra = skills;
        }
      } else if (card?.action?.type === 'skills_with_context') {
        let context = card?.action.context;

        let skillsInContext = skills.filter((skill: any) => {
          for (let text of context) {
            if (skill.label.toLowerCase().includes(text.toLowerCase())) {
              return true;
            }
          }

          return false;
        });

        let macros: any = [];

        for (let skill of skillsInContext) {
          if (skill.parent_skill_id) {
            const macro = skills.find(
              (s) => s.skill_id === skill.parent_skill_id,
            );
            if (!macros.find((m) => m.skill_id === macro.skill_id)) {
              macros.push(macro);
            }
          }
        }

        skillsInContext = [...macros, ...skillsInContext];

        if (!skillsInContext.length) {
          setShowTooltip(index);
          setShowComingSoonAfterCalculation(index);
          blockRedirect = true;
        } else {
          skillsExtra = skillsInContext;
        }
      } else if (card?.action?.type === 'my_role_family') {
        if (!currentUser.role.family_id) {
          setShowTooltip(index);
          setShowComingSoonAfterCalculation(index);
          blockRedirect = true;
        }

        let familyRoles = roles.filter(
          (role: any) => role.family_id === currentUser.role.family_id,
        );

        let skillsInFamilyRoles: any = [];

        for (let role of familyRoles) {
          for (let skill of role.skills) {
            if (
              !skillsInFamilyRoles.find(
                (s: any) => s.skill_id === skill.skill_id,
              )
            ) {
              skillsInFamilyRoles.push(skill);
            }
          }
        }

        skillsExtra = skillsInFamilyRoles;
      } else if (card?.action.filter && card.action.filter.length) {
        skillsFilter = card.action.filter;
      }
    }

    return {
      roleData,
      rolesExtra,
      familyData,
      skillsExtra,
      skillsFilter,
      blockRedirect,
    };
  };

  const handleCardClick = (type, card, index) => {
    if (!type) return;

    const {
      roleData,
      rolesExtra,
      familyData,
      skillsExtra,
      skillsFilter,
      blockRedirect,
    } = getDataForClick(card, type, index);

    if (blockRedirect) return;

    dispatch(
      defineView({
        showSemi: type === 'roles' || type === 'roles mine',
        showSkills: type === 'skills',
        showFamily: type === 'family',
        showSubFamily: false,
        showProgressions: type === 'progressions',
        skillsData: type === 'skills' ? card?.action?.skillsData : undefined,
        familyData: type === 'family' ? familyData : undefined,
        roleData: type === 'roles mine' ? currentUser.role : roleData,
        rolesExtra: type === 'roles' ? rolesExtra : undefined,
        skillsExtra: type === 'skills' ? skillsExtra : undefined,
        skillsFilter: type === 'skills' ? skillsFilter : undefined,
        rolesFilter: type === 'roles' ? card.filter : undefined,
        mySkillsOpen: card?.action.mySkills,
        progressionsData: undefined,
      }),
    );

    // dispatch(
    //   defineView({
    //     showSemi: type === 'roles' || type === 'roles mine',
    //     showSkills: type === 'skills',
    //     showFamily: type === 'family',
    //     showSubFamily: false,
    //     showProgressions: type === 'progressions',
    //     skillsData: hardcodedSkills,
    //     familyData: type === 'family' ? data : undefined,
    //     roleData: type === 'roles mine' ? currentUser.role : { role_id: 144 },
    //     rolesExtra: type === 'roles' ? { openOpps: true } : undefined,
    //     skillsExtra: undefined,
    //     skillsFilter: type === 'skills' ? filter : undefined,
    //     rolesFilter: type === 'roles' ? filter : undefined,
    //     progressionsData: undefined,
    //   }),
    // );

    window.location.assign('/main');
  };

  return (
    <Box
      backgroundImage={background}
      backgroundSize={'cover'}
      backgroundPosition={'center'}
      transition={'none'}
      position={'relative'}
      w={'100vw'}
      h={'100vh'}
      display={'flex'}
      justifyContent={'center'}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1920px',
          overflowY: 'scroll',
        }}
        id="tijubu-home-container-geral"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          id="tijubu-home-wrapper"
        >
          <div
            id="tijubu-home-size-container"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
              id="text-and-input-container-home"
            >
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <div
                  id="text-question-container"
                  style={{ marginBottom: '2rem' }}
                >
                  {t('welcome.title')} {currentUser?.attributes.first_name}?
                </div>

                <AutoComplete
                  dataset={wordList.sort()}
                  value={value}
                  setValue={setValue}
                  setShowCards={setShowCards}
                  cardClickFlag={questionClickFlag}
                />

                <div
                  style={{
                    position: 'absolute',
                    width: '105%',
                    height: '130%',
                    top: '-12.5%',
                    left: '-2.5%',
                    //backdropFilter: 'blur(10px)',
                    zIndex: 1,
                  }}
                ></div>
              </div>

              {(value === null || value === '') &&
                cardsToShow.length === 0 &&
                isRendered && (
                  <VStack w={'full'} mt={'70px'}>
                    <Text
                      color={'white'}
                      fontFamily={'PoppinsLight'}
                      fontSize={'12px'}
                      whiteSpace={'nowrap'}
                      pb={2}
                    >
                      {t('welcome.start_one')}{' '}
                      <Text as={'span'} fontFamily={'PoppinsBold'}>
                        {t('welcome.start_two')}
                      </Text>{' '}
                      {t('welcome.start_three')}{' '}
                      <Text as={'span'} fontFamily={'PoppinsBold'}>
                        {t('welcome.start_four')}{' '}
                      </Text>{' '}
                      {t('welcome.start_five')}{' '}
                    </Text>
                    <HStack {...cardsCont()} mt={0}>
                      {initQuestions.map((card, index) => {
                        return (
                          <Flex
                            border={'2.5px solid #F4F3F21A'}
                            key={index}
                            {...singleQuestion(
                              isLargerThan992,
                              isLargerThan1500,
                            )}
                            onClick={() => {
                              setValue(card.label);
                              setQuestionClickFlag(Date.now());
                            }}
                            position={'relative'}
                            onMouseOver={() => {
                              setShowTooltip(card?.label);
                            }}
                            onMouseLeave={() => {
                              setShowTooltip('none');
                            }}
                            w={'250px'}
                            minH={'100px'}
                          >
                            <Text
                              {...cardTitle(isLargerThan992, isLargerThan1500)}
                            >
                              {card?.label}
                            </Text>
                            {/* <Box {...cardAction(isLargerThan992, isLargerThan1500)}>
                          {card.description}
                        </Box> */}
                            {/* {!Object.keys(card.action).length &&
                        showTooltip === card.title ? (
                          <ComingSoonTooltip mousePosition={{}} />
                        ) : null} */}
                          </Flex>
                        );
                      })}
                    </HStack>
                  </VStack>
                )}

              <HStack {...cardsCont()}>
                {cardsToShow.map((card, index) => {
                  return (
                    <Flex
                      {...singleCardCont(isLargerThan992, isLargerThan1500)}
                      onClick={() => {
                        handleCardClick(card?.action?.view, card, index);
                      }}
                      position={'relative'}
                      onMouseOver={() => {
                        setShowTooltip(index);
                      }}
                      onMouseLeave={() => {
                        setShowTooltip('none');
                        setShowComingSoonAfterCalculation(null);
                      }}
                    >
                      <Text {...cardTitle(isLargerThan992, isLargerThan1500)}>
                        {card.title}
                      </Text>
                      <Box {...cardAction(isLargerThan992, isLargerThan1500)}>
                        {card.description}
                      </Box>
                      {(!Object.keys(card.action).length ||
                        showComingSoonAfterCalculation !== null) &&
                      showTooltip === index ? (
                        <ComingSoonTooltip
                          mousePosition={{}}
                          noInfo={showComingSoonAfterCalculation !== null}
                        />
                      ) : null}
                    </Flex>
                  );
                })}
              </HStack>

              {/* {showCards === 'options' && false && (
                <HStack {...cardsCont()}>
                  <Flex
                    {...singleCardCont(isLargerThan992, isLargerThan1500)}
                    onClick={() => {
                      handleCardClick('progressions');
                    }}
                  >
                    <Text {...cardTitle(isLargerThan992, isLargerThan1500)}>
                      plan your growth
                    </Text>
                    <Box {...cardAction(isLargerThan992, isLargerThan1500)}>
                      map out your career options
                    </Box>
                  </Flex>
                  <Flex
                    {...singleCardCont(isLargerThan992, isLargerThan1500)}
                    onClick={() => {
                      handleCardClick('roles');
                    }}
                  >
                    <Text {...cardTitle(isLargerThan992, isLargerThan1500)}>
                      find new opportunities
                    </Text>
                    <Box
                      {...cardAction(isLargerThan992, isLargerThan1500)}
                    ></Box>
                  </Flex>
                  <Flex
                    {...singleCardCont(isLargerThan992, isLargerThan1500)}
                    onClick={() => {
                      handleCardClick('skills');
                    }}
                  >
                    <Text {...cardTitle(isLargerThan992, isLargerThan1500)}>
                      move into a new challenge
                    </Text>
                    <Box
                      {...cardAction(isLargerThan992, isLargerThan1500)}
                    ></Box>
                  </Flex>
                </HStack>
              )} */}

              {/* {showCards === 'skills' && false && (
                <HStack {...cardsCont()}>
                  <Flex
                    {...singleCardCont(isLargerThan992, isLargerThan1500)}
                    onClick={() => {
                      handleCardClick('progressions');
                    }}
                  >
                    <Text {...cardTitle(isLargerThan992, isLargerThan1500)}>
                      {t('cards.plan')}
                    </Text>
                    <Box {...cardAction(isLargerThan992, isLargerThan1500)}>
                      {t('cards.plantxt')}
                    </Box>
                  </Flex>
                  <Flex
                    {...singleCardCont(isLargerThan992, isLargerThan1500)}
                    onClick={() => {
                      handleCardClick('roles mine');
                    }}
                  >
                    <Text {...cardTitle(isLargerThan992, isLargerThan1500)}>
                      {t('cards.explore')}
                    </Text>
                    <Box {...cardAction(isLargerThan992, isLargerThan1500)}>
                      {t('cards.exploretxt')}
                    </Box>
                  </Flex>
                  <Flex
                    {...singleCardCont(isLargerThan992, isLargerThan1500)}
                    onClick={() => {
                      handleCardClick('skills');
                    }}
                  >
                    <Text {...cardTitle(isLargerThan992, isLargerThan1500)}>
                      {t('cards.move')}
                    </Text>
                    <Box {...cardAction(isLargerThan992, isLargerThan1500)}>
                      {t('cards.movetxt')}
                    </Box>
                  </Flex>
                </HStack>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default TijubuHome;
