import i18next from 'i18next';

// Example outside of a functional component
const translateText = (key: string) => {
  return i18next.t(key);
};

export const tree = [
  {
    input: translateText('welcome_tree.start_finance_career.input'),
    title: translateText('welcome_tree.start_finance_career.family.title'),
    tags: ['career', 'finance'],
    description: translateText(
      'welcome_tree.start_finance_career.family.description',
    ),
    action: {
      view: 'family',
      filter: 'finance',
    },
  },
  {
    input: translateText('welcome_tree.start_finance_career.input'),
    title: translateText('welcome_tree.start_finance_career.skills.title'),
    description: translateText(
      'welcome_tree.start_finance_career.skills.description',
    ),
    tags: ['skills', 'finance'],
    action: {
      view: 'skills',
      type: 'skills_in_family',
      family: ['finance', 'finanças'],
    },
  },
  //TODO where should this start
  {
    input: translateText('welcome_tree.start_finance_career.input'),
    title: translateText(
      'welcome_tree.start_finance_career.progressions.title',
    ),
    description: translateText(
      'welcome_tree.start_finance_career.progressions.description',
    ),
    tags: ['roles', 'finance'],
    action: {
      view: 'progressions',
      filter: 'finance',
    },
  },
  {
    input: translateText('welcome_tree.career_options.input'),
    title: translateText('welcome_tree.career_options.progressions.title'),
    description: translateText(
      'welcome_tree.career_options.progressions.description',
    ),
    tags: ['career'],
    action: {
      view: 'progressions',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.career_options.input'),
    title: translateText('welcome_tree.career_options.roles.title'),
    description: translateText('welcome_tree.career_options.roles.description'),
    tags: ['career'],
    action: {
      view: 'roles',
      type: 'my_role_family',
    },
  },
  {
    input: translateText('welcome_tree.career_options.input'),
    title: translateText('welcome_tree.career_options.skills.title'),
    description: translateText(
      'welcome_tree.career_options.skills.description',
    ),
    tags: ['career'],
    action: {
      view: 'skills',
      type: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.roles_exist.input'),
    title: translateText('welcome_tree.roles_exist.family.title'),
    description: translateText('welcome_tree.roles_exist.family.description'),
    tags: ['roles'],
    action: {
      view: 'family',
    },
  },
  {
    input: translateText('welcome_tree.roles_exist.input'),
    title: translateText('welcome_tree.roles_exist.roles.title'),
    description: translateText('welcome_tree.roles_exist.roles.description'),
    tags: ['roles'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.skills_I_have.input'),
    title: translateText('welcome_tree.skills_I_have.roles.title'),
    description: translateText('welcome_tree.skills_I_have.roles.description'),
    tags: ['skills', 'roles'],
    action: {
      view: 'roles',
      type: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.skills_I_have.input'),
    title: translateText('welcome_tree.skills_I_have.skills.title'),
    description: translateText(
      'welcome_tree.skills_I_have.skills.description',
    ),
    tags: ['skills', 'role'],
    action: {
      view: 'skills',
      mySkills: true
    },
  },
  {
    input: translateText('welcome_tree.skills_to_progress.input'),
    title: translateText('welcome_tree.skills_to_progress.progressions.title'),
    description: translateText(
      'welcome_tree.skills_to_progress.progressions.description',
    ),
    tags: ['skills', 'career'],
    action: {
      view: 'progressions',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.skills_to_progress.input'),
    title: translateText('welcome_tree.skills_to_progress.roles.title'),
    description: translateText(
      'welcome_tree.skills_to_progress.roles.description',
    ),
    tags: ['skills', 'career'],
    action: {
      view: 'roles',
      type: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.can_i_do_my_work.input'),
    title: translateText('welcome_tree.can_i_do_my_work.title'),
    description: translateText('welcome_tree.can_i_do_my_work.description'),
    tags: ['skills', 'role'],
    action: {
      view: 'roles',
      type: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.pursue_my_dream.input'),
    title: translateText('welcome_tree.pursue_my_dream.title'),
    description: translateText('welcome_tree.pursue_my_dream.description'),
    tags: ['purpose'],
    action: {},
  },
  {
    input: translateText('welcome_tree.roles_can_i_do.input'),
    title: translateText('welcome_tree.roles_can_i_do.progressions.title'),
    description: translateText(
      'welcome_tree.roles_can_i_do.progressions.description',
    ),
    tags: ['career', 'roles'],
    action: {
      view: 'progressions',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.roles_can_i_do.input'),
    title: translateText('welcome_tree.roles_can_i_do.family.title'),
    description: translateText(
      'welcome_tree.roles_can_i_do.family.description',
    ),
    tags: ['career', 'roles'],
    action: {
      view: 'family',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.roles_can_i_do.input'),
    title: translateText(
      'welcome_tree.roles_can_i_do.open_opportunities.title',
    ),
    description: translateText(
      'welcome_tree.roles_can_i_do.open_opportunities.description',
    ),
    tags: ['career', 'roles'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.skils_on_demand.input'),
    title: translateText('welcome_tree.skils_on_demand.title'),
    description: translateText('welcome_tree.skils_on_demand.description'),
    tags: ['market', 'trends', 'skills'],
    action: {
      view: 'skills',
    },
  },
  {
    input: translateText('welcome_tree.roles_on_demand.input'),
    title: translateText('welcome_tree.roles_on_demand.skills.title'),
    description: translateText(
      'welcome_tree.roles_on_demand.skills.description',
    ),
    tags: ['market', 'trends', 'roles'],
    action: {
      view: 'skills',
    },
  },
  {
    input: translateText('welcome_tree.roles_on_demand.input'),
    title: translateText('welcome_tree.roles_on_demand.family.title'),
    description: translateText(
      'welcome_tree.roles_on_demand.family.description',
    ),
    tags: ['market', 'trends', 'roles'],
    action: {
      view: 'family',
    },
  },
  {
    input: translateText('welcome_tree.fast_progress_my_career.input'),
    title: translateText(
      'welcome_tree.fast_progress_my_career.progressions.title',
    ),
    description: translateText(
      'welcome_tree.fast_progress_my_career.progressions.description',
    ),
    tags: ['career'],
    action: {
      view: 'progressions',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.fast_progress_my_career.input'),
    title: translateText('welcome_tree.fast_progress_my_career.roles.title'),
    description: translateText(
      'welcome_tree.fast_progress_my_career.roles.description',
    ),
    tags: ['career'],
    action: {
      view: 'roles',
      type: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.fast_progress_my_career.input'),
    title: translateText(
      'welcome_tree.fast_progress_my_career.open_opportunities.title',
    ),
    description: translateText(
      'welcome_tree.fast_progress_my_career.open_opportunities.description',
    ),
    tags: ['career'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.role_skillset_broader.input'),
    title: translateText('welcome_tree.role_skillset_broader.family.title'),
    description: translateText(
      'welcome_tree.role_skillset_broader.family.description',
    ),
    tags: ['roles', 'skills'],
    action: {
      view: 'family',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.role_skillset_broader.input'),
    title: translateText(
      'welcome_tree.role_skillset_broader.open_opportunities.title',
    ),
    description: translateText(
      'welcome_tree.role_skillset_broader.open_opportunities.description',
    ),
    tags: ['roles', 'skills'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.role_skillset_broader.input'),
    title: translateText(
      'welcome_tree.role_skillset_broader.progressions.title',
    ),
    description: translateText(
      'welcome_tree.role_skillset_broader.progressions.description',
    ),
    tags: ['roles', 'skills'],
    action: {
      view: 'progressions',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.important_skill_finance.input'),
    title: translateText('welcome_tree.important_skill_finance.title'),
    description: translateText(
      'welcome_tree.important_skill_finance.description',
    ),
    tags: ['skills', 'finance'],
    action: {
      view: 'skills',
      type: 'skills_in_family',
      family: ['finance', 'finanças'],
    },
  },
  {
    input: translateText('welcome_tree.what_marketing_specialist_does.input'),
    title: translateText(
      'welcome_tree.what_marketing_specialist_does.roles.title',
    ),
    description: translateText(
      'welcome_tree.what_marketing_specialist_does.roles.description',
    ),
    tags: ['roles', 'mission', 'marketing'],
    action: {
      view: 'roles',
      filter: 'marketing',
    },
  },
  {
    input: translateText('welcome_tree.what_marketing_specialist_does.input'),
    title: translateText(
      'welcome_tree.what_marketing_specialist_does.family.title',
    ),
    description: translateText(
      'welcome_tree.what_marketing_specialist_does.family.description',
    ),
    tags: ['roles', 'mission', 'marketing'],
    action: {
      view: 'family',
      filter: 'marketing',
    },
  },
  {
    input: translateText('welcome_tree.is_there_finance_director.input'),
    title: translateText('welcome_tree.is_there_finance_director.roles.title'),
    description: translateText(
      'welcome_tree.is_there_finance_director.roles.description',
    ),
    tags: ['roles', 'mission'],
    action: {
      view: 'roles',
      filter: 'finance',
    },
  },
  {
    input: translateText('welcome_tree.is_there_finance_director.input'),
    title: translateText('welcome_tree.is_there_finance_director.family.title'),
    description: translateText(
      'welcome_tree.is_there_finance_director.family.description',
    ),
    tags: ['roles'],
    action: {
      view: 'family',
      filter: 'finance',
    },
  },
  {
    input: translateText('welcome_tree.is_there_chief_happiness.input'),
    title: translateText('welcome_tree.is_there_chief_happiness.title'),
    description: translateText(
      'welcome_tree.is_there_chief_happiness.description',
    ),
    tags: ['roles'],
    action: {
      view: 'family',
    },
  },
  {
    input: translateText('welcome_tree.good_at_treasury_management.input'),
    title: translateText('welcome_tree.good_at_treasury_management.title'),
    description: translateText(
      'welcome_tree.good_at_treasury_management.description',
    ),
    tags: ['skills', 'roles'],
    action: {
      view: 'skills',
      context: ['treasury'],
      type: 'skills_with_context',
    },
  },
  {
    input: translateText('welcome_tree.role_for_AI.input'),
    title: translateText('welcome_tree.role_for_AI.skills.title'),
    description: translateText('welcome_tree.role_for_AI.skills.description'),
    tags: ['interest', 'roles', 'skills', 'AI'],
    action: {
      view: 'skills',
      type: 'skills_with_context',
      context: [
        ' AI ',
        'Artificial Intelligence',
        ' IA ',
        'Inteligência Artificial',
        'Machine Learning',
      ],
    },
  },
  {
    input: translateText('welcome_tree.role_for_AI.input'),
    title: translateText('welcome_tree.role_for_AI.family.title'),
    description: translateText('welcome_tree.role_for_AI.family.description'),
    tags: ['interest', 'roles', 'skillls', 'AI'],
    action: {
      view: 'family',
      filter: 'AI',
      noLowerFilter: true,
    },
  },
  //TODO in wich role should this start ?
  {
    input: translateText('welcome_tree.role_for_AI.input'),
    title: translateText('welcome_tree.role_for_AI.progressions.title'),
    description: translateText(
      'welcome_tree.role_for_AI.progressions.description',
    ),
    tags: ['interest', 'roles', 'skillls', 'AI'],
    action: {
      view: 'progressions',
      filter: 'Artificial Intelligence',
    },
  },
  {
    input: translateText('welcome_tree.interest_in_paintball.input'),
    title: translateText('welcome_tree.interest_in_paintball.title'),
    description: translateText(
      'welcome_tree.interest_in_paintball.description',
    ),
    tags: ['skills'],
    action: {
      view: 'skills',
    },
  },
  {
    input: translateText('welcome_tree.move_into_role_finance.input'),
    title: translateText('welcome_tree.move_into_role_finance.family.title'),
    description: translateText(
      'welcome_tree.move_into_role_finance.family.description',
    ),
    tags: ['career', 'finance'],
    action: {
      view: 'family',
      filter: 'finance',
    },
  },
  {
    input: translateText('welcome_tree.move_into_role_finance.input'),
    title: translateText('welcome_tree.move_into_role_finance.skills.title'),
    description: translateText(
      'welcome_tree.move_into_role_finance.skills.description',
    ),
    tags: ['skills', 'finance'],
    action: {
      view: 'skills',
      type: 'skills_in_family',
      family: ['finance', 'finanças'],
    },
  },
  //TODO where should this start
  {
    input: translateText('welcome_tree.move_into_role_finance.input'),
    title: translateText(
      'welcome_tree.move_into_role_finance.progressions.title',
    ),
    description: translateText(
      'welcome_tree.move_into_role_finance.progressions.description',
    ),
    tags: ['roles', 'finance'],
    action: {
      view: 'progressions',
      filter: 'finance',
    },
  },
  {
    input: translateText('welcome_tree.good_at_customer_segmentation.input'),
    title: translateText('welcome_tree.good_at_customer_segmentation.title'),
    description: translateText(
      'welcome_tree.good_at_customer_segmentation.description',
    ),
    tags: ['customer segmentation'],
    action: {
      view: 'skills',
      context: ['customer segmentation'],
      type: 'skills_with_context',
    },
  },
  {
    input: translateText(
      'welcome_tree.roles_require_customer_segmentation.input',
    ),
    title: translateText(
      'welcome_tree.roles_require_customer_segmentation.title',
    ),
    description: translateText(
      'welcome_tree.roles_require_customer_segmentation.description',
    ),
    tags: ['customer segmentation', 'assessment'],
    action: {
      view: 'skills',
      context: ['customer segmentation'],
      type: 'skills_with_context',
    },
  },
  {
    input: translateText('welcome_tree.move_new_role.input'),
    title: translateText('welcome_tree.move_new_role.progressions.title'),
    description: translateText(
      'welcome_tree.move_new_role.progressions.description',
    ),
    tags: ['learning'],
    action: {
      view: 'progressions',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.move_new_role.input'),
    title: translateText('welcome_tree.move_new_role.skills.title'),
    description: translateText('welcome_tree.move_new_role.skills.description'),
    tags: ['learning'],
    action: {
      view: 'skills',
      type: 'my_role_family',
    },
  },
  {
    input: translateText('welcome_tree.learn_more_customer_segmentation.input'),
    title: translateText('welcome_tree.learn_more_customer_segmentation.title'),
    description: translateText(
      'welcome_tree.learn_more_customer_segmentation.description',
    ),
    tags: ['learning'],
    action: {
      view: 'skills',
      context: ['customer segmentation'],
      type: 'skills_with_context',
    },
  },
  {
    input: translateText('welcome_tree.create_culture_wellbeing.input'),
    title: translateText('welcome_tree.create_culture_wellbeing.title'),
    description: translateText(
      'welcome_tree.create_culture_wellbeing.description',
    ),
    tags: ['culture', 'well-being'],
    action: {},
  },
  {
    input: translateText('welcome_tree.improve_work_life_balance.input'),
    title: translateText('welcome_tree.improve_work_life_balance.geral.title'),
    description: translateText(
      'welcome_tree.improve_work_life_balance.geral.description',
    ),
    tags: ['work-life balance'],
    action: {},
  },
  {
    input: translateText('welcome_tree.improve_work_life_balance.input'),
    title: translateText('welcome_tree.improve_work_life_balance.roles.title'),
    description: translateText(
      'welcome_tree.improve_work_life_balance.roles.description',
    ),
    tags: ['well-being'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.be_happier_at_work.input'),
    title: translateText('welcome_tree.be_happier_at_work.geral.title'),
    description: translateText(
      'welcome_tree.be_happier_at_work.geral.description',
    ),
    tags: ['work', 'happier'],
    action: {},
  },
  {
    input: translateText('welcome_tree.be_happier_at_work.input'),
    title: translateText('welcome_tree.be_happier_at_work.roles.title'),
    description: translateText(
      'welcome_tree.be_happier_at_work.roles.description',
    ),
    tags: ['well-being'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.stop_feeling_sad_work.input'),
    title: translateText('welcome_tree.stop_feeling_sad_work.geral.title'),
    description: translateText(
      'welcome_tree.stop_feeling_sad_work.geral.description',
    ),
    tags: ['work', 'sad'],
    action: {},
  },
  {
    input: translateText('welcome_tree.stop_feeling_sad_work.input'),
    title: translateText('welcome_tree.stop_feeling_sad_work.roles.title'),
    description: translateText(
      'welcome_tree.stop_feeling_sad_work.roles.description',
    ),
    tags: ['well-being'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.improve_productivity.input'),
    title: translateText('welcome_tree.improve_productivity.geral.title'),
    description: translateText(
      'welcome_tree.improve_productivity.geral.description',
    ),
    tags: ['productivity', 'improve'],
    action: {},
  },
  {
    input: translateText('welcome_tree.improve_productivity.input'),
    title: translateText('welcome_tree.improve_productivity.roles.title'),
    description: translateText(
      'welcome_tree.improve_productivity.roles.description',
    ),
    tags: ['well-being'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.improve_wellbeing.input'),
    title: translateText('welcome_tree.improve_wellbeing.geral.title'),
    description: translateText(
      'welcome_tree.improve_wellbeing.geral.description',
    ),
    tags: ['well-being', 'improve'],
    action: {},
  },
  {
    input: translateText('welcome_tree.improve_wellbeing.input'),
    title: translateText('welcome_tree.improve_wellbeing.roles.title'),
    description: translateText(
      'welcome_tree.improve_wellbeing.roles.description',
    ),
    tags: ['well-being'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.become_more_active.input'),
    title: translateText('welcome_tree.become_more_active.geral.title'),
    description: translateText(
      'welcome_tree.become_more_active.geral.description',
    ),
    tags: ['more', 'active'],
    action: {},
  },
  {
    input: translateText('welcome_tree.become_more_active.input'),
    title: translateText('welcome_tree.become_more_active.roles.title'),
    description: translateText(
      'welcome_tree.become_more_active.roles.description',
    ),
    tags: ['well-being'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.be_more_mindful.input'),
    title: translateText('welcome_tree.be_more_mindful.geral.title'),
    description: translateText(
      'welcome_tree.be_more_mindful.geral.description',
    ),
    tags: ['more', 'mindful'],
    action: {},
  },
  {
    input: translateText('welcome_tree.be_more_mindful.input'),
    title: translateText('welcome_tree.be_more_mindful.roles.title'),
    description: translateText(
      'welcome_tree.be_more_mindful.roles.description',
    ),
    tags: ['well-being'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.roles_best_wellbeing.input'),
    title: translateText('welcome_tree.roles_best_wellbeing.geral.title'),
    description: translateText(
      'welcome_tree.roles_best_wellbeing.geral.description',
    ),
    tags: ['well-being', 'roles'],
    action: {},
  },
  {
    input: translateText('welcome_tree.roles_best_wellbeing.input'),
    title: translateText('welcome_tree.roles_best_wellbeing.roles.title'),
    description: translateText(
      'welcome_tree.roles_best_wellbeing.roles.description',
    ),
    tags: ['well-being'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.more_confident_at_work.input'),
    title: translateText('welcome_tree.more_confident_at_work.title'),
    description: translateText(
      'welcome_tree.more_confident_at_work.description',
    ),
    tags: ['confidence', 'work'],
    action: {},
  },
  {
    input: translateText('welcome_tree.departments_best_wellbeing.input'),
    title: translateText('welcome_tree.departments_best_wellbeing.geral.title'),
    description: translateText(
      'welcome_tree.departments_best_wellbeing.geral.description',
    ),
    tags: ['department', 'well-being', 'organization'],
    action: {},
  },
  {
    input: translateText('welcome_tree.departments_best_wellbeing.input'),
    title: translateText('welcome_tree.departments_best_wellbeing.roles.title'),
    description: translateText(
      'welcome_tree.departments_best_wellbeing.roles.description',
    ),
    tags: ['department'],
    action: {
      view: 'roles',
      type: 'open_opportunities',
    },
  },
  {
    input: translateText('welcome_tree.be_paid_more.input'),
    title: translateText('welcome_tree.be_paid_more.title'),
    description: translateText('welcome_tree.be_paid_more.description'),
    tags: ['learning'],
    action: {
      view: 'progressions',
      filter: 'my_role',
    },
  },
  {
    input: translateText('welcome_tree.skills_better_paid.input'),
    title: translateText('welcome_tree.skills_better_paid.title'),
    description: translateText('welcome_tree.skills_better_paid.description'),
    tags: ['skills'],
    action: {
      view: 'skills',
    },
  },
  {
    input: translateText('welcome_tree.worst_paid_skills.input'),
    title: translateText('welcome_tree.worst_paid_skills.title'),
    description: translateText('welcome_tree.worst_paid_skills.description'),
    tags: ['skills'],
    action: {
      view: 'skills',
    },
  },
];

export const synonymsEnglish = {
  career: [
    'job',
    'jobs',
    'profession',
    'professions',
    'occupation',
    'occupations',
    'work',
    'employment',
    'vocation',
    'vocations',
    'livelihood',
    'trade',
    'craft',
    'calling',
    'pursuit',
    'career',
    'careers',
    'opportunities',
    'opportunity',
  ],
  finance: [
    'finance',
    'finances',
    'financing',
    'financed',
    'financial',
    'banking',
    'investment',
    'money',
    'funds',
    'economics',
    'fiscal',
    'accounting',
    'budgeting',
    'monetary',
  ],
  purpose: [
    'purpose',
    'aim',
    'goal',
    'objective',
    'intention',
    'mission',
    'target',
    'function',
    'meaning',
    'reason',
    'motive',
    'plan',
    'purposeful',
  ],
  learning: [
    'learning',
    'study',
    'education',
    'training',
    'knowledge',
    'skill',
    'development',
    'acquisition',
    'teaching',
    'instruction',
    'scholarship',
    'lesson',
    'studies',
    'learn',
    'learner',
    'learned',
    'studying',
  ],
  leadership: [
    'guidance',
    'direction',
    'management',
    'command',
    'control',
    'authority',
    'leader',
    'leaders',
    'leading',
    'lead',
    'leaderless',
    'leaderships',
  ],
  growth: [
    'expansion',
    'development',
    'progress',
    'advancement',
    'improvement',
    'growing',
    'grown',
    'grows',
    'grow',
    'growths',
    'value',
  ],
  wellness: [
    'health',
    'well-being',
    'fitness',
    'balance',
    'mental health',
    'physical health',
    'well',
    'wellnesses',
  ],
  'work-life': [
    'work-life balance',
    'work-life harmony',
    'work-life integration',
    'worklife',
    'work/life',
    'work and life',
  ],
  mobility: [
    'movement',
    'transition',
    'flexibility',
    'adaptability',
    'job change',
    'mobilities',
    'mobile',
    'mobilization',
    'immobility',
  ],
  communication: [
    'interaction',
    'dialogue',
    'conversation',
    'correspondence',
    'exchange',
    'communicate',
    'communicating',
    'communicative',
    'communicator',
    'communications',
  ],
  transition: [
    'change',
    'shift',
    'move',
    'switch',
    'transformation',
    'conversion',
    'transitioning',
    'transitioned',
    'transitions',
    'transitory',
  ],
  organization: [
    'company',
    'business',
    'institution',
    'enterprise',
    'corporation',
    'structure',
    'organize',
    'organizing',
    'organizational',
    'organized',
    'organizations',
  ],
  role: [
    'position',
    'job',
    'function',
    'responsibility',
    'duty',
    'task',
    'roles',
    'roleplay',
    'roleplaying',
  ],
  'in-demand': [
    'sought-after',
    'high-demand',
    'desirable',
    'popular',
    'trending',
    'indemand',
    'on-demand',
    'in_demand',
  ],
  department: [
    'division',
    'section',
    'unit',
    'branch',
    'sector',
    'area',
    'office',
    'bureau',
    'agency',
    'team',
    'group',
    'subdivision',
    'function',
    'zone',
    'wing',
    'cell',
  ],
};

export const synonymsPortuguese = {
  career: [
    'emprego',
    'empregos',
    'profissão',
    'profissões',
    'ocupação',
    'ocupações',
    'trabalho',
    'emprego',
    'vocação',
    'vocações',
    'sustento',
    'comércio',
    'ofício',
    'chamado',
    'busca',
    'carreira',
    'carreiras',
    'oportunidades',
    'oportunidade',
  ],
  finance: [
    'finança',
    'finanças',
    'financiamento',
    'financiado',
    'financeiro',
    'bancário',
    'investimento',
    'dinheiro',
    'fundos',
    'economia',
    'fiscal',
    'contabilidade',
    'orçamento',
    'monetário',
  ],
  purpose: [
    'propósito',
    'objetivo',
    'meta',
    'objetivo',
    'intenção',
    'missão',
    'alvo',
    'função',
    'significado',
    'razão',
    'motivo',
    'plano',
    'intencional',
  ],
  learning: [
    'aprendizado',
    'estudo',
    'educação',
    'treinamento',
    'conhecimento',
    'habilidade',
    'desenvolvimento',
    'aquisição',
    'ensino',
    'instrução',
    'bolsa de estudos',
    'lição',
    'estudos',
    'aprender',
    'aprendiz',
    'aprendido',
    'estudando',
  ],
  leadership: [
    'orientação',
    'direção',
    'gestão',
    'comando',
    'controle',
    'autoridade',
    'líder',
    'líderes',
    'liderança',
    'liderar',
    'sem líder',
    'lideranças',
  ],
  growth: [
    'expansão',
    'desenvolvimento',
    'progresso',
    'avanço',
    'melhoria',
    'crescendo',
    'crescido',
    'cresce',
    'crescer',
    'crescimentos',
    'valor',
  ],
  wellness: [
    'saúde',
    'bem-estar',
    'condição física',
    'equilíbrio',
    'saúde mental',
    'saúde física',
    'bem',
    'bem-estares',
  ],
  'work-life': [
    'equilíbrio entre trabalho e vida pessoal',
    'harmonia entre trabalho e vida',
    'integração entre trabalho e vida',
    'vida-trabalho',
    'trabalho/vida',
    'trabalho e vida',
  ],
  mobility: [
    'movimento',
    'transição',
    'flexibilidade',
    'adaptabilidade',
    'mudança de emprego',
    'mobilidades',
    'móvel',
    'mobilização',
    'imobilidade',
  ],
  communication: [
    'interação',
    'diálogo',
    'conversa',
    'correspondência',
    'troca',
    'comunicar',
    'comunicando',
    'comunicativo',
    'comunicador',
    'comunicações',
  ],
  transition: [
    'mudança',
    'mudança',
    'movimento',
    'alternar',
    'transformação',
    'conversão',
    'transitando',
    'transitado',
    'transições',
    'transitório',
  ],
  organization: [
    'empresa',
    'negócio',
    'instituição',
    'empreendimento',
    'corporação',
    'estrutura',
    'organizar',
    'organizando',
    'organizacional',
    'organizado',
    'organizações',
  ],
  role: [
    'posição',
    'emprego',
    'função',
    'responsabilidade',
    'dever',
    'tarefa',
    'papéis',
    'jogo de papéis',
    'interpretação de papéis',
  ],
  'in-demand': [
    'cobiçado',
    'alta demanda',
    'desejável',
    'popular',
    'em tendência',
    'em demanda',
    'sob demanda',
    'em_demanda',
  ],
  department: [
    'divisão',
    'seção',
    'unidade',
    'filial',
    'setor',
    'área',
    'escritório',
    'departamento',
    'agência',
    'equipe',
    'grupo',
    'subdivisão',
    'função',
    'zona',
    'ala',
    'célula',
  ],
};
