import { Box } from '@chakra-ui/react';
import {
  progressAfterStyles,
  progressBarStyles,
  progressBeforeStyles,
  progressStyles,
} from './ProgressBarStyles';
import './ProgressBar.css';
import { useEffect, useRef } from 'react';

export const ProgressBar = ({ id, onAnimationEnd }) => {
  const progressBarRef: any = useRef(null);

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (onAnimationEnd) {
        onAnimationEnd();
      }
    };

    const progressBarElement = progressBarRef.current;
    if (progressBarElement) {
      progressBarElement.addEventListener('animationiteration', handleAnimationEnd);
    }

    return () => {
      if (progressBarElement) {
        progressBarElement.removeEventListener(
          'animationiteration',
          handleAnimationEnd,
        );
      }
    };
  }, [onAnimationEnd]);

  return (
    <div className="container">
      <div className="progress-bar">
        <div ref={progressBarRef} id={id} className="progress fill-1">
          <div className="glow"></div>
        </div>
      </div>
    </div>
  );
};
