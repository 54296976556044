const familyColours = {
  Product: 'rgba(255, 255, 255, 0.7)',
  Enablers: 'rgba(255, 255, 255, 0.7)',
  'Insurance Operations': 'rgba(255, 255, 255, 0.7)',
  'Client Support': 'rgba(255, 255, 255, 0.7)',
  'Marketing & Commercial': 'rgba(255, 255, 255, 0.7)',
  Drivers: 'rgba(255, 255, 255, 0.7)',
  Investments: 'rgba(255, 255, 255, 0.7)',
  Multiple: 'rgba(255, 255, 255, 0.7)',
  Reinsurance: 'rgba(255, 255, 255, 0.7)',
  Transversal: 'rgba(255, 255, 255, 0.7)',
};

const moveCameraToNode = (node, references) => {
  // references.current.graphRef.centerAt(
  //   node.x,
  //   node.y,
  //   2000, // ms transition duration
  // );
};

export const getCurrentRole = (selections, graphData, references) => {
  if (!selections.selectedFamily || !selections.selectedRole || !graphData) {
    return null;
  }

  const role = graphData.nodes.find(
    (role) => role.id === selections.selectedRole,
  );

  if (references.current.graphRef) {
    references.current.graphRef.linkVisibility((link) => {
      if (
        role.id === link.id ||
        role.id === link.source.id ||
        role.id === link.target.id
      ) {
        return link.isNaturalProgression;
      }

      return false;
    });

    let node = { ...role };

    references.current.graphRef.nodeColor((insideNode) => {
      if (insideNode.id === role.id) return 'red';

      if (role.id === insideNode.id) {
        node = { ...node, ...insideNode };
      }

      return familyColours[insideNode.family];
    });

    moveCameraToNode(node, references);
  }

  references.current.lastRoleSelected = role;
  return role;
};

export const onNodeClick = (
  node,
  event,
  userRole,
  references,
  isSemiCircle = false,
) => {
  // moveCameraToNode(node, references);

  references.current.graphRef.linkVisibility((link) => {
    if (
      node.id === link.id ||
      node.id === link.source.id ||
      node.id === link.target.id
    ) {
      return link.isNaturalProgression;
    }

    return false;
  });

  references.current.graphRef.nodeColor((insideNode) => {
    if (insideNode.id === node.id) return 'white';
    if (insideNode.id === node.id) return familyColours[insideNode.family];

    return familyColours[insideNode.family];
  });

  references.current.lastRoleSelected = node;
};
