import React, { useState, useRef } from 'react';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { ProgressBar } from '../../components/ProgressBar/ProgressBar'; // Assuming you have a ProgressBar component
import familyLandingPage from '/images/familyLandingPage.png';
import careersLandingPage from '/images/careersLandingPage.png';
import skillsLandingPage from '/images/skillsLandingPage.png';
import careersLandingPage2 from '/images/careersLandingPage2.png';
import {
  animatedBoxesContainer,
  boxesContainer,
  boxesDescriptionStyles,
  boxesStyles,
  boxesTitleStyles,
  imagesContainer,
  progressBarContainer,
  singleImageContainer,
} from './AnimatedBoxesStyles';
import { useTranslation } from 'react-i18next';

const AnimatedBoxes = () => {
  const [currentBox, setCurrentBox] = useState(0);
  const descriptionsRef: any = useRef([]);
  const [isLargerThan576] = useMediaQuery('(min-width: 576px)');
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan992] = useMediaQuery('(min-width: 992px)');
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)');

  const allImages = [
    familyLandingPage,
    careersLandingPage,
    careersLandingPage2,
    skillsLandingPage,
  ];

  const { t } = useTranslation();

  const handleAnimationEnd = () => {
    const nextBox = (currentBox + 1) % 4; // Cycle through the boxes
    setCurrentBox(nextBox);
  };

  return isLargerThan992 ? (
    <Box {...animatedBoxesContainer(isLargerThan992)}>
      <Box {...boxesContainer(isLargerThan992)}>
        {[
          t('landing.explore_org'),
          t('landing.map_career_options'),
          t('landing.identify_skills'),
          t('landing.apply_easy'),
        ].map((title, index) => (
          <Box key={index} {...boxesStyles(isLargerThan992, 0)}>
            <Box
              {...boxesTitleStyles(
                isLargerThan992,
                isLargerThan756,
                isLargerThan576,
              )}
            >
              {title}
            </Box>
            <Box
              ref={(el) => (descriptionsRef.current[index] = el)}
              {...boxesDescriptionStyles(
                currentBox,
                index,
                isLargerThan992,
                isLargerThan756,
                isLargerThan576,
                isLargerThan1300,
              )}
            >
              {index === 0 && t('landing.caption1')}
              {index === 1 && t('landing.caption2')}
              {index === 2 && t('landing.caption3')}
              {index === 3 && t('landing.caption4')}
            </Box>
            <Box {...progressBarContainer()}>
              {currentBox === index && (
                <ProgressBar
                  id={`progress_bar_${index}`}
                  onAnimationEnd={handleAnimationEnd}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Box {...imagesContainer()}>
        {allImages.map((image, index) => {
          return (
            <Box
              key={image}
              {...singleImageContainer(currentBox, index, isLargerThan992)}
            >
              <img src={image} alt="careerPath" />
            </Box>
          );
        })}
      </Box>
    </Box>
  ) : (
    <Box {...animatedBoxesContainer(isLargerThan992)}>
      <Box {...boxesContainer(isLargerThan992)}>
        {[
          t('landing.explore_org'),
          t('landing.map_career_options'),
          t('landing.identify_skills'),
          t('landing.apply_easy'),
        ].map((title, index) => (
          <Box key={index} {...boxesStyles(isLargerThan992, index)}>
            <Box
              key={allImages[index]}
              {...singleImageContainer(currentBox, index, isLargerThan992)}
            >
              <img src={allImages[index]} alt="careerPath" />
            </Box>
            <Box
              {...boxesTitleStyles(
                isLargerThan992,
                isLargerThan756,
                isLargerThan576,
              )}
            >
              {title}
            </Box>
            <Box
              ref={(el) => (descriptionsRef.current[index] = el)}
              {...boxesDescriptionStyles(
                currentBox,
                index,
                isLargerThan992,
                isLargerThan756,
                isLargerThan576,
                isLargerThan1300,
              )}
            >
              {index === 0 && t('landing.caption1')}
              {index === 1 && t('landing.caption2')}
              {index === 2 && t('landing.caption3')}
              {index === 3 && t('landing.caption4')}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AnimatedBoxes;
