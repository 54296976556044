export const lightningBolt = () => {
  return (
    <svg
      width="281"
      height="160"
      viewBox="0 0 281 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        style={{ mixBlendMode: 'hard-light' }}
        filter="url(#filter0_f_2525_5936)"
      >
        <path
          d="M131.938 50.5211L146.893 30.422C147.492 29.617 148.771 30.0406 148.771 31.0441V70.7316C148.771 71.3072 149.237 71.7737 149.813 71.7737H171.64C173.782 71.7737 175.009 74.2153 173.73 75.9339L133.816 129.578C133.217 130.383 131.938 129.959 131.938 128.956V89.2684C131.938 88.6929 131.471 88.2263 130.896 88.2263H109.068C106.926 88.2263 105.7 85.7847 106.978 84.0661L120.716 65.6032"
          stroke="url(#paint0_linear_2525_5936)"
          strokeWidth="10"
          strokeLinecap="round"
        />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }}>
        <path
          d="M132.229 50.5211L147.184 30.422C147.783 29.617 149.062 30.0406 149.062 31.0441V70.7316C149.062 71.3072 149.529 71.7737 150.104 71.7737H171.932C174.074 71.7737 175.301 74.2153 174.022 75.9339L134.107 129.578C133.508 130.383 132.229 129.959 132.229 128.956V89.2684C132.229 88.6929 131.763 88.2263 131.187 88.2263H109.36C107.218 88.2263 105.991 85.7847 107.27 84.0661L121.007 65.6032"
          stroke="#F4F3F2"
          strokeOpacity="0.75"
          strokeWidth="5"
          strokeLinecap="round"
        />
      </g>
      <g style={{ mixBlendMode: 'hard-light' }}>
        <path
          d="M131.938 50.5211L146.893 30.422C147.492 29.617 148.771 30.0406 148.771 31.0441V70.7316C148.771 71.3072 149.237 71.7737 149.813 71.7737H171.64C173.782 71.7737 175.009 74.2153 173.73 75.9339L133.816 129.578C133.217 130.383 131.938 129.959 131.938 128.956V89.2684C131.938 88.6929 131.471 88.2263 130.896 88.2263H109.068C106.926 88.2263 105.7 85.7847 106.978 84.0661L120.716 65.6032"
          stroke="url(#paint1_linear_2525_5936)"
          strokeWidth="5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_2525_5936"
          x="76.4492"
          y="-0.00976562"
          width="127.81"
          height="160.02"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="12.5"
            result="effect1_foregroundBlur_2525_5936"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2525_5936"
          x1="102.203"
          y1="33.7838"
          x2="212.3"
          y2="86.9348"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2525_5936"
          x1="102.203"
          y1="33.7838"
          x2="212.3"
          y2="86.9348"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.18113" stopColor="#BB62F1" />
          <stop offset="0.523151" stopColor="#EE4B60" />
          <stop offset="0.937839" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};
