import { Box, VStack, useMediaQuery } from '@chakra-ui/react';
import { alertIcon } from '../../assets/svg/alertIcon';

export const CustomPlainHeader = ({ title, subTitle, styles = {} }: any) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <Box
      fontFamily={'PPNeueMachinaPlainUltrabold'}
      fontSize={isLargerThan1500 ? '30px' : isLargerThan756 ? '20px' : '15px'}
      color={'white'}
      zIndex={25}
      alignSelf={'baseline'}
      marginTop={isLargerThan1500 ? '30px' : '20px'}
      {...styles}
    >
      {title && <Box lineHeight={'25.5px'}> {title}</Box>}
      {subTitle && (
        <Box
          fontSize={
            isLargerThan1500 ? '16px' : isLargerThan756 ? '12px' : '11px'
          }
          fontFamily={'PoppinsLight'}
          lineHeight={'13.6px'}
          marginTop={isLargerThan1500 ? '10px' : '0px'}
        >
          {subTitle}
        </Box>
      )}
    </Box>
  );
};

export const CustomPlainBody = ({ children, stackStyles = {} }: any) => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      width={'100%'}
    >
      <VStack
        w={'full'}
        maxW={'550px'}
        gap={isLargerThan1500 ? '30px' : '20px'}
        {...stackStyles}
      >
        {children}
      </VStack>
    </Box>
  );
};

export const CustomPlainError = ({ error, styles = {} }) => {
  const [isLargerThan756] = useMediaQuery('(min-width: 756px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');

  return (
    error && (
      <Box
        fontSize={isLargerThan1500 ? '20px' : isLargerThan756 ? '14px' : '12px'}
        lineHeight={
          isLargerThan1500 ? '30px' : isLargerThan756 ? '20px' : '15px'
        }
        fontFamily={'PoppinsLight'}
        display={'flex'}
        color={'white'}
        alignSelf={'baseline'}
        alignItems={isLargerThan1500 ? 'start' : 'center'}
      >
        <Box as="span">{alertIcon()}</Box>
        <Box marginLeft={'5px'}>{error}</Box>
      </Box>
    )
  );
};
