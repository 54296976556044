export const searchBar = {
  outline: 'none',
  border: 'none',
  // background: 'blue',
  color: 'white',
  borderRadius: 'none !important',
  transition: 'all 0.3s ease',
};

export const searchFocus = {
  outline: 'none !important',
  transition: 'all 0.3s ease',
};

export const searchIcon = {
  color: 'white',
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
  outline: 'none',
  transition: 'all 0.3s ease',
};

export const searchDropdownContainer = {
  position: 'absolute' as const,
  maxH: 'calc(90vh - 100px)',
  top: '48px',
  left: '-144px',
  width: '90vw',
  display: 'flex',
  flexDirection: 'column' as const,
  background: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '0px 0px 30px 30px',
  overflow: 'scroll',
  color: 'white',
  alignItems: 'flex-start',
  px: 5,
  py: 0,
  maxW: '920px',
  transition: 'all 0.5s ease',
};

export const cssProp = {
  transition: 'all 0.3s ease',

  '&::-webkit-scrollbar': {
    width: '0px',
  },
  '&::-webkit-scrollbar-track': {
    width: '0px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'none',
    borderRadius: '0px',
  },
  '&::backdropFilter': 'blur(10px)',
};

export const searchResultsCont = {
  transition: 'all 0.3s ease',

  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'flex-start',
  w: 'full',
};

export const searchResult = {
  transition: 'all 0.3s ease',

  mx: 3,
  mt: 3,
  cursor: 'pointer',
  px: 3,
  py: 2,
  borderRadius: '5px',
};

export const searchResultHover = {
  transition: 'all 0.3s ease',

  background: 'rgba(255, 255, 255, 0.1)',
};

export const searchLabel = {
  transition: 'all 0.3s ease',

  textAlign: 'left' as const,
  w: '100px',
  mr: 5,
  fontSize: '20px',
};

export const categoryCont = {
  transition: 'all 0.3s ease',

  w: 'full',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  pb: 5,
  pl: '180px',
};

export const searchCont = {
  transition: 'all 0.3s ease',

  w: 'full',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  cursor: 'text', //not working
  px: 0,
  mx: 0,
};

export const closeModal = {
  position: 'fixed' as const,
  top: '0px',
  left: '0px',
  w: '100vw',
  h: '100vh',
  zIndex: 5,
};

export const closeIcon = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.3s ease',
  borderRadius: '100%',
  cursor: 'pointer',
  color: 'white',
  background: 'transparent',
  border: '1px solid white',
  outline: 'none',
  h: '16px',
  w: '16px',
};
