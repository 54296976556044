export const rightCircle = ({ style = {} }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{...style}}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12.9007 12.1334C13.0072 12.0345 13.0072 11.8659 12.9007 11.767L10.1899 9.24979C9.88632 8.96794 9.86875 8.49339 10.1506 8.18986C10.4325 7.88632 10.907 7.86875 11.2105 8.1506L13.9214 10.6678C14.667 11.3602 14.667 12.5402 13.9214 13.2326L11.2105 15.7498C10.907 16.0316 10.4325 16.0141 10.1506 15.7105C9.86875 15.407 9.88632 14.9325 10.1899 14.6506L12.9007 12.1334Z"
        fill="#F4F3F2"
      />
    </svg>
  );
};
