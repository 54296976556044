export const mainContainerFamily = {
  fontFamily: 'PoppinsBold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
  position: 'fixed' as const,
  top: '50px',
  left: '0',
  zIndex: 25,
  transition: 'all 0.3s ease',
  flexDirection: 'column' as const,
  overflow: 'hidden',
};
