import { Box, Link, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy = () => {
  const { t } = useTranslation();

  const subTitleStyles = {
    marginTop: '50px',
    fontFamily: 'PoppinsBold',
  };

  const subParagraphStyles = {
    marginLeft: '20px',
  };

  const subSubParagraphStyles = {
    marginLeft: '60px',
  };

  const tableSquaresStyles = {
    border: '1px solid white',
    color: 'white',
    textAlign: 'center' as const,
  };

  document.body.classList.remove('hiddenBody');

  return (
    <Box
      width={'100vw'}
      marginTop={'100px'}
      color={'white'}
      display={'flex'}
      justifyContent={'center'}
      flexDirection={'column'}
      alignItems={'center'}
      gap={'40px'}
      padding={'50px'}
    >
      <Box fontFamily={'PPNeuePlainBold'} fontSize={'24px'}>
        {t('privacy_policy.title')}
      </Box>

      <Box
        display={'flex'}
        flexDirection={'column'}
        textAlign={'left'}
        width={'100%'}
        fontFamily={'PoppinsRegular'}
        gap={'10px'}
      >
        <Box>
          {t('privacy_policy.paragraph1_first')}{' '}
          <Link textDecoration={'underline'} href="https://tijubu.com">
            https://tijubu.com/
          </Link>
          {t('privacy_policy.paragraph1_second')}
        </Box>
        <Box>{t('privacy_policy.paragraph2')}</Box>
        <Box>{t('privacy_policy.paragraph3')}</Box>
        <Box>
          {t('privacy_policy.paragraph4_first')}
          <Link textDecoration={'underline'} href="https://tijubu.com">
            https://tijubu.com/
          </Link>
          {t('privacy_policy.paragraph4_second')}
        </Box>

        <Box {...subTitleStyles}>{t('privacy_policy.subtitle_1')}</Box>
        <Box>{t('privacy_policy.paragraph5')}</Box>

        <Box {...subTitleStyles}>{t('privacy_policy.subtitle_2')}</Box>
        <Box>{t('privacy_policy.paragraph6')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph1')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph2')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph3')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph4')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph5')}</Box>
        <Box {...subSubParagraphStyles}>
          {t('privacy_policy.sub_sub_paragraph1')}
        </Box>
        <Box {...subSubParagraphStyles}>
          {t('privacy_policy.sub_sub_paragraph2')}
        </Box>
        <Box {...subSubParagraphStyles}>
          {t('privacy_policy.sub_sub_paragraph3')}
        </Box>
        <Box {...subSubParagraphStyles}>
          {t('privacy_policy.sub_sub_paragraph4')}
        </Box>

        <Box {...subTitleStyles}>{t('privacy_policy.subtitle_3')}</Box>
        <Box>{t('privacy_policy.paragraph7')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph6')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph7')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph8')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph9')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph10')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph11')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph12')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph13')}</Box>
        <Box>{t('privacy_policy.paragraph8')}</Box>
        <Box>{t('privacy_policy.paragraph9')}</Box>

        <Box {...subTitleStyles}>{t('privacy_policy.subtitle_4')}</Box>
        <Box>{t('privacy_policy.paragraph10')}</Box>

        <Table marginTop={'10px'} marginBottom={'10px'}>
          <Thead border={'1px solid white'}>
            <Tr>
              <Th {...tableSquaresStyles}>
                {t('privacy_policy.table_header1')}
              </Th>
              <Th {...tableSquaresStyles}>
                {t('privacy_policy.table_header2')}
              </Th>
              <Th {...tableSquaresStyles}>
                {t('privacy_policy.table_header3')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td {...tableSquaresStyles}>{t('privacy_policy.purpose_1')}</Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.lawfulness_basis_1')}
              </Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.storage_period_1')}
              </Td>
            </Tr>
            <Tr>
              <Td {...tableSquaresStyles}>{t('privacy_policy.purpose_2')}</Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.lawfulness_basis_2')}
              </Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.storage_period_2')}
              </Td>
            </Tr>
            <Tr>
              <Td {...tableSquaresStyles}>{t('privacy_policy.purpose_3')}</Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.lawfulness_basis_3')}
              </Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.storage_period_3')}
              </Td>
            </Tr>
            <Tr>
              <Td {...tableSquaresStyles}>{t('privacy_policy.purpose_4')}</Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.lawfulness_basis_4')}
              </Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.storage_period_4')}
              </Td>
            </Tr>
            <Tr>
              <Td {...tableSquaresStyles}>{t('privacy_policy.purpose_5')}</Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.lawfulness_basis_5')}
              </Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.storage_period_5')}
              </Td>
            </Tr>
            <Tr>
              <Td {...tableSquaresStyles}>{t('privacy_policy.purpose_6')}</Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.lawfulness_basis_6')}
              </Td>
              <Td {...tableSquaresStyles}>
                {t('privacy_policy.storage_period_6')}
              </Td>
            </Tr>
          </Tbody>
        </Table>
        <Box>{t('privacy_policy.paragraph11')}</Box>

        <Box {...subTitleStyles}>{t('privacy_policy.subtitle_5')}</Box>
        <Box>{t('privacy_policy.paragraph12')}</Box>
        <Box>{t('privacy_policy.paragraph13')}</Box>
        <Box>{t('privacy_policy.paragraph14')}</Box>
        <Box>{t('privacy_policy.paragraph15')}</Box>
        <Box>{t('privacy_policy.paragraph16')}</Box>

        <Box {...subTitleStyles}>{t('privacy_policy.subtitle_6')}</Box>
        <Box>{t('privacy_policy.paragraph17')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph14')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph15')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph16')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph17')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph18')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph19')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph20')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph21')}</Box>
        <Box>{t('privacy_policy.paragraph18')}</Box>
        <Box>{t('privacy_policy.paragraph19')}</Box>
        <Box>{t('privacy_policy.paragraph20')}</Box>

        <Box {...subTitleStyles}>{t('privacy_policy.subtitle_7')}</Box>
        <Box>{t('privacy_policy.paragraph21')}</Box>
        <Box>{t('privacy_policy.paragraph22')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph22')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph23')}</Box>
        <Box {...subParagraphStyles}>{t('privacy_policy.sub_paragraph24')}</Box>
      </Box>
    </Box>
  );
};
