import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  barCont,
  innerScroll,
  label,
  percentage,
  scrollContainer,
  singleBar,
} from './TimeMachineStyles';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import GraphContext from '../../context/context';
import { useDispatch, useSelector } from 'react-redux';
import { calculateWidth, maskStyle } from './TimeMachine.service';
import { arrowDirectional } from '../../assets/svg/arrowDirectional';
import GeneralDataContext from '../../context/generalDataContext';
import { SkillHexagonRating } from '../common/SkillHexagonRating';
import { careersAPI } from '../../utils/helpers/axios';
import { addIcon } from '../../assets/svg/addIcon';
import ScrollableTextBox from '../common/ScrollableTextBox';

export const SkillsNavigator = ({
  setShowMySkills,
  setRolesScrollPosition,
}) => {
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)');
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [isLargerThan2500] = useMediaQuery('(min-width: 2500px)');

  const [showAddIcon, setShowAddIcon] = useState(false);
  const [showAddText, setShowAddText] = useState(false);
  const [showLevelSetter, setShowLevelSetter] = useState(false);
  const [currentSkillToAdd, setCurrentSkillToAdd] = useState(null);

  const context = useContext(GraphContext);
  const generalContext = useContext(GeneralDataContext);

  const [hasOverflowSkill, setHasOverflowSkill] = useState(false);
  const [scrollPositionSkill, setScrollPositionSkill] = useState(0);
  const [hasOverflowMacroSkill, setHasOverflowMacroSkill] = useState(false);
  const [scrollPositionMacroSkill, setScrollPositionMacroSkill] = useState(0);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { currentUser, view } = useSelector((state: any) => {
    return { currentUser: state.currentUser, view: state.view };
  });

  const {
    hoveredLabel,
    setHoveredLabel,
    clickedLabel,
    currentSkill,
    hoveredSubLabel,
    setHoveredSubLabel,
    clickedSubLabel,
    handleClickSkills: handleClick,
    setClickedLabel,
    setClickedSubLabel,
    setCurrentSkill,
  } = context;

  const {
    setUpdateMySkills,
    mySkills,
    skills,
    roles,
    setLastAddedSkill,
    companySkillDefaults,
  } = generalContext;

  const hideSubSkillsTimerRef: any = useRef(null);

  useEffect(() => {
    const checkOverflow = () => {
      const element: any = document.getElementById('scroll-box-2'); // Your Box selector
      const element2: any = document.getElementById('scroll-box-macro'); // Your Box selector
      const isOverflowing = element?.scrollHeight > element?.clientHeight;
      const isOverflowing2 = element2?.scrollHeight > element2?.clientHeight;
      setHasOverflowSkill(isOverflowing);
      setHasOverflowMacroSkill(isOverflowing2);
    };

    // Check overflow when the roles change or component mounts
    checkOverflow();

    // Add event listeners for resize and scroll
    const element: any = document.getElementById('scroll-box-2');
    const element2: any = document.getElementById('scroll-box-macro');
    const handleScroll = () => {
      setScrollPositionSkill(element?.scrollTop);
      setScrollPositionMacroSkill(element2?.scrollTop);
    };
    if (element) {
      element?.addEventListener('scroll', handleScroll);
    }

    if (element2) {
      element2?.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('resize', checkOverflow);

    // Cleanup
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }

      if (element2) {
        element2.removeEventListener('scroll', handleScroll);
      }

      window.removeEventListener('resize', checkOverflow);
    };
  }, [currentSkill?.subSkills]);

  const onSkillLevelSave = (score, item) => {
    setCurrentSkillToAdd({ level: score, ...item });
  };

  const itemsToDisplay = useMemo(() => {
    const filtered = skills
      .filter((skill: any) => {
        if (skill.parent_skill_id === 0) {
          return true;
        }
        const subSkills = skills.filter(
          (subSkill) => subSkill.parent_skill_id === skill.skill_id,
        );

        if (!subSkills.length) {
          return false;
        }

        return true;
      })
      .filter((skill: any) => {
        if (view.skillsExtra) {
          return view.skillsExtra.find((extra) => {
            return extra.skill_id === skill.skill_id;
          });
        }

        if (view.skillsFilter) {
          return (
            skill.name
              ?.toLowerCase()
              .includes(view.skillsFilter.toLowerCase()) ||
            skill.label?.toLowerCase().includes(view.skillsFilter.toLowerCase())
          );
        }

        return true;
      });

    if (!filtered.length && currentSkill) {
      filtered.push(currentSkill?.skills[0]);
    }

    //when we come from cards from welcome
    if (
      view.skillsExtra?.length === 2 &&
      view.skillsExtra[0].skill_id === view.skillsExtra[1].parent_skill_id &&
      !currentSkill
    ) {
      setClickedLabel(view.skillsExtra[0]);
      setClickedSubLabel(view.skillsExtra[1]);

      const subSkills = skills.filter(
        (skill) => skill.parent_skill_id === view.skillsExtra[0].skill_id,
      );

      setCurrentSkill({ skills: [view.skillsExtra[0]], subSkills });
    }

    return filtered;
  }, [skills, view.skillsExtra, view.skillsFilter, currentSkill]);

  const renderSkillLabel = (skill, subSkills, userHasSkill) => {
    return (
      hoveredLabel === skill?.label && (
        <Box
          fontFamily={'PoppinsRegular'}
          fontSize={'14px'}
          {...label}
          display={'flex'}
          alignItems={'center'}
          color={'white'}
        >
          <ScrollableTextBox text={hoveredLabel} maxW={"calc(100vw * 0.1)"} />

          {showAddIcon && !subSkills?.length && !userHasSkill ? (
            <Box
              onMouseEnter={() => setShowAddText(true)}
              onMouseLeave={() => {
                setShowAddText(false);
                setShowLevelSetter(false);
                setCurrentSkillToAdd(null);
              }}
              marginLeft={'6px'}
              display={'flex'}
              alignItems={'center'}
              fontFamily={'PoppinsLight'}
              fontSize={'10px'}
              lineHeight={'18px'}
              flexShrink={0}
            >
              {showAddText && showLevelSetter ? (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  marginLeft={'6px'}
                  marginRight={'6px'}
                  fontFamily={'PoppinsLight'}
                  fontSize={'10px'}
                  lineHeight={'18px'}
                  flexShrink={0}
                >
                  <Box marginRight={'6px'} flexShrink={0}>
                    {t('app.your_level')}?
                  </Box>
                  <SkillHexagonRating
                    item={skill}
                    onScoreChange={onSkillLevelSave}
                    initialScore={skill.level}
                    levels={companySkillDefaults}
                    pinkOutline={true}
                  />
                </Box>
              ) : null}
              <Box
                onClick={async (e) => {
                  e.stopPropagation();
                  if (!currentSkillToAdd) {
                    e.stopPropagation();
                    setShowMySkills(true);
                    setShowLevelSetter(true);
                  } else if (currentSkillToAdd) {
                    e.stopPropagation();
                    try {
                      await careersAPI.post('/updateUserSkills', {
                        userId: currentUser.attributes.user_id,
                        skill: currentSkillToAdd,
                        isAdd: true,
                      });

                      setLastAddedSkill(currentSkillToAdd);

                      setShowLevelSetter(false);
                      setCurrentSkillToAdd(null);
                      setUpdateMySkills((prev) => !prev);
                    } catch (err) {}
                  }
                }}
                display={'flex'}
                alignItems={'center'}
                flexShrink={0}
              >
                {!showLevelSetter || currentSkillToAdd ? addIcon() : null}
                {(showAddText && !showLevelSetter) || currentSkillToAdd ? (
                  <Box marginLeft={'6px'} width={'fit-content'} flexShrink={0}>
                    {t('app.add_to_my_skills')}
                  </Box>
                ) : null}
              </Box>
            </Box>
          ) : null}
        </Box>
      )
    );
  };

  const renderSubSkillLabel = (skill) => {
    return (
      hoveredSubLabel === skill?.label && (
        <Box
          fontFamily={'PoppinsRegular'}
          fontSize={'14px'}
          {...label}
          display={'flex'}
          alignItems={'center'}
          flexShrink={0}
          flexGrow={1}
        >
          <ScrollableTextBox text={hoveredSubLabel} maxW={"calc(100vw * 0.1)"} />

          {showAddIcon ? (
            <Box
              onMouseEnter={() => setShowAddText(true)}
              onMouseLeave={() => {
                setShowAddText(false);
                setShowLevelSetter(false);
                setCurrentSkillToAdd(null);
              }}
              marginLeft={'6px'}
              display={'flex'}
              alignItems={'center'}
              fontFamily={'PoppinsLight'}
              fontSize={'10px'}
              lineHeight={'18px'}
              flexShrink={0}
            >
              {showAddText && showLevelSetter ? (
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  marginLeft={'6px'}
                  marginRight={'6px'}
                  fontFamily={'PoppinsLight'}
                  fontSize={'10px'}
                  lineHeight={'18px'}
                  flexShrink={0}
                >
                  <Box marginRight={'6px'} flexShrink={0}>
                    {t('app.your_level')}?
                  </Box>
                  <SkillHexagonRating
                    item={skill}
                    onScoreChange={onSkillLevelSave}
                    initialScore={skill.level}
                    levels={companySkillDefaults}
                    pinkOutline={true}
                  />
                </Box>
              ) : null}
              <Box
                onClick={async (e) => {
                  e.stopPropagation();
                  if (!currentSkillToAdd) {
                    e.stopPropagation();
                    setShowMySkills(true);
                    setShowLevelSetter(true);
                  } else if (currentSkillToAdd) {
                    e.stopPropagation();
                    try {
                      await careersAPI.post('/updateUserSkills', {
                        userId: currentUser.attributes.user_id,
                        skill: currentSkillToAdd,
                        isAdd: true,
                      });

                      setLastAddedSkill(currentSkillToAdd);

                      setShowLevelSetter(false);
                      setCurrentSkillToAdd(null);
                      setUpdateMySkills((prev) => !prev);
                    } catch (err) {}
                  }
                }}
                display={'flex'}
                alignItems={'center'}
                flexShrink={0}
              >
                {!showLevelSetter || currentSkillToAdd ? addIcon() : null}
                {(showAddText && !showLevelSetter) || currentSkillToAdd ? (
                  <Box marginLeft={'6px'} width={'fit-content'} flexShrink={0}>
                    {t('app.add_to_my_skills')}
                  </Box>
                ) : null}
              </Box>
            </Box>
          ) : null}
        </Box>
      )
    );
  };

  const renderClickedSkillLabel = (skill) => {
    return (
      hoveredLabel !== skill?.label &&
      clickedLabel?.label === skill?.label && (
        <Box
          fontFamily={'PoppinsRegular'}
          fontSize={'14px'}
          {...label}
          display={'flex'}
          alignItems={'center'}
        >
          <ScrollableTextBox text={clickedLabel?.label} maxW={"calc(100vw * 0.1)"} />
        </Box>
      )
    );
  };

  const renderSubClickedSkillLabel = (skill) => {
    return (
      hoveredSubLabel !== skill?.label &&
      clickedSubLabel?.label === skill?.label && (
        <Box fontFamily={'PoppinsRegular'} fontSize={'14px'} {...label}>
          <ScrollableTextBox text={clickedSubLabel?.label} maxW={"calc(100vw * 0.1)"} />
        </Box>
      )
    );
  };

  const renderSubSkillsPointer = (currentSkill, subSkills) => {
    if (!hoveredLabel) return null;

    return subSkills.length && hoveredLabel === currentSkill.label ? (
      <Box marginLeft={'5px'}>
        {arrowDirectional({
          transform: '',
          color: '#F4D444',
        })}
      </Box>
    ) : null;
  };

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
      display="inline-flex" // Allows the container to grow based on content
      width="auto" // Ensures no width constraints
    >
      <Box
        {...scrollContainer(
          isLargerThan1300,
          isLargerThan1500,
          isLargerThan2500,
        )}
        left={'0'}
        //position={'absolute'}
        bottom={0}
        py="5"
        marginRight={'0px'}
        width={'auto'}
        backdropFilter={'blur(25px)'}
        background={'#0A0A0A33'}
        height={'350px'}
        marginBottom={"0px"}
      >
        <Box
          fontSize={'16px'}
          w={'full'}
          textAlign={'left'}
          fontFamily={'PoppinsBold'}
        >
          {t('app.global_skills_navigator')}
        </Box>
        <Grid
          display="inline-flex"
          gap={'20px'}
          width="auto"
          overflowY={'hidden'}
          flexGrow={1}
          overflowX={'visible'}
        >
          <GridItem overflowY={'hidden'} overflowX={'visible'} minW={'240px'}>
            <Box
              {...innerScroll}
              borderLeft={hasOverflowMacroSkill ? '1px solid white' : ''}
              h={'fit-content !important'}
              maxH={'80%'}
              overflowX={'visible'}
              id="scroll-box-macro"
              style={{
                maskImage: maskStyle(
                  'scroll-box-macro',
                  hasOverflowMacroSkill,
                  scrollPositionMacroSkill,
                ),
                WebkitMaskImage: maskStyle(
                  'scroll-box-macro',
                  hasOverflowMacroSkill,
                  scrollPositionMacroSkill,
                ),
              }}
              onWheel={(e) => {
                e.stopPropagation();
              }}
            >
              {skills?.length > 0 ? (
                itemsToDisplay.map((skill: any, index) => {
                  if (skill.name) skill.label = skill.name;

                  let widthPercentage = calculateWidth(skill, roles, true);
                  let isZeroWidth = false;

                  const userHasSkill = mySkills.find(
                    (mySkill) => mySkill.skill_id === skill.skill_id,
                  );

                  let subSkills = [];
                  if (hoveredLabel) {
                    const hoveredSkill = skills.find(
                      (skill) => skill.label === hoveredLabel,
                    );
                    subSkills = skills.filter(
                      (skill) =>
                        skill.parent_skill_id === hoveredSkill.skill_id,
                    );
                  }

                  if (widthPercentage === '0%') {
                    widthPercentage = '3%';
                    isZeroWidth = true;
                  }

                  return (
                    <Flex
                      justifyContent={'flex-start'}
                      alignItems={'center'}
                      w={'100%'}
                      cursor={'pointer'}
                      onMouseEnter={() => {
                        skill?.label && setHoveredLabel(skill?.label);
                        if (hideSubSkillsTimerRef.current) {
                          clearTimeout(hideSubSkillsTimerRef.current);
                        }
                        hideSubSkillsTimerRef.current = setTimeout(() => {
                          setClickedLabel(null);
                          setCurrentSkill(null);
                        }, 750);

                        if (!userHasSkill) {
                          setShowAddIcon(true);
                        }
                      }}
                      onClick={(e) => {
                        if (hideSubSkillsTimerRef.current) {
                          clearTimeout(hideSubSkillsTimerRef.current);
                        }
                        setRolesScrollPosition(0);
                        e.stopPropagation();
                        handleClick(skill);
                      }}
                      key={`${skill?.role_id}-${index}`}
                    >
                      <Tooltip
                        label={skill?.description}
                        background={'#0A0A0A33'}
                        backdropFilter={'blur(25px)'}
                        placement="bottom-end"
                        marginRight={'5px'}
                        fontFamily={'PoppinsRegular'}
                        fontSize={'12px'}
                      >
                        <Box
                          display={'flex'}
                          width={'100%'}
                          alignItems={'center'}
                        >
                          <Box
                            {...barCont}
                            id={`${skill.skill_id}`}
                            w={`calc(${widthPercentage} * 1.5) !important`}
                            cursor={'pointer'}
                            _hover={{
                              div: { background: 'rgba(255,255,255,1)' },
                            }}
                          >
                            <Box
                              {...singleBar}
                              w={'full'}
                              {...(skill?.label === clickedLabel?.label && {
                                background: 'white',
                                h: '12px',
                              })}
                              background={
                                isZeroWidth
                                  ? 'transparent'
                                  : 'rgba(255,255,255,0.5)'
                              }
                              border={isZeroWidth ? '1px solid #F4F3F2' : ''}
                            ></Box>
                          </Box>
                          {renderSkillLabel(skill, subSkills, userHasSkill)}
                          {renderClickedSkillLabel(skill)}
                          {subSkills?.length
                            ? renderSubSkillsPointer(skill, subSkills)
                            : null}
                        </Box>
                      </Tooltip>
                    </Flex>
                  );
                })
              ) : (
                <></>
              )}
            </Box>
          </GridItem>
          {currentSkill?.subSkills?.length ? (
            <GridItem
              overflowY={'hidden'}
              overflowX={'visible'}
              minW={'200px'}
              onMouseEnter={() => {
                if (hideSubSkillsTimerRef.current) {
                  clearTimeout(hideSubSkillsTimerRef.current);
                }
              }}
            >
              <Box
                {...innerScroll}
                borderLeft={'1px solid white'}
                h={'fit-content !important'}
                maxH={'80%'}
                overflowX={'visible'}
                width={'auto'}
                id="scroll-box-2"
                style={{
                  maskImage: maskStyle(
                    'scroll-box-2',
                    hasOverflowSkill,
                    scrollPositionSkill,
                  ),
                  WebkitMaskImage: maskStyle(
                    'scroll-box-2',
                    hasOverflowSkill,
                    scrollPositionSkill,
                  ),
                }}
                onWheel={(e) => {
                  e.stopPropagation();
                }}
              >
                {currentSkill?.subSkills?.length > 0 ? (
                  currentSkill?.subSkills.map((skill: any, index) => {
                    if (skill.name) skill.label = skill.name;

                    const userHasSkill = mySkills.find(
                      (mySkill) => mySkill.skill_id === skill.skill_id,
                    );

                    let widthPercentage = calculateWidth(skill, roles, true);
                    let isZeroWidth = false;

                    if (widthPercentage === '0%') {
                      widthPercentage = '3%';
                      isZeroWidth = true;
                    }

                    return (
                      <Flex
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        w={'100%'}
                        cursor={'pointer'}
                        onMouseEnter={() => {
                          skill?.label && setHoveredSubLabel(skill?.label);
                          if (!userHasSkill) {
                            setShowAddIcon(true);
                          }
                        }}
                        key={`${skill?.role_id}-${index}`}
                        onMouseLeave={() => setShowAddIcon(false)}
                        onClick={(e) => {
                          setRolesScrollPosition(0);
                          e.stopPropagation();
                          handleClick(skill, true);
                        }}
                        overflowX={'visible'}
                        width={'auto'}
                      >
                        <Tooltip
                          label={skill?.description}
                          background={'#0A0A0A33'}
                          backdropFilter={'blur(25px)'}
                          placement="bottom-end"
                          fontFamily={'PoppinsRegular'}
                          fontSize={'12px'}
                        >
                          <Box
                            width={'100%'}
                            display={'flex'}
                            alignItems={'center'}
                            overflowX={'visible'}
                          >
                            <Box
                              {...barCont}
                              id={`${skill.skill_id}`}
                              w={`calc(${widthPercentage} * 1.5) !important`}
                              cursor={'pointer'}
                              _hover={{
                                div: { background: 'rgba(255,255,255,1)' },
                              }}
                              position="relative"
                              overflow="visible"
                              //flexShrink={0}
                            >
                              <Box
                                {...singleBar}
                                w={'full'}
                                {...(skill?.label ===
                                  clickedSubLabel?.label && {
                                  background: 'white',
                                  h: '12px',
                                })}
                                background={
                                  isZeroWidth
                                    ? 'transparent'
                                    : 'rgba(255,255,255,0.5)'
                                }
                                border={isZeroWidth ? '1px solid #F4F3F2' : ''}
                                position="relative"
                              ></Box>
                            </Box>
                            {renderSubSkillLabel(skill)}
                            {renderSubClickedSkillLabel(skill)}
                          </Box>
                        </Tooltip>
                      </Flex>
                    );
                  })
                ) : (
                  <></>
                )}
              </Box>
            </GridItem>
          ) : null}
        </Grid>

        <Box fontFamily={'PoppinsExtraLight'} fontSize={'14px'} {...percentage}>
          {currentSkill && currentSkill?.skills && currentSkill?.skills[0] && (
            <Text>
              {t('app.skill_found')}{' '}
              {calculateWidth(
                clickedSubLabel
                  ? skills.find(
                      (skill) => skill.label === clickedSubLabel?.label,
                    )
                  : currentSkill?.skills[0],
                roles,
              )}{' '}
              {t('app.of_roles')}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};
