import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { careersAPI } from '../utils/helpers/axios';
import { useToast } from '@chakra-ui/react';

const DemoContext = React.createContext<any>(undefined as any);

const DemoContextWrapper: React.FC<any> = ({ children, isDemoOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError]: any = useState('');

  const toast = useToast();

  const sendDemoEmail = async () => {
    try {
      await careersAPI.post('/sendDemoMailChimp', {
        ...formikDemo.values,
      });

      setIsSubmitted(true);
      setError(null);
    } catch (err) {
      toast({
        title: t('landing.ambiguous_error'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const validateDemo = yup.object().shape({
    emailDemo: yup
      .string()
      .email(t('login.email_format'))
      .required(t('login.required_email')),
    organization: yup.string().required('Organization is required'),
    jobTitle: yup.string().required('Job title is required'),
    name: yup.string().required('Name is required'),
  });

  const formikDemo = useFormik({
    initialValues: {
      organization: '',
      jobTitle: '',
      emailDemo: '',
      name: '',
    },
    validationSchema: validateDemo,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regex.test(formikDemo.values.emailDemo)) {
        formikDemo.setErrors({
          ...formikDemo.errors,
          emailDemo: t('errors.invalid_email'),
        });
        return;
      }

      await sendDemoEmail();
    },
  });

  useEffect(() => {
    if (!isDemoOpen) {
      formikDemo.resetForm();
      setError('');
      setIsSubmitted(false);
    }
  }, [isDemoOpen]);

  useEffect(() => {
    if (
      Object.keys(formikDemo.errors).length === 1 &&
      formikDemo.errors.emailDemo
    ) {
      setError('Oops. It seems the email you provided is invalid.');
    } else if (Object.keys(formikDemo.errors).length !== 0) {
      setError('Oops. You forgot to fill some of the required information.');
    }
  }, [formikDemo.errors]);

  return (
    <DemoContext.Provider
      value={{
        formikDemo,
        isSubmitted,
        setIsSubmitted,
        error,
        sendDemoEmail,
        errors: formikDemo.errors,
      }}
    >
      {children}
    </DemoContext.Provider>
  );
};

export { DemoContextWrapper };

export default DemoContext;
