import {
  Box,
  Flex,
  HStack,
  Text,
  Tooltip,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cardContainer,
  cardNum,
  cardTitle,
  expandedContainer,
  expandedLearningCont,
  expandedSkillsCont,
  hideScrollBar,
  learningList,
  learningMainTitle,
  mainContainer,
  midExpandedCard,
  midExpandedCardCont,
  numCont,
  score,
  singleCardCont,
  skills,
  totalCont,
} from './ProgressionsStyle';
import { HexagonLine } from '../../assets/svg/HexagonLine';
import { calculatePercentageBySkills } from '../SemiCircle/SemiCircle.service';
import { skillLevelCounter } from '../../assets/svg/skillLevelCounter';
import { CheckedHex } from '../../assets/svg/CheckedHex';
import { Refresh } from '../../assets/svg/Refresh';
import GraphContext from '../../context/context'; // Correct import
import { defineView } from '../../store/actions/viewActions';
import { ctaBtn } from '../../utils/globalStyles/GlobalStyles';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { careersAPI } from '../../utils/helpers/axios';
import GeneralDataContext from '../../context/generalDataContext';
import { SkillHexagonRating } from '../common/SkillHexagonRating';
import { addIcon } from '../../assets/svg/addIcon';
import { MySkillsCard } from '../TimeMachine/MySkillsCard';
import ScrollableTextBox from '../common/ScrollableTextBox';
import { handleHorizontalScroll } from '../../utils/common/horizontalScroll';
import { logoUdemy } from '../../assets/svg/logoUdemy';
import { logoIese } from '../../assets/svg/logoIese';
import { MyLearningJourney } from '../TimeMachine/MyLearningJourney';
import { rightCircle } from '../../assets/svg/rightCircle';

export const Progressions = ({ references }) => {
  // set initial card on the user card

  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const [showApply, setShowApply]: any = useState({
    show: false,
    roleToApply: null,
  });
  const [currentSkillToAdd, setCurrentSkillToAdd]: any = useState([]);
  const [showMySkills, setShowMySkills] = useState(false);
  const [learningsForCards, setLearningsForCards] = useState([]);
  const [refreshLearnings, setRefreshLearnings] = useState(false);
  const [showMyLearnings, setShowMyLearnings] = useState(false);

  const generalData = useContext(GeneralDataContext);

  const {
    mySkills,
    setUpdateMySkills,
    showMySkills: showMySkillsMasterBoolean,
    setLastAddedSkill,
    companySkillDefaults,
    careerPathSkillsEditMode: editMode,
    setCareerPathSkillsEditMode: setEditMode,
    setUpdateUserLearnings,
    userLearnings,
  } = generalData;

  const { currentUser } = useSelector((state: any) => {
    return { currentUser: state.currentUser };
  });

  const containerRef: any = useRef(null);

  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const toast = useToast();

  const {
    expandedCardIndex,
    setExpandedCardIndex,
    viewCardIndex,
    setViewCardIndex,
    cards,
    openOpportunities,
  } = useContext(GraphContext);

  useEffect(() => {
    setExpandedCardIndex(null);
    if (cards.length === 1) {
      setViewCardIndex(0);
    } else {
      setViewCardIndex(cards?.length > 0 ? cards?.length - 1 : null);
    }
  }, []);

  useEffect(() => {
    if (cards.length === 1) {
      setViewCardIndex(0);
    }
    setShowMySkills(false);
    setShowMyLearnings(false);
  }, [cards]);

  useEffect(() => {
    if (showMySkillsMasterBoolean) {
      setShowMySkills(false);
      setShowMyLearnings(false);
    } else if (
      expandedCardIndex !== null &&
      !showMySkillsMasterBoolean &&
      editMode
    ) {
      setShowMySkills(true);
    }
  }, [showMySkillsMasterBoolean, expandedCardIndex, editMode]);

  useEffect(() => {
    const getLearningsForCards = async () => {
      try {
        const { data } = await careersAPI.post('/getMultipleLearnings', {
          skillIds: cards
            .map((card) => {
              return card.skills.map((skill) => skill.skill_id);
            })
            .flat(),
          companyId: currentUser.attributes.company_id,
        });

        setLearningsForCards(data);
      } catch (err) {
        console.log(err);
      }
    };

    getLearningsForCards();
  }, [cards]);

  const onSkillLevelSave = (score, item) => {
    setCurrentSkillToAdd({ ...item, level: score });
  };

  const canShowOpenOpp = (openOpp) => {
    if (!openOpp) return false;

    if (openOpp?.visibility === 'all' || !currentUser.attributes.country) {
      return true;
    }

    if (openOpp?.visibility === 'open_countries') {
      const isUserCountryPresent = openOpp?.open_location.find((open) => {
        return open?.value === currentUser.attributes.country;
      });

      return !!isUserCountryPresent;
    }

    if (openOpp?.visibility === 'international') {
      const isUserCountryPresent = openOpp?.open_location.find((open) => {
        return open?.value === currentUser.attributes.country;
      });

      return !isUserCountryPresent;
    }

    return true;
  };

  const renderSkillsPart = (card, index) => {
    const openOpp = openOpportunities.find((opp: any) => {
      return opp.role_id === card.role_id;
    });
    return (
      <Box {...expandedSkillsCont}>
        {card.skills &&
          card?.skills.map((skill, index) => {
            // Find corresponding skill in cards[0]

            const skillInComparison = {
              skills: mySkills ?? [],
            }.skills.find(
              (insideSkill) => insideSkill.skill_id === skill.skill_id,
            );

            // Determine skill level from cards[0] and current card
            const mySkillLevel = skillInComparison // my skill level
              ? skillInComparison.level
              : 0;
            const requiredSkillLevel = skill.level; // skill level required

            if (!skill?.name) return <></>;

            return (
              <Box
                cursor={'pointer'}
                {...skills}
                onClick={() => {
                  dispatch(
                    defineView({
                      showSemi: false,
                      showSkills: true,
                      showSubFamily: false,
                      showFamily: false,
                      showProgressions: false,
                      roleData: null,
                      familyData: null,
                      skillsData: skill,
                      rolesExtra: undefined,
                      skillsExtra: undefined,
                      skillsFilter: undefined,
                      rolesFilter: undefined,
                      progressionsData: undefined,
                    }),
                  );
                }}
                key={`${skill.id}-${index}`}
              >
                <Box
                  fontSize={'12px'}
                  fontFamily={'PoppinsLight'}
                  textAlign={'left'}
                  whiteSpace={'nowrap'}
                >
                  {skill?.name && skill?.name?.toLowerCase()}
                </Box>
                <Flex flexDir={'row'} alignItems={'center'}>
                  {/* Compare and display skill levels */}
                  {/* add outline white to requiredSkillLevel */}
                  {companySkillDefaults.map((level, index) => {
                    return (
                      <Tooltip
                        backdropFilter={'blur(25px)'}
                        background={'#0A0A0A33'}
                        maxW={'150px'}
                        label={
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={'10px'}
                          >
                            <Box fontFamily={'PoppinsBold'} fontSize={'10px'}>
                              {level?.name}
                            </Box>
                            <Box fontFamily={'PoppinsLight'} fontSize={'10px'}>
                              {level?.description}
                            </Box>
                          </Box>
                        }
                      >
                        <Box>
                          {skillLevelCounter(
                            mySkillLevel >= index + 1,
                            false,
                            requiredSkillLevel >= index + 1,
                          )}
                        </Box>
                      </Tooltip>
                    );
                  })}
                </Flex>
              </Box>
            );
          })}
        <Flex
          fontSize={'12px'}
          fontFamily={'PoppinsExtraLight'}
          gap={5}
          w={'full'}
          justifyContent={'flex-end'}
        >
          <Flex
            fontSize={'12px'}
            fontFamily={'PoppinsLight'}
            alignItems={'center'}
            gap={1}
          >
            {skillLevelCounter(false, false, true)} {t('app.required')}
          </Flex>
          <Flex
            fontSize={'12px'}
            fontFamily={'PoppinsLight'}
            alignItems={'center'}
            gap={1}
          >
            {skillLevelCounter(true, true, false)} {t('app.your_level')}
          </Flex>
        </Flex>
        <HStack>
          {expandedCardIndex === index && canShowOpenOpp(openOpp) && (
            <Box
              {...ctaBtn}
              fontSize={'10px'}
              fontFamily={'PoppinsSemiBold'}
              mt={5}
              height={'24px'}
              width={'90px'}
              onClick={() => {
                setShowApply({
                  show: true,
                  roleToApply: card,
                  applicationEmail: openOpportunities.find((opp: any) => {
                    return opp.role_id === card?.role_id;
                  })?.recruitment_email,
                });
              }}
            >
              <Text>{t('app.apply_now')}</Text>
            </Box>
          )}
          <Box
            {...ctaBtn}
            onClick={() => {
              setEditMode(true);
              setShowMySkills(true);
            }}
            fontSize={'10px'}
            fontFamily={'PoppinsSemiBold'}
            width={'120px'}
            mt={5}
            position={'relative'}
          >
            <Text>{t('app.check_your_skills')}</Text>
          </Box>
        </HStack>
      </Box>
    );
  };

  const renderSkillsPartEdit = (card, index) => {
    const openOpp = openOpportunities.find((opp: any) => {
      return opp.role_id === card.role_id;
    });
    return (
      <Box {...expandedSkillsCont}>
        <Box width={'100%'} display={'flex'} alignItems={'center'}>
          <Box display={'flex'} flexDirection={'column'}>
            {card.skills &&
              card?.skills.map((skill, index) => {
                // Find corresponding skill in cards[0]

                const skillInComparison = {
                  skills: mySkills ?? [],
                }.skills.find(
                  (insideSkill) => insideSkill.skill_id === skill.skill_id,
                );

                // Determine skill level from cards[0] and current card
                const mySkillLevel = skillInComparison // my skill level
                  ? skillInComparison.level
                  : 0;

                if (!skill?.name) return <></>;

                return (
                  <Box
                    cursor={'pointer'}
                    {...skills}
                    key={`${skill.id}-${index}`}
                  >
                    <Box
                      fontSize={'12px'}
                      fontFamily={'PoppinsLight'}
                      textAlign={'left'}
                      whiteSpace={'nowrap'}
                    >
                      {skill?.name && skill?.name?.toLowerCase()}
                    </Box>
                    <Flex
                      flexDir={'row'}
                      alignItems={'center'}
                      position={'relative'}
                    >
                      {/* Compare and display skill levels */}
                      {/* add outline white to requiredSkillLevel */}
                      <Box>
                        <SkillHexagonRating
                          item={skill}
                          onScoreChange={(level) => {
                            onSkillLevelSave(level, skill);
                          }}
                          initialScore={mySkillLevel}
                          disabled={
                            !!mySkills.find((mySkill) => {
                              return mySkill.skill_id === skill.skill_id;
                            })
                          }
                          levels={companySkillDefaults}
                          pinkOutline={true}
                        />
                      </Box>
                    </Flex>
                  </Box>
                );
              })}
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            marginLeft={'5px'}
            marginTop={'-7px'}
          >
            {card.skills &&
              card?.skills.map((skill, index) => {
                const isSkillToAdd =
                  currentSkillToAdd?.skill_id === skill.skill_id;

                if (!skill?.name) return <></>;

                return (
                  <Box cursor={'pointer'} {...skills}>
                    <Flex
                      flexDir={'row'}
                      alignItems={'center'}
                      position={'relative'}
                      visibility={isSkillToAdd ? 'visible' : 'hidden'}
                    >
                      <Box
                        onClick={async (e) => {
                          if (!isSkillToAdd) return;
                          e.stopPropagation();
                          if (currentSkillToAdd) {
                            e.stopPropagation();
                            try {
                              await careersAPI.post('/updateUserSkills', {
                                userId: currentUser.attributes.user_id,
                                skill: currentSkillToAdd,
                                isAdd: true,
                              });

                              setLastAddedSkill(currentSkillToAdd);

                              setCurrentSkillToAdd(null);
                              setUpdateMySkills((prev) => !prev);
                              setShowMySkills(true);
                            } catch (err) {}
                          }
                        }}
                        display={'flex'}
                        alignItems={'center'}
                        fontFamily={'PoppinsLight'}
                        fontSize={'10px'}
                        lineHeight={'18px'}
                        position={'relative'}
                      >
                        {addIcon()}

                        <Box marginLeft={'6px'} as="span" whiteSpace={'nowrap'}>
                          {t('app.add_to_my_skills')}
                        </Box>
                      </Box>
                    </Flex>
                  </Box>
                );
              })}
          </Box>
        </Box>

        <Flex
          fontSize={'12px'}
          fontFamily={'PoppinsExtraLight'}
          gap={5}
          w={'full'}
          justifyContent={'flex-end'}
        >
          <Flex
            fontSize={'12px'}
            fontFamily={'PoppinsLight'}
            alignItems={'center'}
            gap={1}
          >
            {skillLevelCounter(false, false, true)} {t('app.required')}
          </Flex>
          <Flex
            fontSize={'12px'}
            fontFamily={'PoppinsLight'}
            alignItems={'center'}
            gap={1}
          >
            {skillLevelCounter(true, true, false)} {t('app.your_level')}
          </Flex>
        </Flex>
        <HStack>
          {expandedCardIndex === index && canShowOpenOpp(openOpp) && (
            <Box
              {...ctaBtn}
              fontSize={'10px'}
              fontFamily={'PoppinsSemiBold'}
              mt={5}
              height={'24px'}
              width={'90px'}
              onClick={() => {
                setShowApply({
                  show: true,
                  roleToApply: card,
                  applicationEmail: openOpportunities.find((opp: any) => {
                    return opp.role_id === card?.role_id;
                  })?.recruitment_email,
                });
              }}
            >
              <Text>{t('app.apply_now')}</Text>
            </Box>
          )}
          <Box
            {...ctaBtn}
            onClick={() => {
              setEditMode(false);
              setCurrentSkillToAdd(null);
              setShowMySkills(false);
              setShowMyLearnings(false);
            }}
            fontSize={'10px'}
            fontFamily={'PoppinsSemiBold'}
            width={'120px'}
            mt={5}
            position={'relative'}
          >
            <Text>{t('app.conclude_assessment')}</Text>
          </Box>
        </HStack>
      </Box>
    );
  };

  const getTwoRandomIndexes = (array) => {
    if (!array.length) return [0, 0];
    if (array.length === 1) return [0, 0];
    if (array.length === 2) return [0, 1];

    const firstIndex = Math.floor(Math.random() * array.length);
    let secondIndex;

    // Keep generating the second index until it's different from the first
    do {
      secondIndex = Math.floor(Math.random() * array.length);
    } while (secondIndex === firstIndex);

    return [firstIndex, secondIndex];
  };

  const learningsForEachCard = useMemo(() => {
    return cards.map((card) => {
      const allSkillIds = card.skills.map((skill) => skill.skill_id);
      const thisCardLearnings = learningsForCards.filter((learning: any) => {
        return allSkillIds.includes(learning?.skill?.skill_id);
      });

      const thisCardLearningsFiltered = thisCardLearnings
        .filter((learning: any) => {
          const userHasSkill = mySkills.find(
            (skill) => skill.skill_id === learning?.skill?.skill_id,
          );

          if (userHasSkill) {
            for (let level of learning?.skill_level) {
              if (level > userHasSkill.level) {
                return true;
              }
            }

            return false;
          }
          return true;
        })
        .filter((learning: any) => {
          return !userLearnings.find(
            (userLearning) => userLearning.id === learning.id,
          );
        });

      if (!thisCardLearningsFiltered.length) return [];
      if (thisCardLearningsFiltered.length === 1)
        return thisCardLearningsFiltered;

      let indexes = [0, 1];

      if (thisCardLearningsFiltered.length > 2) {
        indexes = getTwoRandomIndexes(thisCardLearningsFiltered);
      }

      return [
        thisCardLearningsFiltered[indexes[0]],
        thisCardLearningsFiltered[indexes[1]],
      ];
    });
  }, [learningsForCards, cards, refreshLearnings, mySkills, userLearnings]);

  console.log(viewCardIndex);

  return (
    <>
      <Box
        {...mainContainer}
        sx={hideScrollBar}
        ref={containerRef}
        onWheel={(e) => {
          handleHorizontalScroll(e, containerRef);
        }}
      >
        <Box {...cardContainer}>
          {cards?.map((card, index) => {
            return (
              <Box
                key={`${card.id}=${index}`}
                minW={index === viewCardIndex ? '600px' : undefined}
                backdropFilter={expandedCardIndex === index ? 'blur(10px)' : ''}
              >
                <Box {...totalCont}>
                  <Box
                    {...singleCardCont}
                    borderRadius={
                      index === expandedCardIndex ? '5px 5px 0px 0px' : 'lg'
                    }
                    onClick={() => {
                      if (
                        index === viewCardIndex &&
                        index !== expandedCardIndex
                      ) {
                        setExpandedCardIndex(index);
                      } else if (
                        index === viewCardIndex &&
                        index === expandedCardIndex
                      ) {
                        setExpandedCardIndex(null);
                      } else {
                        setViewCardIndex(index);
                        setExpandedCardIndex(null);
                      }
                      setEditMode(false);
                      setShowMySkills(false);
                    }}
                    maxW={index === viewCardIndex ? '2000px' : '300px'}
                    w={'full'}
                    backdropFilter={
                      expandedCardIndex !== index ? 'blur(10px)' : ''
                    }
                  >
                    <Box {...numCont}>
                      {t('cards.step')}
                      <Text as={'span'} {...cardNum}>
                        {index}
                      </Text>
                    </Box>
                    {index === viewCardIndex && (
                      <Box {...midExpandedCardCont}>
                        <Box {...midExpandedCard}>
                          <Box
                            fontSize={isLargerThan1500 ? '24px' : '16px'}
                            {...cardTitle}
                          >
                            {card.id?.toLowerCase()}
                          </Box>
                          <Box
                            {...{
                              cursor: 'pointer',
                            }}
                            fontSize={isLargerThan1500 ? '16px' : '15px'}
                            display={'flex'}
                          >
                            <Text
                              _hover={{ transform: 'scale(1.02)' }}
                              cursor={'pointer'}
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                  defineView({
                                    showSemi: false,
                                    showSkills: false,
                                    showProgressions: false,
                                    rolesExtra: undefined,
                                    skillsExtra: undefined,
                                    showFamily: true,
                                    familyData: {
                                      family_id: card.family_id,
                                    },
                                    showSubFamily: false,
                                    skillsFilter: undefined,
                                    rolesFilter: undefined,
                                    progressionsData: null,
                                  }),
                                );
                              }}
                            >
                              {' '}
                              {card.family?.toLowerCase()}{' '}
                            </Text>
                            <Text marginLeft={'0.25em'} marginRight={'0.25em'}>
                              {card.subFamily?.toLowerCase() ? ` | ` : ''}
                            </Text>
                            <Text
                              cursor={'pointer'}
                              _hover={{ transform: 'scale(1.02)' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                  defineView({
                                    showSemi: false,
                                    showSkills: false,
                                    showProgressions: false,
                                    rolesExtra: undefined,
                                    skillsExtra: undefined,
                                    showFamily: true,
                                    showSubFamily: true,
                                    familyData: {
                                      family_id: card.subfamily_id,
                                    },
                                    skillsFilter: undefined,
                                    rolesFilter: undefined,
                                    progressionsData: null,
                                  }),
                                );
                              }}
                            >
                              {' '}
                              {card.subFamily?.toLowerCase()
                                ? `${card.subFamily?.toLowerCase()}`
                                : ''}
                            </Text>
                          </Box>
                        </Box>
                        {calculatePercentageBySkills(
                          { skills: mySkills },
                          card,
                        ) !== 100 ? (
                          <HexagonLine>
                            <Box mb={'-5px'} zIndex={20} {...score}>
                              {calculatePercentageBySkills(
                                { skills: mySkills },
                                card,
                              )}
                            </Box>
                          </HexagonLine>
                        ) : (
                          <CheckedHex />
                        )}
                      </Box>
                    )}
                  </Box>
                  {index !== cards.length - 1 && <Box>{rightCircle({})}</Box>}
                </Box>
                <Box
                  {...expandedContainer}
                  display={expandedCardIndex === index ? 'flex' : 'none'}
                  maxH={expandedCardIndex === index ? '1000px' : '0px'}
                  w={index !== cards.length - 1 ? 'calc(100% - 24px)' : '100%'}
                >
                  {!editMode
                    ? renderSkillsPart(card, index)
                    : renderSkillsPartEdit(card, index)}
                  {!editMode && learningsForEachCard[index]?.length ? (
                    <Box {...expandedLearningCont}>
                      <Box {...learningMainTitle}>
                        {t('app.suggested_learning')}
                      </Box>
                      <Box {...learningList} cursor={'pointer'}>
                        {learningsForEachCard[index].map((learning: any) => (
                          <Box
                            fontFamily={'PoppinsRegular'}
                            fontSize={'8px'}
                            textAlign={'left'}
                            display={'flex'}
                            alignItems={'center'}
                            lineHeight={'18px'}
                            gap={'15px'}
                          >
                            <Box>
                              {learning?.learning_host === 'udemy'
                                ? logoUdemy()
                                : logoIese()}
                            </Box>
                            <Box display={'flex'} flexDirection={'column'}>
                              <Box display={'flex'} width={'160px'}>
                                <Box lineHeight={'18px'}>
                                  <ScrollableTextBox
                                    text={learning?.skill?.label}
                                    maxW={'80px'}
                                    styles={{ marginLeft: '0px' }}
                                  />{' '}
                                </Box>
                                {' | '}
                                <Box lineHeight={'18px'}>
                                  <ScrollableTextBox
                                    text={`level ${learning?.skill_level?.map(
                                      (level, index) => {
                                        return `${
                                          index > 0 ? ',' : ''
                                        }${level}`;
                                      },
                                    )}`}
                                    maxW={'80px'}
                                    styles={{
                                      marginLeft: '3px',
                                      marginRight: '3px',
                                    }}
                                  />{' '}
                                </Box>{' '}
                                {' | '}
                                <Box lineHeight={'18px'}>
                                  {' '}
                                  <ScrollableTextBox
                                    text={learning?.type}
                                    maxW={'40px'}
                                    styles={{ marginLeft: '3px' }}
                                  />
                                </Box>
                              </Box>
                              <Box
                                fontSize={'10px'}
                                fontFamily={'PoppinsExtraBold'}
                                lineHeight={'18px'}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(learning.link);
                                }}
                                cursor={'pointer'}
                              >
                                <ScrollableTextBox
                                  text={learning?.title}
                                  maxW={'150px'}
                                  styles={{ marginLeft: '0px' }}
                                />{' '}
                              </Box>
                            </Box>
                            <Box
                              onClick={async () => {
                                try {
                                  await careersAPI.post('/addLearningToUser', {
                                    userId: currentUser.attributes.user_id,
                                    learningId: learning?.id,
                                  });

                                  setUpdateUserLearnings((prev) => !prev);
                                  setShowMyLearnings(true);
                                } catch (err) {
                                  console.log(err);
                                }
                              }}
                              cursor={'pointer'}
                            >
                              {addIcon()}
                            </Box>
                          </Box>
                        ))}
                        <Flex
                          cursor={'pointer'}
                          w={'full'}
                          justifyContent={'center'}
                          pt={3}
                          onClick={() => {
                            setRefreshLearnings((prev) => !prev);
                          }}
                        >
                          <Refresh />
                        </Flex>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            );
          })}
          {showMySkills && (
            <Box bottom={0} position={'relative'} color={'white'}>
              <MySkillsCard />
            </Box>
          )}
          {showMyLearnings && (
            <Box bottom={0} position={'relative'} color={'white'}>
              <MyLearningJourney />
            </Box>
          )}
        </Box>

        {showApply.show ? (
          <ConfirmModal
            text={t('landing.apply_question')}
            accept={async () => {
              try {
                await careersAPI.post('/createApplication', {
                  user_id: currentUser.attributes.user_id,
                  user_full_name:
                    currentUser.attributes.first_name +
                    ' ' +
                    currentUser.attributes.last_name,
                  user_email: currentUser.attributes.email,
                  role_id: showApply?.roleToApply?.role_id,
                  country: currentUser.attributes.country,
                  companyId: currentUser.attributes.company_id,
                  applicationEmail: showApply?.applicationEmail,
                  skillPercentageComparison: calculatePercentageBySkills(
                    { skills: mySkills },
                    showApply?.roleToApply,
                  ),
                  language: i18n.language,
                });
                setShowApply({ show: false, roleToApply: null });
                toast({
                  title: t('notifications.app_submited').replace(
                    'email_to_replace',
                    showApply?.applicationEmail,
                  ),
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
              } catch (err: any) {
                console.log(err?.response?.data);
                setShowApply({ show: false, roleToApply: null });
                if (err?.response?.data === 'Application already exists') {
                  toast({
                    title: t('notifications.app_already_exists'),
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                  });
                  return;
                }

                toast({
                  title: t('user_preferences.password_ambiguous_error'),
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              }
            }}
            deny={() => {
              setShowApply({ show: false, roleToApply: null });
            }}
          />
        ) : null}
      </Box>
    </>
  );
};
