export const alertIcon = () => {
  return (
    <svg
      width={"20"}
      height={"30"}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#F4F3F2" />
      <path
        d="M11.7773 7.11328L11.8459 6.98982C12.36 6.06451 13.2544 5.41161 14.2924 5.20402V5.20402C14.7595 5.1106 15.2405 5.1106 15.7076 5.20402V5.20402C16.7456 5.41161 17.64 6.06451 18.1541 6.98982L23.9534 17.4285C24.2299 17.9263 24.375 18.4863 24.375 19.0557V19.0557C24.375 20.9062 22.8749 22.4062 21.0245 22.4062H8.97554C7.12509 22.4062 5.625 20.9062 5.625 19.0557V19.0557C5.625 18.4863 5.77011 17.9263 6.04664 17.4285L9.14062 11.8594L10.0195 10.2773"
        stroke="#D52127"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M15 10.6875L15 14.4375"
        stroke="#D52127"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 17.25L15 17.7188"
        stroke="#D52127"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
