export const HamburgerIcon = ({ w, h }): any => {
  return (
    <svg
      width={w ?? '20'}
      height={h ?? '12'}
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2084_2427"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="12"
      >
        <rect width="20" height="1.6" fill="#F4F3F2" />
        <rect y="5" width="20" height="1.6" fill="#F4F3F2" />
        <rect y="10" width="20" height="1.6" fill="#F4F3F2" />
      </mask>
      <g mask="url(#mask0_2084_2427)">
        <circle
          cx="10"
          cy="6"
          r="9"
          fill="#F4F3F2"
          stroke="#F4F3F2"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
