export const animatedBoxesContainer = (isLargerThan992) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: isLargerThan992 ? ('row' as const) : ('column' as const),
    position: 'relative' as const,
    justifyContent: 'space-between',
  };
};

export const boxesContainer = (isLargerThan992) => {
  return {
    position: 'relative' as const,
    display: 'flex',
    flexDirection: 'column' as const,
    width: isLargerThan992 ? '30%' : '100%',
    marginRight: '1rem',
    marginTop: '2%',
  };
};

export const boxesStyles = (isLargerThan992, index) => {
  return {
    fontSize: '18px',
    textAlign: 'left' as const,
    height: 'auto',
    color: 'white',
    marginBottom: index === 3 ? 0 : isLargerThan992 ? '20%' : '5%',
  };
};

export const boxesTitleStyles = (
  isLargerThan992,
  isLargerThan756,
  isLargerThan576,
) => {
  return {
    fontFamily: 'PoppinsBold',
    marginTop: isLargerThan992 ? 0 : '1rem',
    fontSize: isLargerThan992
      ? '16px'
      : isLargerThan756
        ? '16px'
        : isLargerThan576
          ? '14px'
          : '14px',
  };
};

export const boxesDescriptionStyles = (
  currentBox,
  index,
  isLargerThan992,
  isLargerThan756,
  isLargerThan576,
  isLargerThan1300,
) => {
  return {
    marginTop: '0.5rem',
    transition: 'height 0.5s ease, visibility 0.5s ease, opacity 0.5s ease',
    height: isLargerThan1300
      ? currentBox === index
        ? '85px'
        : '0px'
      : isLargerThan992
        ? currentBox === index
          ? '92px'
          : '0px'
        : '85px',
    opacity: isLargerThan992 ? (currentBox === index ? 1 : 0) : 1,
    fontFamily: 'PoppinsExtraLight',
    visibility: isLargerThan992
      ? currentBox === index
        ? ('visible' as const)
        : ('hidden' as const)
      : ('visible' as const),
    overflow: 'hidden',
    width: '92%',
    fontSize: isLargerThan1300
      ? '16px'
      : isLargerThan992
        ? '15.5px'
        : isLargerThan756
          ? '16px'
          : isLargerThan576
            ? '14px'
            : '14px',
  };
};

export const progressBarContainer = () => {
  return {
    h: '20px',
    marginTop: '0.5rem',
    width: '90%',
  };
};

export const imagesContainer = () => {
  return {
    marginTop: '3rem',
    width: '70%',
  };
};

export const singleImageContainer = (currentBox, index, isLargerThan992) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    opacity: isLargerThan992 ? (currentBox === index ? 1 : 0) : 1,
    visibility: isLargerThan992
      ? currentBox === index
        ? ('visible' as const)
        : ('hidden' as const)
      : ('visible' as const),
    height: isLargerThan992 ? (currentBox === index ? 'auto' : '0px') : 'auto',
    transition: 'visibility 1s ease, opacity 1s ease',
  };
};
