import { textDecoration } from '@chakra-ui/react';

export const bannerCont = {
  position: 'fixed' as const,
  bottom: '0',
  left: '0',
  width: '100%',
  bg: 'rgba(0,0,0,0.3)',
  backdropFilter: 'blur(10px)',
  color: 'white',
  px: 20,
  py: 10,
  zIndex: 40,
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'PoppinsLight',
};

export const bannerText = {
  fontSize: 'xl',
  textAlign: 'left' as const,
};

export const bannerButton = {
  bg: 'transparent',
  fontSize: 'sm',
};

export const bannerAltButton = {
  textDecoration: 'underline' as const,
  color: 'white',
  cursor: 'pointer',
  fontSize: 'md',
};
