import { Box, Flex } from '@chakra-ui/react';

export const UserDiamond = ({
  position,
  isSelected,
  isRelative,
  w,
  h,
  hideCheck,
  score,
}: any) => {
  // Default width and height values
  const defaultWidth = 56;
  const defaultHeight = 56;

  // Use provided width and height or fallback to defaults
  const width = w || defaultWidth;
  const height = h || defaultHeight;

  return (
    <Flex
      position={isRelative ? 'relative' : 'absolute'}
      top={`${position?.y}px`}
      left={`${position?.x}px`}
      justifyContent={'center'}
      alignItems={'center'}
      cursor={'pointer'}
      transition={'all 0.1s ease'}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
      >
        <g filter="url(#filter0_f_2925_5301)">
          <rect
            y="28.2842"
            width="40"
            height="40"
            rx="5"
            transform="rotate(-45 0 28.2842)"
            fill={!isSelected ? '#EE4B60' : '#F4F3F2'}
            fillOpacity="0.4"
          />
        </g>
        <rect
          y="28.2842"
          width="40"
          height="40"
          rx="5"
          transform="rotate(-45 0 28.2842)"
          stroke="#EE4B60"
          strokeWidth="2.5"
        />
        {!hideCheck && (
          <path
            d="M38 21L24.3192 35.5157L18.7282 29.6989"
            stroke="#F4F3F2"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        )}
        <defs>
          <filter
            id="filter0_f_2925_5301"
            x="0.0703125"
            y="0.0708008"
            width="56.4277"
            height="56.4268"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="1"
              result="effect1_foregroundBlur_2925_5301"
            />
          </filter>
        </defs>
      </svg>
      {score !== null ? (
        <Box position={'absolute'} color={'white'} zIndex={2}>
          {score}
        </Box>
      ) : null}
    </Flex>
  );
};

export const UserDiamondLegend = ({ isLargerThan1500 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isLargerThan1500 ? '20px' : '18px'}
      height={isLargerThan1500 ? '20px' : '18px'}
      viewBox="0 0 30 30"
      fill="none"
    >
      <g filter="url(#filter0_f_2925_8613)">
        <rect
          y="15"
          width="21.2132"
          height="21.2132"
          rx="5"
          transform="rotate(-45 0 15)"
          fill="#F4F3F2"
          fill-opacity="0.4"
        />
      </g>
      <rect
        y="15"
        width="21.2132"
        height="21.2132"
        rx="5"
        transform="rotate(-45 0 15)"
        stroke="#EE4B60"
        stroke-width="2.5"
      />
      <defs>
        <filter
          id="filter0_f_2925_8613"
          x="0.0703125"
          y="0.0712891"
          width="29.8594"
          height="29.8574"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="1"
            result="effect1_foregroundBlur_2925_8613"
          />
        </filter>
      </defs>
    </svg>
  );
};
