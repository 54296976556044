import { DEFINE_LOCATION } from '../actions/locationActions';

const initialState = null;

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEFINE_LOCATION:
      return action.payload;
    default:
      return state;
  }
};

export { locationReducer };
