export const infoIcon = () => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.5" cy="4.5" r="4.5" fill="#F4F3F2" fill-opacity="0.16" />
      <path
        d="M4.5 6.4286L4.5 4.01788"
        stroke="#F4F3F2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 2.20985L4.5 1.90851"
        stroke="#F4F3F2"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
