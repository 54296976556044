// PrivateRoute.js
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import { useMobileDetect } from '../useMobile';
import useSize from '../useSize';

const PrivateRoute = ({ children }) => {
  const { currentUser, location } = useSelector((state: any) => {
    return { currentUser: state.currentUser, location: state.location };
  });
  const isMobile = useMobileDetect();
  const windowSize = useSize();

  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser && !isMobile) {
      navigate('/');
    }
  }, [isMobile]);

  const isSmallerThan992 = useRef(false);

  useEffect(() => {
    if ((isMobile || windowSize.width < 992) && currentUser) {
      console.log('STIll here');
      navigate('/comingSoon');
      isSmallerThan992.current = true;
    } else if (isSmallerThan992.current && currentUser) {
      console.log('location');
      navigate(location);
      isSmallerThan992.current = false;
    }
  }, [isMobile, currentUser, windowSize]);

  return (
    <>
      {currentUser ? (
        children
      ) : (
        <Box
          w={'100vw'}
          h={'100vh'}
          position={'fixed'}
          top={'0'}
          left={'0'}
          background={'black'}
        ></Box>
      )}
    </>
  );
};

export default PrivateRoute;
