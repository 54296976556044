export const logoIese = () => {
  return (
    <svg
      width="18"
      height="36"
      viewBox="0 0 18 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5342_7706)">
        <path
          d="M11.9744 27.8678C12.4882 27.687 12.9847 27.4594 13.4573 27.1883V17.5333H11.9744V27.8678ZM14.358 26.6021C14.8798 26.2124 15.3581 25.7678 15.7849 25.2757V17.5333H14.358V26.6021ZM9.42539 28.364C9.98916 28.3358 10.5399 28.2552 11.0745 28.1298V17.5333H9.42539V28.364ZM16.685 24.05C17.5466 22.6367 18.0018 21.012 18 19.356V17.5336H16.685V24.0502V24.05ZM7.05458 7.63672H8.52264V16.8035H7.05458V7.63672ZM4.72754 7.63672H6.15643V16.8035H4.72754V7.63672ZM2.34419 7.63672H3.8271V16.8035H2.34419V7.63672ZM1.44195 7.63672H1.12473C0.502838 7.63749 -0.000767204 8.14313 8.77421e-07 8.76627V16.8035H1.44195V7.63672ZM4.492 21.6149C3.69627 21.6149 3.05005 20.9692 3.05005 20.1739C3.05005 19.3786 3.69601 18.7311 4.492 18.7311C5.28799 18.7311 5.9319 19.3776 5.9319 20.1739C5.93139 20.9702 5.28671 21.6154 4.492 21.6149ZM4.492 25.4684C3.69601 25.4684 3.05082 24.8222 3.05005 24.0246C3.05005 23.2283 3.69601 22.5826 4.492 22.5826C5.28799 22.5826 5.9319 23.2283 5.9319 24.0246C5.93215 24.8217 5.28748 25.4679 4.49226 25.4684H4.492ZM8.77421e-07 17.5333V19.3558C8.77421e-07 24.1752 3.77487 28.1128 8.52264 28.3609V17.5331H8.77421e-07V17.5333ZM13.4873 11.6921C12.6923 11.6926 12.0476 11.0472 12.0474 10.2509V10.2501C12.0474 9.45226 12.6915 8.80629 13.4873 8.80629C14.283 8.80629 14.9254 9.45201 14.9254 10.2501C14.9261 11.0459 14.283 11.6914 13.4888 11.6921H13.4873ZM13.4873 15.4902C12.6923 15.4902 12.0479 14.8445 12.0479 14.0479C12.0479 13.2514 12.6923 12.6057 13.4873 12.6057C14.2815 12.6057 14.9254 13.2504 14.9254 14.0462C14.9254 14.0469 14.9254 14.0477 14.9254 14.0485C14.9261 14.844 14.283 15.4895 13.4891 15.4902H13.4873ZM16.8755 7.63672H9.42488V16.8035H18V8.76627C17.9997 8.14211 17.4954 7.63672 16.875 7.63672"
          fill="#FF0000"
        />
      </g>
      <defs>
        <clipPath id="clip0_5342_7706">
          <rect
            width="18"
            height="20.7273"
            fill="white"
            transform="translate(0 7.63672)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
