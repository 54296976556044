import {
  Box,
  FormControl,
  FormLabel,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import { TextInput } from '../../components/TextInput/TextInput';
import { useContext } from 'react';
import LoginContext from '../../context/loginContext';
import {
  CustomModalBody,
  CustomModalButtonSection,
  CustomModalError,
  CustomModalHeader,
  CustomModalInput,
  CustomModalStartText,
} from '../../components/ModelTemplate/ModalTemplateComponents';
import { useTranslation } from 'react-i18next';
import { loginAPI } from '../../utils/helpers/axios';

export const ForgotPassword = () => {
  const [isLargerThan1500] = useMediaQuery('(min-width: 1500px)');
  const loginContext = useContext(LoginContext);
  const { formik, step, error, setForgotPasswordToken } = loginContext;

  const { t, i18n } = useTranslation();
  const toast = useToast();

  const renderWrapper = () => {
    return (
      <Box
        width={isLargerThan1500 ? '598px' : '510px'}
        gap={isLargerThan1500 ? '30px' : '20px'}
        display={'flex'}
        flexDirection={'column'}
      >
        <CustomModalHeader
          title={
            step < 3
              ? t('landing.forgot_password')
              : t('landing.reset_password')
          }
          subTitle={t('landing.got_your_back')}
        />
        <CustomModalError error={error} />
        <CustomModalBody>
          {step === 1 && renderGetEmailStep()}
          {step === 2 && renderConfirmCodeStep()}
          {step === 3 && renderSetPasswordStep()}
          <CustomModalButtonSection
            onClick={() => {
              formik.handleSubmit();
            }}
            text={t('landing.lets_go')}
            width={isLargerThan1500 ? '157px' : '105px'}
            height={isLargerThan1500 ? '66px' : '50px'}
          />
        </CustomModalBody>
      </Box>
    );
  };

  const renderGetEmailStep = () => {
    return (
      <CustomModalInput
        label={t('user_preferences.email')}
        name={'email'}
        formik={formik}
      />
    );
  };

  const renderConfirmCodeStep = () => {
    return (
      <>
        {!error ? (
          <>
            <CustomModalStartText text={t('landing.verification_code_sent')} />
          </>
        ) : (
          <CustomModalStartText
            text={
              <>
                {t('landing.verification_code_resend_part1')}{' '}
                <Box
                  textDecoration={'underline'}
                  cursor={'pointer'}
                  as="span"
                  onClick={async () => {
                    try {
                      const { data } = await loginAPI.post('/resendCode', {
                        email: formik.values.email,
                        language: i18n.language,
                        isForgetPassword: true,
                      });

                      setForgotPasswordToken(data.token);

                      toast({
                        title: t('notifications.code_sent'),
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                      });
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  {t('landing.verification_code_resend_part2')}
                </Box>{' '}
                {t('landing.verification_code_resend_part3')}
              </>
            }
          />
        )}

        <CustomModalInput
          formik={formik}
          label={t('landing.verification_code')}
          name={'code'}
        />
      </>
    );
  };

  const renderSetPasswordStep = () => {
    return (
      <>
        <FormControl>
          <FormLabel
            color={'white'}
            htmlFor="password"
            fontFamily={'PoppinsLight'}
            fontSize={isLargerThan1500 ? '18px' : '14px'}
          >
            {t('landing.new_password')}
          </FormLabel>
          <TextInput
            onChange={formik.handleChange}
            props={{
              name: 'password',
              value: formik.values.password,
              type: 'password',
              noForgot: true,
            }}
            variation="login"
          />
        </FormControl>
      </>
    );
  };

  return renderWrapper();
};
