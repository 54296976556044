import React, { useState, useEffect } from 'react';
import { Box, Button, Flex, HStack, Text } from '@chakra-ui/react';
import {
  bannerAltButton,
  bannerButton,
  bannerCont,
  bannerText,
} from './CookieBannerStyles';
import { ctaBtn } from '../../utils/globalStyles/GlobalStyles';
import { useTranslation } from 'react-i18next';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem('cookieAccepted');
    if (!isCookieAccepted) {
      setIsVisible(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Flex {...bannerCont}>
      <Text {...bannerText} display="inline-block" mr="4">
        {t('landing.no_cookies')}
      </Text>
      <Box
        onClick={acceptCookies}
        {...ctaBtn}
        {...bannerButton}
        bg={'rgba(255, 255, 255, 0.2)'}
      >
        {t('landing.close')}
      </Box>
    </Flex>
  );

  return (
    <Flex {...bannerCont}>
      <Text {...bannerText} display="inline-block" mr="4">
        Our journey starts with your privacy! We use cookies to improve your
        experience. Are you ok with that?
      </Text>
      <HStack gap={5}>
        <Box
          onClick={acceptCookies}
          {...ctaBtn}
          {...bannerButton}
          bg={'rgba(255, 255, 255, 0.2)'}
        >
          accept cookies
        </Box>
        <Box {...ctaBtn} {...bannerButton}>
          manage cookies
        </Box>
        <Text {...bannerAltButton}>know more</Text>
      </HStack>
    </Flex>
  );
};

export default CookieBanner;
